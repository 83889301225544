import React, { useEffect, useState } from "react";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import { BsTrash } from "react-icons/bs";
import TableSkeleton from "../../common/TableSkeleton";
import "./Table.css";
import { AiOutlineEye } from "react-icons/ai";
import { useGetNoteByPatientQuery } from "../../../utils/api/note/note.api";
import { CustomPagination } from "../../common/CustomPagination";
import { formatDate, getName, showModal } from "../../../utils/Utils";
import { IUser } from "../../../utils/api/user/user.type";
import { Note } from "../../../utils/api/note/note.type";
import ShowNoteRdvModal from "../Modals/ShowNoteRdvModal";
import { UseDeleteNote } from "../rdvMedecin/form/UseAddNoteForm";
import { MdOutlineModeEdit } from "react-icons/md";
import { IRdvs } from "../../../utils/api/rdvs/rdvs.type";
import AddNoteModal from "../../patient/Modal/AddNoteModal";

const BtnDeleteNote = ({ slug }: { slug: string }) => {
  const onDelete = UseDeleteNote(slug);

  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
      onClick={onDelete}
    >
      <BsTrash />
    </button>
  );
};

const BtnEditNote = ({ item, rdv }: { item: Note; rdv: IRdvs }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <button
        className="btn btn-action-icon-edit with-tooltip"
        data-tooltip-content="Modifier"
        onClick={() => setShow(true)}
      >
        <MdOutlineModeEdit />
      </button>
      <AddNoteModal
        modalAddNote={show}
        setModalAddNote={setShow}
        rdv={rdv}
        note={item}
      />
    </>
  );
};

function AllDetailPatientNotesTable({
  item,
  rdv,
}: {
  item: IUser;
  rdv?: IRdvs;
}) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data = { results: [], count: 0 }, isLoading,refetch } =
    useGetNoteByPatientQuery({ page, limit, slug: item?.slug });

    useEffect(() => {
      if (!isLoading) refetch();
    }, []);

  const actionFormatter = (cell: string, row: Note) => {
    return (
      <>
        <div className="d-flex gap-3">
          {!!rdv && (
            <div className="container-btn-action-icon-modal">
              <BtnEditNote item={row} rdv={rdv} />
            </div>
          )}

          <div className="container-btn-action-icon-modal">
            <button
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Voir"
              style={{ color: `#099876` }}
              onClick={() => showModal(`showNoteRdv${cell}`)}
            >
              <AiOutlineEye />
            </button>
          </div>
          <div className="container-btn-action-icon-modal">
            <BtnDeleteNote slug={cell} />
          </div>
        </div>
        <ShowNoteRdvModal modalId={`showNoteRdv${cell}`} note={row} />
      </>
    );
  };

  const titleFormatter = (cell: string, row: Note) => {
    return (
      <div>
        <span>
          {" "}
          {cell} <b>{row?.is_updated === true ? `(addendum)` : ""}</b>
        </span>
      </div>
    );
  };

  const columns = [
    {
      dataField: "created_at",
      text: `Date`,
      formatter: (cell: string) => formatDate(cell),
      headerStyle: () => {
        return { width: "100px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "titre",
      text: "Titre",
      formatter: titleFormatter,
    },
    {
      dataField: "rdv.medecin",
      text: "Auteur",
      formatter: (cell: IUser) => getName(cell),
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
      // headerStyle: () => {
      //   return { width: "130px", whiteSpace: "normal" };
      // },
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            condensed
            hover
            wrapperClasses="table-responsive medecin-patients-detail-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default AllDetailPatientNotesTable;
