import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Tache, TacheFormData } from "./tache.type";

export const TacheApi = createApi({
  reducerPath: "tacheApi",
  tagTypes: ["tacheByMedecin"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getTacheByMedecin: builder.query<PaginationResults<Tache>, TypeQuery>({
      providesTags: ["tacheByMedecin"],
      query: ({ slug, ...query }) => QueryUrl(`medecin/${slug}/taches`, query),
    }),
    editTache: builder.mutation<Tache, TacheFormData>({
      query: ({ slug, ...data }) => {
        return {
          url: `taches/${slug}/`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["tacheByMedecin"],
    }),
    deleteTache: builder.mutation<Tache, string>({
      query: (slug) => ({
        url: `taches/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["tacheByMedecin"],
    }),
  }),
});

export const {
  useDeleteTacheMutation,
  useEditTacheMutation,
  useGetTacheByMedecinQuery,
} = TacheApi;
