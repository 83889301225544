import { IRdvs } from "../../../../utils/api/rdvs/rdvs.type";
import { useEffect } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Color } from "../../../../utils/theme";
import { cleannerError } from "../../../../utils/Utils";
import { OrdonnanceFormData } from "../../../../utils/api/ordonnance/ordonnance.type";
import {
  useAddOrEditOrdonnanceMutation,
  useDeleteOrdonnanceMutation,
} from "../../../../utils/api/ordonnance/ordonnance.api";
import { useNavigate } from "react-router-dom";

function UseAddMedicamentForm(item: IRdvs) {
  const validationSchema = yup.object().shape({
    items: yup
      .array()
      .of(
        yup.object().shape({
          posologie: yup.string().required().label("La posologie").nullable(),
          nom: yup.string().required().label("Le nom").nullable(),
          dosage: yup.string().required().label("Le dosage").nullable(),
          duree: yup.string().required().label("La durée").nullable(),
          quantite: yup
            .number()
            .required()
            .label("La quantite")
            .nullable()
            .transform((value) => (isNaN(value) ? null : value)),
        })
      )
      .required(),
  });
  const defaultValues = {
    rdv: item?.id,
    items: [],
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    trigger,
    control,
  } = useForm<OrdonnanceFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });
  const [sendData, { isLoading, isSuccess, isError, error }] =
    useAddOrEditOrdonnanceMutation();
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "L'ordonnance a été créée avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        navigate(`/medecin/rendez-vous/${item?.slug}?step=medicament`, {
          state: item,
        });
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    // console.log("cabinet", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (item?.id) {
      setValue("rdv", item?.id);
    }
  }, [item]);

  const onSubmit = async (data: OrdonnanceFormData) => {
    if (!data?.items?.length) {
      Swal.fire({
        icon: "error",
        title: `Veuillez ajouter des médicaments`,
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      await sendData(data);
    }

    
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    control,
    append,
    fields,
    remove,
    trigger,
  };
}

export default UseAddMedicamentForm;

export function UseDeleteOrdonnace(slug: string, redirectRoute?: string) {
  const [sendData] = useDeleteOrdonnanceMutation();
  const navigate = useNavigate();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cet ordonnance ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `L'ordonnance a été supprimée avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            if (redirectRoute) {
              navigate(redirectRoute);
            }
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}
