import React, { useState } from "react";
import Modal from "react-modal";
import CloseModalBtn, { CloseModalBtnV2 } from "../../../shared/CloseModalBtn";
import UseAddOrEditDisponibiliteForm from "../form/UseAddOrEditDisponibiliteForm";
import { FormError } from "../../../common/Input";
import { BtnSubmit } from "../../../common/Button";
import { Disponibilite } from "../../../../utils/api/disponibilite/disponibilite.type";

function GererDisponibilite({
  modalGererDisponibilite,
  setModalGererDisponibilite,
  item,
}: {
  modalGererDisponibilite: boolean;
  setModalGererDisponibilite: any;
  item?: Disponibilite;
}) {
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    days,
    onChangeDays,
    handleReset,
  } = UseAddOrEditDisponibiliteForm(setModalGererDisponibilite, item);
  const jours = [
    { label: "Lundi", value: "Lundi" },
    { label: "Mardi", value: "Mardi" },
    { label: "Mercredi", value: "Mercredi" },
    { label: "Jeudi", value: "Jeudi" },
    { label: "Vendredi", value: "Vendredi" },
    { label: "Samedi", value: "Samedi" },
    { label: "Dimanche", value: "Dimanche" },
  ];

  return (
    <Modal
      isOpen={modalGererDisponibilite}
      onRequestClose={handleReset}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal-header py-1 ">
        <h6 className="modal-title mb-0">Gérer mes disponnibilités</h6>
        <div className="flex-r-header-modal">
          <CloseModalBtnV2 onClick={handleReset} />
        </div>
      </div>
      <div className="modal-body-content">
        <form onSubmit={onSubmit}>
          <div className="row row-form-modal py-4">
            <div className="col-md-6 col-form-modal mb-3">
              <div className="content-col-form-modal">
                <label htmlFor="date" className="form-label">
                  Date de début
                </label>
                <input
                  type="date"
                  className="form-control form-control-input-modal"
                  {...register("date_debut")}
                />
                <FormError error={errors?.date_debut?.message} />
              </div>
            </div>
            <div className="col-md-6 col-form-modal mb-3">
              <div className="content-col-form-modal">
                <label htmlFor="end-date" className="form-label">
                  Date de fin
                </label>
                <input
                  type="date"
                  className="form-control form-control-input-modal"
                  {...register("date_fin")}
                />
                <FormError error={errors?.date_fin?.message} />
              </div>
            </div>
            <div className="col-md-6 col-form-modal mb-3">
              <div className="content-col-form-modal">
                <label htmlFor="heure" className="form-label">
                  Heure de début
                </label>
                <input
                  type="time"
                  className="form-control form-control-input-modal"
                  {...register("start_time")}
                />
                <FormError error={errors?.start_time?.message} />
              </div>
            </div>
            <div className="col-md-6 col-form-modal mb-3">
              <div className="content-col-form-modal">
                <label htmlFor="heure" className="form-label">
                  Heure de fin
                </label>
                <input
                  type="time"
                  className="form-control form-control-input-modal"
                  {...register("end_time")}
                />
                <FormError error={errors?.end_time?.message} />
              </div>
            </div>
            <div className="col-md-12 mb-4">
              <label className={`form-label`}>Jours</label>
              <div className="add-availity checkbox-add-event mt-3">
                {jours.map((item) => (
                  <div className="label-form-container" key={item?.label}>
                    <div className="container-checkbox py-1">
                      <label>
                        <input
                          type="checkbox"
                          value={item.value}
                          // {...register(`jours.${i}`)}
                          onChange={(e) => onChangeDays(e)}
                          checked={days?.includes(item?.value)}
                        />
                        <span>{item.label}</span>
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              <FormError error={errors?.jours} />
            </div>
            <div className="col-md-6 col-form-modal mb-3">
              <div className="content-col-form-modal">
                <label htmlFor="heure" className="form-label">
                  Durée minimale pour un rendez-vous (en minutes)
                </label>
                <input
                  type="number"
                  min={0}
                  placeholder="La durée"
                  className="form-control form-control-input-modal"
                  {...register("duree")}
                />
                <FormError error={errors?.duree?.message} />
              </div>
            </div>
            {/* <div className="col-md-12 mb-3">
              <div className="container-select-disponibilite my-4">
                <div className="checkbox-add-event">
                  {heures.map((item, i) => (
                    <div className="label-form-container" key={item?.id}>
                      <div className="container-checkbox py-1">
                        <label>
                          <input
                            type="checkbox"
                            value={item?.label}
                            // {...register(`horaires.${i}`)}
                            onChange={(e) => onChangeHours(e)}
                            checked={hours?.includes(item?.label)}
                          />
                          <span>{item.label}h</span>
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
                <FormError error={errors?.horaires} />
              </div>
            </div> */}
            <div className="col-md-3 offset-md-9">
              <div className="content-btn-delete-rv-modal">
                {/* <button className="btn auth-submit-btn">Ajouter</button> */}
                <BtnSubmit label="Enregistrer" isLoading={isLoading} />
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default GererDisponibilite;
export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    zIndex: 99999,
    // height: "60%",
  },
};
