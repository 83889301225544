import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  AiFillExperiment,
  AiOutlineMenu,
  AiOutlineMenuUnfold,
  AiTwotonePieChart,
  AiTwotoneSetting,
} from "react-icons/ai";
import { NavLink } from "react-router-dom";
import {
  FaAccessibleIcon,
  FaFileInvoiceDollar,
  FaFileMedical,
  FaStethoscope,
  FaUserMd,
  FaUsers,
} from "react-icons/fa";
import { IoLogOutOutline, IoMail } from "react-icons/io5";
import { useUser } from "../../../../utils/Utils";
import { useGetChatItemQuery } from "../../../../utils/api/chat/chat.api";
import socket from "../../../../utils/socket-config";
import {
  hasAccess,
  isAdmin,
  isCabinet,
  isMedecin,
  isPatient,
  isSecretaire,
} from "../../../../routes/routerUtils";
import { HiOutlineDocumentText } from "react-icons/hi2";
import { BsCreditCard } from "react-icons/bs";
import {
  FileIcon,
  InvoiceIcon,
  OrdonnanceIcon,
  RdvIcon,
  ResultIcon,
  VaccinateIcon,
} from "../../../common/Icons";
import { ImProfile } from "react-icons/im";
import { useAppDispatch } from "../../../../redux/hook";
import { onlogout } from "../../../../redux/slice/User.slice";

const SidebarMobile = () => {
  const user = useUser();
  const { data = [], refetch } = useGetChatItemQuery(user?.slug);
  const [count, setCount] = useState(0);
  const dispatch = useAppDispatch();
  const onLogout = () => {
    dispatch(onlogout());
  };

  useEffect(() => {
    if (data?.length) {
      setCount(
        data?.reduce((acc, t) => {
          return acc + t?.messages_received_non_lus;
        }, 0)
      );
    }
  }, [data]);

  useEffect(() => {
    socket.on("messageResponse", (data) => {
      if ([data?.sender, data?.receiver].includes(user?.id)) {
        refetch();
      }
    });
    return () => {
      socket.off("messageResponse");
    };
  }, [socket]);
  return (
    <div>
      <div className="container-nav">
        <input type="checkbox" id="check" />
        <label htmlFor="check">
          <AiOutlineMenuUnfold />
          <i className="fas fa-times" id="cancel"></i>
        </label>
        <div className="sidebar" id="scrollbar-nav-mobile">
          <div className="content-nav-mobile">
            <ul className="nav nav-items-custom flex-column">
              {!!isAdmin(user) && (
                <>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/admin/dashboard"
                    >
                      <AiTwotonePieChart />
                      <span className="item-nav-menu drop-header">
                        Dashboard
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                    hidden={hasAccess(user, "specialite_habilitation")}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/admin/specialites"
                    >
                      <AiFillExperiment />
                      <span className="item-nav-menu drop-header">
                        Spécialités
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                    hidden={hasAccess(user, "praticien_habilitation")}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/admin/praticiens"
                    >
                      <FaAccessibleIcon />
                      <span className="item-nav-menu drop-header">
                        Praticiens
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                    hidden={hasAccess(user, "rdv_habilitation")}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/admin/rendez-vous"
                    >
                      <FaStethoscope />
                      <span className="item-nav-menu drop-header">
                        Rendez-vous
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                    hidden={hasAccess(user, "hopitaux_habilitation")}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/admin/cabinets"
                      // data-tooltip-content="Hôpitaux/cabinets"
                    >
                      <HiOutlineDocumentText />
                      <span className="item-nav-menu drop-header">
                        Hôpitaux/cabinets
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/admin/parametres"
                      // data-tooltip-content="Paramètres"
                    >
                      <AiTwotoneSetting />
                      <span className="item-nav-menu drop-header">
                        Paramètres
                      </span>
                    </NavLink>
                  </li>
                </>
              )}

              {!!isMedecin(user) && (
                <>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/medecin/dashboard"
                    >
                      <AiTwotonePieChart />

                      <span className="item-nav-menu drop-header">
                        Dashboard
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/medecin/rendez-vous"
                    >
                      <FaStethoscope />
                      <span className="item-nav-menu drop-header">
                        Rendez-vous
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/medecin/patients"
                    >
                      <FaUsers />
                      <span className="item-nav-menu drop-header">
                        Patients
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/medecin/paiements"
                      // data-tooltip-content="Paiements"
                    >
                      <BsCreditCard />
                      <span className="item-nav-menu drop-header">
                        Paiements
                      </span>
                    </NavLink>
                  </li>

                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/medecin/messages"
                    >
                      <IoMail />
                      <span className="item-nav-menu drop-header">
                        Messages
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/medecin/parametres"
                    >
                      <AiTwotoneSetting />
                      <span className="item-nav-menu drop-header">
                        Paramètres
                      </span>
                    </NavLink>
                  </li>
                </>
              )}

              {!!isSecretaire(user) && (
                <>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin "
                      to="/secretaire-medical/dashboard"
                    >
                      <AiTwotonePieChart />
                      <span className="item-nav-menu drop-header">
                        Dashboard
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin "
                      to="/secretaire-medical/praticiens"
                    >
                      <RdvIcon />
                      <span className="item-nav-menu drop-header">
                        Agenda Médecin
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin "
                      to="/secretaire-medical/patients"
                    >
                      <FaUsers />
                      <span className="item-nav-menu drop-header">
                        Patients
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin "
                      to="/secretaire-medical/rendez-vous"
                    >
                      <FaStethoscope />
                      <span className="item-nav-menu drop-header">
                        Rendez-vous
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin "
                      to="/secretaire-medical/paiements"
                    >
                      <BsCreditCard />
                      <span className="item-nav-menu drop-header">
                        Paiements
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin "
                      to="/secretaire-medical/messages"
                    >
                      <IoMail />
                      <span className="item-nav-menu drop-header">
                        Messages
                      </span>
                      {count > 0 && (
                        <span>
                          <span className="statut-message-tabs-non-lu">
                            {count}
                          </span>
                        </span>
                      )}
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin "
                      to="/secretaire-medical/parametres"
                    >
                      <AiTwotoneSetting />
                      <span className="item-nav-menu drop-header">
                        Paramètres
                      </span>
                    </NavLink>
                  </li>
                </>
              )}

              {!!isPatient(user) && (
                <>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin "
                      to="/patient/menu"
                    >
                      <AiOutlineMenu />
                      <span className="item-nav-menu drop-header">MENU</span>
                    </NavLink>
                  </li>
                  <div className="pt-3 pb-3"></div>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin "
                      to="/patient/dashboard"
                    >
                      <AiTwotonePieChart />
                      <span className="item-nav-menu drop-header">
                        Dashboard
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin "
                      to="/patient/rendez-vous"
                    >
                      <RdvIcon />
                      <span className="item-nav-menu drop-header">
                        Rendez-vous
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin "
                      to="/patient/facture"
                    >
                      <InvoiceIcon />
                      <span className="item-nav-menu drop-header">Facture</span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin "
                      to="/patient/vaccins"
                    >
                      <VaccinateIcon />
                      <span className="item-nav-menu drop-header">Vaccins</span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin "
                      to="/patient/fichiers"
                    >
                      <FileIcon />
                      <span className="item-nav-menu drop-header">
                        Mes fichiers
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin "
                      to="/patient/ordonnance"
                    >
                      <OrdonnanceIcon />
                      <span className="item-nav-menu drop-header">
                        Ordonnance
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin "
                      to="/patient/messages"
                    >
                      <IoMail />
                      <span className="item-nav-menu drop-header">
                        Messages
                      </span>
                      {count > 0 && (
                        <span>
                          <span className="statut-message-tabs-non-lu">
                            {count}
                          </span>
                        </span>
                      )}
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin "
                      to="/patient/resultats"
                    >
                      <ResultIcon />
                      <span className="item-nav-menu drop-header">
                        Résultats
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin "
                      to="/patient/antécédents"
                    >
                      <FaFileMedical />
                      <span className="item-nav-menu drop-header">
                        Antécédents
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin "
                      to="/patient/profil"
                    >
                      <ImProfile />
                      <span className="item-nav-menu drop-header">Profil</span>
                    </NavLink>
                  </li>
                </>
              )}

              {!!isCabinet(user) && (
                <>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/cabinet/dashboard"
                    >
                      <AiTwotonePieChart />
                      <span className="item-nav-menu drop-header">
                        Dashboard
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/cabinet/praticiens"
                    >
                      <FaUserMd />
                      <span className="item-nav-menu drop-header">
                        Praticiens
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/cabinet/secretaires"
                    >
                      <FaUsers />
                      <span className="item-nav-menu drop-header">
                        Secrétaires médicaux
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/cabinet/rendez-vous"
                    >
                      <FaStethoscope />
                      <span className="item-nav-menu drop-header">
                        Rendez-vous
                      </span>
                    </NavLink>
                  </li>
                  {/* <li className="nav-item nav-item-vertical-custom" onClick={() => $("#check").click()}>
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin"
                    to="/cabinet/dossiers"
                    data-tooltip-content="Dossiers"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <HiOutlineDocumentText />
                    </span>
                    <span className="item-nav-menu drop-header">Dossiers</span>
                  </NavLink>
                </li> */}
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/cabinet/tarifications"
                      data-tooltip-content="Tarification"
                    >
                      <FaFileInvoiceDollar />
                      <span className="item-nav-menu drop-header">
                        Tarification
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/cabinet/paiements"
                    >
                      <BsCreditCard />
                      <span className="item-nav-menu drop-header">
                        Paiements
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    onClick={() => $("#check").click()}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin"
                      to="/cabinet/parametres"
                    >
                      <AiTwotoneSetting />
                      <span className="item-nav-menu drop-header">
                        Paramètres
                      </span>
                    </NavLink>
                  </li>
                </>
              )}

              <li className="nav-item nav-item-vertical-custom pt-5">
                <button
                  className="btn nav-link nav-link-vertival-custom-admin sidebar-logout-btn"
                  onClick={() => onLogout()}
                >
                  <IoLogOutOutline />
                  <span className="item-nav-menu drop-header">Déconnexion</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarMobile;
