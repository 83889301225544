import React, { useEffect, useState } from "react";
import "./Table.css";
import { MdAttachFile } from "react-icons/md";
import AddFichierModal from "../../patient/Modal/AddFichierModal";
import { useDeleteDocumentMutation, useGetDocumentByPatientQuery } from "../../../utils/api/document/document.api";
import { IUser } from "../../../utils/api/user/user.type";
import { IRdvs } from "../../../utils/api/rdvs/rdvs.type";
import { AlertInfo } from "../../common/Alert";
import doc_icon from "../../../assets/icons/icon-doc.png";
import pdf_icon from "../../../assets/icons/icon-pdf.png";
import { getFile, get_url_extension, showModal } from "../../../utils/Utils";
import { Document } from "../../../utils/api/document/document.type";
import ShowFileModal from "../../shared/modal/ShowFileModal";
import { CustomPagination } from "../../common/CustomPagination";
import Skeleton from "react-loading-skeleton";
import { BsTrash } from "react-icons/bs";
import Swal from "sweetalert2";
import { Color } from "../../../utils/theme";

export function UseDeleteDocument(slug: string) {
  const [sendData] = useDeleteDocumentMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce document ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le document a été supprimé avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          })
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}

export const FileItemCard = ({
  icon,
  item,
}: {
  icon: string;
  item: Document;
}) => {
  const onDelete = UseDeleteDocument(item?.slug)
  return (
    <>
      <div className={`file-item-card linkable`}>
        <div onClick={() => showModal(`showModalDoc${item?.slug}`)}>
          <img src={icon} alt={item?.nom} srcSet="" />
        </div>

        <span
          className="pt-3"
          onClick={() => showModal(`showModalDoc${item?.slug}`)}
        >
          {item?.nom}
        </span>
        <div className="btn-container mt-2">
          <button
            className="btn with-tooltip btn-action-icon-delete"
            data-tooltip-content={"Supprimer"}
            style={{ color: "#E53E3E" }}
            onClick={onDelete}
          >
            <BsTrash />
          </button>
        </div>
      </div>
      <ShowFileModal
        file={getFile(item?.fichier)}
        modalId={`showModalDoc${item?.slug}`}
        title={item?.nom}
      />
    </>
  );
};

export const FileCardSkeleton = () => {
  return (
    <>
      {[...Array(12)]?.map((item, i) => (
        <div className="col-md-3" key={i}>
          <div className={`file-item-card`}>
            <Skeleton className="mb-3" width={80} height={85}/>
            <Skeleton className="pt-3" width={110} height={22} />
          </div>
        </div>
      ))}
    </>
  );
};

function AllDetailPatientDossierContent({
  item,
  rdv,
}: {
  item: IUser;
  rdv?: IRdvs;
}) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const { data = { results: [], count: 0 }, isLoading,refetch } =
    useGetDocumentByPatientQuery({
      slug: item?.slug,
      page,
      limit,
    });

  useEffect(() => {
    if (!isLoading) refetch()
  }, [])

  const [modalAddFichier, setModalAddFichier] = useState<boolean>(false);
  function openModalAddFichier(e: any) {
    e.preventDefault();
    setModalAddFichier(true);
  }

  return (
    <>
      <div className="folder-tab-top-head d-flex justify-content-between align-items-center">
        <h3>Fichiers</h3>
        {!!rdv && rdv?.status !== "annuler" && (
          <>
            <button className="btn join-file-btn" onClick={openModalAddFichier}>
              <MdAttachFile className="attach-icon" />
              Joindre un fichier
            </button>
            <AddFichierModal
              modalAddFichier={modalAddFichier}
              setModalAddFichier={setModalAddFichier}
              rdv={rdv}
            />
          </>
        )}
      </div>
      <div className="row all-files-container pt-4 ">
        {!!isLoading && <FileCardSkeleton />}
        {!isLoading &&
          (data?.results?.length ? (
            data?.results?.map((file) => (
              <div className="col-md-3" key={file?.id}>
                <FileItemCard
                  icon={
                    get_url_extension(file?.fichier) === "pdf"
                      ? pdf_icon
                      : doc_icon
                  }
                  item={file}
                  //   isActive={file.isActive}
                />
              </div>
            ))
          ) : (
            <AlertInfo message="Aucun fichier trouvé!" />
          ))}
        <div className="col-md-12">
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
              showPerPage={false}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AllDetailPatientDossierContent;
