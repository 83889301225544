import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import {
  closeModal,
  getImage,
  getUrlBase64ToBlob,
  useUser,
} from "../../../../utils/Utils";
import { useAddOrEditPraticienMutation } from "../../../../utils/api/user/user.api";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import { MdDisabledByDefault } from "react-icons/md";
import { FormError } from "../../../common/Input";

function EditSignatureModal() {
  const sigPad = useRef<any>();
  const user = useUser();
  const [sendData, { error, isError, isLoading, isSuccess, data }] =
    useAddOrEditPraticienMutation();
  const [errors, setErrors] = useState("");
  const [trimmedDataURL, setTrimmedDataURL] = useState<any>(null);

  const reset = () => {
    closeModal("editSignatureModal");
    sigPad?.current?.clear();
    setTrimmedDataURL(null);
    //   setValue("signature", null);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        title: "Signature modifiée avec succès !",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        reset();
      });
    }
    const err = error as any;

    if (isError) {
      // console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess]);

  const handleSignatureEnd = () => {
    setTrimmedDataURL(sigPad?.current.toDataURL("image/png"));
  };

  const clear = () => {
    sigPad?.current?.clear();
    setTrimmedDataURL(null);
    //   setValue("signature", null);
  };

  const onSubmit = async (e: any) => {
    e?.preventDefault();
    if (trimmedDataURL) {
      var file: any = new File(
        [getUrlBase64ToBlob(trimmedDataURL)],
        "signature.png",
        {
          type: "image/png",
        }
      );
      file.path = "signature.png";
      // setValue("signature", file);
      const fd = new FormData();
      fd.append("signature", file);

      await sendData({
        slug: user?.slug,
        data: fd,
      });
    } else {
      setErrors("La signature est obligatoire");

      setTimeout(() => {
        setErrors("");
      }, 3000);
    }
  };
  return (
    <div
      className="modal fade"
      id="editSignatureModal"
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby="editSignatureModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="editSignatureModalLabel">
              Modifier la signature
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={() => reset()}
            ></button>
          </div>
          <div className="modal-body">
            <div className="pt-3">
              <form onSubmit={onSubmit}>
                <div className="row auth-form-row">
                  <div className="col-md-12">
                    <label
                      htmlFor="type_propriété"
                      className="form-label form-label-modal-custom"
                    >
                      Signature{" "}
                      <span className="text-danger" style={{ fontSize: 15 }}>
                        *
                      </span>
                    </label>

                    <div className="">
                      <SignatureCanvas
                        penColor="#000"
                        canvasProps={{
                          height: "auto",
                          width: "auto",
                          className: "sigCanvas",
                          id: "my-canvas",
                        }}
                        ref={sigPad}
                        onEnd={handleSignatureEnd}
                      />
                      <div className="btn-signature-container">
                        {!!trimmedDataURL && (
                          <button
                            className="btn btn-clean"
                            data-tooltip-content="Annuler"
                            onClick={() => clear()}
                          >
                            <MdDisabledByDefault style={{ color: "#EB5757" }} />
                          </button>
                        )}
                      </div>
                    </div>
                    <FormError error={errors} />
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="image-item properti-img-card ">
                      <img
                        src={
                          trimmedDataURL
                            ? trimmedDataURL
                            : getImage(user?.signature)
                        }
                        alt={"avatar"}
                        loading="lazy"
                        width={"100%"}
                        className="img-proprety"
                      />
                    </div>
                  </div>

                  <div className="flex-r">
                    <button
                      className="btn btn-cancelled"
                      type="button"
                      onClick={() => reset()}
                    >
                      Annuler
                    </button>
                    <button
                      type="submit"
                      className="btn auth-submit-btn w-auto"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                            role="status"
                          ></span>
                          <span>En cours...</span>
                        </>
                      ) : (
                        "Enregistrer"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditSignatureModal;
