import React from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import { FormError, Input } from "../../common/Input";
import UseAddOrEditCabinetForm from "./form/UseAddOrEditCabinetForm";
import { useCabinetFromLocation } from "../../../utils/api/user/user.api";
import GoogleInput from "../../common/GoogleInput";
import PhoneInput from "react-phone-input-2";

function AddCabinetHopitaux() {
  const [item] = useCabinetFromLocation();
  const {
    register,
    errors,
    isLoading,
    onSubmit,
    address,
    addressCabinet,
    onChangeAddress,
    onChangeAddressCabinet,
    phone,
    handleChangePhone,
  } = UseAddOrEditCabinetForm(item);
  return (
    <WrapperComponent>
      <div className="content-chart-title mb-4">
        <div className="container-title-dash d-flex align-items-center gap-4">
          <h2 className="custom-sm-top-title mb-0">
            {!item ? "Ajouter" : "Modifier"} un hôpital/cabinet
          </h2>
        </div>
      </div>

      <div className="form-add-container mt-4">
        <form className="mt-4" onSubmit={onSubmit}>
          <p className="title-info-perso-praticien">Informations générales</p>
          <div className="row row-add-student my-3">
            <div className="col-md-4">
              <Input
                type="text"
                label="Nom de la structure"
                id="prenom"
                placeholder="Nom de la structure"
                {...register("nom_cabinet")}
                error={errors?.nom_cabinet}
              />
            </div>
            <div className="col-md-4">
              {/* <Input
                type="text"
                label="Adresse de la structure"
                id="nom"
                placeholder="Adresse de la structure"
              /> */}
              <div className="mb-3">
                <label className="form-label form-label-modal-custom">
                  Adresse de la structure{" "}
                </label>
                <GoogleInput
                  className="form-control form-control-modal-custom"
                  value={addressCabinet}
                  placeholder="Adresse de la structure"
                  onChange={(val) => onChangeAddressCabinet(val)}
                />
                <FormError error={errors?.adresse_cabinet?.message} />
              </div>
            </div>
            <div className="col-md-4">
              <Input
                type="text"
                label="Ville/Région"
                id="ville"
                placeholder="Ville/Région"
                {...register("ville")}
                error={errors?.ville}
              />
            </div>
          </div>
          <p className="title-info-perso-praticien pt-4">
            Informations du responsable
          </p>
          <div className="row row-add-student my-3">
            <div className="col-md-4">
              <Input
                type="text"
                label="Prénom"
                id="prenom"
                placeholder="Prénom"
                {...register("prenom")}
                error={errors?.prenom}
              />
            </div>
            <div className="col-md-4">
              <Input
                type="text"
                label="Nom"
                id="nom"
                placeholder="Nom"
                {...register("nom")}
                error={errors?.nom}
              />
            </div>
            <div className="col-md-4">
              <Input
                type="email"
                label="Adresse mail"
                id="email"
                placeholder="Adresse mail"
                {...register("email")}
                error={errors?.email}
              />
            </div>
            <div className="col-md-4">
              {/* <Input
                type="text"
                label="Numéro de téléphone"
                id="telephone"
                placeholder="Numéro de téléphone"
              /> */}
              <div className="mb-3">
                <label className="form-label form-label-modal-custom ">
                  Téléphone{" "}
                </label>
                <PhoneInput
                  defaultMask={".. ... .. .."}
                  containerClass={`mb-3 p-0 input-modal-form-add-phone-container`}
                  inputClass={`input-modal-form-add-phone`}
                  country={"sn"}
                  placeholder=""
                  inputProps={{
                    name: "telephone",
                    required: true,
                    autoFocus: true,
                  }}
                  countryCodeEditable={false}
                  enableAreaCodes={true}
                  prefix="+"
                  value={phone}
                  onChange={(phone, country: any) =>
                    handleChangePhone(phone, country)
                  }
                  inputStyle={{
                    width: "100%",
                    background: "rgba(232, 240, 253, 0.7)",
                  }}
                />
                <FormError error={errors?.telephone?.message} />
              </div>
            </div>

            <div className="col-md-3 offset-md-9 auth-submit-btn-container text-end pt-5">
              <button
                className="btn custom-add-item-btn w-100"
                disabled={isLoading}
              >
                {!isLoading ? (
                  `Enregistrer`
                ) : (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>Chargement...</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </WrapperComponent>
  );
}

export default AddCabinetHopitaux;
