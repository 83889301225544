import React from "react";
import { useRdvFromLocation } from "../../../../utils/api/rdvs/rdvs.api";
import UseAddVaccinForm from "../../rdvMedecin/form/UseAddVaccinForm";
import WrapperComponent from "../../../shared/WrapperComponent";
import { Input } from "../../../common/Input";
import { BtnAnnuler, BtnSubmit } from "../../../common/Button";
import { useNavigate } from "react-router-dom";
import { useVaccinFromLocation } from "../../../../utils/api/vaccin/vaccin.api";

function AddNewVaccin() {
  const [rdv] = useRdvFromLocation();
  const [item] = useVaccinFromLocation();
  const { register, errors, onSubmit, isLoading } = UseAddVaccinForm(rdv, item);
  const navigate = useNavigate();
  return (
    <WrapperComponent>
      <div className="content-chart-title mb-4">
        <div className="container-title-dash d-flex align-items-center gap-4">
          <h2 className="custom-sm-top-title mb-0">
            {!item ? "Ajouter" : "Modifier"} une demande de vaccination
          </h2>
        </div>
      </div>

      <div className="custom-form mt-5">
        <form onSubmit={onSubmit}>
          <div className="row w-100">
            <div className="col-form-add-medicament-item col-md-6">
              <Input
                label="Nom du vaccin"
                type="text"
                placeholder="Nom du vaccin"
                required
                {...register("nom")}
                error={errors?.nom}
              />
            </div>
            <div className="col-form-add-medicament-item col-md-6">
              <Input
                type="date"
                label="Date"
                placeholder="Date"
                required
                {...register("date")}
                error={errors?.date}
              />
            </div>
            <div className="col-form-add-medicament-item col-md-6">
              <Input
                label="N° de lot"
                type="text"
                placeholder="N° de lot"
                required
                {...register("lot")}
                error={errors?.lot}
              />
            </div>
            <div className="col-form-add-medicament-item col-md-6">
              <Input
                type="date"
                label="Date d'expiration"
                placeholder="Date d'expiration"
                required
                {...register("date_expiration")}
                error={errors?.date_expiration}
              />
            </div>
          </div>
          <div className="d-flex flex-r mt-4">
            <div className="col-md-2">
              <BtnAnnuler
                label="Annuler"
                customClass="custom-btn-annuler"
                onClick={() =>
                  navigate(`/medecin/rendez-vous/${rdv?.slug}?step=vaccin`, {
                    state: rdv,
                  })
                }
              />
            </div>
            <div className="col-md-2">
              <BtnSubmit label="Enregistrer" isLoading={isLoading} />
            </div>
          </div>
        </form>
      </div>
    </WrapperComponent>
  );
}

export default AddNewVaccin;
