import React, { useState } from "react";
import WrapperComponent from "../../shared/WrapperComponent";
// import PatientRdvTable from "../Tables/PatientUpComingRdvTable";
import "../Patient.css";
import PatientRdvTable from "../Tables/PatientRdvTable";
// import CalendrierStep from "../../shared/CalendrierStep";

let statusRdv = [
  {
    label: "En attente",
    value: "en_attente",
  },
  {
    label: "En cours",
    value: "en_cours",
  },
  {
    label: "Terminé",
    value: "terminer",
  },
  {
    label: "Annulé",
    value: "annuler",
  },
];
function RdvPatient() {
  const [query, setQuery] = useState("");
  const [date, setDate] = useState(new Date());
  const [statut, setStatut] = useState("")
  return (
    <>
      <WrapperComponent>
        <div className="container-title-dash d-flex align-items-center gap-4 pb-4">
          <h2 className="custom-sm-top-title mb-0">Mes rendez-vous</h2>
          {/* <CalendrierStep setDate={setDate} /> */}
        </div>
        <div className="filtered-and-search-container pt-3 pb-3">
          <div className="d-flex align-items-center gap-3">
            <label htmlFor="filter">Filter</label>
            <input
              type="search"
              name="search_item"
              id="search_item"
              placeholder="Chercher"
              onChange={(e) => setQuery(e?.target?.value)}
            />
            <select
              name="status"
              id="status"
              className="select-status-filter"
              onChange={(e) => setStatut(e?.target?.value)}
            >
              <option value="">Tout</option>
              {statusRdv?.map((status, i) => (
                <option value={status?.value} key={i}>
                  {" "}
                  {status?.label}{" "}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="custom-table pt-4 pb-4">
          <PatientRdvTable statut={statut} q={query} />
        </div>
      </WrapperComponent>
    </>
  );
}

export default RdvPatient;
