import React from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import { FormError, Input } from "../../common/Input";
import { BtnSubmit } from "../../common/Button";
import UseAddOrEditPatient from "./form/UseAddOrEditPatient";
import { usePatientFromLocation } from "../../../utils/api/user/user.api";
import { SelectInput } from "../../common/SelectInput";
import { selectOptions } from "../../admin/PraticiensAdmin/AddOrUpdatePraticienAdmin";
import GoogleInput from "../../common/GoogleInput";
import PhoneInput from "react-phone-input-2";

function AddNewPatient() {
  const [item] = usePatientFromLocation();
  const {
    register,
    errors,
    isLoading,
    onSubmit,
    onChangeAddress,
    address,
    handleChangePhone,
    phone,
    onChangeFile,
    file,
  } = UseAddOrEditPatient(item);
  return (
    <WrapperComponent>
      <div className="content-chart-title mb-4">
        <div className="container-title-dash d-flex align-items-center gap-4">
          <h2 className="custom-sm-top-title mb-0">
            {!item ? "Ajouter" : "Modifier"} un patient
          </h2>
        </div>
      </div>

      <div className="custom-form mt-5">
        <form onSubmit={onSubmit}>
          <div className="row">
            <div className="col-md-6">
              <Input
                label="Nom"
                type="text"
                placeholder="Nom"
                required
                {...register("nom")}
                error={errors?.nom}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Prenom"
                type="text"
                placeholder="Prénom"
                required
                {...register("prenom")}
                error={errors?.prenom}
              />
            </div>
            <div className="col-md-6">
              {/* <Input label="Adresse" type="text" /> */}
              <div className="mb-3">
                <label className="form-label form-label-modal-custom">
                  Adresse{" "}
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                <GoogleInput
                  className="form-control form-control-modal-custom"
                  value={address}
                  placeholder="Adresse"
                  onChange={(val) => onChangeAddress(val)}
                />
                <FormError error={errors?.adresse?.message} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label form-label-modal-custom ">
                  Téléphone{" "}
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                <PhoneInput
                  defaultMask={".. ... .. .."}
                  containerClass={`mb-3 p-0 input-modal-form-add-phone-container`}
                  inputClass={`input-modal-form-add-phone praticiens`}
                  country={"sn"}
                  placeholder=""
                  inputProps={{
                    name: "telephone",
                    required: true,
                    autoFocus: true,
                  }}
                  countryCodeEditable={false}
                  enableAreaCodes={true}
                  prefix="+"
                  value={phone}
                  onChange={(phone, country: any) =>
                    handleChangePhone(phone, country)
                  }
                  inputStyle={{
                    width: "100%",
                    background: "rgba(232, 240, 253, 0.7)",
                  }}
                />
                <FormError error={errors?.telephone?.message} />
              </div>
            </div>
            <div className="col-md-6">
              <SelectInput
                label="Genre"
                options={selectOptions}
                required
                {...register("civilite")}
                error={errors?.civilite}
              />
            </div>

            <div className="col-md-6">
              <Input
                type="email"
                label="Email"
                id="email"
                placeholder="Email"
                required
                {...register("email")}
                error={errors?.email}
              />
            </div>
            <div className="col-md-6">
              <Input
                type="date"
                label="Date de naissance"
                id="date_de_naissance"
                placeholder="Date de naissance"
                required
                {...register("date_de_naissance")}
                error={errors?.date_de_naissance}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Dossier médical"
                type="file"
                accept=".pdf, .doc, .docx"
                onChange={(e) => onChangeFile(e)}
              />
            </div>
          </div>
          <div className="d-flex flex-r mt-4">
            <div className="col-md-2">
              <BtnSubmit label="Enregistrer" isLoading={isLoading} />
            </div>
          </div>
        </form>
      </div>
    </WrapperComponent>
  );
}

export default AddNewPatient;
