import React, { useEffect, useState } from "react";
import "../Secretaire.css";
import { NavLink } from "react-router-dom";
import ActiviteRdvSecretaireTable from "../Tables/ActiviteRdvSecretaireTable";
import WrapperComponent from "../../shared/WrapperComponent";
import { MdOutlineAddBox } from "react-icons/md";
import { isSecretaire } from "../../../routes/routerUtils";
import { getName, useUser } from "../../../utils/Utils";
import Select from "react-select";
import { useGetPraticienByCabinetQuery } from "../../../utils/api/user/user.api";
import { animatedComponents } from "../../common/Input";
import CalendrierStep from "../../shared/CalendrierStep";

function RdvSecretaire() {
  const user = useUser();
  const [date, setDate] = useState(new Date())
  const [query, setQuery] = useState("");
  const [options, setOptions] = useState<any>([]);
  const [option, setOption] = useState<any>(null);
  const [search, setSearch] = useState("")
  const { data = { results: [] }, isLoading } = useGetPraticienByCabinetQuery({
    slug: isSecretaire(user) ? user?.structure?.slug : user?.slug,
    q: search,
    limit: 50,
  });

  useEffect(() => {
    if (user?.praticien) {
      setOption({
        label: getName(user?.praticien),
        value: user?.praticien?.slug,
      });
    }
  }, [user]);

  useEffect(() => {
    if (data?.results?.length) {
      setOptions(
        data?.results?.map((el) => {
          return {
            label: getName(el),
            value: el?.slug,
          };
        })
      );
    }

  }, [data]);

  const handleSelect = (val) => {
    setOption(val);
  };
  return (
    <WrapperComponent>
      <div className="content-chart-title mb-4">
        <div className="container-title-dash d-flex align-items-center gap-4">
          <h2 className="custom-sm-top-title mb-0">Activité de rendez-vous</h2>
          {/* <div className="filtered-rdv-on-date-container">
            <button className="filter-scope-btn">{"<"}</button>
            <span>05 Août 2022</span>
            <button className="filter-scope-btn">{">"}</button>
          </div> */}
          <CalendrierStep setDate={setDate} />
        </div>

        {!!isSecretaire(user) && (
          <div className="content-btn-theme-title">
            <NavLink
              className="btn custom-add-item-btn"
              to={`/secretaire-medical/rendez-vous/ajouter-rendez-vous`}
            >
              <span className="add-icon">
                <MdOutlineAddBox />
              </span>
              Ajouter un rendez-vous
            </NavLink>
          </div>
        )}
      </div>
      <div className="search-patient-box d-flex align-items-center gap-3 mt-4 with-select-medecin w-70">
        <input
          type="search"
          name="search_patient"
          id="search_patient"
          placeholder="Rechercher"
          onChange={(e) => setQuery(e?.target?.value)}
        />
        <Select
          closeMenuOnSelect={true}
          components={animatedComponents}
          placeholder="Sélectionnez ou cherchez un médecin"
          options={options}
          onInputChange={(val) => setSearch(val)}
          classNamePrefix="react-select-with-select-medecin"
          onChange={handleSelect}
          isClearable
          value={option}
          isLoading={isLoading}
        />
      </div>

      <div className="custom-table mt-5">
        <ActiviteRdvSecretaireTable q={query} medecin={option?.value} date={date} />
      </div>
    </WrapperComponent>
  );
}

export default RdvSecretaire;
