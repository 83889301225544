import React, { useEffect, useState } from "react";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../common/TableSkeleton";
import "./Table.css";
import { useGetOrdonnancesByPatientQuery } from "../../../utils/api/ordonnance/ordonnance.api";
import {
  formatDate,
  getName,
  useUser,
} from "../../../utils/Utils";
import { CustomPagination } from "../../common/CustomPagination";
import { IUser } from "../../../utils/api/user/user.type";
import { Medicament, Ordonnance } from "../../../utils/api/ordonnance/ordonnance.type";
import { useNavigate } from "react-router-dom";

function PatientOrdonnanceTable() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const user = useUser();
  const { data = { results: [], count: 0 }, isLoading,refetch } =
    useGetOrdonnancesByPatientQuery({
      slug: user?.slug,
      page,
      limit,
    });
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) refetch();
  }, []);

  // const ordonnanceFormatter = (cell: string, row: Ordonnance) => {
  //   return (
  //     <>
  //       <div
  //         className="file-name-colum"
  //         onClick={() => showModal(`showFile${row?.slug}`)}
  //       >
  //         {fileName(cell)}
  //       </div>
  //       <ShowFileModal
  //         file={ApiBaseUrl + cell}
  //         modalId={`showFile${row?.slug}`}
  //       />
  //     </>
  //   );
  // };

  const columns = [
    {
      dataField: "created_at",
      text: `Date`,
      formatter: (cell: string) => formatDate(cell),
    },
    {
      dataField: "items",
      text: "Médicament",
      formatter: (cell: Medicament[]) => cell[0]?.nom,
    },
    {
      dataField: "rdv.medecin",
      text: "Médecin",
      formatter: (cell: IUser) => "Dr " + getName(cell),
    },
  ];

  const rowEvents = {
    onClick: (e: any, row: Ordonnance) => {
      navigate(`/patient/ordonnance/${row.slug}`, { state: row });
    },
  };

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            condensed
            hover
            rowEvents={rowEvents}
            wrapperClasses="table-responsive medecin-patients-detail-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default PatientOrdonnanceTable;
