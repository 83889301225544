import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DocumentFormData } from "../../../../utils/api/document/document.type";
import { useAddOrUpdateDocumentMutation } from "../../../../utils/api/document/document.api";
import { Color } from "../../../../utils/theme";
import { cleannerError, useUser } from "../../../../utils/Utils";

function UseCrudFileForm(setShow: Dispatch<SetStateAction<boolean>>) {
  const validationSchema = yup.object().shape({
    nom: yup.string().required().label("Le nom du fichier").nullable(),
    fichier: yup.mixed().required().label("Le fichier").nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
    resetField,
  } = useForm<DocumentFormData>({
    resolver: yupResolver(validationSchema),
  });
  const user = useUser()
  const [sendData, { isLoading, isSuccess, isError, error }] =
    useAddOrUpdateDocumentMutation();

  const handleReset = () => {
    setShow(false);
    resetField("nom");
    resetField("fichier");
  };

  const handleChange = (e) => {
    let file = e?.target?.files[0];
    setValue("fichier", file);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Le fichier a été joint avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        
        handleReset();
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    // console.log("cabinet", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (user?.id) {
      setValue("patient", user?.id);
    }
  }, [user]);

  const onSubmit = async (data: DocumentFormData) => {
    let fd = new FormData();

    for (const field in data) {
      fd.append(field, data[field]);
    }
    await sendData({ slug: undefined, data: fd });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    control,
    handleChange,
    handleReset,
  };
}

export default UseCrudFileForm;
