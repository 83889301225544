import React from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import { Input, InputQuill } from "../../common/Input";
import { BtnSubmit } from "../../common/Button";
import { BreadCrumbV2 } from "../../shared/BreadCrumb";
import { useRdvFromLocation } from "../../../utils/api/rdvs/rdvs.api";
import { getAge, getAvatar, getName } from "../../../utils/Utils";
import { SelectInput } from "../../common/SelectInput";
import { IRdvs } from "../../../utils/api/rdvs/rdvs.type";
import UseConsultationForm from "./form/UseConsultationForm";

const AddConstanteForm = ({ item }: { item: IRdvs }) => {
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    allergie,
    onChangeAllergie,
    onChange,
    contenu,
  } = UseConsultationForm(item);

  return (
    <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-md-4">
          <Input
            label="Poids"
            type="text"
            placeholder="Poids"
            required
            {...register("poids")}
            error={errors?.poids}
          />
        </div>
        <div className="col-md-4">
          <Input
            label="Taille"
            type="text"
            placeholder="Taille"
            required
            {...register("taille")}
            error={errors?.taille}
          />
        </div>
        <div className="col-md-4">
          <Input
            label="Tension artérielle"
            type="text"
            id="tension"
            placeholder="Tension artérielle"
            required
            {...register("tension")}
            error={errors?.tension}
          />
        </div>

        <div className="col-md-4">
          <Input
            label="Pouls"
            type="text"
            id="pouls"
            placeholder="Pouls"
            required
            {...register("pouls")}
            error={errors?.pouls}
          />
        </div>
        <div className="col-md-4">
          <Input
            label="Température"
            type="text"
            placeholder="Température"
            required
            {...register("temperature")}
            error={errors?.temperature}
          />
        </div>
        <div className="col-md-4">
          <SelectInput
            label="Ce patient est-il allergique ?"
            options={[
              {
                label: "Oui",
                value: "true",
              },
              {
                label: "Non",
                value: "false",
              },
            ]}
            required
            value={allergie}
            // {...register("allergies")}
            onChange={(e) => onChangeAllergie(e)}
            error={errors?.allergies}
          />
        </div>
        <div className="col-md-12">
          <InputQuill
            label="Notes"
            value={contenu}
            onChange={onChange}
            id="instruction"
          />
        </div>
      </div>
      <div className="d-flex flex-r mt-4">
        <div className="col-md-2">
          <BtnSubmit label="Enregistrer" isLoading={isLoading} />
        </div>
      </div>
    </form>
  );
};

function DemarrerConsultation() {
  const [item] = useRdvFromLocation();
  // const [modalTypeOrdonnance, setModalTypeOrdonnance] = useState(false);
  // const [modalAddNote, setModalAddNote] = useState<boolean>(false);
  // function openModalAddNote(e: any) {
  //   e.preventDefault();
  //   setModalAddNote(true);
  // }
  // function openOrdonnanceModal() {
  //   setModalTypeOrdonnance(true);
  // }
  return (
    <div>
      <div className="mb-3">
        <BreadCrumbV2
          principalPage="Mes rendez-vous"
          linkPrincipal={`/medecin/rendez-vous/${item?.slug}`}
          secondPage={`Constantes`}
          // principalCustomClass="custom-sm-top-title"
          // secondCustomClass="custom-sm-top-subtitle"
        />
      </div>

      <WrapperComponent>
        <div className="content-chart-title mb-4">
          <div className="container-title-dash d-flex align-items-center gap-4 container-title-page-header">
            <h2 className="custom-sm-top-title mb-0">{`Constantes pour ${item?.patient?.prenom}`}</h2>
          </div>
          {/* <div className="top-content-head d-flex flex-r gap-3">
            <button
              className="add-notes-btn"
              onClick={openModalAddNote}
            >
              <RiFileEditLine className="add-icon" />
              Ajouter une note
            </button>
            <button
              className="btn custom-add-item-btn"
              onClick={(e) => openOrdonnanceModal()}
            >
              <span className="add-icon">
                <MdOutlineAddBox />
              </span>
              Ajouter une ordonnance
            </button>
            <TypeOrdonnanceModal
              modalTypeOrdonnance={modalTypeOrdonnance}
              setModalTypeOrdonnance={setModalTypeOrdonnance}
              rdv={item}
            />
            <AddNoteModal
              modalAddNote={modalAddNote}
              setModalAddNote={setModalAddNote}
              rdv={item}
            />
          </div> */}
        </div>

        <div className="custom-form mt-5">
          <div className="container-header-consultation-page mb-5">
            <div className="content-img-header-consultation">
              <img
                src={getAvatar(item?.patient?.avatar)}
                alt={getName(item?.patient) || "Profil"}
                className="img-header-consultation"
              />
            </div>
            <div className="content-info-header-consultation">
              <p className="title-profil-user-header-consultation mb-1">
                {getName(item?.patient)}
              </p>
              <div className="d-flex gap-3">
                <p className="info-date-header-consultationinfo-profil mb-0">
                  {getAge(item?.patient?.date_de_naissance)} ,{" "}
                  {item?.patient?.adresse},
                </p>
                <p className="info-date-header-consultationinfo-profil mb-0">
                  N° {item?.patient?.matricule}
                </p>
              </div>
            </div>
          </div>
          <AddConstanteForm item={item} />
        </div>
      </WrapperComponent>
    </div>
  );
}

export default DemarrerConsultation;
