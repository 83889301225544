import React from 'react'
import { IRdvs } from '../../../../utils/api/rdvs/rdvs.type';
import UseConsultationForm from '../../rdvMedecin/form/UseConsultationForm';
import { Input } from '../../../common/Input';
import { SelectInput } from '../../../common/SelectInput';
import { BtnSubmit } from '../../../common/Button';

function AddConstanteModal({modalId,rdv}:{modalId:string;rdv:IRdvs}) {
    const {
      register,
      onSubmit,
      errors,
      handleReset,
      isLoading
    } = UseConsultationForm(rdv,modalId);
  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby={modalId + "Label"}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id={modalId + "Label"}>
              Constantes
            </h1>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => handleReset()}
            ></button>
          </div>
          <div className="modal-body">
            <div className="pt-3">
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-4">
                    <Input
                      label="Tension artérielle"
                      type="text"
                      id="tension"
                      placeholder="Tension artérielle"
                      required
                      {...register("tension")}
                      error={errors?.tension}
                    />
                  </div>

                  <div className="col-md-4">
                    <Input
                      label="Pouls"
                      type="text"
                      id="pouls"
                      placeholder="Pouls"
                      required
                      {...register("pouls")}
                      error={errors?.pouls}
                    />
                  </div>
                  <div className="col-md-4">
                    <Input
                      label="Température"
                      type="text"
                      placeholder="Température"
                      {...register("temperature")}
                      error={errors?.temperature}
                    />
                  </div>
                  <div className="col-md-4">
                    <Input
                      label="Poids"
                      type="text"
                      placeholder="Poids"
                      {...register("poids")}
                      error={errors?.poids}
                    />
                  </div>
                  <div className="col-md-4">
                    <Input
                      label="Taille"
                      type="text"
                      placeholder="Taille"
                      {...register("taille")}
                      error={errors?.taille}
                    />
                  </div>
                  {/* <div className="col-md-4">
                    <Input
                      label="Groupe sanguin"
                      type="text"
                      placeholder="Groupe sanguin"
                      {...register("groupe_sanguin")}
                      error={errors?.groupe_sanguin}
                    />
                  </div> */}

                  {/* <div className="col-md-6">
                    <SelectInput
                      label="Ce patient est-il allergique ?"
                      options={[
                        {
                          label: "Oui",
                          value: "true",
                        },
                        {
                          label: "Non",
                          value: "false",
                        },
                      ]}
                      value={allergie}
                      // {...register("allergies")}
                      onChange={(e) => onChangeAllergie(e)}
                      error={errors?.allergies}
                    />
                  </div> */}
                  <div className="flex-r mt-3">
                    <button
                      className="btn btn-cancelled"
                      type="button"
                      onClick={() => handleReset()}
                    >
                      Annuler
                    </button>
                    <BtnSubmit
                      label="Enregistrer"
                      isLoading={isLoading}
                      customClass="w-auto"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddConstanteModal