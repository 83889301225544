import React, { useEffect, useState } from "react";
import WrapperComponent from "../../../shared/WrapperComponent";
import { Input } from "../../../common/Input";
import { BtnSubmit } from "../../../common/Button";
import * as yup from "yup";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import OrdonnancesMedecinTable from "../../Tables/OrdonnancesMedecinTable";
import { Medicament } from "../../../../utils/api/ordonnance/ordonnance.type";
import { cleannerError } from "../../../../utils/Utils";

function AddNewMedicament() {
  const validationSchema = yup.object().shape({
    posologie: yup.string().required().label("La posologie").nullable(),
    nom: yup.string().required().label("Le nom").nullable(),
    dosage: yup.string().required().label("Le dosage").nullable(),
    duree: yup.string().required().label("La durée").nullable(),
    quantite: yup
      .number()
      .required()
      .label("La quantite")
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset
  } = useForm<Medicament>({
    resolver: yupResolver(validationSchema),
  });
  const [medicamentItem, setMedicamentItem] = useState<Medicament>()

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = (data: Medicament) => {
    // console.log("data medicament",data)
    setMedicamentItem(data)
    setTimeout(() => {
      reset()
      setMedicamentItem(undefined)
    }, 1000);
  }

  return (
    <WrapperComponent>
      <div className="content-chart-title mb-4">
        <div className="container-title-dash d-flex align-items-center gap-4">
          <h2 className="custom-sm-top-title mb-0">Ajouter un médicament</h2>
        </div>
      </div>

      <div className="custom-form mt-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container-form-add-medicament">
            <div className="col-form-add-medicament-item">
              <Input
                label="Nom du médicament"
                type="text"
                placeholder="Nom du médicament"
                required
                {...register("nom")}
                error={errors?.nom}
              />
            </div>
            <div className="col-form-add-medicament-item">
              <Input
                label="Quantité"
                type="number"
                placeholder="Quantité"
                min={0}
                required
                {...register("quantite")}
                error={errors?.quantite}
              />
            </div>
            <div className="col-form-add-medicament-item">
              <Input
                label="Posologie"
                type="text"
                placeholder="Posologie"
                required
                {...register("posologie")}
                error={errors?.posologie}
              />
            </div>
            <div className="col-form-add-medicament-item">
              <Input
                label="Dosage"
                type="text"
                placeholder="Dosage"
                required
                {...register("dosage")}
                error={errors?.dosage}
              />
            </div>
            <div className="col-form-add-medicament-item">
              <Input
                label="Durée"
                placeholder="Durée en jours"
                type="text"
                required
                {...register("duree")}
                error={errors?.duree}
              />
            </div>
          </div>
          <div className="d-flex flex-r mt-4">
            <div className="col-md-2">
              <BtnSubmit label="Ajouter" isLoading={false} />
            </div>
          </div>
        </form>
        <hr className="my-5" />
        <div className="admin-table">
          <OrdonnancesMedecinTable item={medicamentItem} />
        </div>
      </div>
    </WrapperComponent>
  );
}

export default AddNewMedicament;
