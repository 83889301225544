import React from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import { FaCheckCircle, FaListAlt } from "react-icons/fa";
import "../Patient.css";
import { useRdvFromLocation } from "../../../utils/api/rdvs/rdvs.api";
import { BreadCrumbV2 } from "../../shared/BreadCrumb";
import {
  createMarkup,
  formatDate,
  getName,
  getRdvRoute,
  getStatus,
  useUser,
} from "../../../utils/Utils";
import moment from "moment";

function DetailRdvPatient() {
  const [item] = useRdvFromLocation();
  const user = useUser();
  return (
    <>
      <WrapperComponent>
        <div className="container-title-dash d-flex align-items-center gap-4 pb-4">
          {/* <h2 className="custom-sm-top-title mb-0">
            Mes rendez-vous {" > "}
            <strong>Détail rendez-vous</strong>
          </h2> */}
          <BreadCrumbV2
            principalPage="Mes rendez-vous"
            linkPrincipal={getRdvRoute(user)}
            secondPage="Détail rendez-vous"
            principalCustomClass="custom-sm-top-title"
            secondCustomClass="custom-sm-top-subtitle"
          />
        </div>

        <div className="custom-content pt-4 pb-4">
          <div className="light-card">
            <div className="custom-card-header d-flex pb-3">
              <div className="rdv-title-content d-flex align-items-center gap-3">
                <FaCheckCircle className="icon" />
                <div className="custom-title d-flex flex-column gap-2">
                  <span className="demand">
                    Rendez-vous {getStatus(item)?.toLocaleLowerCase()}
                  </span>
                  {/* <span className="description">
                    Vous recevrez un mail de confirmation si elle est acceptée.
                  </span> */}
                </div>
              </div>
              {/* <button className="cancel-rdv-content d-flex align-items-center gap-3">
                <MdOutlineCancel />
                Annuler le Rdv
              </button> */}
            </div>
            <div className="card-separator"></div>
            <div className="custom-card-content">
              <div className="rdv-location mb-3">
                <span className="cs-title">Lieu:</span> &nbsp;
                <span className="cs-value">
                  {item?.structure?.nom_cabinet ||
                    item?.patient?.structure?.nom_cabinet}
                </span>
              </div>
              <div className="rdv-location mb-3">
                <span className="cs-title">Adresse du cabinet:</span> &nbsp;
                <span className="cs-value">
                  {item?.structure?.adresse_cabinet ||
                    item?.patient?.structure?.adresse_cabinet}
                </span>
              </div>
              <div className="rdv-location mb-3">
                <span className="cs-title">Motif:</span> &nbsp;
                <span className="cs-value">{item?.motif}</span>
              </div>
              <div className="person-concerned mb-3">
                <span className="cs-title">Réservé pour: </span> &nbsp;
                <span className="cs-value">{getName(item?.patient)}</span>
              </div>
              <div className="date-and-hour mb-3">
                <span className="cs-title">Date et heure:</span> &nbsp;
                <span className="cs-value">
                  {" "}
                  {formatDate(item?.date)} à{" "}
                  {moment(item?.horaire_de_visite, "HH:mm").format("HH[h]mm")}{" "}
                </span>
              </div>
              <div className="date-and-hour mb-3">
                <span className="cs-title">Médecin:</span> &nbsp;
                <span className="cs-value">{getName(item?.medecin)}</span>
              </div>
              <div className="date-and-hour mb-3">
                <span className="cs-title">Téléphone:</span> &nbsp;
                <span className="cs-value">{item?.medecin?.telephone}</span>
              </div>
              <div className="date-and-hour mb-3">
                <span className="cs-title">Spécialité:</span> &nbsp;
                <span className="cs-value">
                  {item?.medecin?.specialite?.nom}
                </span>
              </div>
              {/* <button className="get-location-rdv-content d-flex align-items-center gap-3">
                Envoyer la localisation
                <MdLocationPin />
              </button> */}
            </div>
          </div>
          <div className="instruction-content">
            <div className="title d-flex align-items-center gap-3 pb-4">
              <FaListAlt />
              Instructions à suivre
            </div>
            <div className="intructions-list text-start">
              <p
                dangerouslySetInnerHTML={createMarkup(item?.instructions || "")}
              />
            </div>
          </div>
        </div>
      </WrapperComponent>
    </>
  );
}

export default DetailRdvPatient;
