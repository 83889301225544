import { ChangeEvent, useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleannerError, validatePhone } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import { IUser, UserFormData } from "../../../../utils/api/user/user.type";
import { Data } from "../../../common/GoogleInput";
import {
  useAddOrEditPraticienMutation,
  useDeletePraticienMutation,
  useGetListCabinetsQuery,
} from "../../../../utils/api/user/user.api";
import {
  useAddOrUpdateSpecialiteMutation,
  useGetSpecialiteQuery,
} from "../../../../utils/api/specialite/specialite.api";
import { useAppSelector } from "../../../../redux/hook";
import { isAdmin, isCabinet } from "../../../../routes/routerUtils";

function UseAddOrEditPraticienForm(item?: IUser) {
  const [address, setAddress] = useState("");
  const [avatar, setAvatar] = useState<File | null>(null);
  const [phone, setPhone] = useState<any>();
  const [code, setCode] = useState("");
  const [searchSpecialite, setSearchSpecialite] = useState("");
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState<{ label: string; value: number }[]>(
    []
  );
  const [option, setOption] = useState<
    { label: string; value: number } | undefined
  >();
  const [specialites, setSpecialites] = useState<
    { label: string; value: number }[]
  >([]);
  const [specialite, setSpecialite] = useState<
    { label: string; value: number } | undefined
  >();
  const validationSchema = yup.object().shape({
    nom: yup.string().required().label("Le nom"),
    prenom: yup.string().required().label("Le prénom"),
    civilite: yup.string().required().label("Le genre"),
    adresse: yup.string().required().label("L'adresse"),
    telephone: validatePhone(yup, code).required(),
    email: yup.string().email().required().label("L'email"),
    structure: yup
      .number()
      .required()
      .label("L'hôpital ou clinique affilié(e)")
      .transform((val: any) => (isNaN(val) ? null : val))
      .nullable(),
    specialite: yup
      .number()
      .required()
      .label("La spécialité")
      .transform((val: any) => (isNaN(val) ? null : val))
      .nullable(),
    numero_de_licence: yup.string().nullable().label("Le numéro de licence"),
    annee_obtention: yup.string().nullable().label("L'année d’obtention"),
    experience: yup
      .number()
      .label("L'expérience")
      .transform((val: any) => (isNaN(val) ? null : val))
      .nullable(),
    avatar: yup.mixed().nullable().label("La photo"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
  } = useForm<UserFormData>({
    resolver: yupResolver(validationSchema),
  });
  const { user } = useAppSelector((s) => s?.user);
  const { data = { results: [] } } = useGetSpecialiteQuery({
    q: searchSpecialite,
    limit: 50,
  });
  const { data: cabinets = { results: [] } } = useGetListCabinetsQuery({
    q: search,
  });
  const navigate = useNavigate();
  const [addData, { isSuccess: isDone, data: dataAdded }] =
    useAddOrUpdateSpecialiteMutation();
  const [sendData, { isLoading, isSuccess, isError, error }] =
    useAddOrEditPraticienMutation();

  useEffect(() => {
    if (isCabinet(user)) {
      setValue("structure", user?.id);
    }
  }, [user]);

  useEffect(() => {
    if (cabinets?.results?.length) {
      setOptions(
        cabinets?.results?.map((el) => {
          return {
            label: el?.nom_cabinet,
            value: el?.id,
          };
        })
      );
    }
  }, [cabinets]);

  useEffect(() => {
    if (data?.results?.length) {
      setSpecialites(
        data?.results?.map((el) => {
          return {
            label: el?.nom,
            value: el?.id,
          };
        })
      );
    }
  }, [data]);

  useEffect(() => {
    if (isDone) {
      if (dataAdded) {
        setSpecialite({ label: dataAdded?.nom, value: dataAdded?.id });
        setValue("specialite", dataAdded?.id);
      }
    }
  }, [isDone]);

  const addOptionIfNeeded = (valueToAdd) => {
    setSearchSpecialite(valueToAdd);
    const optionExists = specialites?.some((opt) =>
      opt?.label?.toLocaleLowerCase()?.includes(valueToAdd?.toLocaleLowerCase())
    );

    if (!optionExists) {
      setSpecialites((prevOptions) => [
        ...prevOptions,
        { label: valueToAdd, value: valueToAdd },
      ]);
    }
  };

  const onChangeAddress = (val: Data) => {
    setAddress(val?.rue);
    setValue(`adresse`, val?.rue);
    setValue(`latitude`, parseFloat(val?.latitude).toFixed(6));
    setValue(`longitude`, parseFloat(val?.longitude).toFixed(6));
    if (val?.ville) {
      setValue("ville", val?.ville);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    let file =
      e?.target?.files && e?.target?.files?.length ? e?.target?.files[0] : null;
    setAvatar(file);
    setValue("avatar", file);
  };
  const handleSelectSpecialite = (val) => {
    setSpecialite(val);
    if (typeof val?.value !== "number") {
      if (val?.value?.toLowerCase() === searchSpecialite?.toLocaleLowerCase()) {
        addData({ data: { nom: searchSpecialite } });
      }
    } else {
      setValue(`specialite`, val?.value);
    }
  };

  const handleSelectCabinet = (val) => {
    setOption(val);
    setValue(`structure`, val?.value);
  };

  const handleChangePhone = (item: any, country: any) => {
    setPhone(item);
    setCode(country?.countryCode);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue("telephone", item);
    } else {
      setValue("telephone", "");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: !item
          ? "Praticien ajouté avec succès"
          : "Le praticien a été modifié avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (isAdmin(user)) {
          navigate("/admin/praticiens");
        } else if (isCabinet(user)) {
          navigate("/cabinet/praticiens");
        } else {
          navigate("/secretaire-medical/praticiens");
        }
      });
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("user with this email already exists.")) {
        Swal.fire({
          icon: "error",
          title: "Un utilisateur avec cet email exite déjà!",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Erreur de statut ${err?.status}`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    // console.log("cabinet", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (item?.id) {
      let fields: (keyof UserFormData)[] = [
        "nom",
        "prenom",
        "telephone",
        "email",
        "adresse",
        "ville",
        "structure",
        "civilite",
        "specialite",
        "numero_de_licence",
        "annee_obtention",
        "experience",
      ];

      if (item?.telephone) {
        setPhone(item.telephone);
      }

      if (item?.adresse) {
        setAddress(item.adresse);
      }

      if (item?.structure) {
        setOption({
          label: item?.structure?.nom_cabinet,
          value: item?.structure?.id,
        });
      }

      if (item?.specialite) {
        setSpecialite({
          label: item?.specialite?.nom,
          value: item?.specialite?.id,
        });
      }

      for (let field of fields) {
        register(field);
        if (field !== "structure" && field !== "specialite") {
          setValue(field, item[field]);
        } else {
          setValue(field, item[field]?.id);
        }
      }
    }
  }, [item]);

  const onSubmit = async (data: UserFormData) => {
    const fd = new FormData();

    for (let key of Object.keys(data)) {
      if (key === "avatar") {
        if (data?.avatar) {
          fd.append(key, data?.avatar);
        }
      } else {
        fd.append(key, data[key]);
      }
    }
    await sendData({
      slug: item?.slug,
      data: fd,
    });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChangePhone,
    handleSelectSpecialite,
    Controller,
    control,
    phone,
    address,
    options,
    option,
    specialites,
    specialite,
    handleSelectCabinet,
    setSearchSpecialite,
    setSearch,
    onChange,
    avatar,
    onChangeAddress,
    addOptionIfNeeded,
  };
}

export default UseAddOrEditPraticienForm;

export function UseArchivePraticien(item: IUser) {
  const [sendData] = useAddOrEditPraticienMutation();
  const onArchive = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir ${
        item?.is_archive ? "désarchiver" : "archiver"
      } ce praticien ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData({
          slug: item?.slug,
          data: { is_archive: !item?.is_archive },
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le praticien a été ${
              item?.is_archive ? "désarchivé" : "archivé"
            } avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onArchive;
}

export function UseDeleteUser(slug: string, redirectRoute?: string) {
  const [sendData] = useDeletePraticienMutation();
  const navigate = useNavigate();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce médecin ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le médecin a été supprimé avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            if (redirectRoute) {
              navigate(redirectRoute);
            }
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}
