import React, { useEffect, useState } from "react";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import { BsTrash } from "react-icons/bs";
import { MdOutlineModeEdit } from "react-icons/md";
import TableSkeleton from "../../common/TableSkeleton";
import "./Table.css";
import ShowPaiementModal from "../Modals/ShowPaiementModal";
import { useGetFacturationByUserQuery } from "../../../utils/api/facturation/facturation.api";
import {
  formatCurrency,
  formatDate,
  getFile,
  getName,
  showModal,
  useUser,
} from "../../../utils/Utils";
import { IUser } from "../../../utils/api/user/user.type";
import { FaFileInvoice } from "react-icons/fa";
import ShowFileModal from "../../shared/modal/ShowFileModal";
import { Facturation } from "../../../utils/api/facturation/facturation.type";
import { CustomPagination } from "../../common/CustomPagination";
import AddOrEditFacturationModal from "../Modals/AddOrEditFacturationModal";
import {
  UseDeleteFacturation,
  UseEditStatutFacturation,
} from "../paiementMedecin/form/UseAddOrEditFacturationForm";
import {
  isCabinet,
  isMedecin,
  isSecretaire,
} from "../../../routes/routerUtils";
import moment from "moment";

const BtnDelete = ({ slug }) => {
  const onDelete = UseDeleteFacturation(slug);
  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
      onClick={onDelete}
    >
      <BsTrash />
    </button>
  );
};

export const StatusBtn = ({ item }: { item: Facturation }) => {
  const onEdit = UseEditStatutFacturation(item);
  const user = useUser();

  return (
    <>
      <span
        className={`btn statut-rdv-${
          item?.status === "payé" ? "terminer" : "annuler"
        } with-tooltip`}
        onClick={() => {
          if (isSecretaire(user)) {
            onEdit();
          }
        }}
        // data-tooltip-content="Modifier"
      >
        {item?.status === "payé" ? "Payé" : "Non payé"}
      </span>
    </>
  );
};

function AllPaiementMedecinTable({ date }: { date: Date }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const user = useUser();
  const { data = { results: [], count: 0 }, isLoading,refetch } =
    useGetFacturationByUserQuery({
      page,
      limit,
      slug: !isSecretaire(user) ? user?.slug : user?.structure?.slug,
      type: user?.user_type,
      date: moment(date).format("DD-MM-YYYY"),
    });

    useEffect(() => {
      if (!isLoading) refetch();
    }, []);

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center gap-3">
          {!!isMedecin(user) && (
            <div className="container-btn-action-icon-modal">
              <button
                className="btn btn-action-icon-edit with-tooltip"
                data-tooltip-content="Modifier"
                onClick={() => showModal(`editFacturationModal${cell}`)}
              >
                <MdOutlineModeEdit />
              </button>
            </div>
          )}
          <div className="container-btn-action-icon-modal">
            <BtnDelete slug={cell} />
          </div>
        </div>
        <ShowPaiementModal modalId={`detailsFacture${cell}`} item={row} />
        <AddOrEditFacturationModal
          item={row}
          modalId={`editFacturationModal${cell}`}
        />
      </>
    );
  };
  const amountFormatter = (cell: string, row: Facturation) => {
    let amount = row?.tarifs?.reduce((acc, t) => {
      return acc + parseInt(t?.montant);
    }, 0);
    return (
      <>
        <div
          className="amount-formatter-container"
          onClick={() => {
            if (row?.tarifs?.length) {
              showModal(`detailsFacture${row?.slug}`);
            }
          }}
        >
          {!!row?.tarifs?.length && formatCurrency(amount)}
          {!row?.tarifs?.length && "À définir"}
        </div>
      </>
    );
  };
  const factureFormatter = (cell: string, row: Facturation) => {
    return (
      <>
        <div
          className="paiement-method-formatter-container d-flex align-items-center gap-2"
          onClick={() => {
            if (cell) {
              showModal(`fileInvoiceModal${row?.slug}`);
            }
          }}
        >
          {!!cell && (
            <>
              <span className="file-icon">
                <FaFileInvoice />
              </span>
              Facture
            </>
          )}
          {!cell && "Indisponible"}
        </div>
        <ShowFileModal
          modalId={`fileInvoiceModal${row?.slug}`}
          file={getFile(cell)}
          title={`Facture de ${getName(row?.patient)}`}
        />
      </>
    );
  };

  const statutFormatter = (cell: string, row: Facturation) => {
    return (
      <>
        <div className="statut-formatter-container d-flex align-items-center gap-2">
          <StatusBtn item={row} />
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "patient",
      text: `Patient`,
      formatter: (cell: IUser) => getName(cell),
    },
    {
      dataField: "rdv.date",
      text: "Date",
      formatter: (cell: string) => formatDate(cell),
    },
    {
      dataField: "montant",
      text: "Montant",
      formatter: amountFormatter,
    },
    {
      dataField: "medecin",
      text: `Médecin`,
      formatter: (cell: IUser) => getName(cell),
      hidden: isMedecin(user),
    },
    {
      dataField: "facture",
      text: "Facture",
      formatter: factureFormatter,
    },
    {
      dataField: "status",
      text: "Statut",
      formatter: statutFormatter,
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
    },
  ];

  const rowEvents = {
    onClick: (e, row: Facturation) => {
      let getCurrentCellIndex = e.target.cellIndex;
      let id: any = document.querySelector("table tr:last-child td:last-child");
      let idx: any = document.querySelector(
        "table tr:nth-last-child(2) td:nth-last-child(2)"
      );
      let getLastCellIndex = id?.cellIndex;
      let getBeforeLastCellIndex = idx?.cellIndex;

      if (
        getCurrentCellIndex !== getLastCellIndex &&
        getCurrentCellIndex !== undefined &&
        getCurrentCellIndex !== getBeforeLastCellIndex
      ) {
        showModal(`detailsFacture${row?.slug}`);
      }
    },
  };

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            rowEvents={rowEvents}
            rowClasses="custom-table-row-class"
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default AllPaiementMedecinTable;
