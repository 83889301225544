import { Navigate } from "react-router-dom";
import { IUser } from "../utils/api/user/user.type";
import { useAppSelector } from "../redux/hook";
import { ReactElement, useEffect } from "react";
import { toast } from "react-toastify";
import socket from "../utils/socket-config";
import { FieldType } from "../utils/constante";

export const isMedecin = (user: IUser) => {
  return user?.user_type === "medecin";
};

export const isPatient = (user: IUser) => {
  return user?.user_type === "patient";
};

export const isCabinet = (user: IUser) => {
  return user?.user_type === "cabinet";
};

export const isSecretaire = (user: IUser) => {
  return user?.user_type === "secretaire";
};

export const isAdmin = (user: IUser) => {
  return user?.user_type === "admin" || user?.user_type === "superadmin";
};

export const IsAdminAllow = ({ children }: { children: ReactElement }) => {
  const { user, token } = useAppSelector((state) => state.user);
  let path: string = "/";
  if (token) {
    if (isMedecin(user)) {
      path = "/medecin/dashboard";
    } else if (isCabinet(user)) {
      path = "/cabinet/dashboard";
    } else if (isPatient(user)) {
      path = "/patient/menu";
    } else if (isSecretaire(user)) {
      path = "/secretaire-medical/dashboard";
    }
  }
  return !token || !isAdmin(user) ? <Navigate to={path} replace /> : children;
};

export const IsMedecinAllow = ({ children }: { children: ReactElement }) => {
  const { user, token } = useAppSelector((state) => state.user);
  let path: string = "/";

  useEffect(() => {
    socket.on("messageResponse", (data) => {
      if ([data?.sender, data?.receiver].includes(user?.id)) {
        // console.log("socket app", data);
        if (data?.receiver === user?.id) {
          toast("Vous avez reçu un nouveau message!", {
            hideProgressBar: true,
          });
        }
      }
    });
    return () => {
      socket.off("messageResponse");
    };
  }, [socket]);
  if (token) {
    if (isAdmin(user)) {
      path = "/admin/dashboard";
    } else if (isCabinet(user)) {
      path = "/cabinet/dashboard";
    } else if (isPatient(user)) {
      path = "/patient/menu";
    } else if (isSecretaire(user)) {
      path = "/secretaire-medical/dashboard";
    }
  }

  return !token || !isMedecin(user) ? <Navigate to={path} replace /> : children;
};

export const IsCabinetAllow = ({ children }: { children: ReactElement }) => {
  const { user, token } = useAppSelector((state) => state.user);
  let path: string = "/";
  if (token) {
    if (isAdmin(user)) {
      path = "/admin/dashboard";
    } else if (isMedecin(user)) {
      path = "/medecin/dashboard";
    } else if (isPatient(user)) {
      path = "/patient/menu";
    } else if (isSecretaire(user)) {
      path = "/secretaire-medical/dashboard";
    }
  }

  return !token || !isCabinet(user) ? <Navigate to={path} replace /> : children;
};

export const IsPatientAllow = ({ children }: { children: ReactElement }) => {
  const { user, token } = useAppSelector((state) => state.user);
  let path: string = "/";

  useEffect(() => {
    socket.on("messageResponse", (data) => {
      if ([data?.sender, data?.receiver].includes(user?.id)) {
        // console.log("socket app", data);
        if (data?.receiver === user?.id) {
          // console.log("socket alert", data);

          toast("Vous avez reçu un nouveau message!", {
            hideProgressBar: true,
          });
        }
      }
    });
    return () => {
      socket.off("messageResponse");
    };
  }, [socket]);
  if (token) {
    if (isAdmin(user)) {
      path = "/admin/dashboard";
    } else if (isMedecin(user)) {
      path = "/medecin/dashboard";
    } else if (isCabinet(user)) {
      path = "/cabinet/dashboard";
    } else if (isSecretaire(user)) {
      path = "/secretaire-medical/dashboard";
    }
  }
  return !token || !isPatient(user) ? <Navigate to={path} replace /> : children;
};

export const IsSecretaireAllow = ({ children }: { children: ReactElement }) => {
  const { user, token } = useAppSelector((state) => state.user);
  let path: string = "/";

  useEffect(() => {
    socket.on("messageResponse", (data) => {
      if ([data?.sender, data?.receiver].includes(user?.id)) {
        // console.log("socket app", data);
        if (data?.receiver === user?.id) {
          // console.log("socket alert", data);

          toast("Vous avez reçu un nouveau message!", {
            hideProgressBar: true,
          });
        }
      }
    });
    return () => {
      socket.off("messageResponse");
    };
  }, [socket]);

  if (isAdmin(user)) {
    path = "/admin/dashboard";
  } else if (isMedecin(user)) {
    path = "/medecin/dashboard";
  } else if (isCabinet(user)) {
    path = "/cabinet/dashboard";
  } else if (isPatient(user)) {
    path = "/patient/menu";
  }

  return !token || !isSecretaire(user) ? (
    <Navigate to={path} replace />
  ) : (
    children
  );
};

export const RedirectAuthRoute = ({ children }: { children: ReactElement }) => {
  const { token, user } = useAppSelector((state) => state.user);
  let path: string = "/";
  if (isAdmin(user)) {
    path = "/admin/dashboard";
  } else if (isMedecin(user)) {
    path = "/medecin/dashboard";
  } else if (isCabinet(user)) {
    path = "/cabinet/dashboard";
  } else if (isPatient(user)) {
    path = "/patient/menu";
  } else if (isSecretaire(user)) {
    path = "/secretaire-medical/dashboard";
  }

  return !token && !user?.id ? children : <Navigate to={path} replace />;
};

export const getProfil = (user: IUser) => {
  let router = "/admin/parametres";

  if (isMedecin(user)) {
    router = "/medecin/parametres";
  } else if (isCabinet(user)) {
    router = "/cabinet/parametres";
  } else if (isSecretaire(user)) {
    router = "/secretaire-medical/parametres";
  } else if (isPatient(user)) {
    router = "/patient/profil";
  }

  return router;
};

export const hasAccess = (user: IUser, field: FieldType) => {
  let noAccess = false;
  if (user[field] !== true && user?.user_type !== "superadmin") {
    noAccess = true;
  }

  return noAccess;
};
