import React from "react";
import { useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import { Tarif, TarifFormData } from "../../../../utils/api/tarif/tarif.type";
import { cleannerError, closeModal, useUser } from "../../../../utils/Utils";
import { useAddOrEditTarifMutation, useDeleteTarifMutation } from "../../../../utils/api/tarif/tarif.api";
import { Color } from "../../../../utils/theme";

function AddOrEditTarifForm(modalId: string, item?: Tarif) {
  const validationSchema = yup.object().shape({
    denomination: yup.string().required().label("La dénomination"),
    montant: yup.string().required().label("Le montant").nullable(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
  } = useForm<TarifFormData>({
    resolver: yupResolver(validationSchema),
  });
  const user = useUser();
  const [sendData, { isLoading }] = useAddOrEditTarifMutation();

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (item?.id) {
      setValue("denomination", item?.denomination);
      setValue("montant", item?.montant);
      setValue("slug", item?.slug);
      setValue("cabinet", item?.cabinet?.id);
    }
  }, [item]);

  const handleReset = () => {
    closeModal(modalId);
    if (!item) {
      reset();
    }
  };

  const onSubmit = async (data: TarifFormData) => {
    // console.log({ data });
    if (!item) {
      data["cabinet"] = user?.id;
    }
    const res = await sendData(data);
    if ("data" in res) {
      Swal.fire({
        icon: "success",
        title: `Le tarif a été ${item ? "modifié" : "ajouté"} avec succès`,
        showConfirmButton: false,
        timer: 2000,
        iconColor: Color.success,
      }).then(() => {
        handleReset();
      });
    } else if ("error" in res) {
      const err = res.error as any;
      Swal.fire({
        icon: "error",
        title: err.data?.message ?? "Une erreur inconnue a survenu",
        showConfirmButton: false,
        timer: 5000,
      });
    }
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
    handleReset,
  };
}

export default AddOrEditTarifForm;

export function UseDeleteTarif(slug: string) {
  const [sendData] = useDeleteTarifMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce tarif ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le tarif a été supprimé avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}