import React, { useState } from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import { FormError, Input } from "../../common/Input";
import { FiEdit } from "react-icons/fi";
import { BtnSubmit } from "../../common/Button";
import avatar from "../../../assets/appImages/medecin_image.png";
import UseCrudProfilForm from "../../admin/Profil/request/UseCrudProfil";
import { useAppSelector } from "../../../redux/hook";
import { getAvatar } from "../../../utils/Utils";
import { HiOutlineCamera } from "react-icons/hi2";
import PhoneInput from "react-phone-input-2";
import GoogleInput from "../../common/GoogleInput";
import { isCabinet, isPatient } from "../../../routes/routerUtils";
import ChangePasswordModal from "../../medecin/parametreMedecin/modals/ChangePasswordModal";
import CompleteProfilModal from "../../patient/Modal/CompleteProfilModal";

export const EditPasswordComponent = () => {
  const [show, setShow] = useState(false);
  return (
    <div className="mt-4">
      <WrapperComponent>
        <h2 className="custom-sm-pass-title mb-0">Mot de passe</h2>
        <div className="pt-5">
          <form action="">
            <div className="col-md-6">
              <Input
                label="Mot de passe"
                type="password"
                placeholder="**************"
                readOnly
              />
            </div>
          </form>
        </div>
        <button
          className="btn modify-password-btn"
          onClick={() => setShow(true)}
        >
          <span className="icon">
            <FiEdit />
          </span>
          Changer de mot de passe
        </button>
        <ChangePasswordModal
          modalChangePassword={show}
          setModalChangePassword={setShow}
        />
      </WrapperComponent>
    </div>
  );
};

function ParametreSecretaire() {
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    phone,
    handleChangePhone,
    onChangeAddress,
    address,
    handleChangeAvatar,
    avatar,
    onChangeAddressCabinet,
    addressCabinet,
  } = UseCrudProfilForm();

  const { user } = useAppSelector((s) => s?.user);
  const [modalCompleteProfil, setModalCompleteProfil] = useState(false);

  function openCompleteProfilModal() {
    setModalCompleteProfil(true);
  }
  return (
    <>
      <WrapperComponent>
        <div className="container-title-dash d-flex align-items-center gap-4">
          <h2 className="custom-sm-top-title mb-0">Paramètres</h2>
        </div>
        {!!isPatient(user) && (
          <>
            <button
              className="btn complete-profil-btn"
              onClick={() => openCompleteProfilModal()}
            >
              Completer votre profil
            </button>
            <CompleteProfilModal
              modalCompleteProfil={modalCompleteProfil}
              setModalCompleteProfil={setModalCompleteProfil}
            />
          </>
        )}
        <div className="parametres-tabs-content mt-5">
          <form onSubmit={onSubmit}>
            <div className="d-flex justify-content-center align-items-center mb-4 leader-auto-user-avatar-container">
              <div>
                <label htmlFor="input-file" className="input-testt">
                  <img
                    src={
                      avatar
                        ? URL.createObjectURL(avatar)
                        : getAvatar(user?.avatar)
                    }
                    className="custom-img-profil-user-admin mon-profil-img"
                    alt="Photo"
                  />

                  <div className="leader-auto-user-avatar-edit-hover-deux">
                    <HiOutlineCamera className="edit-avatar-camera-icon" />
                  </div>
                </label>
                <div className="mt-4">
                  <input
                    type="file"
                    className="input-add-avatar"
                    id="input-file"
                    onChange={handleChangeAvatar}
                    accept="image/*"
                  />
                </div>
                <p className="libelle-input-file-pp-praticien">
                  Modifier la photo
                </p>
              </div>
            </div>
            <div className="row row-infos-medecin">
              {!!isCabinet(user) && (
                <>
                  <div className="col-md-6">
                    <Input
                      label="Nom de la structure"
                      type="text"
                      placeholder="Nom de la structure"
                      {...register("nom_cabinet")}
                      error={errors?.nom_cabinet}
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label form-label-modal-custom">
                        Adresse de la structure{" "}
                      </label>
                      <GoogleInput
                        className="form-control form-control-modal-custom"
                        value={addressCabinet}
                        placeholder="Adresse de la structure"
                        onChange={(val) => onChangeAddressCabinet(val)}
                      />
                      <FormError error={errors?.adresse_cabinet?.message} />
                    </div>
                  </div>
                </>
              )}
              <div className="col-md-6">
                <Input
                  label="Prénom"
                  type="text"
                  placeholder="Prénom"
                  {...register("prenom")}
                  error={errors?.prenom}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Nom"
                  type="text"
                  placeholder="Nom"
                  {...register("nom")}
                  error={errors?.nom}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Email"
                  type="email"
                  placeholder="Email"
                  {...register("email")}
                  error={errors?.email}
                />
              </div>
              <div className="col-md-6">
                {/* <Input label="Téléphone" type="numeric" /> */}
                <div className="mb-3">
                  <label className="form-label form-label-modal-custom ">
                    Téléphone{" "}
                  </label>
                  <PhoneInput
                    defaultMask={".. ... .. .."}
                    containerClass={`mb-3 p-0 input-modal-form-add-phone-container`}
                    inputClass={`input-modal-form-add-phone praticiens`}
                    country={"sn"}
                    placeholder=""
                    inputProps={{
                      name: "telephone",
                      required: true,
                      autoFocus: true,
                    }}
                    countryCodeEditable={false}
                    enableAreaCodes={true}
                    prefix="+"
                    value={phone}
                    onChange={(phone, country: any) =>
                      handleChangePhone(phone, country)
                    }
                    inputStyle={{
                      width: "100%",
                      background: "rgba(232, 240, 253, 0.7)",
                    }}
                  />
                  <FormError error={errors?.telephone?.message} />
                </div>
              </div>
              <div className="col-md-6">
                {/* <Input label="Adresse" type="text" /> */}
                <div className="mb-3">
                  <label className="form-label form-label-modal-custom">
                    Adresse{" "}
                  </label>
                  <GoogleInput
                    className="form-control form-control-modal-custom"
                    value={address}
                    placeholder="Adresse"
                    onChange={(val) => onChangeAddress(val)}
                  />
                  <FormError error={errors?.adresse?.message} />
                </div>
              </div>
              {!!isPatient(user) && (
                <div className="col-md-6">
                  <Input
                    label="Date de naissance"
                    type="date"
                    placeholder="Date de naissance"
                    {...register("date_de_naissance")}
                    error={errors?.date_de_naissance}
                  />
                </div>
              )}
              <div className="col-md-6">
                <Input
                  label="Boite postale"
                  type="text"
                  placeholder="Boite postale"
                  {...register("boite_postal")}
                  error={errors?.boite_postal}
                />
              </div>
            </div>
            <div className="d-flex flex-r mt-4">
              <div className="col-md-2">
                <BtnSubmit label="Modifier" isLoading={isLoading} />
              </div>
            </div>
          </form>
        </div>
      </WrapperComponent>

      <EditPasswordComponent />
    </>
  );
}

export default ParametreSecretaire;
