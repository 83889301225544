/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useRef } from "react";
import { IRdvs, RdvsFormData } from "../../../../utils/api/rdvs/rdvs.type";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Color } from "../../../../utils/theme";
import { useAddOrUpdateRdvMutation } from "../../../../utils/api/rdvs/rdvs.api";
import { cleannerError, closeModal } from "../../../../utils/Utils";

function UseConsultationForm(item: IRdvs, modalId?: string) {
  const [contenu, setContenu] = useState("");
  const validationSchema = yup.object().shape({
    taille: yup.string().nullable().label("La taille"),
    poids: yup.string().nullable().label("Le poids"),
    tension: yup.string().nullable().label("La tension").required(),
    pouls: yup.string().label("Le pouls").nullable().required(),
    temperature: yup.string().nullable().label("La température"),
    allergies: yup.boolean().label("L'allergie").nullable(),
    groupe_sanguin: yup.string().label("Le groupe sanguin").nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
    resetField,
    watch,
    getValues,
  } = useForm<RdvsFormData>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });
  const ref = useRef<any>(null);
  const [allergie, setAllergie] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [sendData, { isLoading, isSuccess, isError, error }] =
    useAddOrUpdateRdvMutation();

  const onChange = (val: string) => {
    setContenu(val);
    setValue("instructions", val);
  };

  const onChangeAllergie = (e) => {
    setAllergie(e?.target?.value);
    if (e?.target?.value === "true") {
      setValue("allergies", true);
    } else if (e?.target?.value === "false") {
      setValue("allergies", false);
    } else {
      resetField("allergies");
    }
  };

  const handleReset = () => {
    if (modalId) {
      closeModal(modalId);
      setAllergie("");
      resetField("allergies");
      resetField("poids");
      resetField("taille");
      resetField("temperature");
      resetField("tension");
      resetField("pouls");
      resetField("groupe_sanguin");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Les constantes ont été enregistrées avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        // navigate(`/medecin/rendez-vous/${item?.slug}`, { state: item });
        setShowInput(false);
        if (modalId) {
          handleReset();
        }
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (item?.id) {
      console.log(item)
      let fields: (keyof RdvsFormData)[] = [
        "poids",
        "allergies",
        "pouls",
        "temperature",
        "taille",
        "tension",
        "groupe_sanguin",
      ];

      if (item?.allergies === true) {
        setAllergie("true");
      } else {
        setAllergie("false");
      }

      if (item?.instructions) {
        setContenu(item?.instructions || "");
      }

      for (let field of fields) {
        register(field);
        if (field === "groupe_sanguin") {
          setValue(
            field,
            item[field] ||
              item?.patient[field] ||
              item?.patient?.constantes[field]
          );
        } else if (field === "taille" || field === "poids") {
          setValue(field, item[field] || item?.patient?.constantes[field]);
        } else if (
          field !== "structure" &&
          field !== "medecin" &&
          field !== "patient"
        ) {
          setValue(field, item[field]);
        } else {
          setValue(field, item[field]?.id);
        }
      }
    }
  }, [item]);

  // useEffect(() => {
  //   const keyDownHandler = (event) => {
  //     console.log("event", event);
  //     if (event.key === "Enter") {
  //       // event.preventDefault();
  //       let data = {
  //         poids: getValues("poids"),
  //         allergies: getValues("allergies"),
  //         pouls: getValues("pouls"),
  //         temperature: getValues("temperature"),
  //         taille: getValues("taille"),
  //         tension: getValues("tension"),
  //         groupe_sanguin: getValues("groupe_sanguin"),
  //       };
  //       onSubmit(data);
  //     }
  //   };

  //   document.addEventListener("keydown", keyDownHandler);

  //   return () => {
  //     document.removeEventListener("keydown", keyDownHandler);
  //   };
  // }, [watch]);

  const onSubmit = async (data: RdvsFormData) => {
    
    if (item?.status === "en_attente") {
      data["status"] = "en_cours";
    }
    await sendData({
      slug: item?.slug,
      data: data,
    });
    // if (Object.values(data)?.some((key) => key)) {
    //   console.log("ok");
    //   await sendData({
    //     slug: item?.slug,
    //     data: data,
    //   });
    // } else {
    //   console.log("Nok");
    // }
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    Controller,
    control,
    onChange,
    onChangeAllergie,
    contenu,
    allergie,
    setShowInput,
    showInput,
    ref,
    handleReset,
  };
}

export default UseConsultationForm;
