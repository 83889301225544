import React from "react";
import { Navigate } from "react-router-dom";
import DashboardAdmin from "../components/admin/DashboardAdmin/DashboardAdmin";
import Login from "../components/auth/Login";
import ForgetPassword from "../components/auth/ForgetPassword";
import ResetPassword from "../components/auth/ResetPassword";
import AdminLayout from "../components/shared/AdminLayout/AdminLayout";
import PagesError404 from "../components/shared/PagesError/PagesError404";
import PraticiensAdmin from "../components/admin/PraticiensAdmin/PraticiensAdmin";
import RdvAdmin from "../components/admin/RdvAdmin/RdvAdmin";
import ParametreAdmin from "../components/admin/ParametreAdmin/ParametreAdmin";
import AddAdmin from "../components/admin/ParametreAdmin/AddAdmin";
import DetailsAdmin from "../components/admin/ParametreAdmin/DetailsAdmin";

import RdvMedecin from "../components/medecin/rdvMedecin/RdvMedecin";
import PatientMedecin from "../components/medecin/patientMedecin/PatientMedecin";
import AddNewPatient from "../components/medecin/patientMedecin/AddNewPatient";
import PatientDetail from "../components/medecin/patientMedecin/patientDetail/PatientDetail";
import PaiementMedecin from "../components/medecin/paiementMedecin/PaiementMedecin";
import TacheMedecin from "../components/medecin/tacheMedecin/TacheMedecin";
import ParametreMedecin from "../components/medecin/parametreMedecin/ParametreMedecin";

import DashboardPatient from "../components/patient/dashboardPatient/DashboardPatient";
import RdvPatient from "../components/patient/rdvPatient/RdvPatient";
import DetailRdvPatient from "../components/patient/rdvPatient/DetailRdvPatient";
import FacturePatient from "../components/patient/facturePatient/FacturePatient";
import DetailFacturePatient from "../components/patient/facturePatient/DetailFacturePatient";
import VaccinPatient from "../components/patient/vaccinPatient/VaccinPatient";
import FichierPatient from "../components/patient/fichierPatient/FichierPatient";
import OrdonnancePatient from "../components/patient/ordonnancePatient/OrdonnancePatient";
import ResultatPatient from "../components/patient/resultatPatient/ResultatPatient";

import PraticienSecretaire from "../components/secretaire/praticienSecretaire/PraticienSecretaire";
import DetailPraticen from "../components/secretaire/praticienSecretaire/DetailPraticen";
import RdvSecretaire from "../components/secretaire/rdvSecretaire/RdvSecretaire";
import AddRdvSecretaire from "../components/secretaire/rdvSecretaire/AddRdvSecretaire";
import DetailRdvSecretaire from "../components/secretaire/rdvSecretaire/DetailRdvSecretaire";

import DetailDossier from "../components/secretaire/dossierSecretaire/DetailDossier";
import ParametreSecretaire from "../components/secretaire/parametreSecretaire/ParametreSecretaire";
import MessagerieAdmin from "../components/admin/Messages/MessagerieAdmin";
import DetailRdv from "../components/shared/ActiviteRdv/DetailRdv";
import DetailOrdonnance from "../components/medecin/patientMedecin/patientDetail/DetailOrdonnance";
import AddNewMedicament from "../components/medecin/patientMedecin/patientDetail/AddNewMedicament";
import AddNewAnalyse from "../components/medecin/patientMedecin/patientDetail/AddNewAnalyse";
import AddNewScanner from "../components/medecin/patientMedecin/patientDetail/AddNewScanner";
import AddOrUpdatePraticienAdmin from "../components/admin/PraticiensAdmin/AddOrUpdatePraticienAdmin";
import SecretaireMedical from "../components/cabinet/SecretaireMedical/SecretaireMedical";
import AddSecretaireMedicalCabinet from "../components/cabinet/SecretaireMedical/AddSecretaireMedicalCabinet";
import CabinetHopitaux from "../components/admin/CabinetHopitaux/CabinetHopitaux";
import AddCabinetHopitaux from "../components/admin/CabinetHopitaux/AddCabinetHopitaux";
import DashboardCabinet from "../components/cabinet/DashboardCabinet/DashboardCabinet";
import {
  IsAdminAllow,
  IsCabinetAllow,
  IsMedecinAllow,
  IsPatientAllow,
  IsSecretaireAllow,
  RedirectAuthRoute,
} from "./routerUtils";
import SpecialitesAdmin from "../components/admin/SpecialitesAdmin/SpecialitesAdmin";
import DossierPatient from "../components/secretaire/dossierSecretaire/Dossier";
import DemarrerConsultation from "../components/medecin/rdvMedecin/DemarrerConsultation";
import AddNewVaccin from "../components/medecin/patientMedecin/patientDetail/AddNewVaccin";
import ConsultationPage from "../components/medecin/rdvMedecin/consultation/ConsultationPage";
import MessageItemMobile from "../components/admin/Messages/MessageItemMobile";
import AgendaMedecin from "../components/secretaire/praticienSecretaire/AgendaMedecin";
import AddOrEditAntecedent from "../components/medecin/patientMedecin/patientDetail/AddOrEditAntecedent";
import TarificationPage from "../components/cabinet/tarification/TarificationPage";
import MenuPatientV2 from "../components/patient/menuPatient/MenuPatientV2";
import HistoryPatient from "../components/patient/history/historyPatient";

// UserRoutes

export const AppRoutes = [
  {
    path: "/",
    element: (
      <RedirectAuthRoute>
        <Login />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "/mot-de-passe-oublie",
    element: (
      <RedirectAuthRoute>
        <ForgetPassword />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "/reinitialiser-mot-de-passe",
    element: (
      <RedirectAuthRoute>
        <ResetPassword />
      </RedirectAuthRoute>
    ),
  },
  // {
  //   path: "/inscription",
  //   element: <RedirectAuthRoute><Register /></RedirectAuthRoute>,
  // },
  {
    path: "*",
    element: <PagesError404 />,
  },
  {
    path: "admin/",
    element: (
      <IsAdminAllow>
        <AdminLayout />
      </IsAdminAllow>
    ),
    children: [
      { element: <Navigate to="/admin/dashboard" replace />, index: true },
      {
        path: "dashboard",
        element: <DashboardAdmin />,
      },
      {
        path: "praticiens",
        element: <PraticiensAdmin />,
      },
      {
        path: "praticiens/:slug",
        element: <DetailPraticen />,
      },
      {
        path: "rendez-vous",
        element: <RdvAdmin />,
      },
      {
        path: "rendez-vous/:slug",
        element: <DetailRdv />,
      },
      // {
      //   path: "rendez-vous/ajouter-rendez-vous",
      //   element: <AddRdv />,
      // },
      // {
      //   path: "rendez-vous/modifier-rendez-vous/:slug",
      //   element: <AddRdv />,
      // },
      // {
      //   path: "dossiers",
      //   element: <DossiersAdmin />,
      // },
      // {
      //   path: "dossiers/:slug",
      //   element: <DetailDossier />,
      // },
      {
        path: "parametres",
        element: <ParametreAdmin />,
      },
      {
        path: "parametres/ajouter-un-admin",
        element: <AddAdmin />,
      },
      {
        path: "parametres/modifier-un-admin/:slug",
        element: <AddAdmin />,
      },
      {
        path: "parametres/liste-admins/:slug",
        element: <DetailsAdmin />,
      },
      {
        path: "specialites",
        element: <SpecialitesAdmin />,
      },
      {
        path: "messages",
        element: <MessagerieAdmin />,
      },
      {
        path: "ajouter-praticien",
        element: <AddOrUpdatePraticienAdmin />,
      },
      {
        path: "cabinets",
        element: <CabinetHopitaux />,
      },
      {
        path: "cabinets/ajouter-cabinet",
        element: <AddCabinetHopitaux />,
      },
      {
        path: "cabinets/modifier-cabinet/:slug",
        element: <AddCabinetHopitaux />,
      },
    ],
  },
  {
    path: "medecin/",
    element: (
      <IsMedecinAllow>
        <AdminLayout />
      </IsMedecinAllow>
    ),
    children: [
      { element: <Navigate to="/medecin/dashboard" replace />, index: true },
      {
        path: "dashboard",
        element: <DashboardAdmin />,
      },
      {
        path: "rendez-vous",
        element: <RdvMedecin />,
      },
      {
        path: "rendez-vous/:slug",
        element: <ConsultationPage />,
      },
      {
        path: "ajouter-constantes/:slug",
        element: <DemarrerConsultation />,
      },
      // {
      //   path: "rendez-vous/ajouter-rendez-vous",
      //   element: <AddRdvSecretaire />,
      // },
      // {
      //   path: "rendez-vous/modifier-rendez-vous/:slug",
      //   element: <AddRdvSecretaire />,
      // },
      {
        path: "patients",
        element: <PatientMedecin />,
      },
      {
        path: "patients/ajouter-patient",
        element: <AddNewPatient />,
      },
      {
        path: "patients/modifier-patient/:slug",
        element: <AddNewPatient />,
      },
      {
        path: "patients/:slug",
        element: <PatientDetail />,
      },
      {
        path: "ordonnance/:slug/:type",
        element: <DetailOrdonnance />,
      },
      {
        path: "ajouter-medicament/:slug",
        element: <AddNewMedicament />,
      },
      {
        path: "ajouter-analyse/:slug",
        element: <AddNewAnalyse />,
      },
      {
        path: "ajouter-scanner/:slug",
        element: <AddNewScanner />,
      },
      {
        path: "ajouter-vaccination/:slug",
        element: <AddNewVaccin />,
      },
      {
        path: "modifier-vaccination/:slug/vaccin/:id",
        element: <AddNewVaccin />,
      },
      {
        path: "antecedent-medical/:slug",
        element: <AddOrEditAntecedent />,
      },
      {
        path: "paiements",
        element: <PaiementMedecin />,
      },
      {
        path: "taches",
        element: <TacheMedecin />,
      },
      {
        path: "parametres",
        element: <ParametreMedecin />,
      },
      {
        path: "messages",
        element: <MessagerieAdmin />,
      },
      {
        path: "messages/details",
        element: <MessageItemMobile />,
      },
    ],
  },
  {
    path: "secretaire-medical/",
    element: (
      <IsSecretaireAllow>
        <AdminLayout />
      </IsSecretaireAllow>
    ),
    children: [
      {
        element: <Navigate to="/secretaire-medical/dashboard" replace />,
        index: true,
      },
      {
        path: "dashboard",
        element: <DashboardAdmin />,
      },
      {
        path: "agenda-medecins",
        element: <AgendaMedecin />,
      },
      {
        path: "praticiens",
        // element: <PraticienSecretaire />,
        element: <AgendaMedecin />,
      },
      {
        path: "praticiens/:slug",
        element: <DetailPraticen />,
      },
      {
        path: "patients",
        element: <PatientMedecin />,
      },
      {
        path: "patients/ajouter-patient",
        element: <AddNewPatient />,
      },
      {
        path: "patients/modifier-patient/:slug",
        element: <AddNewPatient />,
      },
      {
        path: "rendez-vous",
        element: <RdvSecretaire />,
      },
      {
        path: "rendez-vous/ajouter-rendez-vous",
        element: <AddRdvSecretaire />,
      },
      {
        path: "rendez-vous/modifier-rendez-vous/:slug",
        element: <AddRdvSecretaire />,
      },
      {
        path: "rendez-vous/:slug",
        element: <DetailRdvSecretaire />,
      },
      // {
      //   path: "dossiers",
      //   element: <DossierPatient />,
      // },
      // {
      //   path: "dossiers/:slug",
      //   element: <DetailDossier />,
      // },
      {
        path: "paiements",
        element: <PaiementMedecin />,
      },
      {
        path: "parametres",
        element: <ParametreSecretaire />,
      },
      {
        path: "messages",
        element: <MessagerieAdmin />,
      },
    ],
  },
  {
    path: "patient/",
    element: (
      <IsPatientAllow>
        <AdminLayout />
      </IsPatientAllow>
    ),
    children: [
      { element: <Navigate to="/patient/menu" replace />, index: true },
      {
        path: "menu",
        // element: <MenuPatient />,
        element: <MenuPatientV2 />,
      },
      {
        path: "dashboard",
        element: <DashboardPatient />,
      },
      {
        path: "rendez-vous",
        element: <RdvPatient />,
      },
      {
        path: "rendez-vous/detail/:slug",
        element: <DetailRdvPatient />,
      },
      {
        path: "facture",
        element: <FacturePatient />,
      },
      {
        path: "facture/detail",
        element: <DetailFacturePatient />,
      },
      {
        path: "vaccins",
        element: <VaccinPatient />,
      },
      {
        path: "fichiers",
        element: <FichierPatient />,
      },
      {
        path: "ordonnance",
        element: <OrdonnancePatient />,
      },
      {
        path: "ordonnance/:slug",
        element: <DetailOrdonnance />,
      },
      {
        path: "resultats",
        element: <ResultatPatient />,
      },
      {
        path: "antécédents",
        element: <HistoryPatient />,
      },
      {
        path: "profil",
        element: <ParametreSecretaire />,
      },
      {
        path: "messages",
        element: <MessagerieAdmin />,
      },
    ],
  },
  {
    path: "cabinet/",
    element: (
      <IsCabinetAllow>
        <AdminLayout />
      </IsCabinetAllow>
    ),
    children: [
      { element: <Navigate to="/cabinet/dashboard" replace />, index: true },
      {
        path: "dashboard",
        element: <DashboardAdmin />,
      },
      {
        path: "praticiens",
        element: <PraticienSecretaire />,
      },
      {
        path: "praticiens/:slug",
        element: <DetailPraticen />,
      },
      {
        path: "secretaires",
        element: <SecretaireMedical />,
      },
      {
        path: "secretaires/ajouter-secretaire",
        element: <AddSecretaireMedicalCabinet />,
      },
      {
        path: "secretaires/modifier-secretaire/:slug",
        element: <AddSecretaireMedicalCabinet />,
      },
      {
        path: "rendez-vous",
        element: <RdvSecretaire />,
      },
      // {
      //   path: "rendez-vous/ajouter-rendez-vous",
      //   element: <AddRdvSecretaire />,
      // },
      // {
      //   path: "rendez-vous/modifier-rendez-vous/:slug",
      //   element: <AddRdvSecretaire />,
      // },
      {
        path: "rendez-vous/:slug",
        element: <DetailRdvSecretaire />,
      },
      // {
      //   path: "dossiers",
      //   element: <DossierPatient />,
      // },
      // {
      //   path: "dossiers/:slug",
      //   element: <DetailDossier />,
      // },
      {
        path: "paiements",
        element: <PaiementMedecin />,
      },
      {
        path: "parametres",
        element: <ParametreSecretaire />,
      },
      {
        path: "tarifications",
        element: <TarificationPage />,
      },
      {
        path: "praticiens/ajouter-praticien",
        element: <AddOrUpdatePraticienAdmin />,
      },
      {
        path: "praticiens/modifier-praticien/:slug",
        element: <AddOrUpdatePraticienAdmin />,
      },
    ],
  },
];
