/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Facturation,
  FacturationFormData,
} from "../../../../utils/api/facturation/facturation.type";
import {
  useAddOrEditFacturationMutation,
  useDeleteFacturationMutation,
} from "../../../../utils/api/facturation/facturation.api";
import {
  cleannerError,
  closeModal,
  formatCurrency,
  formatDate,
  getName,
  useUser,
} from "../../../../utils/Utils";
import {
  useGetPatientsByCabinetQuery,
  useGetPraticienByCabinetQuery,
} from "../../../../utils/api/user/user.api";
import {
  isCabinet,
  isMedecin,
  isSecretaire,
} from "../../../../routes/routerUtils";
import { Color } from "../../../../utils/theme";
import { useGetTarifByCabinetQuery } from "../../../../utils/api/tarif/tarif.api";

function UseAddOrEditFacturationForm(modalId: string, item?: Facturation) {
  const validationSchema = yup.object().shape({
    patient: yup.string().required().label("Le patient").nullable(),
    medecin: yup.string().required().label("Le médecin").nullable(),
    tarifs: yup
      .array()
      .min(1, "Le tarif est obligatoire")
      .required()
      .nullable()
      .label("Le tarif"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
    reset,
  } = useForm<FacturationFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [options, setOptions] = useState<any>([]);
  const [option, setOption] = useState<any>(null);
  const [search, setSearch] = useState("");
  const [optionsPraticien, setOptionsPraticien] = useState<any>([]);
  const [optionPraticien, setOptionPraticien] = useState<any>(null);
  const [optionsTarif, setOptionsTarif] = useState<any>([]);
  const [optionTarif, setOptionTarif] = useState<any>(null);
  const [query, setQuery] = useState("");
  const [searchPraticien, setSearchPraticien] = useState("");
  const user = useUser();
  const { data: tarifs = { results: [] } } = useGetTarifByCabinetQuery({
    slug: isCabinet(user) ? user?.slug : user?.structure?.slug,
    limit: 50,
    q: query,
  });
  const { data = { results: [] } } = useGetPatientsByCabinetQuery({
    q: search,
    limit: 50,
    slug: isCabinet(user) ? user?.slug : user?.structure?.slug,
  });
  const { data: praticiens = { results: [] } } = useGetPraticienByCabinetQuery({
    q: searchPraticien,
    limit: 50,
    slug: isCabinet(user) ? user?.slug : user?.structure?.slug,
  });
  const [sendData, { isLoading, isSuccess, isError, error }] =
    useAddOrEditFacturationMutation();

  useEffect(() => {
    if (isCabinet(user)) {
      setValue("cabinet", user?.id);
    } else if (isSecretaire(user)) {
      setValue("cabinet", user?.structure?.id);
    } else if (isMedecin(user)) {
      setValue("cabinet", user?.structure?.id);
      setValue("medecin", user?.id);
    }
  }, [user]);

  useEffect(() => {
    if (data?.results?.length) {
      setOptions(
        data?.results?.map((el) => {
          return {
            label:
              getName(el) +
              "( Né(e) le " +
              formatDate(el?.date_de_naissance) +
              " )",
            value: el?.id,
            ...el,
          };
        })
      );
    }
  }, [data]);

  useEffect(() => {
    if (praticiens?.results?.length) {
      setOptionsPraticien(
        praticiens?.results?.map((el) => {
          return {
            label: getName(el),
            value: el?.id,
            ...el,
          };
        })
      );
    }
  }, [praticiens]);

  useEffect(() => {
    if (tarifs?.results?.length) {
      setOptionsTarif(
        tarifs?.results?.map((el) => {
          return {
            label: el?.denomination + "(" + formatCurrency(el?.montant) + ")",
            value: el?.id,
            ...el,
          };
        })
      );
    }
  }, [tarifs]);

  const handleSelectPraticien = (val) => {
    setOptionPraticien(val);
    setValue("medecin", val?.value);
  };

  const handleSelectTarif = (val) => {
    setOptionTarif(val);
    // console.log(val);

    setValue(
      "tarifs",
      val?.map((el) => el?.value)
    );
  };

  const handleSelectPatient = (val) => {
    setOption(val);
    setValue(`patient`, val?.value);
  };

  const handleReset = () => {
    // resetField("resultat");
    if (!item) {
      reset();
      setOption(null);
      setOptionPraticien(null);
      setOptionTarif(null);
    }
    closeModal(modalId);
  };

  useEffect(() => {
    if (item?.id) {
      let fields: (keyof FacturationFormData)[] = [
        "cabinet",
        "medecin",
        "patient",
        "tarifs",
      ];

      if (item?.patient) {
        setOption({
          label: getName(item?.patient),
          value: item?.patient?.id,
        });
      }

      if (item?.medecin) {
        setOptionPraticien({
          label: getName(item?.medecin),
          value: item?.medecin?.id,
        });
      }

      if (item?.tarifs?.length) {
        setOptionTarif(
          item?.tarifs?.map((el) => {
            return {
              label: el?.denomination + "(" + formatCurrency(el?.montant) + ")",
              value: el?.id,
              ...el,
            };
          })
        );
      }

      for (let field of fields) {
        register(field);
        if (field !== "cabinet" && field !== "medecin" && field !== "patient") {
          if (field === "tarifs") {
            setValue(
              field,
              item[field]?.map((el) => el?.id)
            );
          } else {
            setValue(field, item[field]);
          }
        } else {
          setValue(field, item[field]?.id);
        }
      }
    }
  }, [item]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `La facturation a été ${
          item ? "modifiée" : "effectuée"
        } avec succès`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        handleReset();
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    // console.log("cabinet", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: FacturationFormData) => {
    // let fd = new FormData();

    // for (const field in data) {
    //   fd.append(field, data[field]);
    // }
    await sendData({ slug: item?.slug, data });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    control,
    handleReset,
    options,
    option,
    optionsPraticien,
    optionPraticien,
    handleSelectPatient,
    setSearchPraticien,
    setSearch,
    setQuery,
    optionTarif,
    optionsTarif,
    handleSelectTarif,
    handleSelectPraticien,
    Controller,
  };
}

export default UseAddOrEditFacturationForm;
export function UseDeleteFacturation(slug: string) {
  const [sendData] = useDeleteFacturationMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce paiement ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le paiement a été supprimé avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}

export function UseEditStatutFacturation(item: Facturation) {
  const [sendData] = useAddOrEditFacturationMutation();
  const onEdit = async () => {
    await Swal.fire({
      title: `Status du paiement`,
      input: "select",
      inputValue: item?.status,
      inputOptions: {
        payé: "Payé",
        nopayé: "Non payé",
      },
      inputPlaceholder: "Selectionner un statut",
      showCancelButton: true,
      confirmButtonText: "Modifier",
      cancelButtonText: "Annuler",
      reverseButtons: true,
      confirmButtonColor: Color.success,
      showLoaderOnConfirm: true,

      preConfirm: (status) => {
        // console.log("statut", status);
        if (!status) {
          return Swal.showValidationMessage(`Veuillez sélectionner un statut`);
        }
        return sendData({
          slug: item?.slug,
          data: { status: status === "nopayé" ? "non payé" : status },
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le statut du paiement a été modifié avec succès!`,
            iconColor: Color.success,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onEdit;
}
