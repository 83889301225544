/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { Dispatch, SetStateAction } from "react";
import Modal from "react-modal";
import { CloseModalBtnV2 } from "../../shared/CloseModalBtn";
import { Input } from "../../common/Input";
import { BtnSubmit } from "../../common/Button";
import { IRdvs } from "../../../utils/api/rdvs/rdvs.type";
import UseAddFileForm from "../../medecin/rdvMedecin/form/UseAddFileForm";

const AddFichierModal = ({
  modalAddFichier,
  setModalAddFichier,
  rdv,
}: {
  modalAddFichier: boolean;
  setModalAddFichier: Dispatch<SetStateAction<boolean>>;
  rdv: IRdvs;
}) => {
  const { register, errors, onSubmit, isLoading, handleChange, handleReset } =
    UseAddFileForm(rdv, setModalAddFichier);
  function closeModalAddFichier() {
    setModalAddFichier(false);
    handleReset();
  }

  return (
    <Modal
      isOpen={modalAddFichier}
      onRequestClose={closeModalAddFichier}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal-header pe-2 py-1">
        <h5 className="modal-title" id="AddFichierModalLabel">
          Ajouter une fichier
        </h5>
        <CloseModalBtnV2 onClick={closeModalAddFichier} />
      </div>
      <div className="container-scroll-modal">
        <div className="container-form pt-4">
          <form onSubmit={onSubmit}>
            <div className="row add-Fichier-row">
              <div className="col-md-12">
                <Input
                  label="Nom du fichier"
                  type="text"
                  {...register("nom")}
                  error={errors?.nom}
                />
              </div>
              <div className="col-md-12">
                <Input
                  label="Fichier"
                  type="file"
                  onChange={handleChange}
                  accept=".pdf, .doc, .docx"
                  error={errors?.fichier}
                />
              </div>
            </div>
            <div className="d-flex flex-r mt-4">
              <div className="col-md-4">
                <BtnSubmit label="Ajouter" isLoading={isLoading} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default AddFichierModal;

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    zIndex: 99999,
  },
};
