import React from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import Calendar from "./CalendarAgenda";
import {
  MdLocationOn,
  MdOutlineModeEdit,
  MdUnarchive,
  MdWifiCalling3,
} from "react-icons/md";
import { BsTrash } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { IoMail } from "react-icons/io5";
import { usePraticienFromLocation } from "../../../utils/api/user/user.api";
import { formatDate, getAvatar, getName } from "../../../utils/Utils";
import { useAppSelector } from "../../../redux/hook";
import { isCabinet } from "../../../routes/routerUtils";
import { IUser } from "../../../utils/api/user/user.type";
import {
  UseArchivePraticien,
  UseDeleteUser,
} from "../../admin/PraticiensAdmin/requestForm/UseAddOrEditPraticienForm";
import { BiArchiveIn } from "react-icons/bi";
import { useUpcommingRdvByPraticienQuery } from "../../../utils/api/rdvs/rdvs.api";
import { AlertInfo } from "../../common/Alert";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

const BtnArchivePraticien = ({ item }: { item: IUser }) => {
  const onArchive = UseArchivePraticien(item);

  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content={item?.is_archive ? "Désarchiver" : "Archiver"}
      style={{ color: "#E53E3E" }}
      onClick={onArchive}
    >
      {/* <BsTrash /> */}
      {item?.is_archive ? <MdUnarchive /> : <BiArchiveIn />}
    </button>
  );
};

export const BtnDelete = ({
  slug,
  redirect,
}: {
  slug: string;
  redirect?: string;
}) => {
  const onDelete = UseDeleteUser(slug, redirect);

  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content={"Supprimer"}
      style={{ color: "#E53E3E" }}
      onClick={onDelete}
    >
      <BsTrash />
    </button>
  );
};

const CardSkeleton = () => {
  return (
    <>
      {[...Array(3)]?.map((el, i) => (
        <div className="rdv-upcoming-item" key={i}>
          <div className="custom-cs d-flex align-items-center gap-4 pt-4 pb-2">
            <Skeleton className="rdv-cs-title" width={150} />
           
            <Skeleton className="rdv-cs-value" width={200} height={15} />
          </div>
          <div className="rdv-cs-hour d-flex gap-2">
            <Skeleton width={100} /> - <Skeleton width={75} />
          </div>
          <div className="card-separator" />
        </div>
      ))}
    </>
  );
};

export const InfosPraticien = ({ item }: { item: IUser }) => {
  const { data = { results: [], count: 0 }, isLoading } =
    useUpcommingRdvByPraticienQuery({
      slug: item?.slug,
      limit: 3,
    });

  // console.log("useUpcommingRdv", data);
  return (
    <div className="row row-detail-praticien">
      <div className="col-md-7 col-detail-praticien">
        <div className="light-card incoming-rdv-card">
          <div className="custom-card-header">
            <div className="custom-title">
              <span className="demand">Prochains rendez-vous</span>
            </div>

            {!!isLoading && <CardSkeleton />}
            {!isLoading &&
              (data?.results?.length ? (
                data?.results?.map((rdv, i) => (
                  <div className="rdv-upcoming-item" key={rdv?.slug}>
                    <div className="custom-cs d-flex align-items-center gap-4 pt-4 pb-2">
                      <span className="rdv-cs-title">
                        {getName(rdv?.patient)}
                      </span>
                      {/* <Skeleton /> */}
                      <span className="rdv-cs-value">{rdv?.motif}</span>
                    </div>
                    <div className="rdv-cs-hour">
                      {formatDate(rdv?.date)} -{" "}
                      {moment(rdv?.horaire_de_visite, "HH:mm").format(
                        "HH[h]mm"
                      )}
                    </div>
                    <div className="card-separator" />
                  </div>
                ))
              ) : (
                <AlertInfo message="Aucun rendez-vous disponible!" />
              ))}
          </div>
        </div>
      </div>
      <div className="col-md-5 col-detail-praticien">
        <div className="light-card detail-pra-card">
          <div className="custom-card-header d-flex pb-3">
            <div className="rdv-title-content d-flex flex-column gap-3">
              <div className="custom-title ">
                <span className="demand">Détail du praticien</span>
              </div>
              <div className="d-flex align-items-center gap-3">
                <img src={getAvatar(item?.avatar)} alt="photo" />
                <div className="custom-infos-content d-flex flex-column gap-2">
                  <span className="cs-title">
                    {getName(item)}
                  </span>
                  <span className="cs-value">{item?.email}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="card-separator"></div>
          <div className="med-info d-flex align-items-center gap-3 pt-2">
            <span></span>
            <div className="custom-infos-content">
              <div className="cs-title pb-2">{item?.specialite?.nom}</div>
              <div className="cs-value">{item?.structure?.nom_cabinet}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function DetailPraticen() {
  const [item] = usePraticienFromLocation();
  const { user } = useAppSelector((s) => s?.user);

  return (
    <WrapperComponent>
      <div className="container-title-dash d-flex align-items-center gap-4">
        <h2 className="custom-sm-top-title mb-0">Detail praticien</h2>
      </div>

      <div className="custom-content pt-4 pb-4">
        {isCabinet(user) ? (
          <div className="row-detail-praticien-cabinet">
            <div className="col-detail-praticien">
              <div className="light-card incoming-rdv-card">
                <div className="container-info-detail-praticien-cabinet">
                  <div className="content-cabinet-praticien-detail-praticien">
                    <div className="container-img-praticien-cabinet">
                      <img
                        src={getAvatar(item?.avatar)}
                        alt={getName(item) || "avatar"}
                        className="img-praticien-cabinet"
                      />
                    </div>
                    <div>
                      <div className="custom-card-header">
                        <div className="custom-cs d-flex align-items-center gap-3 py-2">
                          <span className="rdv-cs-title">Nom :</span>
                          <span className="rdv-cs-value">{getName(item)}</span>
                        </div>
                      </div>
                      <div className="custom-cs d-flex align-items-center gap-3 py-2">
                        <span className="rdv-cs-title">Spécialité :</span>
                        <span className="rdv-cs-value">
                          {item?.specialite?.nom}
                        </span>
                      </div>
                      <div className="custom-cs d-flex align-items-center gap-3 py-2">
                        <span className="rdv-cs-title">Genre :</span>
                        <span className="rdv-cs-value">{item?.civilite}</span>
                      </div>
                      <div className="d-flex gap-4">
                        <div className="custom-cs d-flex align-items-center gap-3 py-2">
                          <span className="rdv-cs-title">
                            <MdLocationOn />
                          </span>
                          <span className="rdv-cs-title">{item?.adresse} </span>
                        </div>
                        <div className="custom-cs d-flex align-items-center gap-3 py-2">
                          <span className="rdv-cs-title">
                            <MdWifiCalling3 />
                          </span>
                          <span className="rdv-cs-title">
                            {item?.telephone}
                          </span>
                        </div>
                      </div>
                      <div className="custom-cs d-flex align-items-center gap-3 py-2">
                        <span className="rdv-cs-title">
                          <IoMail />
                        </span>
                        <span className="rdv-cs-title">{item?.email}</span>
                      </div>
                    </div>
                  </div>
                  <div className="table-actions-btn-container-commandes d-flex gap-3">
                    <div className="container-btn-action-icon-modal">
                      <NavLink
                        className="btn btn-action-icon-edit with-tooltip"
                        data-tooltip-content="Modifier"
                        to={`/cabinet/praticiens/modifier-praticien/${item?.slug}`}
                        state={item}
                      >
                        <MdOutlineModeEdit />
                      </NavLink>
                    </div>
                    {/* <div className="container-btn-action-icon-modal">
                      <NavLink
                        to="/cabinet/messages"
                        className="btn btn-action-modal-icon with-tooltip"
                        data-tooltip-content="Message"
                      >
                        <IoMail />
                      </NavLink>
                    </div> */}
                    <div className="container-btn-action-icon-modal">
                      <BtnArchivePraticien item={item} />
                    </div>
                    <div className="container-btn-action-icon-modal">
                      <BtnDelete
                        slug={item?.slug}
                        redirect="/cabinet/praticiens"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <InfosPraticien item={item} />
        )}
      </div>
      <div className="content-graph-admin">
        <Calendar user={item} />
      </div>
    </WrapperComponent>
  );
}

export default DetailPraticen;
