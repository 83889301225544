/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import WrapperComponent from "../../../shared/WrapperComponent";
import { useStepType } from "../../../../utils/type";
import { useStep } from "react-hooks-helper";
import Notes from "./patientDetailsTabs/Notes";
import RendezVous from "./patientDetailsTabs/RendezVous";
import Resultats from "./patientDetailsTabs/Resultats";
import Medicaments from "./patientDetailsTabs/Medicaments";
import Vaccins from "./patientDetailsTabs/Vaccins";
import Dossier from "./patientDetailsTabs/Dossier";
import Thermometer from "../../../../assets/icons/celsius.png";
import { usePatientFromLocation } from "../../../../utils/api/user/user.api";
import {
  getAge,
  getAvatar,
  getName,
  showModal,
  useUser,
} from "../../../../utils/Utils";
import { IUser } from "../../../../utils/api/user/user.type";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MdOutlineAddBox } from "react-icons/md";
import { IRdvs, RdvsFormData } from "../../../../utils/api/rdvs/rdvs.type";
import { useAddOrUpdateRdvMutation, useGetRdvsByPatientQuery } from "../../../../utils/api/rdvs/rdvs.api";
import moment from "moment";
import Swal from "sweetalert2";
import UseConsultationForm from "../../rdvMedecin/form/UseConsultationForm";
import { UseFormRegister } from "react-hook-form";
import pressure from "../../../../assets/icons/variable1.png";
import pulse from "../../../../assets/icons/variable2.png";
import allergy from "../../../../assets/icons/variable3.png";
import poids from "../../../../assets/icons/variable4.png";
import human_size from "../../../../assets/icons/variable5.png";
import blood_group from "../../../../assets/icons/variable6.png";
import Historique from "./patientDetailsTabs/Historique";
import AddConstanteModal from "./AddConstanteModal";

const SideBarItem = ({
  title,
  icon,
  customColor,
  value,
  pst,
  register,
  field,
}: {
  title: string;
  icon?: string;
  value: string;
  customColor?: string;
  pst?: number;
  rdv: IRdvs;
  field: "poids" | "allergies" | "pouls" | "temperature" | "taille" | "tension";
  register: UseFormRegister<RdvsFormData>;
}) => {
  const [showInput, setShowInput] = useState(false);
  // const { register, onSubmit, setShowInput, showInput } =
  //   UseConsultationForm(rdv);
  return (
    <div className={`patient-side-bar-item ${`it` + pst}`}>
      <span className="item-title d-flex align-items-center gap-1 pb-2">
        <img src={icon} alt="icon" srcSet="" />
        {title}
      </span>
      {!!showInput && (
        <>
          {field !== "allergies" ? (
            <input
              className={`item-value ${customColor} d-flex flex-r w-100`}
              {...register(field)}
            />
          ) : (
            <select
              className={`item-value ${customColor} d-flex flex-r w-100`}
              {...register(field)}
            >
              <option value="true">Oui</option>
              <option value="false">Non</option>
            </select>
          )}
        </>
      )}
      {!showInput && (
        <span
          className={`item-value ${customColor} d-flex flex-r`}
          onClick={() => setShowInput(true)}
        >
          {value}
        </span>
      )}
    </div>
  );
};

const ConstantesForm = ({ item, rdv }: { item: IUser; rdv: IRdvs }) => {
  const { register, onSubmit, ref } = UseConsultationForm(rdv);
  return (
    <>
      <div className="row all-side-bar-items-container pt-4">
        <div className="col-md-12 mb-3">
          <div
            className={`patient-side-bar-item `}
            onClick={() => showModal(`addConstante${rdv?.slug}`)}
          >
            <span className="item-title d-flex align-items-center gap-1 pb-2">
              <img src={pressure} alt="tension" srcSet="" />
              Tension
            </span>
            <span
              className={`item-value color-red d-flex flex-r w-100`}
            >
              {" "}
              {rdv?.tension}{" "}
            </span>
          </div>
        </div>
        <div className="col-md-12 mb-3">
          <div
            className={`patient-side-bar-item `}
            onClick={() => showModal(`addConstante${rdv?.slug}`)}
          >
            <span className="item-title d-flex align-items-center gap-1 pb-2">
              <img src={pulse} alt="pulse" srcSet="" />
              Pouls
            </span>
            <span
              className={`item-value color-blu d-flex flex-r w-100`}
            >
              {" "}
              {rdv?.pouls}{" "}
            </span>
          </div>
        </div>
        {/* <div className="col-md-12 mb-3">
          <div
            className={`patient-side-bar-item `}
            onClick={() => showModal(`addConstante${rdv?.slug}`)}
          >
            <span className="item-title d-flex align-items-center gap-1 pb-2">
              <img src={allergy} alt="allergy" srcSet="" />
              Allergie
            </span>
            <span className={`item-value color-oran d-flex flex-r w-100`}>
              {!rdv?.allergies ? "Non" : "Oui"}
            </span>
          </div>
        </div> */}
        <div className="col-md-12 mb-3">
          <div
            className={`patient-side-bar-item `}
            onClick={() => showModal(`addConstante${rdv?.slug}`)}
          >
            <span className="item-title d-flex align-items-center gap-1 pb-2">
              <img src={Thermometer} alt="temperature" srcSet="" />
              Température
            </span>
            <span
              className={`item-value color-green d-flex flex-r w-100`}
            >
              {" "}
              {rdv?.temperature}{" "}
            </span>
          </div>
        </div>
        <div className="col-md-12 mb-3">
          <div
            className={`patient-side-bar-item `}
            onClick={() => showModal(`addConstante${rdv?.slug}`)}
          >
            <span className="item-title d-flex align-items-center gap-1 pb-2">
              <img src={poids} alt="blood_group" srcSet="" />
              Poids
            </span>
            <span
              className={`item-value color-vio d-flex flex-r w-100`}
            >
              {" "}
              {rdv?.poids || item?.constantes?.poids}{" "}
            </span>
          </div>
        </div>
        <div className="col-md-12 mb-3">
          <div
            className={`patient-side-bar-item `}
            onClick={() => showModal(`addConstante${rdv?.slug}`)}
          >
            <span className="item-title d-flex align-items-center gap-1 pb-2">
              <img src={human_size} alt="human_size" srcSet="" />
              Taille
            </span>
            <span
              className={`item-value color-gre d-flex flex-r w-100`}
            >
              {" "}
              {rdv?.taille || item?.constantes?.taille}{" "}
            </span>
          </div>
        </div>
        <div className="col-md-12 mb-3">
          <div
            className={`patient-side-bar-item `}
            onClick={() => showModal(`addConstante${rdv?.slug}`)}
          >
            <span className="item-title d-flex align-items-center gap-1 pb-2">
              <img src={blood_group} alt="blood_group" srcSet="" />
              Groupe saanguin
            </span>
            <span
              className={`item-value color-pin d-flex flex-r w-100`}
            >
              {" "}
              {item?.groupe_sanguin}{" "}
            </span>
          </div>
        </div>
      </div>
      <AddConstanteModal rdv={rdv} modalId={`addConstante${rdv?.slug}`} />
    </>
  );
};

export const SideBarPatientDetail = ({
  item,
  rdv,
}: {
  item: IUser;
  rdv?: IRdvs;
}) => {
  return (
    <div className="patient-side-bar">
      <div className="patient-image-and-infos-container text-center pb-4 d-flex flex-column align-items-center gap-2">
        <img
          src={getAvatar(item?.avatar)}
          alt="photo"
          className="patient-photo"
        />
        <span className="patient-name">{getName(item)}</span>
        <span className="patient-other-infos">
          {item?.date_de_naissance ? getAge(item?.date_de_naissance) + "," : ""}
          {item?.adresse} <br />
          N° {item?.matricule || ""}
        </span>
      </div>
      {!!rdv && <ConstantesForm item={item} rdv={rdv} />}
    </div>
  );
};

const steps = [
  { id: "notes", Component: Notes },
  { id: "rendez-vous", Component: RendezVous },
  { id: "resultat", Component: Resultats },
  { id: "medicament", Component: Medicaments },
  { id: "vaccin", Component: Vaccins },
  { id: "dossier", Component: Dossier },
  { id: "historique", Component: Historique },
];
const tabs = [
  "Notes",
  "Rendez-vous",
  "Résultats",
  "Médicaments",
  "Vaccins",
  "Dossier",
  "Antécédents",
];

function PatientDetail() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  let [searchParams, setSearchParams] = useSearchParams();
  let key = searchParams.get("step") || "";
  const { Component } = step;
  const [item] = usePatientFromLocation();
  const user = useUser();
  const navigate = useNavigate();
  const { data = { results: [] } } = useGetRdvsByPatientQuery({
    // date: moment(new Date()).format("DD-MM-YYYY"),
    slug: item?.slug,
    medecin: user?.slug,
  });
  const props = {
    item,
  };

  useEffect(() => {
    if (key) {
      go(key);
    }
  }, [key]);
  const [onEdit] = useAddOrUpdateRdvMutation();

  const onStartConsultation = () => {
    let rdv = data?.results?.find((val) => val?.status !== "annuler");
    if (data?.results?.length && rdv) {
      
      if (rdv?.status === "terminer") {
        Swal.fire({
          icon: "warning",
          title: `Ce rendez-vous a été officiellement clôturé.`,
          text: "Toute information ou ajout ultérieur sera traité comme une note additionnelle.",
          showConfirmButton: true,
          confirmButtonText: "Continuer",
          cancelButtonText: "Annuler",
          showCancelButton: true,
        }).then((result) => {
          if (result?.isConfirmed) {
            onEdit({ slug: rdv?.slug, data: { status: "addendum" } });
            navigate(`/medecin/rendez-vous/${rdv?.slug}?type=addendum`, {
              state: rdv,
            });
          }
        });
      } else {
        navigate(`/medecin/rendez-vous/${rdv?.slug}`, { state: rdv });
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: `Il n'y a aucun rendez-vous disponible pour ce patient`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  };

  return (
    <WrapperComponent>
      <div className="row">
        <div className="col-md-2">
          <SideBarPatientDetail item={item} />
        </div>
        <div className="col-md-10 ajust-content">
          <div className="content-btn-theme-title text-end">
            <button
              className="btn custom-add-item-btn"
              onClick={() => onStartConsultation()}
            >
              <span className="add-icon">
                <MdOutlineAddBox />
              </span>
              Démarrer une consultation
            </button>
          </div>

          <div className="pt-2 pb-2"></div>
          <div className="patient-detail-tabs-content mt-5">
            <ul className="tabs-title-content detail-patient-tabs">
              {tabs.map((tab, key) => (
                <li
                  className={`tab-item ${index === key && "tab-item-active"}`}
                  key={key}
                  onClick={() => go(key)}
                  role="button"
                >
                  {tab}
                </li>
              ))}
            </ul>
            <div className="patient-detail-content custom-table-content mt-5">
              <Component {...props} />
            </div>
          </div>
        </div>
      </div>
    </WrapperComponent>
  );
}

export default PatientDetail;
