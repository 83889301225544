import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl, useLocationState } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { Vaccin, VaccinFormData } from "./vaccin.type";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const VaccinApi = createApi({
  reducerPath: "vaccin",
  tagTypes: ["vaccinsByPatient", "vaccin", "vaccinList", "vaccinBySlug"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getVaccin: builder.query<PaginationResults<Vaccin>, TypeQuery>({
      query: (query) => QueryUrl(`vaccins/`, query),
      providesTags: ["vaccinList"],
    }),
    getVaccinByPatient: builder.query<PaginationResults<Vaccin>, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`patient/${slug}/vaccins/`, query),
      providesTags: ["vaccinsByPatient"],
    }),
    vaccinBySlug: builder.query<Vaccin | any, string>({
      query: (slug) => `vaccins/${slug}/`,
      providesTags: ["vaccinBySlug"],
    }),
    addOrUpdateVaccin: builder.mutation<Vaccin, VaccinFormData>({
      query: ({ slug, ...data }) => ({
        url: slug ? `vaccins/${slug}/` : `vaccins/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["vaccinList", "vaccinsByPatient"],
    }),
    deleteVaccin: builder.mutation<VaccinFormData, string>({
      query: (slug) => ({
        url: `vaccins/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["vaccinList", "vaccinsByPatient"],
    }),
  }),
});

export const {
  useAddOrUpdateVaccinMutation,
  useGetVaccinByPatientQuery,
  useLazyVaccinBySlugQuery,
} = VaccinApi;

export function useVaccinFromLocation(): [
  Vaccin,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<Vaccin | undefined>(undefined);
  const [item, setItem] = useState<Vaccin | undefined>();
  const { id } = useParams<{ id: string }>();
  const [findBySlug, { data, isError, isLoading }] = useLazyVaccinBySlugQuery();
  const navigate = useNavigate();
  

  useEffect(() => {
    if (id) {
      findBySlug(id as string);
    }
  }, [id]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  return [item as Vaccin, isLoading, id as string, findBySlug];
}
