/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { Dispatch, SetStateAction } from "react";
import Modal from "react-modal";
import CloseModalBtn, { CloseModalBtnV2 } from "../../shared/CloseModalBtn";
import { Input } from "../../common/Input";
import { BtnSubmit } from "../../common/Button";
import UseCrudFileForm from "../fichierPatient/form/UseCrudFileForm";

const CompleteProfilModal = ({
  modalCompleteProfil,
  setModalCompleteProfil,
}: {
  modalCompleteProfil: boolean;
  setModalCompleteProfil: Dispatch<SetStateAction<boolean>>;
}) => {
  const { register, errors, handleChange, handleReset, onSubmit, isLoading } =
    UseCrudFileForm(setModalCompleteProfil);
  function closeModalCompleteProfil() {
    handleReset();
  }

  return (
    <Modal
      isOpen={modalCompleteProfil}
      onRequestClose={closeModalCompleteProfil}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal-header pe-2 py-1">
        <h5 className="modal-title" id="CompleteProfilModalLabel">
          Ajouter un document administratif ou médical
        </h5>
        <CloseModalBtnV2 onClick={closeModalCompleteProfil} />
      </div>
      <div className="container-scroll-modal">
        <div className="container-form pt-5">
          <form onSubmit={onSubmit}>
            <div className="row complete-profil-row">
              <div className="col-md-12 mb-3">
                <Input
                  label="Nom du document"
                  type="text"
                  placeholder="Nom du document"
                  {...register("nom")}
                  error={errors?.nom}
                />
              </div>
              <div className="col-md-12">
                <Input
                  label="Fichier"
                  accept=".pdf, .doc, .docx"
                  type="file"
                  onChange={(e) => handleChange(e)}
                  error={errors?.fichier}
                />
              </div>
            </div>
            <div className="d-flex flex-r mt-4">
              <div className="col-md-4">
                <BtnSubmit label="Enregister" isLoading={isLoading} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CompleteProfilModal;

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    zIndex: 99999,
  },
};
