import React, { useEffect, useState } from "react";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../common/TableSkeleton";
import { useNavigate } from "react-router-dom";
import "./Table.css";
import { useGetRdvsByPatientQuery } from "../../../utils/api/rdvs/rdvs.api";
import { CustomPagination } from "../../common/CustomPagination";
import { StatusBtn } from "../../secretaire/Tables/ActiviteRdvSecretaireTable";
import { IRdvs } from "../../../utils/api/rdvs/rdvs.type";
import { IUser } from "../../../utils/api/user/user.type";
import { formatDate, formatHour, getName } from "../../../utils/Utils";
import { ISpecialite } from "../../../utils/api/specialite/specialite.type";
import Swal from "sweetalert2";

function AllDetailPatientRendezVousTable({item}:{item:IUser}) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data = { results: [], count: 0 }, isLoading,refetch } =
    useGetRdvsByPatientQuery({
      page,
      limit,
      slug: item?.slug,
    });

    useEffect(() => {
      if (!isLoading) refetch();
    }, []);

  const navigate = useNavigate();

  // const actionFormatter = (cell: any, row: any) => {
  //   return (
  //     <>
  //       <div className="d-flex justify-content-center gap-3">
  //         <div className="container-btn-action-icon-modal">
  //           <NavLink
  //             to={`/medecin/patient`}
  //             className="btn btn-action-icon-edit with-tooltip"
  //             data-tooltip-content="Modifier"
  //           >
  //             <MdOutlineModeEdit />
  //           </NavLink>
  //         </div>
  //         <div className="container-btn-action-icon-modal">
  //           <button
  //             className="btn with-tooltip btn-action-icon-delete"
  //             data-tooltip-content="Supprimer"
  //             style={{ color: "#E53E3E" }}
  //           >
  //             <BsTrash />
  //           </button>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  const statutFormatter = (cell: string, row: IRdvs) => {
    return (
      <>
        <div className="statut-formatter-container d-flex align-items-center gap-2">
          <StatusBtn item={row} />
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "date",
      text: `Date`,
      formatter: (cell: string) => formatDate(cell),
    },
    // {
    //   dataField: "horaire_de_visite",
    //   text: "Heure de visite",
    //   formatter: (cell: string) => formatHour(cell),
    // },
    // {
    //   dataField: "patient.telephone",
    //   text: "Contact",
    // },
    {
      dataField: "medecin",
      text: "Médecin",
      formatter: (cell: IUser) => getName(cell),
    },
    {
      dataField: "medecin.specialite",
      text: "Spécialité",
      formatter: (cell: ISpecialite) => cell?.nom,
    },
    {
      dataField: "motif",
      text: "Motif",
    },
    {
      dataField: "status",
      text: "Statut",
      formatter: statutFormatter,
    },
    // {
    //   dataField: "id",
    //   text: "Action",
    //   formatter: actionFormatter,
    // },
  ];

  const rowEvents = {
    onClick: (e: any, row: IRdvs) => {
      // navigate(`/medecin/rendez-vous/${row.slug}`, {state: row});
      if (row?.status !== "terminer") {
        navigate(`/medecin/rendez-vous/${row?.slug}`, { state: row });
      } else {
        Swal.fire({
          icon: "warning",
          title: `Ce rendez-vous a été officiellement clôturé.`,
          text: "Toute information ou ajout ultérieur sera traité comme une note additionnelle.",
          showConfirmButton: true,
          confirmButtonText: "Continuer",
          cancelButtonText: "Annuler",
          showCancelButton: true,
        }).then((result) => {
          if (result?.isConfirmed) {
            navigate(`/medecin/rendez-vous/${row?.slug}`, { state: row });
          }
        });
      }
    },
  };

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            rowEvents={rowEvents}
            rowClasses="custom-table-row-class"
            condensed
            hover
            wrapperClasses="table-responsive medecin-patients-detail-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default AllDetailPatientRendezVousTable;
