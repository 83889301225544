import React from "react";
import { Facturation } from "../../../utils/api/facturation/facturation.type";
import UseAddOrEditFacturationForm from "../paiementMedecin/form/UseAddOrEditFacturationForm";
import { FormError, animatedComponents } from "../../common/Input";
import Select from "react-select";
import { isMedecin } from "../../../routes/routerUtils";
import { useUser } from "../../../utils/Utils";
import { BtnSubmit } from "../../common/Button";

function AddOrEditFacturationModal({
  modalId,
  item,
}: {
  modalId: string;
  item?: Facturation;
}) {
  const {
    onSubmit,
    isLoading,
    handleReset,
    errors,
    option,
    options,
    handleSelectPatient,
    handleSelectTarif,
    optionTarif,
    optionsTarif,
    optionPraticien,
    optionsPraticien,
    setQuery,
    setSearch,
    setSearchPraticien,
    handleSelectPraticien,
    control,
    Controller,
  } = UseAddOrEditFacturationForm(modalId, item);
  const user = useUser();
  
  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby={modalId + "Label"}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id={modalId + "Label"}>
              Facturation
            </h1>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => handleReset()}
            ></button>
          </div>
          <div className="modal-body">
            <div className="pt-3">
              <form onSubmit={onSubmit}>
                <div className="row auth-form-row">
                  <div className="col-md-6 mb-3">
                    <div className="mb-3">
                      <label className="form-label form-label-register-tabs">
                        Patient
                        <span className="text-danger" style={{ fontSize: 15 }}>
                          *
                        </span>
                      </label>

                      <Controller
                        name="patient"
                        control={control}
                        render={({ field }: any) => {
                          return (
                            <Select
                              {...field}
                              closeMenuOnSelect={true}
                              placeholder="Sélectionnez ou cherchez un patient"
                              options={options}
                              onInputChange={(val) => setSearch(val)}
                              classNamePrefix="react-select-option-custom"
                              onChange={handleSelectPatient}
                              value={option}
                              components={animatedComponents}
                            />
                          );
                        }}
                      />
                      <FormError error={errors?.patient} />
                    </div>
                  </div>

                  {!isMedecin(user) && (
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label form-label-register-tabs">
                          Médecin
                          <span
                            className="text-danger"
                            style={{ fontSize: 15 }}
                          >
                            *
                          </span>
                        </label>

                        <Controller
                          name="medecin"
                          control={control}
                          render={({ field }: any) => {
                            return (
                              <Select
                                {...field}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                placeholder="Sélectionnez ou cherchez un médecin"
                                options={optionsPraticien}
                                onInputChange={(val) => setSearchPraticien(val)}
                                classNamePrefix="react-select-option-custom"
                                onChange={handleSelectPraticien}
                                value={optionPraticien}
                              />
                            );
                          }}
                        />
                        <FormError error={errors?.patient} />
                      </div>
                    </div>
                  )}

                  <div className="col-md-12 mb-3">
                    <div className="mb-3">
                      <label className="form-label form-label-register-tabs">
                        Tarifs
                        <span className="text-danger" style={{ fontSize: 15 }}>
                          *
                        </span>
                      </label>

                      <Controller
                        name="tarifs"
                        control={control}
                        render={({ field }: any) => {
                          return (
                            <Select
                              {...field}
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              placeholder="Sélectionnez ou cherchez un tarif"
                              isMulti
                              options={optionsTarif}
                              onInputChange={(val) => setQuery(val)}
                              classNamePrefix="react-select-option-custom"
                              onChange={handleSelectTarif}
                              value={optionTarif}
                            />
                          );
                        }}
                      />
                      <FormError error={errors?.tarifs} />
                    </div>
                  </div>

                  <div className="flex-r">
                    <button
                      className="btn btn-cancelled"
                      type="button"
                      onClick={() => handleReset()}
                    >
                      Annuler
                    </button>
                    <BtnSubmit
                      label="Enregistrer"
                      isLoading={isLoading}
                      customClass="w-auto"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrEditFacturationModal;
