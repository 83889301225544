import React from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import { FaCheckCircle, FaListAlt } from "react-icons/fa";
import { MdOutlineCancel, MdLocationPin } from "react-icons/md";
import "../Patient.css";


function DetailFacturePatient() {

    return(
        <>
            <WrapperComponent>
                <div className="container-title-dash d-flex align-items-center gap-4 pb-4">
                    <h2 className="custom-sm-top-title mb-0">
                        Mes Facture {" > "} 
                        <strong>Détail facture</strong>
                    </h2>
                </div>
                
                <div className="custom-content pt-4 pb-4">
                    <div className="light-card">
                        <div className="custom-card-content">
                            <div className="rdv-location">
                                <span className="cs-title">Lieu:</span>{" "}
                                <span className="cs-value">
                                    Clinique SUMA ASSISTANCE
                                </span>
                            </div>
                            <div className="person-concerned">
                                <span className="cs-title">Réservé pour: </span>{" "}
                                <span className="cs-value"> 
                                    Fatim Tall
                                </span>
                            </div>
                            <div className="date-and-hour">
                                <span className="cs-title">Date et heure:</span>{" "}
                                <span className="cs-value">
                                    10h30 - 11h00
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="instruction-content">
                        <div className="title d-flex align-items-center gap-3 pb-4">
                            <FaListAlt />
                            Instructions à suivre
                        </div>
                        <div className="intructions-list text-center">
                            Les instructions à suivre s’afficheront ici
                        </div>
                    </div>
                </div>
            </WrapperComponent>
        </>
    )
}

export default DetailFacturePatient;