import React from "react";
import { FormError, Input, animatedComponents } from "../../../common/Input";
import { BtnSubmit } from "../../../common/Button";
import UseCrudProfilForm from "../../../admin/Profil/request/UseCrudProfil";
import GoogleInput from "../../../common/GoogleInput";
import PhoneInput from "react-phone-input-2";
import { HiOutlineCamera } from "react-icons/hi2";
import { getAvatar, useUser } from "../../../../utils/Utils";
import { isMedecin } from "../../../../routes/routerUtils";
import Select from "react-select";

function InfosPersonnelles() {
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    phone,
    handleChangePhone,
    onChangeAddress,
    address,
    handleChangeAvatar,
    avatar,
    addOptionIfNeeded,
    options,
    option,
    control,
    Controller,
    handleSelectSpecialite,
  } = UseCrudProfilForm();

  const user = useUser();
  return (
    <>
      <div className="medecin-info-content pt-3">
        <form onSubmit={onSubmit}>
          <div className="d-flex justify-content-center align-items-center mb-4 leader-auto-user-avatar-container">
            <div>
              <label htmlFor="input-file" className="input-testt">
                <img
                  src={
                    avatar
                      ? URL.createObjectURL(avatar)
                      : getAvatar(user?.avatar)
                  }
                  className="custom-img-profil-user-admin mon-profil-img"
                  alt="Photo"
                />

                <div className="leader-auto-user-avatar-edit-hover-deux">
                  <HiOutlineCamera className="edit-avatar-camera-icon" />
                </div>
              </label>
              <form className="mt-4">
                <input
                  type="file"
                  className="input-add-avatar"
                  id="input-file"
                  onChange={handleChangeAvatar}
                  accept="image/*"
                />
              </form>
              <p className="libelle-input-file-pp-praticien">
                Modifier la photo
              </p>
            </div>
          </div>
          <div className="row row-infos-medecin">
            <div className="col-md-6">
              <Input
                label="Prénom"
                type="text"
                placeholder="Prénom"
                {...register("prenom")}
                error={errors?.prenom}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Nom"
                type="text"
                placeholder="Nom"
                {...register("nom")}
                error={errors?.nom}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Email"
                type="email"
                placeholder="Email"
                {...register("email")}
                error={errors?.email}
              />
            </div>
            <div className="col-md-6">
              {/* <Input label="Téléphone" type="numeric" /> */}
              <div className="mb-3">
                <label className="form-label form-label-modal-custom ">
                  Téléphone{" "}
                </label>
                <PhoneInput
                  defaultMask={".. ... .. .."}
                  containerClass={`mb-3 p-0 input-modal-form-add-phone-container`}
                  inputClass={`input-modal-form-add-phone praticiens`}
                  country={"sn"}
                  placeholder=""
                  inputProps={{
                    name: "telephone",
                    required: true,
                    autoFocus: true,
                  }}
                  countryCodeEditable={false}
                  enableAreaCodes={true}
                  prefix="+"
                  value={phone}
                  onChange={(phone, country: any) =>
                    handleChangePhone(phone, country)
                  }
                  inputStyle={{
                    width: "100%",
                    background: "rgba(232, 240, 253, 0.7)",
                  }}
                />
                <FormError error={errors?.telephone?.message} />
              </div>
            </div>
            <div className="col-md-6">
              {/* <Input label="Adresse" type="text" /> */}
              <div className="mb-3">
                <label className="form-label form-label-modal-custom">
                  Adresse{" "}
                </label>
                <GoogleInput
                  className="form-control form-control-modal-custom"
                  value={address}
                  placeholder="Adresse"
                  onChange={(val) => onChangeAddress(val)}
                />
                <FormError error={errors?.adresse?.message} />
              </div>
            </div>
            {!!isMedecin(user) && (
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label form-label-register-tabs">
                    Spécialité
                    <span className="text-danger" style={{ fontSize: 15 }}>
                      *
                    </span>
                  </label>

                  <Controller
                    name="specialite"
                    control={control}
                    render={({ field }: any) => {
                      return (
                        <Select
                          {...field}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          placeholder="Sélectionnez une spécialité"
                          options={options}
                          onInputChange={(val) => addOptionIfNeeded(val)}
                          classNamePrefix="react-select-option-custom"
                          onChange={handleSelectSpecialite}
                          value={option}
                          // onBlur={(val) => addOptionIfNeeded(val)}
                        />
                      );
                    }}
                  />
                  <FormError error={errors?.specialite} />
                </div>
              </div>
            )}
            <div className="col-md-6">
              <Input
                label="Boite postale"
                type="text"
                placeholder="Boite postale"
                {...register("boite_postal")}
                error={errors?.boite_postal}
              />
            </div>
          </div>
          <div className="d-flex flex-r mt-4">
            <div className="col-md-2">
              <BtnSubmit label="Modifier" isLoading={isLoading} />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default InfosPersonnelles;
