import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { getAvatar } from "../../../utils/Utils";
import { HiOutlineCamera } from "react-icons/hi2";
import { useAddOrEditUserMutation } from "../../../utils/api/user/user.api";
import Swal from "sweetalert2";
import { onSetUser } from "../../../redux/slice/User.slice";
import { BiLink } from "react-icons/bi";

function AvatarComponent() {
  const { user } = useAppSelector((s) => s?.user);
  const dispatch = useAppDispatch();
  const [avatar, setAvatar] = useState<File | null>(null);
  const [editAvatar, { isLoading, isError, error, isSuccess, data }] =
    useAddOrEditUserMutation();

  useEffect(() => {
    if (isSuccess) {
      setAvatar(null);
      if (data?.id) {
        if (user?.id === data?.id) {
          dispatch(onSetUser(data));
        }
      }
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isError]);

  const handleChangeAvatar = (e) => {
    let files = e.target.files[0];
    setAvatar(files);
    let fd = new FormData();
    fd.append("nom", user?.nom);
    fd.append("prenom", user?.nom);
    fd.append("email", user?.email);
    fd.append("avatar", files);

    editAvatar({
      slug: user?.slug,
      data: fd,
    });
  };
  return (
    <>
      {/* <div className="medsain-mon-profil-img-title mb-3">Photo</div>
      <div className="container-img-profil-user mb-4">
        <div className="d-flex justify-content-center align-items-center mb-4 leader-auto-user-avatar-container">
          <label htmlFor="input-test" className="input-testt">
            <img
              src={
                avatar ? URL.createObjectURL(avatar) : getAvatar(user?.avatar)
              }
              alt="Profil"
              className="custom-img-profil-user-admin medsain-mon-profil-img"
            />
            <div className="leader-auto-user-avatar-edit-hover-deux">
              <HiOutlineCamera className="edit-avatar-camera-icon" />
            </div>
          </label>
          <form className="mt-4">
            <input
              type="file"
              className="input-add-avatar"
              id="input-test"
              onChange={handleChangeAvatar}
            />
          </form>
        </div>
      </div> */}
      <div className="d-flex justify-content-center align-items-center mb-4 leader-auto-user-avatar-container">
        <div>
          <label htmlFor="input-testt" className="input-testt">
            <img
              src={
                avatar ? URL.createObjectURL(avatar) : getAvatar(user?.avatar)
              }
              className="custom-img-profil-user-admin mon-profil-img"
              alt="Photo"
            />

            <div className="leader-auto-user-avatar-edit-hover-deux">
              <HiOutlineCamera className="edit-avatar-camera-icon" />
            </div>
          </label>
          <form className="mt-4">
            <input
              type="file"
              className="input-add-avatar"
              id="input-testt"
              onChange={handleChangeAvatar}
              accept="image/*"
            />
          </form>
          <p className="libelle-input-file-pp-praticien">Ajouter une photo</p>
        </div>
      </div>
    </>
  );
}

export default AvatarComponent;
