import React, { useEffect, useState } from "react";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import { MdOutlineModeEdit } from "react-icons/md";
import TableSkeleton from "../../common/TableSkeleton";
import { NavLink } from "react-router-dom";
import "./Table.css";
import { useGetPatientsByCabinetQuery } from "../../../utils/api/user/user.api";
import { formatDate, useUser } from "../../../utils/Utils";
import { CustomPagination } from "../../common/CustomPagination";
import { IUser } from "../../../utils/api/user/user.type";
import moment from "moment";
import { BtnArchive } from "../../medecin/Tables/AllPatientsMedecinTable";

function AllPatientsSecretaireTable({
  q,
  date,
}: {
  q: string;
  date: Date | null;
}) {
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("");
  const [limit, setLimit] = useState(10);
  const user = useUser();
  const {
    data = { results: [], count: 0 },
    isLoading,
    refetch,
  } = useGetPatientsByCabinetQuery({
    page,
    limit,
    slug: user?.structure?.slug,
    q,
    date: date ? moment(date).format("YYYY-MM-DD") : "",
    order,
  });

  useEffect(() => {
    if (!isLoading) refetch();
  }, []);

  const actionFormatter = (cell: string, row: IUser) => {
    return (
      <>
        <div className="d-flex justify-content-start gap-3">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/secretaire-medical/patients/modifier-patient/${cell}`}
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Modifier"
              state={row}
            >
              <MdOutlineModeEdit />
            </NavLink>
          </div>
          <div className="container-btn-action-icon-modal">
            <BtnArchive item={row} />
          </div>
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "matricule",
      text: `Numéro`,
    },
    {
      dataField: "nom",
      text: "Nom",
      sort: true,
      onSort: (field, order) => {
        setPage(1);
        if (order === "asc") {
          setOrder("asc_nom");
        }
        if (order === "desc") {
          setOrder("desc_nom");
        }
        return "";
      },
    },
    {
      dataField: "prenom",
      text: "Prénom(s)",
      sort: true,
      classes: "id-custom-cell",
      onSort: (field, order) => {
        setPage(1);
        if (order === "asc") {
          setOrder("asc_prenom");
        }
        if (order === "desc") {
          setOrder("desc_prenom");
        }

        return;
      },
    },
    {
      dataField: "adresse",
      text: "Adresse",
    },
    {
      dataField: "date_de_naissance",
      text: "Date de naissance",
      formatter: (cell: string) => formatDate(cell),
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default AllPatientsSecretaireTable;
