import React from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import AllPatientFactureTable from "../Tables/PatientFactureTable";
import "../Patient.css";

function FacturePatient() {
  return (
    <>
      <WrapperComponent>
        <div className="container-title-dash d-flex align-items-center gap-4 pb-4">
          <h2 className="custom-sm-top-title mb-0">Mes factures</h2>
        </div>
        {/* <div className="filtered-and-search-container pt-3 pb-3">
          <div className="d-flex align-items-center gap-3">
            <label htmlFor="filter">Filter</label>
            <input
              type="search"
              name="search_item"
              id="search_item"
              placeholder="Chercher"
            />
          </div>
        </div> */}

        <div className="custom-table pt-4 pb-4">
          <AllPatientFactureTable />
        </div>
      </WrapperComponent>
    </>
  );
}

export default FacturePatient;
