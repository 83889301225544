import React, { useState } from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import { MdOutlineAddBox, MdSearch } from "react-icons/md";
import AllPatientsMedecinTable from "../Tables/AllPatientsMedecinTable";
import { NavLink } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useUser } from "../../../utils/Utils";
import { isMedecin, isSecretaire } from "../../../routes/routerUtils";
import AllPatientsSecretaireTable from "../../secretaire/Tables/AllPatientsSecretaireTable";

function PatientMedecin() {
  const [query, setQuery] = useState("");
  const [date, setDate] = useState<Date | null>(null);
  const user = useUser();
  const props = {
    date,
    q: query,
  };
  return (
    <WrapperComponent>
      <div className="container-title-dash d-flex align-items-center gap-4">
        <div className="custom-sm-top">
          <h2 className="custom-sm-top-title mb-0">Chercher un patient</h2>
          <NavLink
            to={
              isSecretaire(user)
                ? `/secretaire-medical/patients/ajouter-patient`
                : `/medecin/patients/ajouter-patient`
            }
            className="btn custom-add-item-btn"
          >
            <span className="add-icon">
              <MdOutlineAddBox />
            </span>
            Ajouter un patient
          </NavLink>
        </div>
      </div>
      <div className="search-patient-box d-flex align-items-center gap-3 mt-4">
        <input
          type="search"
          name="search_patient"
          id="search_patient"
          placeholder="Entrer un Nom ou un N° d’idenfication"
          onChange={(e) => setQuery(e?.target?.value)}
        />
        <DatePicker
          selected={date}
          onChange={(date) => setDate(date)}
          locale="fr"
          isClearable
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          placeholderText="Entrer une date de naissance"
          className="input-date-naissance"
          dateFormat="dd/MM/yyyy"
        />
      </div>

      <div className="custom-table mt-5">
        {!!isSecretaire(user) && <AllPatientsSecretaireTable {...props} />}
        {!!isMedecin(user) && <AllPatientsMedecinTable {...props} />}
      </div>
    </WrapperComponent>
  );
}

export default PatientMedecin;
