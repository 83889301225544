import React, { useEffect, useRef, useState } from "react";
import { RiSendPlaneFill } from "react-icons/ri";
import { Message } from "../../../utils/api/chat/chat.type";
import { IUser } from "../../../utils/api/user/user.type";
import { getAvatar, getMsgDate, getName, useUser } from "../../../utils/Utils";
import { useMajMessageMutation } from "../../../utils/api/chat/chat.api";
import Swal from "sweetalert2";
import socket from "../../../utils/socket-config";
import { FaSpinner } from "react-icons/fa";
import UseDeviceDetect from "../../../utils/detectSize";
import Skeleton from "react-loading-skeleton";

function isOdd(num) {
  return num % 2;
}

const SendSkeleton = () => {
  return (
    <div className="received-msg-item">
      <div className="conatiner-received-msg-item">
        <div className="content-img-received-msg-item">
          <Skeleton className="img-received-msg" />
        </div>
        <div className="container-text-message-recu w-100">
          <div className="bloc-message-recu mb-2">
            <Skeleton className="text-message-recu " />
          </div>
          <Skeleton className="time-detail-message w-15" />
        </div>
      </div>
    </div>
  );
};

const ResponseSkeleton = () => {
  return (
    <div className="bloc-reponse">
      <div className="sending-msg-item w-100 text-end">
        <div className="bloc-message-envoyer">
          <Skeleton className="text-message-envoyer mb-2" />
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <Skeleton className="time-detail-message px-2" width={`5vw`} />
        </div>
      </div>
    </div>
  );
};

const MessageItemSkeleton = () => {
  return (
    <>
      {[...Array(6)]?.map((el, i) => {
        if (isOdd(i) === 0) {
          return <SendSkeleton />;
        }

        return <ResponseSkeleton />;
      })}
    </>
  );
};

function DetailsMessagerieAdmin({
  messages = [],
  user,
  isLoading,
}: {
  messages: Message[];
  user: IUser | null;
  isLoading: boolean;
}) {
  const dummy = useRef<any>(null);
  let isMobile = UseDeviceDetect();
  useEffect(() => {
    if (messages && messages?.length >= 4) {
      dummy?.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      if (!isMobile) {
        window.scrollTo(200, 200);
      }
    }
  }, [messages]);
  return (
    <div className="dtails-messages-tabs-component">
      <div className="d-flex gap-3 align-items-center border-bottom details-message-item">
        <div className="">
          <img
            src={getAvatar(user?.avatar)}
            className="img-received-msg"
            alt={user ? getName(user) : "avatar"}
            style={{ width: "60", height: "60" }}
          />
        </div>
        <div className="">
          <div className="msg-user-infos-container">
            <div className="d-flex align-items-center msg-user-name">
              <p className="profil-detail-message mb-0">
                {!!user && getName(user)}
              </p>
            </div>
            <div className="bloc-user-disconnect-time msg-user-lastonline">
              {/* <p className="text-disconnect-time mb-0">
                Online - Last seen, 2.02pm
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="bloc-details pb-5">
        <div>
          {!!isLoading && <MessageItemSkeleton />}
          {!isLoading &&
            (messages?.length
              ? [...messages]
                  ?.sort((a, b) => a?.id - b?.id)
                  ?.map((message) => {
                    if (user) {
                      if (message.sender.id === user.id) {
                        return <MessageItem item={message} key={message.id} />;
                      }
                      return <Response item={message} key={message.id} />;
                    }
                    return "";
                  })
              : "")}
          <div ref={dummy} />
        </div>
      </div>
      {!!user && <ChatInput item={user} />}
    </div>
  );
}

export default DetailsMessagerieAdmin;

function MessageItem({ item }: { item: Message }) {
  return (
    <div className="received-msg-item">
      <div className="conatiner-received-msg-item">
        <div className="content-img-received-msg-item">
          <img
            src={getAvatar(item?.sender?.avatar)}
            className="img-received-msg"
            alt={item?.sender?.prenom}
          />
        </div>
        <div className="container-text-message-recu w-100">
          <div className="bloc-message-recu mb-2">
            <div className="text-message-recu">{item?.content}</div>
          </div>
          <span className="time-detail-message">
            {getMsgDate(item?.created_at)}
          </span>
        </div>
      </div>
    </div>
  );
}

function Response({ item }: { item: Message }) {
  return (
    <div className="bloc-reponse">
      <div className="sending-msg-item w-100">
        <div className="bloc-message-envoyer">
          <div className="text-message-envoyer mb-2">{item?.content}</div>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <span className="time-detail-message px-2">
            {getMsgDate(item?.created_at)}
          </span>
        </div>
      </div>
    </div>
  );
}

function ChatInput({ item }: { item: IUser }) {
  const [exec, { isLoading, isError, isSuccess, error }] =
    useMajMessageMutation();
  const user = useUser();
  const [message, setMessage] = useState("");
  useEffect(() => {
    if (isError) {
      const err = error as any;
      Swal.fire({
        icon: "error",
        iconColor: "#F00",
        confirmButtonColor: "#F00",
        title: err?.data?.message ? err?.data?.message : "Message non envoyé",
      });
    }
  }, [isError]);
  useEffect(() => {
    if (isSuccess) {
      setMessage("");
    }
  }, [isSuccess]);

  const sendMessage = async () => {
    if (!message) {
      return;
    }
    const dataToSend = {
      content: message,
      sender: user?.id,
      receiver: item?.id,
    };
    const res = await exec({ data: dataToSend });
    if ("data" in res) {
      socket.emit("message", dataToSend);
      // console.log("resData", dataToSend);
      setMessage("");
    }
  };
  return (
    <div className="p-3 border-top">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          sendMessage();
        }}
      >
        <div className="left-footer">
          <div className="left-footer-container">
            <div className="input-group">
              <div className="input-container">
                <div className="container-display-input-message">
                  {/* <div className="share">
                    <FiPaperclip className="img-icon-chat" />
                  </div> */}
                  <div className="inp w-100">
                    <textarea
                      className="textarrea-message-custom-chat form-control"
                      rows={1}
                      value={message}
                      name="reponse"
                      placeholder="Tapez votre message ici..."
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-container custom-btn-chat-container">
              {/* <div className="emoji">
                <BsEmojiWink className="img-icon-chat" />
              </div> */}
              <button
                type="submit"
                className="btn btn-send-message-tabs"
                disabled={isLoading}
              >
                {!!isLoading && <FaSpinner />}
                {!isLoading && <RiSendPlaneFill />}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
