import React, { useEffect, useState } from "react";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../common/TableSkeleton";
import { useNavigate } from "react-router-dom";
import "./Table.css";
import {
  useAddOrUpdateRdvMutation,
  useGetRdvsByPraticienQuery,
} from "../../../utils/api/rdvs/rdvs.api";
import { useAppSelector } from "../../../redux/hook";
import { CustomPagination } from "../../common/CustomPagination";
import {
  BtnDeleteRdv,
  StatusBtn,
} from "../../secretaire/Tables/ActiviteRdvSecretaireTable";
import { IRdvs } from "../../../utils/api/rdvs/rdvs.type";
import { formatDate, formatHour, getName } from "../../../utils/Utils";
import { IUser } from "../../../utils/api/user/user.type";
import moment from "moment";
import Swal from "sweetalert2";

function ActiviteRdvMedecinTable({ q, date }: { q: string; date: Date }) {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { user } = useAppSelector((s) => s?.user);
  const {
    data = { results: [], count: 0 },
    isLoading,
    refetch,
  } = useGetRdvsByPraticienQuery({
    slug: user?.slug,
    limit,
    page,
    q,
    date: moment(date).format("DD-MM-YYYY"),
  });
  const [onEdit] = useAddOrUpdateRdvMutation();

  useEffect(() => {
    if (!isLoading) refetch();
  }, []);

  const actionFormatter = (cell: string, row: IRdvs) => {
    return (
      <>
        <div className="d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <BtnDeleteRdv slug={cell} />
          </div>
        </div>
      </>
    );
  };
  const statutFormatter = (cell: string, row: IRdvs) => {
    return (
      <>
        <div className="statut-formatter-container d-flex align-items-center gap-2">
          <StatusBtn item={row} />
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "date",
      text: `Date`,
      formatter: (cell: string) => formatDate(cell),
    },
    {
      dataField: "horaire_de_visite",
      text: "Heure de visite",
      formatter: (cell: string) => formatHour(cell),
    },
    {
      dataField: "patient",
      text: "Patient",
      formatter: (cell: IUser) => getName(cell),
    },
    {
      dataField: "patient",
      text: "Adresse",
      formatter: (cell: IUser) => cell?.adresse,
    },
    {
      dataField: "patient",
      text: "Contact",
      formatter: (cell: IUser) => cell?.telephone,
    },
    {
      dataField: "motif",
      text: "Motif",
    },
    {
      dataField: "status",
      text: "Statut",
      formatter: statutFormatter,
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
    },
  ];

  const rowEvents = {
    onClick: (e, row: IRdvs) => {
      let getCurrentCellIndex = e.target.cellIndex;
      let id: any = document.querySelector("table tr:last-child td:last-child");
      let getLastCellIndex = id?.cellIndex;

      if (
        getCurrentCellIndex !== getLastCellIndex &&
        getCurrentCellIndex !== undefined
      ) {
        if (row?.status !== "terminer") {
          navigate(`/medecin/rendez-vous/${row?.slug}`, { state: row });
        } else {
          Swal.fire({
            icon: "warning",
            title: `Ce rendez-vous a été officiellement clôturé.`,
            text: "Toute information ou ajout ultérieur sera traité comme une note additionnelle.",
            showConfirmButton: true,
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            showCancelButton: true,
          }).then((result) => {
            if (result?.isConfirmed) {
              onEdit({ slug: row?.slug, data: { status: "addendum" } });
              navigate(`/medecin/rendez-vous/${row?.slug}?type=addendum`, {
                state: row,
              });
            }
          });
        }
      }
    },
  };

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            condensed
            hover
            // selectRow={selectRow}
            rowEvents={rowEvents}
            rowClasses="custom-table-row-class"
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default ActiviteRdvMedecinTable;
