import React from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import {
  FormError,
  Input,
  InputAgenda,
  InputQuill,
  animatedComponents,
} from "../../common/Input";
import { BtnSubmit } from "../../common/Button";
import { useRdvFromLocation } from "../../../utils/api/rdvs/rdvs.api";
import UseAddOrEditRdvForm from "./form/UseAddOrEditRdvForm";
import Select, { components } from "react-select";
import PhoneInput from "react-phone-input-2";
import { SelectInput } from "../../common/SelectInput";
import { selectOptions } from "../../admin/PraticiensAdmin/AddOrUpdatePraticienAdmin";
import GoogleInput from "../../common/GoogleInput";
import { useAppSelector } from "../../../redux/hook";
import { isMedecin } from "../../../routes/routerUtils";

function AddRdvSecretaire() {
  const [item] = useRdvFromLocation();
  const {user} = useAppSelector((s) => s?.user)
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    phone,
    handleChangePhone,
    handleSelectPatient,
    handleSelectPraticien,
    options,
    option,
    optionsPraticien,
    optionPraticien,
    onChange,
    onChangeAddress,
    Controller,
    control,
    setSearch,
    showFields,
    handleAddNewPatient,
    address,
    contenu,
    setSearchPraticien,
  } = UseAddOrEditRdvForm(item);

  const SelectMenuButton = (props) => {
    return (
      <components.MenuList {...props}>
        {props.children}
        {!item && (
          <div className="text-center">
            <button
              className="btn custom-add-item-btn mt-3"
              onClick={() => {
                props.selectProps.onMenuClose();
                handleAddNewPatient();
              }}
            >
              Ajouter un patient
            </button>
          </div>
        )}
      </components.MenuList>
    );
  };
  return (
    <WrapperComponent>
      <div className="content-chart-title mb-4">
        <div className="container-title-dash d-flex align-items-center gap-4">
          <h2 className="custom-sm-top-title mb-0">Ajouter un rendez-vous</h2>
        </div>
      </div>

      <div className="custom-form mt-5">
        <form onSubmit={onSubmit}>
          <div className="row">
            <div className="col-md-6">
              {/* <Input label="Patient" type="text" /> */}
              <div className="mb-3">
                <label className="form-label form-label-register-tabs">
                  Patient
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>

                <Controller
                  name="patient"
                  control={control}
                  render={({ field }: any) => {
                    return (
                      <Select
                        {...field}
                        closeMenuOnSelect={true}
                        placeholder="Sélectionnez ou cherchez un patient"
                        options={options}
                        onInputChange={(val) => setSearch(val)}
                        classNamePrefix="react-select-option-custom"
                        onChange={handleSelectPatient}
                        value={option}
                        components={{ MenuList: SelectMenuButton }}
                      />
                    );
                  }}
                />
                <FormError error={errors?.patient} />
              </div>
            </div>
            {!!showFields && (
              <>
                <div className="col-md-6">
                  <Input
                    label="Prénom du patient"
                    type="text"
                    placeholder="Prénoms"
                    required
                    {...register("prenom")}
                    error={errors?.prenom}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    label="Nom du patient"
                    type="text"
                    placeholder="Nom"
                    required
                    {...register("nom")}
                    error={errors?.nom}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    label="Email du patient"
                    type="email"
                    id="email"
                    placeholder="Email"
                    required
                    {...register("email")}
                    error={errors?.email}
                  />
                </div>

                <div className="col-md-6">
                  <SelectInput
                    label="Genre du patient"
                    options={selectOptions}
                    required
                    {...register("civilite")}
                    error={errors?.civilite}
                  />
                </div>
              </>
            )}
            <div className="col-md-6">
              <Input
                label="Date de naissance"
                type="date"
                id="date_de_naissance"
                placeholder="Date de naissance"
                required
                {...register("date_de_naissance")}
                error={errors?.date_de_naissance}
              />
            </div>
            <div className="col-md-6">
              {/* <Input label="Adresse du patient" type="text" /> */}
              <div className="mb-3">
                <label className="form-label form-label-modal-custom">
                  Adresse du patient{" "}
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                <GoogleInput
                  className="form-control form-control-modal-custom"
                  value={address}
                  placeholder="Adresse"
                  onChange={(val) => onChangeAddress(val)}
                />
                <FormError error={errors?.adresse?.message} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label form-label-modal-custom ">
                  Téléphone{" "}
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                <PhoneInput
                  defaultMask={".. ... .. .."}
                  containerClass={`mb-3 p-0 input-modal-form-add-phone-container`}
                  inputClass={`input-modal-form-add-phone praticiens`}
                  country={"sn"}
                  placeholder=""
                  inputProps={{
                    name: "telephone",
                    required: true,
                    autoFocus: true,
                  }}
                  countryCodeEditable={false}
                  enableAreaCodes={true}
                  prefix="+"
                  value={phone}
                  onChange={(phone, country: any) =>
                    handleChangePhone(phone, country)
                  }
                  inputStyle={{
                    width: "100%",
                    background: "rgba(232, 240, 253, 0.7)",
                  }}
                />
                <FormError error={errors?.telephone?.message} />
              </div>
            </div>
            {!isMedecin(user) && (
              <div className="col-md-6">
                {/* <Input label="Patient" type="text" /> */}
                <div className="mb-3">
                  <label className="form-label form-label-register-tabs">
                    Médecin
                    <span className="text-danger" style={{ fontSize: 15 }}>
                      *
                    </span>
                  </label>

                  <Controller
                    name="medecin"
                    control={control}
                    render={({ field }: any) => {
                      return (
                        <Select
                          {...field}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          placeholder="Sélectionnez ou cherchez un médecin"
                          options={optionsPraticien}
                          onInputChange={(val) => setSearchPraticien(val)}
                          classNamePrefix="react-select-option-custom"
                          onChange={handleSelectPraticien}
                          value={optionPraticien}
                        />
                      );
                    }}
                  />
                  <FormError error={errors?.patient} />
                </div>
              </div>
            )}

            <div className="col-md-6">
              <Input
                label="Motif"
                type="text"
                id="motif"
                placeholder="Motif"
                required
                {...register("motif")}
                error={errors?.motif}
              />
            </div>
            <div className="col-md-6">
              <InputAgenda
                label="Date"
                type="date"
                required
                {...register("date")}
                error={errors?.date}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Définir l'heure de visite"
                type="time"
                required
                {...register("horaire_de_visite")}
                error={errors?.horaire_de_visite}
              />
            </div>
            <div className="col-md-12">
              <InputQuill
                label="Instructions à suivre"
                value={contenu}
                onChange={onChange}
                error={errors?.instructions}
                id="instruction"
              />
            </div>
          </div>
          <div className="d-flex flex-r mt-4">
            <div className="col-md-2">
              <BtnSubmit label="Enregistrer" isLoading={isLoading} />
            </div>
          </div>
        </form>
      </div>
    </WrapperComponent>
  );
}

export default AddRdvSecretaire;
