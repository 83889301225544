import React from "react";
import AllDetailPatientResultatTable from "../../../Tables/AllDetailPatientResultatTable";
import { IUser } from "../../../../../utils/api/user/user.type";
import { IRdvs } from "../../../../../utils/api/rdvs/rdvs.type";
import { NavLink } from "react-router-dom";
import { MdOutlineAddBox } from "react-icons/md";

function Resultats({ item, rdv }: { item: IUser; rdv?: IRdvs }) {
  return (
    <>
      {!!rdv && rdv?.status !== "annuler" && (
        <div className="add-notes-btn-content d-flex flex-r pb-4">
          <NavLink
            to={`/medecin/ajouter-analyse/${rdv?.slug}`}
            className="add-notes-btn no-link"
            state={rdv}
          >
            <MdOutlineAddBox className="add-icon" />
            Ajouter une analyse
          </NavLink>
        </div>
      )}
      <AllDetailPatientResultatTable item={item} />
    </>
  );
}

export default Resultats;
