import React from 'react';
import AllDetailPatientRendezVousTable from '../../../Tables/AllDetailPatientRendezVousTable';

function RendezVous({ item }) {
  return (
    <>
      <AllDetailPatientRendezVousTable item={item} />
    </>
  );
}

export default RendezVous;