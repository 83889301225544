import React, { Dispatch, SetStateAction, useState } from "react";
import { FiSearch } from "react-icons/fi";
import Modal from "react-modal";
import { CloseModalBtnV2 } from "../../../shared/CloseModalBtn";
import { getAvatar, getChatRoute, getName, getType, useUser } from "../../../../utils/Utils";
import { useGetDestinataireByUserQuery } from "../../../../utils/api/chat/chat.api";
import { AlertInfo } from "../../../common/Alert";
import { IUser } from "../../../../utils/api/user/user.type";
import UseDeviceDetect from "../../../../utils/detectSize";
import { useNavigate } from "react-router-dom";


const NewChatModal = ({
  modalNewChat,
  setModalNewChat,
  onSelect,
}: {
  modalNewChat: boolean;
  setModalNewChat: Dispatch<SetStateAction<boolean>>;
  onSelect: (user: IUser) => any;
}) => {
  let isMobile = UseDeviceDetect();
  const user = useUser();
  const navigate = useNavigate()
  const [limit, setLimit] = useState(50);
  const [query, setQuery] = useState("");
  const { data = { results: [], count: 0 }, isLoading } =
    useGetDestinataireByUserQuery({
      limit,
      q: query,
      slug: user?.slug,
    });
    // console.log(data)
  function closeModalNewChat() {
    setModalNewChat(false);
  }

  const onSelectUser = (item) => {
    if (isMobile) {
      navigate(getChatRoute(user), { state: item });
    } else {
      onSelect(item);
      closeModalNewChat();
    }
    
  };

  return (
    <Modal
      isOpen={modalNewChat}
      onRequestClose={closeModalNewChat}
      style={!isMobile ? customStyles : customStylesMobile}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="modal-header pe-2 py-1">
        <h5 className="modal-title" id="ChangePasswordModalLabel">
          Nouvelle discussion
        </h5>
        <CloseModalBtnV2 onClick={closeModalNewChat} />
      </div>
      {/* <div className="content-btn-send-message flex-r">
        <button className="btn btn-send-message-modal disabled">Suivant</button>
      </div> */}
      <div className="pt-3">
        <div className="form-search-user-container position-relative">
          <input
            type="text"
            className="form-search-user form-control"
            placeholder="Rechercher des personnes"
            onChange={(e) => setQuery(e?.target?.value)}
            value={query}
          />
          <FiSearch
            style={{
              color: "#919EAB",
              fontSize: 22,
              position: "absolute",
              top: "25%",
              left: "2%",
            }}
          />
        </div>
      </div>
      <ul className="user-for-send-message-container mt-3  px-2">
        {!isLoading &&
          (data?.results?.length ? (
            data?.results?.map((item) => (
              <li
                className="user-for-send-message  mb-3 px-3 py-1 linkable"
                key={item?.slug}
                onClick={() => onSelectUser(item)}
              >
                <div className="d-flex align-items-center gap-2">
                  <div>
                    <img
                      src={getAvatar(item?.avatar)}
                      alt="user-avatar"
                      className="w-fluid img-profil-user-message"
                    />
                  </div>
                  <div className="user-for-send-message-infos">
                    <h3 className="mb-0">{getName(item)}</h3>
                    <h4 className="mb-0">{getType(item)}</h4>
                  </div>
                </div>
              </li>
            ))
          ) : (
            <AlertInfo message="Aucune donnée disponible" />
          ))}

        {limit < data?.count && (
          <li className="user-for-send-message text-center">
            <button
              className="see-more-btn"
              onClick={() => setLimit(data?.count)}
            >
              Voir plus
            </button>
          </li>
        )}
        {limit >= data?.count && data?.count > 50 && (
          <li className="user-for-send-message text-center">
            <button className="see-more-btn" onClick={() => setLimit(50)}>
              Voir moins
            </button>
          </li>
        )}
      </ul>
    </Modal>
  );
};

export default NewChatModal;
export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    height: "80%",
    zIndex: 99999,
  },
};

const customStylesMobile = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    zIndex: 99999,
  },
};
