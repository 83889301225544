import React, { useEffect, useState } from "react";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../common/TableSkeleton";
import "./Table.css";
import { AiOutlineEye } from "react-icons/ai";
import { useGetTacheByMedecinQuery } from "../../../utils/api/tache/tache.api";
import { getStatusTache, useUser } from "../../../utils/Utils";
import { CustomPagination } from "../../common/CustomPagination";
import { Tache } from "../../../utils/api/tache/tache.type";
import { UseEditStatutTache } from "../tacheMedecin/TacheMedecin";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useAddOrUpdateRdvMutation } from "../../../utils/api/rdvs/rdvs.api";

const StatusBtn = ({ item }: { item: Tache }) => {
  const onEdit = UseEditStatutTache(item);

  return (
    <>
      <span
        className={`btn statut-tache-${item?.status} with-tooltip`}
        onClick={() => onEdit()}
        data-tooltip-content="Modifier"
      >
        {getStatusTache(item?.status)}
      </span>
    </>
  );
};

function TacheMedecinTable() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const user = useUser();
  const [onEdit] = useAddOrUpdateRdvMutation();
  const { data = { results: [], count: 0 }, isLoading,refetch } =
    useGetTacheByMedecinQuery({
      slug: user?.slug,
      limit,
      page,
    });

    useEffect(() => {
      if (!isLoading) refetch();
    }, []);

  // const actionFormatter = (cell: any, row: any) => {
  //   return (
  //     <>
  //       <div className="d-flex justify-content-center gap-3">
  //         <div className="container-btn-action-icon-modal">
  //           <button
  //             className="btn with-tooltip btn-action-icon-edit"
  //             data-tooltip-content="Voir"
  //             style={{ color: "#099876" }}
  //           >
  //             <AiOutlineEye />
  //           </button>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  const statusFormatter = (cell, row: Tache) => {
    return (
      <div className="statut-formatter-container d-flex align-items-center gap-2">
        {/* <span
          className={`btn statut-tache-${cell} with-tooltip`}
          data-tooltip-content="Statut"
        >
          {cell}
        </span> */}
        <StatusBtn item={row} />
      </div>
    );
  };

  const columns = [
    {
      dataField: "rdv.patient.nom",
      text: `Nom`,
    },
    {
      dataField: "rdv.patient.prenom",
      text: "Prénom(s)",
    },
    {
      dataField: "rdv.motif",
      text: "Motif",
    },
    {
      dataField: "tache_manquante",
      text: "Tâches maquantes",
    },
    {
      dataField: "status",
      text: "Statut",
      formatter: statusFormatter,
      headerStyle: () => {
        return { width: "120px", whiteSpace: "normal" };
      },
    },
    // {
    //   dataField: "slug",
    //   text: "Action",
    //   formatter: actionFormatter,
    //   headerStyle: () => {
    //     return { width: "100px", whiteSpace: "normal" };
    //   },
    // },
  ];

  

  const rowEvents = {
    onClick: (e, row: Tache) => {
      let getCurrentCellIndex = e.target.cellIndex;
      let id: any = document.querySelector("table tr:last-child td:last-child");
      
      let getLastCellIndex = id?.cellIndex;

      if (
        getCurrentCellIndex !== getLastCellIndex &&
        getCurrentCellIndex !== undefined 
      ) {
        
        if (row?.rdv?.status !== "terminer") {
          navigate(`/medecin/rendez-vous/${row?.rdv?.slug}`, {
            state: row?.rdv,
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: `Ce rendez-vous a été officiellement clôturé.`,
            text: "Toute information ou ajout ultérieur sera traité comme une note additionnelle.",
            showConfirmButton: true,
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            showCancelButton: true,
          }).then((result) => {
            if (result?.isConfirmed) {
              onEdit({ slug: row?.slug, data: { status: "addendum" } });
              navigate(`/medecin/rendez-vous/${row?.rdv?.slug}`, {
                state: row?.rdv,
              });
            }
          });
        }
      }
    },
  };

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            condensed
            hover
            rowEvents={rowEvents}
            rowClasses="custom-table-row-class"
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default TacheMedecinTable;
