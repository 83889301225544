import React, { useEffect, useState } from "react";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import { MdOutlineModeEdit, MdUnarchive } from "react-icons/md";
import TableSkeleton from "../../common/TableSkeleton";
import { NavLink } from "react-router-dom";
import { useGetListCabinetsQuery } from "../../../utils/api/user/user.api";
import { CustomPagination } from "../../common/CustomPagination";
import { IUser } from "../../../utils/api/user/user.type";
import {
  UseActiveCabinet,
  UseArchiveCabinet,
} from "./form/UseAddOrEditCabinetForm";
import { BiArchiveIn } from "react-icons/bi";

const BtnArchive = ({ item }: { item: IUser }) => {
  const onArchive = UseArchiveCabinet(item);

  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content={item?.is_archive ? "Désarchiver" : "Archiver"}
      style={{ color: "#E53E3E" }}
      onClick={onArchive}
    >
      {/* <BsTrash /> */}
      {item?.is_archive ? <MdUnarchive /> : <BiArchiveIn />}
    </button>
  );
};

const BtnActive = ({ active, item }: { active: boolean; item: IUser }) => {
  const onActive = UseActiveCabinet(item);

  return (
    <>
      {active === true ? (
        <span
          className="btn statut-cabinet-actif with-tooltip"
          onClick={onActive}
          data-tooltip-content="Désactiver"
        >
          Actif
        </span>
      ) : (
        <span
          className="btn statut-cabinet-inactif with-tooltip"
          onClick={onActive}
          data-tooltip-content="Activer"
        >
          Inactif
        </span>
      )}
    </>
  );
};

function CabinetHopitauxTable({ q }: { q: string }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data = { results: [], count: 0 }, isLoading,refetch } =
    useGetListCabinetsQuery({
      q,
      page,
      limit,
    });

    useEffect(() => {
      if (!isLoading) refetch();
    }, []);

  const actionFormatter = (cell: string, row: IUser) => {
    return (
      <>
        <div className="d-flex justify-content-center gap-3">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/cabinets/modifier-cabinet/${cell}`}
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Modifier"
              state={row}
            >
              <MdOutlineModeEdit />
            </NavLink>
          </div>
          <div className="container-btn-action-icon-modal">
            <BtnArchive item={row} />
          </div>
        </div>
      </>
    );
  };

  const statutFormatter = (cell: boolean, row: IUser) => {
    
    return (
      <>
        {/* <span className="statut-cabinet-actif">Actif</span> */}
        <BtnActive active={cell} item={row} />
      </>
    );
  };

  const columns = [
    {
      dataField: "nom_cabinet",
      text: `Nom de la structure`,
    },
    {
      dataField: "adresse_cabinet",
      text: "Adresse",
    },
    {
      dataField: "email",
      text: "Adresse mail",
    },
    {
      dataField: "telephone",
      text: "Contact",
    },
    {
      dataField: "is_active",
      text: "statut",
      formatter: statutFormatter,
      headerStyle: () => {
        return { width: "100px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
      headerStyle: () => {
        return { width: "100px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            rowClasses="custom-table-row-class"
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
            // bodyStyle={{ width: 200, maxWidth: 200, wordBreak: "break-all" }}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default CabinetHopitauxTable;
