import React, { useEffect, useState } from "react";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import { BsTrash } from "react-icons/bs";
import { MdOutlineModeEdit, MdOutlineTimer } from "react-icons/md";
import TableSkeleton from "../../common/TableSkeleton";
import { NavLink, useNavigate } from "react-router-dom";
import { ActiviteRdvSecretaireTableData } from "../Tables";
import "./Table.css";
import { AiOutlineEye } from "react-icons/ai";
import { useGetRdvsByCabinetQuery } from "../../../utils/api/rdvs/rdvs.api";
import { useAppSelector } from "../../../redux/hook";
import { isSecretaire } from "../../../routes/routerUtils";
import { CustomPagination } from "../../common/CustomPagination";
import { IUser } from "../../../utils/api/user/user.type";
import { formatDate, formatHour, getName, getStatus, useUser } from "../../../utils/Utils";
import { IRdvs } from "../../../utils/api/rdvs/rdvs.type";
import {
  UseDeleteRdv,
  UseEditStatutRdv,
} from "../rdvSecretaire/form/UseAddOrEditRdvForm";
import moment from "moment";

export const BtnDeleteRdv = ({ slug }: { slug: string }) => {
  const onDelete = UseDeleteRdv(slug);

  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
      onClick={onDelete}
    >
      <BsTrash />
    </button>
  );
};

export const StatusBtn = ({ item }: { item: IRdvs }) => {
  const onEdit = UseEditStatutRdv(item);
  const user = useUser();

  return (
    <>
      <span
        className={`btn statut-rdv-${item?.status} with-tooltip`}
        onClick={() => {
          if (isSecretaire(user)) {
            onEdit();
          }
        }}
        data-tooltip-content="Modifier"
      >
        {getStatus(item)}
      </span>
    </>
  );
};

function ActiviteRdvSecretaireTable({
  q,
  medecin,
  date
}: {
  q: string;
  date: Date;
  medecin?: string;
}) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { user } = useAppSelector((s) => s?.user);
  const { data = { results: [], count: 0 }, isLoading,refetch } =
    useGetRdvsByCabinetQuery({
      slug: isSecretaire(user) ? user?.structure?.slug : user?.slug,
      limit,
      page,
      q,
      medecin,
      date: moment(date).format("DD-MM-YYYY"),
    });

    useEffect(() => {
      if (!isLoading) refetch();
    }, []);

  const actionFormatter = (cell: string, row: IRdvs) => {
    return (
      <>
        <div className="d-flex justify-content-center gap-3">
          {!!isSecretaire(user) && (
            <>
              <div className="container-btn-action-icon-modal">
                <NavLink
                  to={`/secretaire-medical/rendez-vous/modifier-rendez-vous/${cell}`}
                  className="btn btn-action-icon-edit with-tooltip"
                  data-tooltip-content="Modifier"
                  state={row}
                >
                  <MdOutlineModeEdit />
                </NavLink>
              </div>
              <div className="container-btn-action-icon-modal">
                <BtnDeleteRdv slug={cell} />
              </div>
            </>
          )}
        </div>
      </>
    );
  };
  const statutFormatter = (cell: string, row: IRdvs) => {
    return (
      <>
        <div className="statut-formatter-container d-flex align-items-center gap-2">
          <StatusBtn item={row} />
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "date",
      text: `Date`,
      formatter: (cell: string) => formatDate(cell),
    },
    {
      dataField: "horaire_de_visite",
      text: "Heure de visite",
      formatter: (cell: string) => formatHour(cell),
    },
    {
      dataField: "patient",
      text: "Patient",
      formatter: (cell: IUser) => getName(cell),
    },
    {
      dataField: "patient.adresse",
      text: "Adresse",
      formatter: (cell: string) => cell,
    },
    {
      dataField: "patient.telephone",
      text: "Contact",
      formatter: (cell: string) => cell,
    },
    {
      dataField: "motif",
      text: "Motif",
    },
    {
      dataField: "status",
      text: "Statut",
      formatter: statutFormatter,
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
      hidden: !isSecretaire(user),
    },
  ];

  const navigate = useNavigate();

  const rowEvents = {
    onClick: (e, row: IRdvs) => {
      let getCurrentCellIndex = e.target.cellIndex;
      let id: any = document.querySelector("table tr:last-child td:last-child");
      let idx: any = document.querySelector(
        "table tr:nth-last-child(2) td:nth-last-child(2)"
      );
      let getLastCellIndex = id?.cellIndex;
      let getBeforeLastCellIndex = idx?.cellIndex;

      if (!isSecretaire(user)) {
        navigate(`/cabinet/rendez-vous/${row?.slug}`, { state: row });
      } else if (
        getCurrentCellIndex !== getLastCellIndex &&
        getCurrentCellIndex !== undefined &&
        getCurrentCellIndex !== getBeforeLastCellIndex
      ) {
        navigate(`/secretaire-medical/rendez-vous/${row?.slug}`, {
          state: row,
        });
      }
    },
  };

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            condensed
            hover
            rowEvents={rowEvents}
            rowClasses="custom-table-row-class"
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default ActiviteRdvSecretaireTable;
