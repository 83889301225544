import React, {
  Dispatch,
  SetStateAction,
  forwardRef,
  useEffect,
  useState,
} from "react";
import moment from "moment";
import DatePicker from "react-datepicker";

// import fr from "date-fns/locale/fr";
// registerLocale("fr", fr);

function CalendrierStep({
  setDate,
}: {
  setDate?: Dispatch<SetStateAction<Date>>;
}) {
  const [currentDay, setCurrentDay] = useState(new Date());

  useEffect(() => {
    if (setDate) {
      setDate(currentDay);
    }
  }, [currentDay]);
  const nextDay = () => {
    setCurrentDay(new Date(currentDay.setDate(currentDay.getDate() + 1)));
  };

  const previousDay = () => {
    setCurrentDay(new Date(currentDay.setDate(currentDay.getDate() - 1)));
  };

  const DateLabel = forwardRef(({ value, onClick }: any, ref: any) => (
    <span className="text-capitalize linkable" onClick={onClick} ref={ref}>
      {moment(value).format("DD MMMM YYYY")}
    </span>
  ));
  return (
    <div className="calendar">
      <div className="filtered-rdv-on-date-container">
        <button className="filter-scope-btn" onClick={previousDay}>
          {"<"}
        </button>
        <DatePicker
          selected={currentDay}
          onChange={(date) => setCurrentDay(date)}
          customInput={<DateLabel />}
          locale="fr"
        />

        <button className="filter-scope-btn" onClick={nextDay}>
          {">"}
        </button>
      </div>
    </div>
  );
}

export default CalendrierStep;
