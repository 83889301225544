import React from "react";
import { Note } from "../../../utils/api/note/note.type";
import { BtnCloseModalV2 } from "../../common/Button";
import {
  closeModal,
  createMarkup,
  formatDate,
  getName,
} from "../../../utils/Utils";

function ShowNoteRdvModal({ note, modalId }: { note: Note; modalId: string }) {
  return (
    <div
      className="modal fade custom-modal"
      id={modalId}
      aria-labelledby={modalId + "Label"}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id={modalId + "Label"}>
              Note du rendez-vous du {formatDate(note?.rdv?.date)}
            </h1>
            <BtnCloseModalV2
              label=""
              onClick={() => closeModal(modalId)}
              customClass="bg-transparent border-none"
            />
          </div>
          <div className="modal-body">
            <div className="container-scroll-modal">
              <div className="row row-paiement-details pt-4">
                <div className="col-md-12 d-flex align-items-center justify-content-between gap-3">
                  <span className="sm-title">Date:</span>
                  <span className="sm-value">
                    {formatDate(note?.created_at)}
                  </span>
                </div>
                <div className="col-md-12 d-flex align-items-center justify-content-between gap-3">
                  <span className="sm-title">Titre:</span>
                  <span className="sm-value">{note?.titre}</span>
                </div>
                <div className="col-md-12 d-flex align-items-center justify-content-between gap-3">
                  <span className="sm-title">Médecin:</span>
                  <span className="sm-value">
                    {getName(note?.rdv?.medecin)}
                  </span>
                </div>
                <div className="col-md-12 ">
                  <span className="sm-title">Contenu:</span>
                  <span
                    className="sm-value"
                    dangerouslySetInnerHTML={createMarkup(note?.contenu)}
                  />
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowNoteRdvModal;
