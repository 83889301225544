import React, { useEffect, useState } from "react";
import WrapperComponent from '../../shared/WrapperComponent';
import { MdOutlineAddBox } from 'react-icons/md';
import { NavLink, useNavigate } from "react-router-dom";
import CabinetHopitauxTable from "./CabinetHopitauxTable";
import { useUser } from "../../../utils/Utils";
import { hasAccess } from "../../../routes/routerUtils";

function CabinetHopitaux() {
    const [query, setQuery] = useState("")
    const user = useUser()
    const navigate = useNavigate()
    
    useEffect(() => {
      if (user && hasAccess(user, "hopitaux_habilitation")) {
        navigate("/admin/parametres")
      }
    }, [user])
    return (
      <WrapperComponent>
        <div className="container-title-dash d-flex align-items-center gap-4">
          <div className="custom-sm-top">
            <h2 className="custom-sm-top-title mb-0">Hôpitaux/cabinets</h2>
            <NavLink
              to={`/admin/cabinets/ajouter-cabinet`}
              className="btn custom-add-item-btn"
            >
              <span className="add-icon">
                <MdOutlineAddBox />
              </span>
              Ajouter un hôpital/cabinet
            </NavLink>
          </div>
        </div>
        <div className="search-patient-box d-flex align-items-center gap-3 mt-4">
          <input
            type="search"
            name="search_patient"
            id="search_patient"
            placeholder="Entrer un Nom, un numéro d’idenfication,..."
            onChange={(e) => setQuery(e?.target?.value)}
          />
          {/* <button className="btn search-submit-patient-btn">
            <span>
              <MdSearch />
            </span>
            Chercher
          </button> */}
        </div>

        <div className="custom-table mt-5">
          <CabinetHopitauxTable q={query} />
        </div>
      </WrapperComponent>
    );
}

export default CabinetHopitaux;