import { PhoneNumberUtil } from "google-libphonenumber";
import * as Yup from "yup";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import { IUser } from "./api/user/user.type";
import Avatar from "../assets/appImages/default.png";
import Image from "../assets/appImages/img-bg.png";
import { ApiBaseUrl } from "./http";
import $ from "jquery";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import moment from "moment";
import { Modal } from "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { IRdvs } from "./api/rdvs/rdvs.type";
import { useAppSelector } from "../redux/hook";
import {
  isCabinet,
  isMedecin,
  isPatient,
  isSecretaire,
} from "../routes/routerUtils";

const phoneUtil = PhoneNumberUtil.getInstance();

export const useUser = () => {
  const { user } = useAppSelector((s) => s?.user);

  return user;
};

export function validateNumber(phone?: string, country?: string) {
  if (!phone || !country) return true;
  try {
    const number = phoneUtil.parse(phone, country);
    if (phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number)) {
      return true;
    }
  } catch (ex) {
    console.log("error phone", ex);
  }
  return false;
}

export function validatePhone(yup: typeof Yup, country: string) {
  return yup
    .string()
    .test("passwords-match", "Numéro téléphone invalide", (val) =>
      validateNumber(val, country)
    )
    .label("Le téléphone")
    .nullable();
}

export function validatePassword(Yup: any) {
  return Yup.string()
    .required("Le mot de passe est obligatoire ")
    .matches(/[a-z]+/, "Le mot de passe doit avoir au moins une minuscule.")
    .matches(/[A-Z]+/, "Le mot de passe doit avoir au moins une majuscule.")
    .matches(
      /[!@#$%^&*(),;-_+*/.?":{}|<>]+/,
      "Le mot de passe doit avoir au moins un caractère spécial"
    )
    .matches(/\d+/, "Le mot de passe doit avoir au moins un chiffre.")
    .min(8, "Le mot de passe doit être supérieur à 5 caractére");
  //.label("Le mot de passe");
}

export function useLocationState<T>(
  defaultValue: T | any,
  redirectToWhenNull: string | null = null
): T {
  const state = useLocation().state as T;
  const navigate = useNavigate();
  useEffect(() => {
    if (!state && redirectToWhenNull) {
      navigate(redirectToWhenNull);
    }
  }, [state]);
  return state || defaultValue;
}
export function createMarkup(
  text: string | any,
  showDots?: boolean,
  numberSlice?: number
) {
  return {
    __html: `${numberSlice ? text.slice(0, numberSlice) : text}${
      showDots === true ? "..." : ""
    }`,
  };
}
export function readMarkup(
  text: string | any,
  showDots?: boolean,
  numberSlice?: number
) {
  let content = text ? text.replace(/<.+?>/g, "") : text;
  return {
    __html: `${numberSlice ? content.slice(0, numberSlice) : content}${
      showDots === true ? "..." : ""
    }`,
  };
}

export function cleannerError(
  errors: any,
  cleanner: any,
  timeOut: number = 3000
) {
  if (errors) {
    setTimeout(
      () => Object.entries(errors).map(([key]: any) => cleanner(key)),
      timeOut
    );
  }
}

export function isRH(user: any) {
  if (!user) return false;
  return ["rh"].includes(user.user_type);
}

// export function isGestionnaire(user: any) {
//   if (!user) return false;
//   return ["gestionnaire"].includes(user.user_type);
// }

export function QueryUrl(baseUrl: string, additionalSearchQuery: any) {
  if (additionalSearchQuery) {
    for (let key of Object.keys(additionalSearchQuery)) {
      let val = additionalSearchQuery[key];

      if (Array.isArray(val)) {
        if (val.length > 0) {
          const string = val.map((v) => `${key}=${v}`).join("&");
          if (baseUrl.includes("?")) {
            baseUrl += `&${string}`;
          } else {
            baseUrl += `?${string}`;
          }
        }
      } else if (typeof val === "boolean") {
        if (baseUrl.includes("?")) {
          baseUrl += `&${key}=${val}`;
        } else {
          baseUrl += `?${key}=${val}`;
        }
      } else {
        if (val) {
          if (baseUrl.includes("?")) {
            baseUrl += `&${key}=${val}`;
          } else {
            baseUrl += `?${key}=${val}`;
          }
        }
      }
    }
  }
  return baseUrl;
}

export const isUser = (user: IUser) => {
  return user?.user_type === "user";
};

export const isSuperAdmin = (user: IUser) => {
  return user?.user_type === "superadmin";
};

export const isAdmin = (user: IUser) => {
  return user?.user_type === "admin";
};

export function isPraticien(user: any) {
  return user?.user_type === "praticien";
}

export function isGestionnaire(user: any) {
  return user?.user_type === "gestionnaire";
}

export const getAvatar = (avatar: string | any) => {
  if (avatar && !avatar?.includes("default.png")) {
    if (avatar.indexOf("http") === 0) return avatar;
    return `${ApiBaseUrl}/${avatar}`;
  }
  return Avatar;
};

export const getImage = (image: string | any) => {
  if (image && !image?.includes("default.png")) {
    if (image.indexOf("http") === 0) return image;
    return `${ApiBaseUrl}/${image}`;
  }
  return Image;
};

export function getName(user: IUser) {
  // console.log(user,"teste")
  let name = user?.prenom + " " + user?.nom;
  if (isMedecin(user)) {
    name = "Dr" + " " + user?.prenom + " " + user?.nom;
  }

  if (isCabinet(user)) {
    name = user?.nom_cabinet || user?.prenom + " " + user?.nom;
  }
  return name;
}

export function getNameCabinet(user: IUser) {
  // console.log(user,"teste")
  let name = user?.structure?.nom_cabinet;

  if (isCabinet(user)) {
    name = user?.nom_cabinet;
  }
  return name;
}

export function getType(user: IUser) {
  let type = "Admin";
  if (isMedecin(user)) {
    type = "Médecin";
  }

  if (isSecretaire(user)) {
    type = "Secrétaire médicale";
  }

  if (isPatient(user)) {
    type = "Patient";
  }

  if (isCabinet(user)) {
    type = "Cabinet";
  }

  return type;
}

export const onHide = (modalId) => {
  // console.log("modalId",modalId)
  document.getElementById(modalId)?.classList.remove("show");
  $(`#${modalId}`).hide();
  // $(`#${modalId}`).click();
  $("body").removeClass("modal-open");
  $(".modal-backdrop").remove();
  $(`body`).css({ overflowY: "scroll" });
};

export const onShow = (modalId: string) => {
  document.getElementById(modalId)?.classList.add("show");
  $(`#${modalId}`).show();
};

export const countUppercase = (str: any) => {
  if (str.replace(/[^A-Z]/g, "")?.length >= 1) {
    return true;
  }
  return false;
};

export const countLowercase = (str: any) => {
  if (str.replace(/[^a-z]/g, "")?.length >= 1) {
    return true;
  }
  return false;
};

export const countNumber = (str: any) => {
  if (str.replace(/[^0-9]/g, "")?.length >= 1) {
    return true;
  }
  return false;
};

export const countCharacters = (str: any) => {
  if (str?.length >= 8) {
    return true;
  }
  return false;
};

export const countSpecial = (str: any) => {
  const punct = `/[!@#$%^&*()_+\\-=\\[\\]{};':"\\|,.<>\\/?]+/;`;
  let count = 0;
  for (let i = 0; i < str?.length; i++) {
    if (!punct.includes(str[i])) {
      continue;
    }
    count++;
  }
  // return count;
  if (count >= 1) {
    return true;
  }
  return false;
};

const CURRENCY_FORMATTER = new Intl.NumberFormat(undefined, {
  currency: "XOF",
  style: "currency",
});

export function formatCurrency(number: number | any) {
  return CURRENCY_FORMATTER.format(number);
}

export const getAddress = (user: IUser) => {
  return user?.adresse;
};

export const formatDateHour = (date) => {
  return moment(date).format("DD/MM/YYYY à HH:mm:ss");
};

export const formatDate = (date) => {
  if (!date) return "";
  return moment(date).format("DD/MM/YYYY");
};

export const formatHour = (hour) => {
  if (!hour) return "";
  return moment(hour, "HH:mm").format("HH[h]mm");
};

export const sortByAlphabetical = (arr) => {
  return [...arr]?.sort(function (a, b) {
    if (a?.name < b?.name) {
      return -1;
    }
    if (a?.name > b?.name) {
      return 1;
    }
    return 0;
  });
};

export const truncate = (str) => {
  return str != null && str.length > 10 ? str.substring(0, 19) + "..." : str;
};

export const showModal = (id: string) => {
  // console.log("id", id);
  const element = document.getElementById(id);

  const myModal = new Modal(element);
  myModal.show();
};

export const closeModal = (id: string) => {
  const element = document.getElementById(id);
  const myModal = Modal.getInstance(element);
  $(".modal-backdrop").remove();
  $("body").removeClass("modal-open");
  $("body").css("padding-right", "");
  $("body").css("overflow", "scroll");
  myModal?.hide();
  $("body").css("overflow", "auto");
};

export const getStatus = (item: IRdvs) => {
  let status = "En attente";

  if (item?.status === "en_cours" || item?.status === "addendum") {
    status = "En cours";
  }

  if (item?.status === "terminer") {
    status = "Terminé";
  }

  if (item?.status === "annuler") {
    status = "Annulé";
  }

  return status;
};

export const getStatusTache = (item: "todo" | "doing" | "done") => {
  let status = "À faire";

  if (item === "doing") {
    status = "En cours";
  }

  if (item === "done") {
    status = "Terminé";
  }

  return status;
};
export const getRdvRoute = (user: IUser) => {
  let route = "/secretaire-medical/rendez-vous";
  if (isAdmin(user)) {
    route = "/admin/rendez-vous";
  }
  if (isCabinet(user)) {
    route = "/cabinet/rendez-vous";
  }
  if (isMedecin(user)) {
    route = "/medecin/rendez-vous";
  }

  if (isPatient(user)) {
    route = "/patient/rendez-vous";
  }

  return route;
};

export const getChatPageRoute = (user: IUser) => {
  let route = "/secretaire-medical/messages";
  if (isAdmin(user)) {
    route = "/admin/messages";
  }
  if (isCabinet(user)) {
    route = "/cabinet/messages";
  }
  if (isMedecin(user)) {
    route = "/medecin/messages";
  }

  if (isPatient(user)) {
    route = "/patient/messages";
  }

  return route;
};

export const getChatRoute = (user: IUser) => {
  let route = "/secretaire-medical/messages/details";
  if (isAdmin(user)) {
    route = "/admin/messages/details";
  }
  if (isCabinet(user)) {
    route = "/cabinet/messages/details";
  }
  if (isMedecin(user)) {
    route = "/medecin/messages/details";
  }

  if (isPatient(user)) {
    route = "/patient/messages/details";
  }

  return route;
};

export const getAge = (date: string) => {
  if (!date) {
    return "";
  }
  var birthyear = moment(date, "YYYY-MM-DD");
  var visitdate = moment(new Date());
  return visitdate.diff(birthyear, "y") + "ans";
};

export const checkDate = (date: string) => {
  let isValid = moment(date, "YYYY-MM-DD")?.isSame(new Date(), "day");
  // console.log("date",date)
  return isValid;
};

export const fileName = (uri) => {
  var filename = uri?.split("/")?.pop();

  return filename || "";
};

export const OnClickRow = (e: any, row: any, route: string) => {
  const navigate = useNavigate();
  let getCurrentCellIndex = e.target.cellIndex;
  let id: any = document.querySelector("table tr:last-child td:last-child");
  let getLastCellIndex = id?.cellIndex;

  if (
    getCurrentCellIndex !== getLastCellIndex &&
    getCurrentCellIndex !== undefined
  ) {
    return navigate(`/medecin/rendez-vous/${row?.slug}`, { state: row });
  }
  return "";
};

export function get_url_extension(uri: any) {
  if (uri?.type) {
    return uri?.type?.toLowerCase()?.split("/")[1];
  }
  return uri?.toLowerCase()?.split(/[#?]/)[0]?.split(".")?.pop()?.trim();
}

export const getFile = (file: string | any) => {
  if (file && !file.includes("default.png")) {
    if (file.indexOf("http") === 0) return file;
    return ApiBaseUrl + file;
  }
  return "";
};

export const getMsgDate = (date: string) => {
  let value = formatDate(date);
  var yesterday = moment().subtract(1, "d");

  if (moment(date).isSame(new Date(), "d")) {
    value = moment(date).format("HH[h]mm");
  } else if (moment(date).isSame(new Date(), "week")) {
    if (moment(date).isSame(yesterday, "d")) {
      value = "Hier";
    } else {
      value = moment(date).format("DDDD");
    }
  }

  return value;
};

export const getUrlBase64ToBlob = (url) => {
  if (!url) return null;

  return dataURLtoBlob(url);
};

export const generateHours = (heureDebut: number, heureFin: number) => {
  const heures: string[] = [];

  // Générer les heures entre l'heure de début et de fin
  for (let heure = heureDebut; heure <= heureFin; heure++) {
    heures.push(`${heure}h`);
    // heures.push(`${heure}h`);
    // heures.push(`${heure}h`);
    // heures.push(`${heure}h`);
  }

  return heures;
};

export const generateArrayDates = (
  dateDebut,
  dateFin,
  jours: string[] = [],
  heures,
  data: any = []
) => {
  let arrayDates: any = [];

  const currentDate = new Date(dateDebut);
  while (currentDate <= new Date(dateFin)) {
    const jourActuel = currentDate.toLocaleDateString("fr-FR", {
      weekday: "long",
    });

    if (jours?.length > 0 && typeof jours !== "string") {
      if (jours?.some((j: string) => j?.toLocaleLowerCase() === jourActuel)) {
        heures?.forEach((heure) => {
          const startDate = new Date(currentDate);
          const endDate = new Date(currentDate);

          // const [heureDuJour, minute] = heure.split(":");
          startDate.setHours(Number(heure), Number("00"), 0, 0);
          endDate.setHours(Number(heure), Number("45"), 0, 0);
          let day = moment(startDate).format("DD/MM/YYYY HH:mm");
          // moment(el?.jour)?.isSame(startDate, "d") &&
          //   moment(el?.jour)?.isSame(startDate, "h");

          if (!data?.some((el) => new Date(el?.jour) === new Date(day))) {
            arrayDates.push({
              jour: moment(startDate).format("DD/MM/YYYY HH:mm"),
              heure_debut: moment(startDate).format("HH:mm"),
              heure_fin: moment(endDate).format("HH:mm"),
              start: startDate,
              end: endDate,
              title: "",
              // display: "background",
              class: `dispo-event-container`,
            });
          } else {
            let item = data?.find((el) => new Date(el?.jour) === new Date(day));
            // console.log("item same", item);
          }
        });
      }
    }

    currentDate.setDate(currentDate.getDate() + 1);
  }

  // console.log(arrayDates, "arrayDates");

  return arrayDates;
};

export const generateDisponibilites = (data: any, rdvArray: IRdvs[]) => {
  const disponibilites: any = [];

  // Obtenir les dates entre date_debut et date_fin
  const startDate = new Date(data.date_debut);
  const endDate = new Date(data.date_fin);

  // Récupérer les jours disponibles
  const joursDisponibles = data?.jours?.map((jour) => {
    switch (jour) {
      case "Lundi":
        return 1;
      case "Mardi":
        return 2;
      case "Mercredi":
        return 3;
      case "Jeudi":
        return 4;
      case "Vendredi":
        return 5;
      case "Samedi":
        return 6;
      case "Dimanche":
        return 0;
      default:
        return -1;
    }
  });

  // Générer les disponibilités
  for (
    let current = new Date(startDate);
    current <= endDate;
    current.setDate(current.getDate() + 1)
  ) {
    const currentDay: any = current.getDay();

    if (joursDisponibles?.includes(currentDay)) {
      data?.horaires?.forEach((horaire) => {
        const [hours, minutes] = horaire?.split(":");
        const startTime = new Date(current);
        startTime.setHours(hours, minutes);

        const endTime = new Date(startTime.getTime() + data?.duree * 60000); // Ajouter la durée en minutes
        let startHours = moment(startTime).format("HH:mm:ss");
        let endHours = moment(endTime).format("HH:mm:ss");

        // Vérifier chaque rendez-vous dans le tableau de rendez-vous
        let isAvailable = true;
        let rdvItem: any = undefined;
        if (rdvArray?.length) {
          isAvailable = rdvArray?.every((rdvObject) => {
            const rdvDate = new Date(rdvObject?.date);
            const rdvTime = new Date(
              rdvObject?.date + "T" + rdvObject?.horaire_de_visite
            );
            const [heure, minute] = rdvObject?.horaire_de_visite?.split(":");
            rdvDate.setHours(Number(heure), Number(minute), 0);
            const endHour = moment(
              new Date(rdvDate.getTime() + data?.duree * 60000)
            ).format("HH:mm");
            const rdvEndTime = new Date(rdvObject?.date + "T" + endHour);

            let rdvStartHours = moment(rdvTime).format("HH:mm:ss");
            let rdvEndHours = moment(rdvEndTime).format("HH:mm:ss");

            if (moment(rdvTime)?.isSame(startTime, "d")) {
              if (
                moment(rdvStartHours, "HH:mm:ss")?.isSame(
                  moment(startHours, "HH:mm:ss"),
                  "h"
                ) &&
                moment(rdvStartHours, "HH:mm:ss")?.isSame(
                  moment(startHours, "HH:mm:ss"),
                  "minute"
                )
              ) {
                rdvItem = rdvObject;
                return false;
              }

              if (
                moment(rdvStartHours, "HH:mm:ss").isBetween(
                  moment(startHours, "HH:mm:ss"),
                  moment(endHours, "HH:mm:ss")
                )
              ) {
                // console.log(" rdvStartHours is between");
                rdvItem = rdvObject;
                return false;
              }

              if (
                moment(rdvEndHours, "HH:mm:ss").isBetween(
                  moment(startHours, "HH:mm:ss"),
                  moment(endHours, "HH:mm:ss")
                )
              ) {
                // console.log(" rdvEndHours is between");
                rdvItem = rdvObject;
                return false;
              }
              return true;
            }

            return true;
          });
        }
        if (isAvailable) {
          disponibilites.push({
            start: startTime.toISOString(),
            end: endTime.toISOString(),
            title: data?.indisponibilite_dates?.every(
              (el) =>
                el?.start !== startTime.toISOString() &&
                el?.end !== endTime.toISOString()
            )
              ? ""
              : "Indisponible",
            borderColor: data?.indisponibilite_dates?.every(
              (el) =>
                el?.start !== startTime.toISOString() &&
                el?.end !== endTime.toISOString()
            )
              ? "099876"
              : "red",
            groupId: data?.slug,
            dispo: data?.indisponibilite_dates?.every(
              (el) =>
                el?.start !== startTime.toISOString() &&
                el?.end !== endTime.toISOString()
            ),
            start_time: moment(startTime.toISOString()).format("HH:mm"),
            end_time: moment(endTime.toISOString()).format("HH:mm"),
          });
        } else {
          if (rdvItem) {
            // console.log(rdvItem, "rdvItem");
            const rdvDate = new Date(rdvItem?.date);
            const rdvTime = new Date(
              rdvItem?.date + "T" + rdvItem?.horaire_de_visite
            );
            const [heure, minute] = rdvItem?.horaire_de_visite?.split(":");
            rdvDate.setHours(Number(heure), Number(minute), 0);
            const endHours = moment(
              new Date(rdvDate.getTime() + data?.duree * 60000)
            ).format("HH:mm");
            const rdvEndTime = new Date(rdvItem?.date + "T" + endHours);
            if (disponibilites?.every((el) => el?.id !== rdvItem?.id)) {
              disponibilites.push({
                start: rdvTime.toISOString(),
                end: rdvEndTime.toISOString(),
                title: `Rendez-vous avec ${getName(rdvItem?.patient)}`,
                borderColor: "red",
                // backgroundColor: "red",
                groupId: rdvItem?.slug,
                id: rdvItem?.id,
                dispo: false,
                start_time: moment(rdvTime.toISOString()).format("HH:mm"),
                end_time: moment(rdvEndTime.toISOString()).format("HH:mm"),
                type: "rdv",
              });
            }
          }
        }
      });
    }
  }

  if (rdvArray?.length) {
    let outDispos = rdvArray?.filter(
      (el) => !joursDisponibles?.includes(new Date(el?.date)?.getDay())
    );
    // console.log(outDispos, "outDispos");
    if (outDispos?.length) {
      outDispos.forEach((dispo) => {
        const rdvDate = new Date(dispo?.date);
        const rdvTime = new Date(dispo?.date + "T" + dispo?.horaire_de_visite);
        const [heure, minute] = dispo?.horaire_de_visite?.split(":");
        rdvDate.setHours(Number(heure), Number(minute), 0);
        const endHours = moment(
          new Date(rdvDate.getTime() + data?.duree * 60000)
        ).format("HH:mm");
        const rdvEndTime = new Date(dispo?.date + "T" + endHours);
        if (disponibilites?.every((el) => el?.id !== dispo?.id)) {
          disponibilites.push({
            start: rdvTime.toISOString(),
            end: rdvEndTime.toISOString(),
            title: `Rendez-vous avec ${getName(dispo?.patient)}`,
            borderColor: "red",
            // backgroundColor: "red",
            groupId: dispo?.slug,
            id: dispo?.id,
            dispo: false,
            start_time: moment(rdvTime.toISOString()).format("HH:mm"),
            end_time: moment(rdvEndTime.toISOString()).format("HH:mm"),
            type: "rdv",
          });
        }
      });
    }
  }

  return disponibilites;
};

export const useOutsideClick = (callback) => {
  const ref = React.useRef<any>(null);

  React.useEffect(() => {
    const handleClick = (event) => {
      callback();
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return ref;
};
