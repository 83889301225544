import React from "react";
import Modal from "react-modal";
import CloseModalBtn, { CloseModalBtnV2 } from "../../shared/CloseModalBtn";
import { BsCreditCard } from "react-icons/bs";
import { Facturation } from "../../../utils/api/facturation/facturation.type";
import {
  closeModal,
  formatCurrency,
  formatDate,
  getName,
} from "../../../utils/Utils";

const ShowPaiementModal = ({
  modalId,
  item,
}: {
  modalId: string;
  item: Facturation;
}) => {
  let amount = item?.tarifs?.reduce((acc, t) => {
    return acc + parseInt(t?.montant);
  }, 0);
  return (
    <div
      className="modal fade custom-modal"
      id={modalId}
      aria-labelledby={modalId + "Label"}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-header pe-2 py-1">
            <h5
              className="modal-title show-paiement-modal-title"
              id="ShowPaiementModalLabel"
            >
              Paiement de {getName(item?.patient)}
            </h5>
            <CloseModalBtnV2 onClick={() => closeModal(modalId)} />
          </div>
          <div className="modal-body">
            <div className="container-scroll-modal">
              <div className="row row-paiement-details pt-4">
                <div className="col-md-12 d-flex align-items-center justify-content-between gap-3">
                  <span className="sm-title">Date:</span>
                  <span className="sm-value">
                    {formatDate(item?.created_at)}
                  </span>
                </div>
                {item?.tarifs?.map((el) => (
                  <div
                    className="col-md-12 d-flex align-items-center justify-content-between gap-3"
                    key={el?.id}
                  >
                    <span className="sm-title">{el?.denomination}:</span>
                    <span className="sm-value">
                      {formatCurrency(el?.montant)}
                    </span>
                  </div>
                ))}

                <div className="col-md-12 d-flex align-items-center justify-content-between gap-3">
                  <span className="sm-title">Montant:</span>
                  <span className="sm-value">{formatCurrency(amount)}</span>
                </div>

                {/* <div className="col-md-12 d-flex align-items-center justify-content-between gap-3">
                <span className="sm-title">Carte:</span>
                <span className="sm-value">
                  **** 4125 <BsCreditCard />
                </span>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowPaiementModal;

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    zIndex: 99999,
  },
};
