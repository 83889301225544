import React from 'react';
import AllDetailPatientDossierContent from '../../../Tables/AllDetailPatientDossierContent';
import { IUser } from '../../../../../utils/api/user/user.type';
import { IRdvs } from '../../../../../utils/api/rdvs/rdvs.type';

function Dossier({item,rdv}:{item:IUser;rdv?:IRdvs}) {
    return (
        <>
            <AllDetailPatientDossierContent item={item} rdv={rdv} />
        </>
    )
}

export default Dossier;