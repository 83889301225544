import React from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import { NavLink } from "react-router-dom";
import { MdOutlineAddBox } from "react-icons/md";
import TarificationTable from "./TarificationTable";
import AddOrEditTArifModal from "./modal/AddOrEditTArifModal";
import { showModal } from "../../../utils/Utils";

function TarificationPage() {
  return (
    <WrapperComponent>
      <div className="container-title-dash d-flex align-items-center gap-4">
        <div className="custom-sm-top">
          <h2 className="custom-sm-top-title mb-0">
            Tarification des prestations
          </h2>
          <button
            className="btn custom-add-item-btn"
            onClick={() => showModal("addTarifModal")}
          >
            <span className="add-icon">
              <MdOutlineAddBox />
            </span>
            Ajouter un tarif
          </button>
        </div>
      </div>

      <div className="custom-table mt-5">
        <TarificationTable />
      </div>
      <AddOrEditTArifModal modalId="addTarifModal" />
    </WrapperComponent>
  );
}

export default TarificationPage;
