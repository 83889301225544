import React from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import "../Patient.css";
import AllPatientVaccinTable from "../Tables/PatientVaccinTable";


function VaccinPatient() {

    return(
        <>
            <WrapperComponent>
                <div className="container-title-dash d-flex align-items-center gap-4 pb-4">
                    <h2 className="custom-sm-top-title mb-0">
                        Mes vaccins
                    </h2>
                </div>
                
                <div className="custom-table pt-4 pb-4">
                    <AllPatientVaccinTable />
                </div>
            </WrapperComponent>
        </>
    )
}

export default VaccinPatient;