import { ChangeEvent, useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  cleannerError,
  getName,
  isAdmin,
  validatePhone,
} from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import { IUser, UserFormData } from "../../../../utils/api/user/user.type";
import { useAppSelector } from "../../../../redux/hook";
import {
  useAddOrEditSecretaireMutation,
  useDeleteSecretaireMutation,
  useGetPraticienByCabinetQuery,
} from "../../../../utils/api/user/user.api";
import { isCabinet } from "../../../../routes/routerUtils";

function UseAddOrEditSecretaireForm(item?: IUser) {
  const [phone, setPhone] = useState<any>();
  const [code, setCode] = useState("");
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState<{ label: string; value: number }[]>(
    []
  );
  const [option, setOption] = useState<
    { label: string; value: number } | undefined
  >();
  const validationSchema = yup.object().shape({
    nom: yup.string().required().label("Le nom"),
    prenom: yup.string().required().label("Le prénom"),
    telephone: validatePhone(yup, code).required(),
    email: yup.string().email().required().label("L'email"),
    structure: yup
      .number()
      .required()
      .label("L'hôpital ou clinique affilié(e)")
      .transform((val: any) => (isNaN(val) ? null : val))
      .nullable(),
    praticien: yup
      .number()
      .label("L'assignation")
      .required()
      .transform((val: any) => (isNaN(val) ? null : val))
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
  } = useForm<UserFormData>({
    resolver: yupResolver(validationSchema),
  });
  const { user } = useAppSelector((s) => s?.user);
  const { data = { results: [] } } = useGetPraticienByCabinetQuery({
    slug: user?.slug,
    q: search,
  });
  const navigate = useNavigate();
  const [sendData, { isLoading, isSuccess, isError, error }] =
    useAddOrEditSecretaireMutation();

  useEffect(() => {
    if (isCabinet(user)) {
      setValue("structure", user?.id);
    }
  }, [user]);

  useEffect(() => {
    if (data?.results?.length) {
      setOptions(
        data?.results?.map((el) => {
          return {
            label: getName(el),
            value: el?.id,
          };
        })
      );
    }
  }, [data]);

  const handleSelectPraticien = (val) => {
    setOption(val);
    setValue(`praticien`, val?.value);
  };

  const handleChangePhone = (item: any, country: any) => {
    setPhone(item);
    setCode(country?.countryCode);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue("telephone", item);
    } else {
      setValue("telephone", "");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: !item
          ? "Secretaire ajouté avec succès"
          : "Le secretaire a été modifié avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (isAdmin(user)) {
          navigate("/admin/secretaires");
        } else {
          navigate("/cabinet/secretaires");
        }
      });
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("user with this email already exists.")) {
        Swal.fire({
          icon: "error",
          title: "Un utilisateur avec cet email exite déjà!",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Erreur de statut ${err?.status}`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    // console.log("cabinet", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (item?.id) {
      
      let fields: (keyof UserFormData)[] = [
        "nom",
        "prenom",
        "telephone",
        "email",
        "adresse",
        "ville",
        "structure",
        "praticien",
      ];

      if (item?.telephone) {
        setPhone(item.telephone);
      }

      if (item?.praticien) {
        setOption({
          label: getName(item?.praticien),
          value: item?.praticien?.id,
        });
      }

      for (let field of fields) {
        register(field);
        if (field !== "structure" && field !== "praticien") {
          setValue(field, item[field]);
        } else {
          if (item[field]?.id) {
            setValue(field, item[field]?.id);
          }
          
        }
      }
    }
  }, [item]);

  const onSubmit = async (data: UserFormData) => {
    // const fd = new FormData();

    // for (let key of Object.keys(data)) {
    //   if (key === "avatar") {
    //     if (data?.avatar) {
    //       fd.append(key, data?.avatar);
    //     }
    //   } else {
    //     fd.append(key, data[key]);
    //   }
    // }
    // console.log("data",data)
    await sendData({
      slug: item?.slug,
      data: data,
    });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChangePhone,
    handleSelectPraticien,
    Controller,
    control,
    phone,
    options,
    option,
    setSearch,
  };
}

export default UseAddOrEditSecretaireForm;


export function UseArchiveSecretaire(item: IUser) {
  const [sendData] = useAddOrEditSecretaireMutation();
  const onArchive = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir ${
        item?.is_archive ? "désarchiver" : "archiver"
      } cette secrétaire ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData({
          slug: item?.slug,
          data: { is_archive: !item?.is_archive },
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `La secrétaire a été ${
              item?.is_archive ? "désarchivée" : "archivée"
            } avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        }else {
        let err = result?.value?.error;
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Une erreur de statut ${err?.status} est survenue!`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
      } 
    });
  };
  return onArchive;
}


export function UseActiveSecretaire(item: IUser) {
  const [sendData] = useAddOrEditSecretaireMutation();
  const onActive = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir ${
        item?.is_active ? "désactiver" : "activer"
      } cette secrétaire ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData({
          slug: item?.slug,
          data: { is_active: !item?.is_active },
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `La secrétaire a été ${
              item?.is_active ? "désactivée" : "activée"
            } avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      } 
    });
  };
  return onActive;
}

export function UseDeleteSecretaire(slug: string, redirectRoute?: string) {
  const [sendData] = useDeleteSecretaireMutation();
  const navigate = useNavigate();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette secrétaire ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `La secrétaire a été supprimée avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            if (redirectRoute) {
              navigate(redirectRoute);
            }
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}