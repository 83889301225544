import React, { useEffect, useState } from "react";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import { BsTrash } from "react-icons/bs";
import TableSkeleton from "../../common/TableSkeleton";
import { OrdonnancesMedecinTableData } from "../Tables";
import "./Table.css";
import UseAddMedicamentForm from "../rdvMedecin/form/UseAddMedicamentForm";
import { useRdvFromLocation } from "../../../utils/api/rdvs/rdvs.api";
import { BtnAnnuler, BtnSubmit } from "../../common/Button";
import { useNavigate } from "react-router-dom";
import { Medicament } from "../../../utils/api/ordonnance/ordonnance.type";

function OrdonnancesMedecinTable({ item }: { item?: Medicament }) {
  const [rdv] = useRdvFromLocation();
  const navigate = useNavigate();
  const { fields, remove, append, onSubmit, isLoading } =
    UseAddMedicamentForm(rdv);

  useEffect(() => {
    if (item) {
      append(item);
    }
  }, [item]);

  const actionFormatter = (cell: any, row: any, i) => {
    return (
      <>
        <div className="d-flex justify-content-center gap-3">
          <div className="container-btn-action-icon-modal">
            <button
              className="btn with-tooltip btn-action-icon-delete p-0"
              data-tooltip-content="Supprimer"
              type="button"
              style={{ color: "#E53E3E" }}
              onClick={() => remove(i)}
            >
              <BsTrash />
            </button>
          </div>
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "nom",
      text: `Nom du médicament`,
    },
    {
      dataField: "posologie",
      text: "Posologie",
    },
    {
      dataField: "quantite",
      text: "Quantité",
    },
    {
      dataField: "duree",
      text: "Durée",
    },
    {
      dataField: "id",
      text: "Action",
      formatter: actionFormatter,
    },
  ];

  return (
    <form onSubmit={onSubmit}>
      <BootstrapTable
        keyField="id"
        data={fields}
        columns={columns}
        condensed
        hover
        rowClasses="custom-table-row-class"
        wrapperClasses="table-responsive admin-table"
        noDataIndication={() => (
          <AlertInfo message="Veuillez ajouter des médicaments" />
        )}
      />
      <hr className="my-5" />
      <div className="d-flex flex-r mt-4">
        <div className="col-md-2">
          <BtnAnnuler
            label="Annuler"
            customClass="custom-btn-annuler"
            onClick={() =>
              navigate(`/medecin/rendez-vous/${rdv?.slug}?step=medicament`, {
                state: rdv,
              })
            }
          />
        </div>
        <div className="col-md-2">
          <BtnSubmit label="Valider" isLoading={isLoading} />
        </div>
      </div>
      {/* <div className="custom-pagination-container">
              <CustomPagination
                  nbPages={FacturePraticienDatas?.length}
                  page={page}
                  onChange={(page, perPage) => {
                  setPerPage(perPage)
                  setPage(page)
                  }}
                  perPage={perPage}
              />
              </div> */}
    </form>
  );
}

export default OrdonnancesMedecinTable;
