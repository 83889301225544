import React, { useState, useEffect } from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import Select from "react-select";
import { getName, useUser } from "../../../utils/Utils";
import Calendar from "./CalendarAgenda";
import { useGetPraticienByCabinetQuery } from "../../../utils/api/user/user.api";
import { animatedComponents } from "../../common/Input";
import { InfosPraticien } from "./DetailPraticen";

function AgendaMedecin() {
  const user = useUser();
  const [query, setQuery] = useState("");
  const [options, setOptions] = useState<any>([]);
  const [option, setOption] = useState<any>(null);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetPraticienByCabinetQuery({
      slug: user?.structure?.slug,
      limit: 50,
      q: query,
    });

  useEffect(() => {
    if (user?.praticien) {
      setOption({
        label: getName(user?.praticien),
        value: user?.praticien?.id,
        ...user?.praticien,
      });
    }
  }, [user]);

  useEffect(() => {
    if (data?.results?.length) {
      setOptions(
        data?.results?.map((el) => {
          return {
            label: getName(el),
            value: el?.slug,
            ...el,
          };
        })
      );
    }
  }, [data]);
  const handleSelect = (val) => {
    setOption(val);
  };
  return (
    <WrapperComponent>
      <div className="container-title-dash d-flex align-items-center gap-4 pb-4 justify-content-between">
        <h2 className="custom-sm-top-title mb-0">Agenda</h2>
      </div>
      <div className="search-patient-box d-flex align-items-center gap-3 mt-4 mb-4 with-select-medecin w-70">
        <Select
          closeMenuOnSelect={true}
          components={animatedComponents}
          placeholder="Sélectionnez ou cherchez un médecin"
          options={options}
          onInputChange={(val) => setQuery(val)}
          classNamePrefix="react-select-with-select-medecin"
          onChange={handleSelect}
          value={option}
          isLoading={isLoading}
        />
      </div>

      <div className="custom-content pt-4 pb-4">
        {/* <div className="row"></div> */}
        <div className="custom-content pt-4 pb-4">
          {!isLoading && option && <InfosPraticien item={option} />}
        </div>
        <div className="content-graph-admin">
          <Calendar user={option} />
        </div>
      </div>
    </WrapperComponent>
  );
}

export default AgendaMedecin;
