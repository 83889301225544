import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useSearchPatientsQuery } from "../../utils/api/user/user.api";
import { formatDate, getAvatar, getName, useOutsideClick, useUser } from "../../utils/Utils";
import { NavLink } from "react-router-dom";

function SearchList({ search, setSearch }: { search: string; setSearch: Dispatch<SetStateAction<string>> }) {
  let [searchParams, setSearchParams] = useSearchParams();
  let q = searchParams.get("search") || "";
  const user = useUser();
  const { data = { results: [], count: 0 } } =
    useSearchPatientsQuery({
      slug: user?.slug,
      q,
    });
  useEffect(() => {
    setSearchParams((params) => {
      params.set("search", search || "");
      return params;
    });
  }, [search]);

  const handleClickOutside = () => {
    setSearch("");
  };

  const ref = useOutsideClick(handleClickOutside);


  return (
    <div className="bg-white search-results shadow-lg" ref={ref}>
      {q && data?.results?.length > 0 && (
        <ul className="border-top">
          {data?.results?.map((item) => (
            <li
              key={item?.slug}
              className="mb-3 praticien-result-search"
              //   onClick={() => handleClick(getName(item))}
            >
              <NavLink
                to={`/medecin/patients/${item?.slug}`}
                state={item}
                onClick={() => setSearch("")}
                className="d-flex align-items-center gap-2 no-link"
              >
                <div>
                  <img
                    src={getAvatar(item?.avatar)}
                    alt="user avatar"
                    style={{
                      width: "50px",
                      height: "50px",
                      border: "4.75676px solid #099876",
                      borderRadius: "50px",
                    }}
                  />
                </div>
                <div className="">
                  <h5 className="resultat-recherche-user-infos-specialite">
                    {getName(item)}
                  </h5>
                  <address className="resultat-recherche-user-infos-adresse m-0">
                    Né le {formatDate(item?.date_de_naissance)}
                  </address>
                </div>
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default SearchList;
