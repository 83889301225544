import React from "react";
import { BtnCloseModalV2 } from "../../common/Button";
import { closeModal, get_url_extension } from "../../../utils/Utils";

function ShowFileModal({
  file,
  modalId,
  title = "Fichier",
}: {
  file: string;
  modalId: string;
  title?: string;
}) {
  return (
    <div
      className="modal fade custom-modal"
      id={modalId}
      aria-labelledby={modalId + "Label"}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id={modalId + "Label"}>
              {title}
            </h1>
            <BtnCloseModalV2
              label=""
              onClick={() => closeModal(modalId)}
              customClass="bg-transparent border-none"
            />
          </div>
          <div className="modal-body">
            <iframe
              src={
                get_url_extension(file) === "pdf"
                  ? file
                  : `https://docs.google.com/gview?url=${file}&embedded=true`
              }
              width="100%"
              height={600}
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowFileModal;
