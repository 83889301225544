import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { prepareHeaders } from "../user/user.api";
import { Notification } from "./notification.type";
import { QueryUrl } from "../../Utils";

import { NotificationFormData } from "./notification.type";

export const NotificationApi = createApi({
  reducerPath: "notification",
  tagTypes: [
    "notification",
    "notificationList",
    "notificationBySlug",
    "notificationsByUser",
    "notificationsByAdmin",
    "notificationsNonLuByUser",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    notificationsByUser: builder.query<
      PaginationResults<Notification>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`user/${slug}/notifications/`, query),
      providesTags: ["notificationsByUser"],
    }),
    updateNotificationRead: builder.mutation<
      Notification,
      NotificationFormData
    >({
      query: ({ slug, ...data }) => ({
        url: `notifications/${slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [
        "notification",
        "notificationsByUser",
        "notificationsByAdmin",
        "notificationsNonLuByUser",
      ],
    }),
  }),
});

export const {
  useUpdateNotificationReadMutation,
  useNotificationsByUserQuery,
} = NotificationApi;
