import React from 'react'
import WrapperComponent from '../../../shared/WrapperComponent';
import { FormError, Input, InputQuill, animatedComponents } from '../../../common/Input';
import PhoneInput from 'react-phone-input-2';
import { SelectInput } from '../../../common/SelectInput';
import { BtnSubmit } from '../../../common/Button';
import UseCompleteAntecedentPatient from '../form/UseCompleteAntecedentPatient';
import { useRdvFromLocation } from '../../../../utils/api/rdvs/rdvs.api';
import Select from "react-select";

function AddOrEditAntecedent() {
  const [item] = useRdvFromLocation();
  const {
    register,
    errors,
    isLoading,
    onSubmit,
    handleChangePhone,
    phone,
    onChangeAllergie,
    onChangeAntecedent,
    onChangeChirurgie,
    onChangeGynecologie,
    onChangeObstetrique,
    allergie,
    antecedent,
    chirurgie,
    gynecologie,
    obstetrique,
    Controller,
    control,
    handleChangeInput,
    handleSelectSocial,
    option,
    options,
  } = UseCompleteAntecedentPatient(item);
  return (
    <WrapperComponent>
      <div className="content-chart-title mb-4">
        <div className="container-title-dash d-flex align-items-center gap-4">
          <h2 className="custom-sm-top-title mb-0">
            Mêtre à jour les antécédents médicaux
          </h2>
        </div>
      </div>

      <div className="custom-form mt-5">
        <form onSubmit={onSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label form-label-modal-custom ">
                  Contact d'urgence{" "}
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                <PhoneInput
                  defaultMask={".. ... .. .."}
                  containerClass={`mb-3 p-0 input-modal-form-add-phone-container`}
                  inputClass={`input-modal-form-add-phone praticiens`}
                  country={"sn"}
                  placeholder=""
                  inputProps={{
                    name: "telephone",
                    required: true,
                    autoFocus: true,
                  }}
                  countryCodeEditable={false}
                  enableAreaCodes={true}
                  prefix="+"
                  value={phone}
                  onChange={(phone, country: any) =>
                    handleChangePhone(phone, country)
                  }
                  inputStyle={{
                    width: "100%",
                    background: "rgba(232, 240, 253, 0.7)",
                  }}
                />
                <FormError error={errors?.contact_urgence?.message} />
              </div>
            </div>
            <div className="col-md-6">
              <Input
                type="text"
                label="Groupe sanguin"
                id="groupe_sanguin"
                placeholder="Groupe sanguin"
                required
                {...register("groupe_sanguin")}
                error={errors?.groupe_sanguin}
              />
            </div>

            <div className="col-md-6">
              <Input
                label="Assurance medicale"
                type="text"
                placeholder="Assurance medicale"
                {...register("assurance_medicale")}
                error={errors?.assurance_medicale}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="N° Assurance medicale"
                type="text"
                placeholder="N° Assurance medicale"
                {...register("numero_assurance")}
                error={errors?.numero_assurance}
              />
            </div>
            <div className="col-md-6">
              {/* <SelectInput
                label="Social"
                options={[
                  {
                    label: "Alcool",
                    value: "alcohol",
                  },
                  {
                    label: "Drogue",
                    value: "drogue",
                  },
                  {
                    label: "Fumeur",
                    value: "fumeur",
                  },
                ]}
                {...register("social")}
                error={errors?.social}
              /> */}
              <div className="mb-3">
                <label className="form-label form-label-register-tabs">
                  Social
                  
                </label>

                <Controller
                  name="social"
                  control={control}
                  render={({ field }: any) => {
                    return (
                      <Select
                        {...field}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        placeholder="Sélectionnez"
                        options={options}
                        isMulti
                        onInputChange={(val) => handleChangeInput(val)}
                        classNamePrefix="react-select-option-custom"
                        onChange={handleSelectSocial}
                        value={option}
                        // onBlur={(val) => addOptionIfNeeded(val)}
                      />
                    );
                  }}
                />
                <FormError error={errors?.social} />
              </div>
            </div>

            <div className="col-md-12 position-relative">
              <InputQuill
                label="Antécédents medicaux"
                value={antecedent}
                onChange={(val) => onChangeAntecedent(val)}
                id="antecedent_medical"
                error={errors?.antecedent_medical}
              />
            </div>
            <div className="col-md-12 position-relative">
              <InputQuill
                label="Chirurgies"
                value={chirurgie}
                onChange={(val) => onChangeChirurgie(val)}
                id="chirurgie"
                error={errors?.chirurgie}
              />
            </div>
            <div className="col-md-12 position-relative">
              <InputQuill
                label="Allergies et réactions"
                value={allergie}
                onChange={(val) => onChangeAllergie(val)}
                id="allergie"
                error={errors?.allergie}
              />
            </div>

            {item?.patient?.civilite === "femme" && (
              <>
                <div className="col-md-12 position-relative">
                  <InputQuill
                    label="Gynécologie"
                    value={gynecologie}
                    onChange={(val) => onChangeGynecologie(val)}
                    id="gynecologie"
                    error={errors?.gynecologie}
                  />
                </div>
                <div className="col-md-12 position-relative">
                  <InputQuill
                    label="Obstétrique"
                    value={obstetrique}
                    onChange={(val) => onChangeObstetrique(val)}
                    id="obstetrique"
                    error={errors?.obstetrique}
                  />
                </div>
              </>
            )}
          </div>
          <div className="d-flex flex-r mt-4">
            <div className="col-md-2">
              <BtnSubmit label="Enregistrer" isLoading={isLoading} />
            </div>
          </div>
        </form>
      </div>
    </WrapperComponent>
  );
}

export default AddOrEditAntecedent