/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from "react";
import Modal from "react-modal";
import { CloseModalBtnV2 } from "../../shared/CloseModalBtn";
import { Input, InputQuill } from "../../common/Input";
import ReactQuill from "react-quill";
import { BtnSubmit } from "../../common/Button";
import { IRdvs } from "../../../utils/api/rdvs/rdvs.type";
import UseAddNoteForm from "../../medecin/rdvMedecin/form/UseAddNoteForm";
import { Note } from "../../../utils/api/note/note.type";

const AddNoteModal = ({
  modalAddNote,
  setModalAddNote,
  rdv,
  note
}: {
  modalAddNote: any;
  setModalAddNote: any;
  rdv: IRdvs;
  note?: Note
}) => {
  const {
    register,
    errors,
    isLoading,
    onSubmit,
    resetField,
    onChange,
    contenu,
    setContenu,
  } = UseAddNoteForm(rdv, setModalAddNote,note);

  function closeModalAddNote() {
    setModalAddNote(false);
    resetField("contenu");
    resetField("titre");
    setContenu("")
  }

  return (
    <Modal
      isOpen={modalAddNote}
      onRequestClose={closeModalAddNote}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal-header pe-2 py-1">
        <h5 className="modal-title" id="AddNoteModalLabel">
          {!note ? "Ajouter" : "Modifier"} une note
        </h5>
        <CloseModalBtnV2 onClick={closeModalAddNote} />
      </div>
      <div className="container-scroll-modal">
        <div className="container-form pt-4">
          <form onSubmit={onSubmit}>
            <div className="row add-Note-row">
              <div className="col-md-12">
                <Input
                  label="Titre"
                  type="text"
                  required
                  {...register("titre")}
                  error={errors?.titre}
                />
              </div>
              <div className="col-md-12 position-relative">
                <InputQuill
                  label="Contenu de la note"
                  value={contenu}
                  onChange={onChange}
                  id="note"
                  required
                  error={errors?.contenu}
                />
              </div>
            </div>
            <div className="d-flex flex-r mt-4">
              <div className="col-md-4">
                <BtnSubmit label="Enregistrer" isLoading={isLoading} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default AddNoteModal;

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    zIndex: 99999,
  },
};
