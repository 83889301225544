import React from "react";
import WrapperComponent from "../../../shared/WrapperComponent";
import { FormError, Input } from "../../../common/Input";
import { BtnAnnuler, BtnSubmit } from "../../../common/Button";
import UseAddScannerForm from "../../rdvMedecin/form/UseAddScannerForm";
import { useRdvFromLocation } from "../../../../utils/api/rdvs/rdvs.api";
import { useNavigate } from "react-router-dom";

function AddNewScanner() {
  const [rdv] = useRdvFromLocation();
  const { register, errors, onSubmit, isLoading } = UseAddScannerForm(rdv);
  const navigate = useNavigate();
  return (
    <WrapperComponent>
      <div className="content-chart-title mb-4">
        <div className="container-title-dash d-flex align-items-center gap-4">
          <h2 className="custom-sm-top-title mb-0">Ajouter une analyse</h2>
        </div>
      </div>

      <div className="custom-form mt-5">
        <form onSubmit={onSubmit}>
          <div className="row">
            <div className="col-md-6">
              <Input
                label="Type de scanner"
                type="text"
                placeholder="Type de scanner"
                required
                {...register("type_scanner")}
                error={errors?.type_scanner}
              />
            </div>
            <div className="col-md-6">
              <label
                htmlFor="description"
                className="form-label form-label-modal-custom"
                aria-labelledby="description"
              >
                Raison de la demande
                <span className="text-danger" style={{ fontSize: 15 }}>
                  *
                </span>
              </label>
              <textarea
                id=""
                rows={4}
                className="form-control form-control-modal-custom"
                {...register("raisons")}
              />
              <FormError error={errors?.raisons} />
            </div>
          </div>
          <div className="d-flex flex-r mt-4">
            <div className="col-md-2">
              <BtnAnnuler
                label="Annuler"
                customClass="custom-btn-annuler"
                onClick={() =>
                  navigate(`/medecin/rendez-vous/${rdv?.slug}?step=resultat`, {
                    state: rdv,
                  })
                }
              />
            </div>
            <div className="col-md-2">
              <BtnSubmit label="Ajouter" isLoading={isLoading} />
            </div>
          </div>
        </form>
      </div>
    </WrapperComponent>
  );
}

export default AddNewScanner;
