import React, { useEffect, useState } from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import { MdOutlineAddBox } from "react-icons/md";
import SpecialiteTable from "./table/SpecialiteTable";
import AddOrEditSpecialiteModal from "./modal/AddOrEditSpecialiteModal";
import { showModal, useUser } from "../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import { hasAccess } from "../../../routes/routerUtils";

function SpecialitesAdmin() {
  const [query, setQuery] = useState("");
  const user = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && hasAccess(user, "specialite_habilitation")) {
      navigate("/admin/parametres");
    }
  }, [user]);
  return (
    <WrapperComponent>
      <div className="container-title-dash d-flex align-items-center gap-4">
        <div className="custom-sm-top">
          <h2 className="custom-sm-top-title mb-0">Gestion des spécialités</h2>
          <button
            className="btn custom-add-item-btn"
            onClick={() => showModal("addSpecialiteModal")}
          >
            <span className="add-icon">
              <MdOutlineAddBox />
            </span>
            Ajouter une spécialité
          </button>
        </div>
      </div>
      <div className="search-patient-box d-flex align-items-center gap-3 mt-4">
        <input
          type="search"
          name="search_patient"
          id="search_patient"
          placeholder="Entrer un Nom"
          onChange={(e) => setQuery(e?.target?.value)}
        />
        {/* <button className="btn search-submit-patient-btn">
            <span>
              <MdSearch />
            </span>
            Chercher
          </button> */}
      </div>

      <div className="custom-table mt-5">
        <SpecialiteTable q={query} />
        <AddOrEditSpecialiteModal modalId="addSpecialiteModal" />
      </div>
    </WrapperComponent>
  );
}

export default SpecialitesAdmin;
