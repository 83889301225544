import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { IUser, UserFormData } from "../../../../utils/api/user/user.type";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import {
  useAddOrEditUserMutation,
  useArchiveUserMutation,
  useUnArchiveUserMutation,
} from "../../../../utils/api/user/user.api";
import { onSetUser } from "../../../../redux/slice/User.slice";
import { Color } from "../../../../utils/theme";
import { cleannerError, validatePhone } from "../../../../utils/Utils";
import { Data } from "../../../common/GoogleInput";
import { isCabinet } from "../../../../routes/routerUtils";
import {
  useAddOrUpdateSpecialiteMutation,
  useGetSpecialiteQuery,
} from "../../../../utils/api/specialite/specialite.api";

function UseCrudProfilForm() {
  const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string>();
  const validationSchema = yup.object().shape({
    isCabinet: yup.boolean(),
    nom: yup.string().required().label("Le nom"),
    prenom: yup.string().required().label("Le prénom"),
    telephone: validatePhone(yup, code).required(),
    email: yup.string().email().required().label("L'email"),
    adresse: yup.string().required().label("L'adresse").nullable(),
    nom_cabinet: yup.string().when("iscabinet", {
      is: true,
      then: yup.string().required().label("Le nom de la structure").nullable(),
    }),
    adresse_cabinet: yup
      .string()
      .when("iscabinet", {
        is: true,
        then: yup
          .string()
          .required()
          .label("L'adresse de la structure")
          .nullable(),
      })
      .nullable(),
  });
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.user);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
  } = useForm<UserFormData>({
    resolver: yupResolver(validationSchema),
  });
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState<{ label: string; value: number }[]>(
    []
  );
  const [option, setOption] = useState<
    { label: string; value: number } | undefined
  >();
  const { data: specialites = { results: [] } } = useGetSpecialiteQuery({
    q: search,
    limit: 50,
  });
  const [addOrUpdateUser, { isLoading, isSuccess, error, isError, data }] =
    useAddOrEditUserMutation();
  const [addData, { isSuccess: isDone, data: dataAdded }] =
    useAddOrUpdateSpecialiteMutation();
  const [avatar, setAvatar] = useState(null);
  const [address, setAddress] = useState("");
  const [addressCabinet, setAddressCabinet] = useState("");

  useEffect(() => {
    if (specialites?.results?.length) {
      setOptions(
        specialites?.results?.map((el) => {
          return {
            label: el?.nom,
            value: el?.id,
          };
        })
      );
    }
  }, [specialites]);

  useEffect(() => {
    if (isDone) {
      if (dataAdded) {
        setOption({ label: dataAdded?.nom, value: dataAdded?.id });
        setValue("specialite", dataAdded?.id);
      }
    }
  }, [isDone]);

  useEffect(() => {
    setValue("isCabinet", isCabinet(user));
  }, [user]);

  const addOptionIfNeeded = (valueToAdd) => {
    setSearch(valueToAdd);
    const optionExists = options?.some((opt) =>
      opt?.label?.toLocaleLowerCase()?.includes(valueToAdd?.toLocaleLowerCase())
    );

    if (!optionExists) {
      setOptions((prevOptions) => [
        ...prevOptions,
        { label: valueToAdd, value: valueToAdd },
      ]);
    }
  };

  const handleChangeAvatar = (e) => {
    let files = e.target.files[0];
    setValue("avatar", files);
    setAvatar(files);
  };

  const onChangeAddress = (val: Data) => {
    setAddress(val?.rue);
    setValue(`adresse`, val?.rue);
    setValue(`latitude`, parseFloat(val?.latitude).toFixed(6));
    setValue(`longitude`, parseFloat(val?.longitude).toFixed(6));
  };

  const onChangeAddressCabinet = (val: Data) => {
    setAddressCabinet(val?.rue);
    setValue(`adresse_cabinet`, val?.rue);
    setValue(`latitude_cabinet`, parseFloat(val?.latitude).toFixed(6));
    setValue(`longitude_cabinet`, parseFloat(val?.longitude).toFixed(6));
    if (val?.ville) {
      setValue("ville", val?.ville);
    }
  };

  const handleSelectSpecialite = (val) => {
    setOption(val);
    // if (val?.value?.toLowerCase() === search?.toLocaleLowerCase()) {
    //   addData({ data: { nom: search } });
    // } else {
    //   setValue(`specialite`, val?.value);
    // }
    if (typeof val?.value !== "number") {
      if (val?.value?.toLowerCase() === search?.toLocaleLowerCase()) {
        addData({ data: { nom: search } });
      }
    } else {
      setValue(`specialite`, val?.value);
    }
  };

  const handleChangePhone = (item: any, country: any) => {
    setPhone(item);
    setCode(country?.countryCode);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue("telephone", item);
    } else {
      setValue("telephone", "");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      // if (data?.id) {
      //   if (user?.id === data?.id) {
      //     dispatch(onSetUser(data));
      //   }
      // }

      Swal.fire({
        icon: "success",
        title: !user
          ? "Admin ajouté avec succès"
          : "Votre profil a été modifié avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        // navigate("/admin/mon-profil");
      });
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("user with this email already exists.")) {
        Swal.fire({
          icon: "error",
          title: "Un utilisateur avec cet email exite déjà!",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Erreur de statut ${err?.status}`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (user?.id) {
      // console.log("user", user);
      let fields: (keyof UserFormData)[] = [
        "nom",
        "prenom",
        "telephone",
        "email",
        "adresse",
        "adresse_cabinet",
        "nom_cabinet",
        "date_de_naissance",
        "boite_postal",
        "specialite",
      ];

      if (user?.telephone) {
        setPhone(user.telephone);
      }

      if (user?.adresse_cabinet) {
        setAddressCabinet(user.adresse_cabinet);
      }

      if (user?.adresse) {
        setAddress(user.adresse);
      }
      if (user?.specialite?.id) {
        setOption({
          label: user?.specialite?.nom,
          value: user?.specialite?.id,
        });
      }
      for (let field of fields) {
        register(field);
        if (field === "date_de_naissance") {
          if (user[field]) {
            setValue(field, user[field]);
          }
        } else if (field === "specialite") {
          if (user?.specialite) {
            setValue(field, user[field]?.id);
          }
        } else {
          setValue(field, user[field]);
        }
      }
    }
  }, [user]);

  const onSubmit = async (data: UserFormData) => {
    // !user && (data["user_type"] = "admin");
    
    const fd = new FormData();

    for (let key of Object.keys(data)) {
      if (key === "avatar") {
        if (data?.avatar) {
          fd.append(key, data?.avatar);
        }
      } else if (key === "date_de_naissance") {
        if (data?.date_de_naissance) {
          fd.append(key, data?.date_de_naissance);
        }
      } else {
        fd.append(key, data[key]);
      }
    }
    await addOrUpdateUser({
      slug: user?.slug,
      data: fd,
    });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChangeAvatar,
    handleChangePhone,
    onChangeAddress,
    Controller,
    control,
    phone,
    address,
    avatar,
    onChangeAddressCabinet,
    addressCabinet,
    isSuccess,
    addOptionIfNeeded,
    options,
    option,
    handleSelectSpecialite,
  };
}

export default UseCrudProfilForm;

export function useToggleArchiveAdmin(user: IUser) {
  const [editData] = user?.is_archive
    ? useUnArchiveUserMutation()
    : useArchiveUserMutation();
  const onArchive = async () => {
    let data: UserFormData = {
      is_archive: !user.is_archive,
    };
    await Swal.fire({
      title: `Êtes-vous sure de vouloir  ${
        user?.is_archive === true ? "désarchiver" : "archiver"
      } cet admin ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.success,
      preConfirm: () => {
        return editData(user.slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      // console.log(result, "archiver");
      if (result?.value?.data) {
        Swal.fire({
          icon: "success",
          title: `Admin ${
            result?.value?.data?.is_archive ? "archivé " : "désarchivé"
          } avec succèss!`,
          iconColor: Color.success,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onArchive;
}
