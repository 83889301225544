/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Medicament, MedicamentFormData, Ordonnance, OrdonnanceFormData } from "./ordonnance.type";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const OrdonnanceApi = createApi({
  reducerPath: "ordonnanceApi",
  tagTypes: ["ordonnanceList", "ordonnanceBySlug", "ordonnanceByPatient"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListOrdonnancesAdmin: builder.query<
      PaginationResults<Ordonnance>,
      TypeQuery
    >({
      providesTags: ["ordonnanceList"],
      query: (query) => QueryUrl("ordonnances/", query),
    }),
    getOrdonnancesByPatient: builder.query<
      PaginationResults<Ordonnance>,
      TypeQuery
    >({
      providesTags: ["ordonnanceList"],
      query: ({ slug, ...query }) =>
        QueryUrl(`patient/${slug}/ordonnances/`, query),
    }),
    addOrEditOrdonnance: builder.mutation<Ordonnance, OrdonnanceFormData>({
      query: ({ slug, ...data }) => ({
        url: slug ? `ordonnances/${slug}/` : `ordonnances/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: [
        "ordonnanceList",
        "ordonnanceByPatient",
        "ordonnanceBySlug",
      ],
    }),
    addOrEditMedicament: builder.mutation<Medicament, MedicamentFormData>({
      query: ({ slug, ...data }) => ({
        url: slug ? `ordonnance_items/${slug}/` : `ordonnance_items/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: [
        "ordonnanceList",
        "ordonnanceByPatient",
        "ordonnanceBySlug",
      ],
    }),
    deleteOrdonnance: builder.mutation<Ordonnance, string>({
      query: (slug) => ({
        url: `ordonnances/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["ordonnanceList"],
    }),
    ordonnanceBySlug: builder.query<Ordonnance | any, string>({
      query: (slug) => `ordonnances/${slug}/`,
      providesTags: ["ordonnanceBySlug"],
    }),
  }),
});

export const {
  useAddOrEditOrdonnanceMutation,
  useGetListOrdonnancesAdminQuery,
  useLazyOrdonnanceBySlugQuery,
  useDeleteOrdonnanceMutation,
  useGetOrdonnancesByPatientQuery,
  useAddOrEditMedicamentMutation
} = OrdonnanceApi;

export function useOrdonnanceFromLocation(): [
  Ordonnance,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<Ordonnance | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading }] =
    useLazyOrdonnanceBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findBySlug(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      
      setItem(data);
    }
  }, [data]);

  return [item as Ordonnance, isLoading, slug as string, findBySlug];
}