import React, { useEffect, useState } from "react";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../common/TableSkeleton";
import "./Table.css";
import { useNavigate } from "react-router-dom";
import { useGetOrdonnancesByPatientQuery } from "../../../utils/api/ordonnance/ordonnance.api";
import { formatDate, getName } from "../../../utils/Utils";
import {
  Medicament,
  Ordonnance,
} from "../../../utils/api/ordonnance/ordonnance.type";
import { IUser } from "../../../utils/api/user/user.type";
import { CustomPagination } from "../../common/CustomPagination";
import { IRdvs } from "../../../utils/api/rdvs/rdvs.type";

function AllDetailPatientMedicamentTable({ item,rdv }: {item:IUser;rdv?:IRdvs}) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data = { results: [], count: 0 }, isLoading,refetch } =
    useGetOrdonnancesByPatientQuery({
      slug: item?.slug,
      page,
      limit,
    });
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) refetch();
  }, []);

 const titleFormatter = (cell: Medicament[], row: Ordonnance) => {
   return (
     <div>
       <span>
         {" "}
         {cell[0]?.nom} <b>{row?.is_updated === true ? `(addendum)` : ""}</b>
       </span>
     </div>
   );
 };

  const columns = [
    {
      dataField: "created_at",
      text: `Date`,
      formatter: (cell: string) => formatDate(cell),
    },
    {
      dataField: "items",
      text: "Medicament",
      formatter: titleFormatter,
    },
    {
      dataField: "rdv.medecin",
      text: "Médecin",
      formatter: (cell: IUser) => "Dr " + getName(cell),
    },
  ];
  const rowEvents = {
    onClick: (e: any, row: Ordonnance) => {
      if (rdv) {
         navigate(`/medecin/ordonnance/${row.slug}/rdv?slug=${rdv?.slug}`, { state: row });
      } else {
         navigate(`/medecin/ordonnance/${row.slug}/patient`, { state: row });
      }
     
    },
  };
  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            rowEvents={rowEvents}
            condensed
            hover
            wrapperClasses="table-responsive medecin-patients-detail-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default AllDetailPatientMedicamentTable;
