import React from "react";
import UseCrudProfilForm from "../../Profil/request/UseCrudProfil";
import { FormError, Input } from "../../../common/Input";
import PhoneInput from "react-phone-input-2";
import GoogleInput from "../../../common/GoogleInput";
import { BtnSubmit } from "../../../common/Button";
import AvatarComponent from "../../Profil/AvatarComponent";

// export const Input = ({
//   label,
//   type = "text",
//   placeholder,
//   onClick,
// }: {
//   label: string;
//   type?: string;
//   placeholder?: string;
//   onClick?: any;
// }) => {
//   return (
//     <>
//       <div className="label-and-input-content mb-3">
//         <label htmlFor="name">{label}</label>
//         <div className="custom-sm-input">
//           <input type={type} placeholder={placeholder} />
//           <span className="edit-btn" onClick={onClick}>
//             <FiEdit />
//           </span>
//         </div>
//       </div>
//     </>
//   );
// };

function MonCompte() {
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    phone,
    handleChangePhone,
    onChangeAddress,
    address,
  } = UseCrudProfilForm();
  return (
    <>
      {/* <div className="admin-cover-container text-center pt-3 pb-3">
        <img src={photo} alt="cover" srcSet="" loading="lazy" />
        
      </div> */}
      <AvatarComponent />
      <div className="custom-form-container">
        <div className="custom-title">Informations personnelles</div>
        <form onSubmit={onSubmit}>
          <div className="row">
            <>
              <div className="col-md-6 position-relative">
                <Input
                  type="text"
                  label="Prénom"
                  id="prenom"
                  {...register("prenom")}
                  error={errors?.prenom}
                />
              </div>
              <div className="col-md-6 position-relative">
                <Input
                  type="text"
                  label="Nom"
                  id="nom"
                  {...register("nom")}
                  error={errors?.nom}
                />
              </div>
              <div className="col-md-6 position-relative">
                <div className="mb-3">
                  <label className="form-label form-label-modal-custom ">
                    Téléphone{" "}
                  </label>
                  <PhoneInput
                    defaultMask={".. ... .. .."}
                    containerClass={`mb-3 p-0 input-modal-form-add-phone-container`}
                    inputClass={`input-modal-form-add-phone`}
                    country={"sn"}
                    placeholder=""
                    inputProps={{
                      name: "telephone",
                      required: true,
                      autoFocus: true,
                    }}
                    countryCodeEditable={false}
                    enableAreaCodes={true}
                    prefix="+"
                    value={phone}
                    onChange={(phone, country: any) =>
                      handleChangePhone(phone, country)
                    }
                    inputStyle={{
                      width: "100%",
                      background: "rgba(232, 240, 253, 0.7)",
                    }}
                  />
                  <FormError error={errors?.telephone?.message} />
                </div>
              </div>
              <div className="col-md-6 position-relative">
                <Input
                  type="email"
                  label="Email"
                  id="email"
                  {...register("email")}
                  error={errors?.email}
                />
              </div>
              <div className="col-md-6 position-relative">
                <div className="mb-3">
                  <label className="form-label form-label-modal-custom">
                    Adresse{" "}
                  </label>
                  <GoogleInput
                    className="form-control form-control-modal-custom"
                    value={address}
                    onChange={(val) => onChangeAddress(val)}
                  />
                  <FormError error={errors?.adresse?.message} />
                </div>
              </div>
            </>

            <div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-5">
              <BtnSubmit label="Mettre à jour" isLoading={isLoading} />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default MonCompte;
