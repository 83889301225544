import React, { useEffect, useState } from "react";
import {
  getChatPageRoute,
  useLocationState,
  useUser,
} from "../../../utils/Utils";
import { Conversation } from "../../../utils/api/chat/chat.type";
import { useNavigate } from "react-router-dom";
import { useGetChatItemQuery, useMajMessageMutation } from "../../../utils/api/chat/chat.api";
import { IUser } from "../../../utils/api/user/user.type";
import DetailsMessagerieAdmin from "./DetailsMessagerieAdmin";
import { MdArrowBackIosNew } from "react-icons/md";
import socket from "../../../utils/socket-config";
import UseDeviceDetect from "../../../utils/detectSize";
import { Navigate } from "react-router-dom";

function MessageItemMobile() {
  let isMobile = UseDeviceDetect();
  const navigate = useNavigate();
  const sUser = useLocationState<IUser>(null);
  const [selectedChat, setSelectedChat] = useState<Conversation | null>(null);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const user = useUser();
  const { data = [], refetch, isLoading } = useGetChatItemQuery(user?.slug);
  const [updateChat] = useMajMessageMutation();

  useEffect(() => {
    if (!isLoading) refetch();
  }, []);

  useEffect(() => {
    socket.on("messageResponse", (data) => {
      if ([data?.sender, data?.receiver].includes(user?.id)) {
        refetch();
      }
    });
    return () => {
      socket.off("messageResponse");
    };
  }, [socket]);

  useEffect(() => {
    if (!selectedUser) {
      if (sUser && user?.id !== sUser?.id) {
        setSelectedUser(sUser);
      } else {
        // if (data.length > 0) {
        //   let item = [...data]?.sort(
        //     (a, b) => b?.last_message?.id - a?.last_message?.id
        //   )[0];
        //   if (user?.id !== item?.last_message?.receiver?.id) {
        //     setSelectedUser(item?.last_message?.receiver);
        //   } else {
        //     setSelectedUser(item?.last_message?.sender);
        //   }
        // }
      }
    }
  }, [sUser, data, selectedUser, user]);

  useEffect(() => {
    if (selectedUser && data?.length) {
      setSelectedChat(
        data.find(
          (l) =>
            l?.last_message?.receiver?.id === selectedUser?.id ||
            l?.last_message?.sender?.id === selectedUser?.id
        ) || null
      );
    } else {
      setSelectedChat(null);
    }
  }, [selectedUser, data]);

  useEffect(() => {
    if (selectedChat) {
      setTimeout(() => {
        const promises = selectedChat?.messages
          .filter((chat) => {
            return !chat?.read && chat?.sender?.id !== user?.id;
          })
          .map((chat) =>
            updateChat({ slug: chat?.slug, data: { read: true } })
          );
        Promise.all(promises).then((res) =>
          console.log("marked as read", res?.length)
        );
      }, 2000);
    }
  }, [selectedChat, user]);

  if (!isMobile) return <Navigate to={getChatPageRoute(user)} />;
  return (
    // <div className="mes-messages-tabs-component">
    //   <div className="message-tabs-container mt-5">
    //     <div className="dtails-messages-tabs-component">
    //       <div className="row border-bottom p-3 align-items-center">
    //         <div className="col-xl-1 col-lg-2 col-sm-1 col-md-3">
    //           <img
    //             src={getAvatar(selectedUser?.avatar)}
    //             className="image-profil-entete pe-0"
    //             alt="Image prfil detail message"
    //           />
    //         </div>
    //         <div className="col-xl-10 col-lg-8 col-md-9 col-sm-10 ps-0">
    //           <div className="d-flex align-items-center">
    //             <p className="profil-detail-message" style={{ fontSize: 15 }}>
    //               {selectedUser?.prenom} {selectedUser?.nom}
    //             </p>
    //             <div className="statut-expediteur ms-3"></div>
    //           </div>
    //         </div>
    //         <div className="col-xl-1 col-lg-2 col-md-2 col-sm-1 pe-1" />
    //       </div>
    //       {/* <div className="bloc-details pb-5">
    //         {chat?.messages?.map((message) => {
    //           if (message.sender.id === user.id) {
    //             return <Message item={message} key={message.id} />;
    //           }
    //           return <Response item={message} key={message.id} />;
    //         })}
    //       </div>
    //       <ChatInput userId={user?.id} /> */}
    //     </div>
    //   </div>
    // </div>
    <div className="content-graph-admin px-1">
      <div className="admin-page-title-header-container">
        <div className="container-title-dash d-flex align-items-center gap-4">
          <div className="custom-sm-top d-flex align-items-center gap-2 justify-content-start">
            <div className="no-view-desktop">
              <MdArrowBackIosNew onClick={() => navigate(-1)} />
            </div>
            <h2 className="custom-sm-top-title mb-0">Messages</h2>
          </div>
        </div>
        <div className="admin-tabs-container">
          <div className="tabs-component-container-admin">
            <div className="users-network-connect-list mt-2">
              <div className="no-view-desktop">
                <div className="row">
                  <div className="col-md-12">
                    <DetailsMessagerieAdmin
                      user={selectedUser}
                      messages={selectedChat?.messages || []}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="NewDiscussionModale"
          aria-labelledby="NewDiscussionModaleLabel"
          aria-hidden="true"
        >
          {/* <NewDiscussionModale onSelect={setSelectedUser} /> */}
        </div>
      </div>
    </div>
  );
}

export default MessageItemMobile;
