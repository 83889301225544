import React, { useEffect } from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import { Input } from "../../common/Input";
import cover from "../../../assets/appImages/medecin_image.png";
import { useAdminFromLocation } from "../../../utils/api/user/user.api";
import { getAvatar, getName } from "../../../utils/Utils";
import { Authorisations } from "../../../utils/constante";


const PermissionSmallCard = ({
  text,
  checked,
  i,
}: {
  text: string;
  checked: boolean;
  i: number;
}) => {
  return (
    <div className="permission-small-card d-flex align-items-center gap-3">
      <input
        type="checkbox"
        readOnly
        className="permission-input"
        checked={checked}
        name="permission"
        id={`permission-${i}`}
      />
      <label htmlFor={`permission-${i}`}>{text}</label>
    </div>
  );
};

function DetailsAdmin() {
  const [item] = useAdminFromLocation();
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <WrapperComponent>
            <div className="container-title-dash pb-3">
              <h2 className="custom-title mb-0">Détails de l’administrateur</h2>
            </div>
            <div className="admin-cover-and-name-container d-flex flex-column align-items-center gap-3 pb-3 pt-3">
              <img src={getAvatar(item?.avatar)} alt="photo" srcSet="" />
              <span className="">{getName(item)}</span>
            </div>
          </WrapperComponent>
        </div>

        <div className="col-md-12">
          <WrapperComponent>
            <div className="container-title-dash">
              <h2 className="custom-title mb-0">Informations personnelles</h2>
            </div>
            <div className="custom-form-container">
              <div>
                <div className="c_grid">
                  <Input
                    label="Prénom"
                    type="text"
                    placeholder=""
                    value={item?.prenom}
                    readOnly
                  />
                  <Input
                    label="Nom"
                    type="text"
                    placeholder=""
                    value={item?.nom}
                    readOnly
                  />
                  <Input
                    label="Adresse mail"
                    type="email"
                    placeholder=""
                    value={item?.email}
                    readOnly
                  />
                  <Input
                    label="Numéro de téléphone"
                    type="tel"
                    placeholder=""
                    value={item?.telephone}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </WrapperComponent>
        </div>

        <div className="col-md-12">
          <WrapperComponent>
            <div className="container-title-dash">
              <h2 className="custom-title mb-0">Permissions</h2>
            </div>
            <div className="all-permissions-content pt-4">
              {Authorisations.map((perm, key) => {
                return (
                  <PermissionSmallCard
                    text={perm.label}
                    checked={item[perm.field]}
                    key={key}
                    i={key}
                  />
                );
              })}
            </div>
          </WrapperComponent>
        </div>
      </div>
    </>
  );
}

export default DetailsAdmin;
