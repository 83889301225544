import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { ChatTypeFormData, Conversation, Message } from "./chat.type";
import { IUser, PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";

export const ChatApi = createApi({
  tagTypes: ["messages", "discussions", "userList"],
  reducerPath: "ChatApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    majMessage: builder.mutation<
      Message,
      { slug?: string; data: ChatTypeFormData | FormData }
    >({
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `messages/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `messages/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (a, n, { slug }) => ["messages", "discussions"],
    }),

    getChatItem: builder.query<Conversation[], string>({
      query: (slug) => `mobile/user/${slug}/conversations/`,
      providesTags: ["discussions"],
      //   transformResponse: transformChatToChatData,
    }),

    getMessageItem: builder.query<Message[], string>({
      query: (slug) => `conversation/${slug}/messages/`,
      providesTags: (a, b, slug) => [{ id: slug, type: "messages" }],
    }),

    deleteConversation: builder.mutation<Conversation, string>({
      query: (slug) => ({
        url: `/conversations/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["discussions"],
    }),
    getDestinataireByUser: builder.query<PaginationResults<IUser>, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`user/${slug}/destinataires/`, query),
      providesTags: ["userList"],
    }),
  }),
});

export const {
  useGetChatItemQuery,
  useDeleteConversationMutation,
  useGetMessageItemQuery,
  useMajMessageMutation,
  useGetDestinataireByUserQuery,
  useLazyGetMessageItemQuery,
  useLazyGetChatItemQuery
} = ChatApi;

