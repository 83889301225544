import React, { useEffect } from "react";
import { MdOutlineCancel } from "react-icons/md";
import WrapperComponent from "../../../shared/WrapperComponent";
import OrdonnanceItemsTable from "../../Tables/OrdonnanceItemsTable";
import { useOrdonnanceFromLocation } from "../../../../utils/api/ordonnance/ordonnance.api";
import {
  fileName,
  formatDate,
  getName,
  showModal,
  useUser,
} from "../../../../utils/Utils";
import ShowFileModal from "../../../shared/modal/ShowFileModal";
import { ApiBaseUrl } from "../../../../utils/http";
import { isMedecin } from "../../../../routes/routerUtils";
import { UseDeleteOrdonnace } from "../../rdvMedecin/form/UseAddMedicamentForm";
import { BreadCrumbV2 } from "../../../shared/BreadCrumb";
import { useParams, useSearchParams } from "react-router-dom";

function DetailOrdonnance() {
  const [item] = useOrdonnanceFromLocation();
  const {type} = useParams()
  const [searchParams] = useSearchParams();
  let slug = searchParams.get("slug") || "";
  const user = useUser();
  const onDelete = UseDeleteOrdonnace(
    item?.slug,
    type === "rdv"
      ? `/medecin/rendez-vous/${slug || item?.rdv?.slug}?step=medicament`
      : `/medecin/patients/${item?.rdv?.patient?.slug}?step=medicament`
  );

  useEffect(() => {
    console.log(type)
  }, [type])
  return (
    <>
      <WrapperComponent>
        <div className="container-title-dash d-flex align-items-center gap-4 pb-4">
          {/* <h2 className="custom-sm-top-title mb-0">
            Médicaments {" > "}
            <strong> Détails d’une ordonnance</strong>
          </h2> */}
          <BreadCrumbV2
            principalPage="Médicaments"
            linkPrincipal={
              isMedecin(user)
                ? type === "rdv"
                  ? `/medecin/rendez-vous/${
                      slug || item?.rdv?.slug
                    }?step=medicament`
                  : `/medecin/patients/${item?.rdv?.patient?.slug}?step=medicament`
                : `/patient/ordonnance`
            }
            secondPage="Détails d’une ordonnance"
            principalCustomClass="custom-sm-top-title"
            secondCustomClass="custom-sm-top-subtitle"
            state={
              isMedecin(user)
                ? type === "rdv"
                  ? item?.rdv
                  : item?.rdv?.patient
                : undefined
            }
          />
        </div>

        <div className="custom-content pt-4 pb-4">
          <div className="light-card">
            {!!isMedecin(user) && type === "rdv" && (
              <div className="custom-card-header flex-r">
                <button
                  className="delete-ordonnance-btn d-flex align-items-center gap-2"
                  onClick={() => onDelete()}
                  type="button"
                >
                  <MdOutlineCancel />
                  Supprimer
                </button>
              </div>
            )}

            <div className="custom-card-content">
              <div className="rdv-location pb-3">
                <span className="cs-title">Nom du patient:</span>{" "}
                <span className="cs-value">{getName(item?.rdv?.patient)}</span>
              </div>
              <div className="person-concerned pb-3">
                <span className="cs-title">Praticien:</span>{" "}
                <span className="cs-value">
                  Dr. {getName(item?.rdv?.medecin)}
                </span>
              </div>
              <div className="date-and-hour pb-3">
                <span className="cs-title">Date de prescription:</span>{" "}
                <span className="cs-value">{formatDate(item?.created_at)}</span>
              </div>
              <div className="date-and-hour pb-3">
                <span className="cs-title">Motif:</span>{" "}
                <span className="cs-value">{item?.rdv?.motif}</span>
              </div>
              <div className="date-and-hour">
                <span className="cs-title">Fichier:</span>{" "}
                <span
                  className="cs-value file-name-colum"
                  onClick={() => showModal(`showFile${item?.slug}`)}
                >
                  {fileName(item?.ordonnance)}
                </span>
              </div>
            </div>
          </div>

          <div className="light-card mt-4">
            <OrdonnanceItemsTable items={item?.items} type={type} />
          </div>
        </div>
        <ShowFileModal
          file={ApiBaseUrl + item?.ordonnance}
          modalId={`showFile${item?.slug}`}
        />
      </WrapperComponent>
    </>
  );
}

export default DetailOrdonnance;
