import React from "react";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleannerError, validatePhone } from "../../../../utils/Utils";
import { IUser, UserFormData } from "../../../../utils/api/user/user.type";
import { Data } from "../../../common/GoogleInput";
import { Color } from "../../../../utils/theme";
import { useAddOrEditCabinetMutation } from "../../../../utils/api/user/user.api";

function UseAddOrEditCabinetForm(cabinet?: IUser) {
  const [address, setAddress] = useState("");
  const [addressCabinet, setAddressCabinet] = useState("");
  const [phone, setPhone] = useState<any>();
  const [code, setCode] = useState("");
  const validationSchema = yup.object().shape({
    nom: yup.string().required().label("Le nom"),
    prenom: yup.string().required().label("Le prénom"),
    telephone: validatePhone(yup, code).required(),
    email: yup.string().email().required().label("L'email"),
    // adresse: yup.string().required().label("L'adresse"),
    nom_cabinet: yup.string().required().label("Le nom de la structure"),
    adresse_cabinet: yup.string().required().label("L'adresse de la structure"),
    ville: yup.string().required().label("La ville ou région"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
  } = useForm<UserFormData>({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const [sendData, { isLoading, isSuccess, isError, error }] =
    useAddOrEditCabinetMutation();

  const onChangeAddress = (val: Data) => {
    setAddress(val?.rue);
    setValue(`adresse`, val?.rue);
    setValue(`latitude`, parseFloat(val?.latitude).toFixed(6));
    setValue(`longitude`, parseFloat(val?.longitude).toFixed(6));
  };

  const onChangeAddressCabinet = (val: Data) => {
    setAddressCabinet(val?.rue);
    setValue(`adresse_cabinet`, val?.rue);
    setValue(`latitude_cabinet`, parseFloat(val?.latitude).toFixed(6));
    setValue(`longitude_cabinet`, parseFloat(val?.longitude).toFixed(6));
    if (val?.ville) {
      setValue("ville", val?.ville);
    }
  };

  const handleChangePhone = (item: any, country: any) => {
    setPhone(item);
    setCode(country?.countryCode);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue("telephone", item);
    } else {
      setValue("telephone", "");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: !cabinet
          ? "Cabinet ajouté avec succès"
          : "Le cabinet a été modifié avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        navigate("/admin/cabinets");
      });
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("user with this email already exists.")) {
        Swal.fire({
          icon: "error",
          title: "Un utilisateur avec cet email exite déjà!",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Erreur de statut ${err?.status}`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    // console.log("cabinet", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (cabinet?.id) {
      // console.log("cabinet", cabinet);
      let fields: (keyof UserFormData)[] = [
        "nom",
        "prenom",
        "telephone",
        "email",
        "adresse",
        "adresse_cabinet",
        "ville",
        "nom_cabinet",
        "boite_postal"
      ];

      if (cabinet?.telephone) {
        setPhone(cabinet.telephone);
      }

      if (cabinet?.adresse) {
        setAddress(cabinet.adresse);
      }

      if (cabinet?.adresse_cabinet) {
        setAddressCabinet(cabinet.adresse_cabinet);
      }

      for (let field of fields) {
        register(field);
        setValue(field, cabinet[field]);
      }
    }
  }, [cabinet]);

  const onSubmit = (data: UserFormData) => {
    // !user && (data["user_type"] = "admin");
    
    sendData({
      slug: cabinet?.slug,
      data: data,
    });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    onChangeAddressCabinet,
    handleChangePhone,
    onChangeAddress,
    Controller,
    control,
    phone,
    address,
    addressCabinet,
  };
}

export default UseAddOrEditCabinetForm;

export function UseArchiveCabinet(item: IUser) {
  const [sendData] = useAddOrEditCabinetMutation();
  const onArchive = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir ${
        item?.is_archive ? "désarchiver" : "archiver"
      } ce cabinet ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData({
          slug: item?.slug,
          data: { is_archive: !item?.is_archive },
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le cabinet a été ${
              item?.is_archive ? "désarchivé" : "archivé"
            } avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        }else {
        let err = result?.value?.error;
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Une erreur de statut ${err?.status} est survenue!`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
      } 
    });
  };
  return onArchive;
}


export function UseActiveCabinet(item: IUser) {
  const [sendData] = useAddOrEditCabinetMutation();
  const onActive = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir ${
        item?.is_active ? "désactiver" : "activer"
      } ce cabinet ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData({
          slug: item?.slug,
          data: { is_active: !item?.is_active },
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le cabinet a été ${
              item?.is_active ? "désactivé" : "activé"
            } avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      } 
    });
  };
  return onActive;
}