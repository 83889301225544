import React from "react";
import { FaFileMedicalAlt } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { IUser } from "../../../../../utils/api/user/user.type";
import { IRdvs } from "../../../../../utils/api/rdvs/rdvs.type";
import { createMarkup, formatDate, getName } from "../../../../../utils/Utils";

function Historique({ item, rdv }: { item: IUser; rdv?: IRdvs }) {
  return (
    <div className="historique-container">
      <div className="folder-tab-top-head d-flex justify-content-between align-items-center">
        <h3>Antécédents médicaux</h3>
        {!!rdv && rdv?.status !== "annuler" && (
          <NavLink
            to={`/medecin/antecedent-medical/${rdv?.slug}`}
            state={rdv}
            className="btn join-file-btn"
          >
            <FaFileMedicalAlt className="attach-icon-antecedent" />
            Mettre à jour
          </NavLink>
        )}
      </div>
      {/* Component */}
      <HistoryItem item={item} />
    </div>
  );
}

export default Historique;

export const HistoryItem = ({item}:{item:IUser}) => {
  return (
    <div className=" all-files-container pt-4 ">
      <div className="accordion accordion-history" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              disabled
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Informations Générales
            </button>
          </h2>
          <div id="collapseOne" className="accordion-collapse collapse show">
            <div className="accordion-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="rdv-location mb-3">
                    <span className="cs-title">Prénom&Nom:</span> &nbsp;
                    <span className="cs-value">{getName(item)}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="rdv-location mb-3">
                    <span className="cs-title">Adresse:</span> &nbsp;
                    <span className="cs-value">{item?.adresse}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="rdv-location mb-3">
                    <span className="cs-title">Email:</span> &nbsp;
                    <span className="cs-value">{item?.email}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="rdv-location mb-3">
                    <span className="cs-title">Téléphone:</span> &nbsp;
                    <span className="cs-value">{item?.telephone}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="rdv-location mb-3">
                    <span className="cs-title">Date de Naissance:</span> &nbsp;
                    <span className="cs-value">
                      {formatDate(item?.date_de_naissance)}
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="rdv-location mb-3">
                    <span className="cs-title">Genre:</span> &nbsp;
                    <span className="cs-value">{item?.civilite}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="rdv-location mb-3">
                    <span className="cs-title">Groupe Sanguin:</span> &nbsp;
                    <span className="cs-value">
                      {item?.groupe_sanguin ||
                        item?.constantes?.groupe_sanguin ||
                        "----"}
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="rdv-location mb-3">
                    <span className="cs-title">Contact d'urgence:</span> &nbsp;
                    <span className="cs-value">
                      {item?.contact_urgence || "----"}
                    </span>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="rdv-location mb-3">
                    <span className="cs-title">Assurance medicale:</span> &nbsp;
                    <span className="cs-value">
                      {item?.assurance_medicale || "----"}
                    </span>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="rdv-location mb-3">
                    <span className="cs-title">Numéro Assurance medicale:</span>{" "}
                    &nbsp;
                    <span className="cs-value">
                      {item?.numero_assurance || "----"}
                    </span>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="rdv-location mb-3">
                    <span className="cs-title">Social:</span> &nbsp;
                    <span className="cs-value">{item?.social ? item?.social?.toString() : "----"}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button "
              type="button"
              disabled
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Antécédents medicaux
            </button>
          </h2>
          <div id="collapseTwo" className="accordion-collapse collapse show">
            <div className="accordion-body hitory-body">
              <div
                dangerouslySetInnerHTML={createMarkup(
                  item?.antecedent_medical || "----"
                )}
              />
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button "
              type="button"
              disabled
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Chirurgies
            </button>
          </h2>
          <div id="collapseThree" className="accordion-collapse collapse show">
            <div className="accordion-body hitory-body">
              <div
                dangerouslySetInnerHTML={createMarkup(
                  item?.chirurgie || "----"
                )}
              />
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button "
              type="button"
              disabled
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              Allergies et réactions
            </button>
          </h2>
          <div id="collapseFour" className="accordion-collapse collapse show">
            <div className="accordion-body hitory-body">
              <div
                dangerouslySetInnerHTML={createMarkup(item?.allergie || "----")}
              />
            </div>
          </div>
        </div>
        {item?.civilite === "femme" && (
          <>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button "
                  type="button"
                  disabled
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Gynécologie
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body hitory-body">
                  <div
                    dangerouslySetInnerHTML={createMarkup(
                      item?.gynecologie || "----"
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button "
                  type="button"
                  disabled
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  Obstétrique
                </button>
              </h2>
              <div
                id="collapseSix"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body hitory-body">
                  <div
                    dangerouslySetInnerHTML={createMarkup(
                      item?.obstetrique || "----"
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}