/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from "react";
import { useUser } from "../../../../utils/Utils";
import { useGetDisponibiliteByMedecinQuery } from "../../../../utils/api/disponibilite/disponibilite.api";
import moment from "moment";
import { Disponibilite } from "../../../../utils/api/disponibilite/disponibilite.type";
import GererDisponibilite from "../modals/GererDisponibilite";
import Calendar from "../../../secretaire/praticienSecretaire/CalendarAgenda";

function Disponibilites({
  modalGererDisponibilite,
  setModalGererDisponibilite,
}) {
  const user = useUser();
  const [date, setDate] = useState(new Date());
  const [dispo, setDispo] = useState<Disponibilite | undefined>();
  const { data = [] } = useGetDisponibiliteByMedecinQuery({
    slug: user?.slug,
    date: moment(date).format("DD-MM-YYYY"),
  });

  useEffect(() => {
    if (data?.length) {
      setDispo(data[0]);
      // console.log("dataCopy", data);
    }
  }, [data]);
  return (
    <>
      <div className="admin-table pt-5">
        <div className="tabs-component-container">
          {/* <div className="checkbox-add-event-container">
            <CalendarWeekly setDate={setDate} />
            <div className="checkbox-add-event mt-4">
              {constantAgendas.map((item) => (
                <div className="label-form-container">
                  <p className="label-form-add-event mb-2">{item.day}</p>
                  <div className="container-checkbox">
                    {item.heures.map((itm) => (
                      <label>
                        <input
                          type="checkbox"
                          disabled
                          checked={
                            dispo &&
                            dispo?.jours?.includes(item?.day) &&
                            (dispo?.horaires?.includes(itm?.value) ||
                              dispo?.horaires?.some((val) =>
                                moment(val, "HH:mm")?.isSame(
                                  moment(itm?.value, "HH:mm"),
                                  "hour"
                                )
                              ))
                          }
                          // defaultChecked={itm.defaultChecked}
                        />
                        <span>{itm.label}h</span>
                      </label>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div> */}
          <div className="custom-content pt-4 pb-4">
            <div className="content-graph-admin">
              <Calendar user={user} />
            </div>
          </div>
        </div>
      </div>
      <GererDisponibilite
        modalGererDisponibilite={modalGererDisponibilite}
        setModalGererDisponibilite={setModalGererDisponibilite}
        item={dispo}
      />
    </>
  );
}

export default Disponibilites;
export const constantAgendas = [
  {
    id: 1,
    day: "Lundi",
    heures: [
      {
        id: 1,
        label: "08",
        value: "08:00",
      },
      {
        id: 2,
        label: "09",
        value: "09:00",
      },
      {
        id: 3,
        label: "10",
        value: "10:00",
      },
      {
        id: 4,
        label: "11",
        value: "11:00",
      },
      {
        id: 5,
        label: "12",
        value: "12:00",
      },
      {
        id: 6,
        label: "13",
        value: "13:00",
      },
      {
        id: 7,
        label: "14",
        value: "14:00",
      },
      {
        id: 8,
        label: "15",
        value: "15:00",
      },
      {
        id: 9,
        label: "16",
        value: "16:00",
      },
      {
        id: 10,
        label: "17",
        value: "17:00",
      },
      {
        id: 11,
        label: "18",
        value: "18:00",
      },
      {
        id: 12,
        label: "19",
        value: "19:00",
      },
    ],
  },
  {
    id: 2,
    day: "Mardi",
    heures: [
      {
        id: 1,
        label: "08",
        value: "08:00",
      },
      {
        id: 2,
        label: "09",
        value: "09:00",
      },
      {
        id: 3,
        label: "10",
        value: "10:00",
      },
      {
        id: 4,
        label: "11",
        value: "11:00",
      },
      {
        id: 5,
        label: "12",
        value: "12:00",
      },
      {
        id: 6,
        label: "13",
        value: "13:00",
      },
      {
        id: 7,
        label: "14",
        value: "14:00",
      },
      {
        id: 8,
        label: "15",
        value: "15:00",
      },
      {
        id: 9,
        label: "16",
        value: "16:00",
      },
      {
        id: 10,
        label: "17",
        value: "17:00",
      },
      {
        id: 11,
        label: "18",
        value: "18:00",
      },
      {
        id: 12,
        label: "19",
        value: "19:00",
      },
    ],
  },
  {
    id: 3,
    day: "Mercredi",
    heures: [
      {
        id: 1,
        label: "08",
        value: "08:00",
      },
      {
        id: 2,
        label: "09",
        value: "09:00",
      },
      {
        id: 3,
        label: "10",
        value: "10:00",
      },
      {
        id: 4,
        label: "11",
        value: "11:00",
      },
      {
        id: 5,
        label: "12",
        value: "12:00",
      },
      {
        id: 6,
        label: "13",
        value: "13:00",
      },
      {
        id: 7,
        label: "14",
        value: "14:00",
      },
      {
        id: 8,
        label: "15",
        value: "15:00",
      },
      {
        id: 9,
        label: "16",
        value: "16:00",
      },
      {
        id: 10,
        label: "17",
        value: "17:00",
      },
      {
        id: 11,
        label: "18",
        value: "18:00",
      },
      {
        id: 12,
        label: "19",
        value: "19:00",
      },
    ],
  },
  {
    id: 4,
    day: "Jeudi",
    heures: [
      {
        id: 1,
        label: "08",
        value: "08:00",
      },
      {
        id: 2,
        label: "09",
        value: "09:00",
      },
      {
        id: 3,
        label: "10",
        value: "10:00",
      },
      {
        id: 4,
        label: "11",
        value: "11:00",
      },
      {
        id: 5,
        label: "12",
        value: "12:00",
      },
      {
        id: 6,
        label: "13",
        value: "13:00",
      },
      {
        id: 7,
        label: "14",
        value: "14:00",
      },
      {
        id: 8,
        label: "15",
        value: "15:00",
      },
      {
        id: 9,
        label: "16",
        value: "16:00",
      },
      {
        id: 10,
        label: "17",
        value: "17:00",
      },
      {
        id: 11,
        label: "18",
        value: "18:00",
      },
      {
        id: 12,
        label: "19",
        value: "19:00",
      },
    ],
  },
  {
    id: 5,
    day: "Vendredi",
    heures: [
      {
        id: 1,
        label: "08",
        value: "08:00",
      },
      {
        id: 2,
        label: "09",
        value: "09:00",
      },
      {
        id: 3,
        label: "10",
        value: "10:00",
      },
      {
        id: 4,
        label: "11",
        value: "11:00",
      },
      {
        id: 5,
        label: "12",
        value: "12:00",
      },
      {
        id: 6,
        label: "13",
        value: "13:00",
      },
      {
        id: 7,
        label: "14",
        value: "14:00",
      },
      {
        id: 8,
        label: "15",
        value: "15:00",
      },
      {
        id: 9,
        label: "16",
        value: "16:00",
      },
      {
        id: 10,
        label: "17",
        value: "17:00",
      },
      {
        id: 11,
        label: "18",
        value: "18:00",
      },
      {
        id: 12,
        label: "19",
        value: "19:00",
      },
    ],
  },
  {
    id: 6,
    day: "Samedi",
    heures: [
      {
        id: 1,
        label: "08",
        value: "08:00",
      },
      {
        id: 2,
        label: "09",
        value: "09:00",
      },
      {
        id: 3,
        label: "10",
        value: "10:00",
      },
      {
        id: 4,
        label: "11",
        value: "11:00",
      },
      {
        id: 5,
        label: "12",
        value: "12:00",
      },
      {
        id: 6,
        label: "13",
        value: "13:00",
      },
      {
        id: 7,
        label: "14",
        value: "14:00",
      },
      {
        id: 8,
        label: "15",
        value: "15:00",
      },
      {
        id: 9,
        label: "16",
        value: "16:00",
      },
      {
        id: 10,
        label: "17",
        value: "17:00",
      },
      {
        id: 11,
        label: "18",
        value: "18:00",
      },
      {
        id: 12,
        label: "19",
        value: "19:00",
      },
    ],
  },
  {
    id: 7,
    day: "Dimanche",
    heures: [
      {
        id: 1,
        label: "08",
        value: "08:00",
      },
      {
        id: 2,
        label: "09",
        value: "09:00",
      },
      {
        id: 3,
        label: "10",
        value: "10:00",
      },
      {
        id: 4,
        label: "11",
        value: "11:00",
      },
      {
        id: 5,
        label: "12",
        value: "12:00",
      },
      {
        id: 6,
        label: "13",
        value: "13:00",
      },
      {
        id: 7,
        label: "14",
        value: "14:00",
      },
      {
        id: 8,
        label: "15",
        value: "15:00",
      },
      {
        id: 9,
        label: "16",
        value: "16:00",
      },
      {
        id: 10,
        label: "17",
        value: "17:00",
      },
      {
        id: 11,
        label: "18",
        value: "18:00",
      },
      {
        id: 12,
        label: "19",
        value: "19:00",
      },
    ],
  },
];
