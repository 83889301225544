import React, { useEffect, useState } from "react";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../common/TableSkeleton";
import "./Table.css";
import { useGetFacturationByPatientQuery } from "../../../utils/api/facturation/facturation.api";
import {
  formatCurrency,
  formatDate,
  getFile,
  getName,
  showModal,
  useUser,
} from "../../../utils/Utils";
import { Facturation } from "../../../utils/api/facturation/facturation.type";
import { FaFileInvoice } from "react-icons/fa";
import ShowFileModal from "../../shared/modal/ShowFileModal";
import { IUser } from "../../../utils/api/user/user.type";
import { CustomPagination } from "../../common/CustomPagination";
import { StatusBtn } from "../../medecin/Tables/AllPaiementMedecinTable";

function PatientFactureTable() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const user = useUser();
  const { data = { results: [], count: 0 }, isLoading,refetch } =
    useGetFacturationByPatientQuery({
      page,
      limit,
      slug: user?.slug,
    });

    useEffect(() => {
      if (!isLoading) refetch();
    }, []);

 
  const amountFormatter = (cell: string, row: Facturation) => {
    let amount = row?.tarifs?.reduce((acc, t) => {
      return acc + parseInt(t?.montant);
    }, 0);
    return (
      <>
        <div className="amount-formatter-container">
          {formatCurrency(amount)}
        </div>
      </>
    );
  };

  const factureFormatter = (cell: string, row: Facturation) => {
    return (
      <>
        <div
          className="paiement-method-formatter-container d-flex align-items-center gap-2 linkable"
          onClick={() => showModal(`fileInvoiceModal${row?.slug}`)}
        >
          <span className="file-icon">
            <FaFileInvoice />
          </span>
          Facture
        </div>
        <ShowFileModal
          modalId={`fileInvoiceModal${row?.slug}`}
          file={getFile(cell)}
          title={`Facture de ${getName(row?.patient)}`}
        />
      </>
    );
  };

  const statutFormatter = (cell: string, row: Facturation) => {
    return (
      <>
        <div className="statut-formatter-container d-flex align-items-center gap-2">
          <StatusBtn item={row} />
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "cabinet",
      text: `Cabinet`,
      formatter: (cell: IUser) => getName(cell),
    },
    {
      dataField: "created_at",
      text: "Date",
      formatter: (cell: IUser) => formatDate(cell),
    },
    {
      dataField: "montant",
      text: "Montant",
      formatter: amountFormatter,
    },
    {
      dataField: "facture",
      text: "Facture",
      formatter: factureFormatter,
    },
    {
      dataField: "status",
      text: "Statut",
      formatter: statutFormatter,
    },
  ];

  // const rowEvents = {
  //   onClick: (e, row: Facturation) => {
  //     showModal(`detailsFacture${row?.slug}`);
  //   },
  // };

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            // rowEvents={rowEvents}
            // rowClasses="custom-table-row-class"
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default PatientFactureTable;
