import React, { useState } from 'react';
import "../Medecin.css";
import ActiviteRdvMedecinTable from '../Tables/ActiviteRdvMedecinTable';
import ActiviteRdv from '../../shared/ActiviteRdv/ActiviteRdv';


function RdvMedecin() {
  const [query, setQuery] = useState("");
  const [date, setDate] = useState(new Date());

  return (
    <>
      <ActiviteRdv
        tableComponent={<ActiviteRdvMedecinTable q={query} date={date} />}
        setQuery={setQuery}
        setDate={setDate}
      />
    </>
  );
}

export default RdvMedecin

