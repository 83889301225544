import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl, useLocationState } from "../../Utils";
import { Dossier, DossierFormData } from "./dossier.type";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const DossierApi = createApi({
  reducerPath: "dossierApi",
  tagTypes: ["dossier", "dossierByCabinet", "listDossiers"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    addOrUpdateDossier: builder.mutation<
      Dossier,
      { slug?: string; data: DossierFormData | FormData }
    >({
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `dossiers/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `dossiers/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["dossierByCabinet", "dossier", "listDossiers"],
    }),
    getDossierList: builder.query<PaginationResults<Dossier>, TypeQuery>({
      query: (query) => QueryUrl(`dossiers/`, query),
      providesTags: ["listDossiers"],
    }),
    getDossierBySlug: builder.query<Dossier, string>({
      query: (slug) => `dossiers/${slug}/`,
      providesTags: ["dossier"],
    }),
    getDossierByCabinet: builder.query<PaginationResults<Dossier>, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`cabinet/${slug}/dossiers/`, query),
      providesTags: ["dossierByCabinet"],
    }),
    deleteDossier: builder.mutation<DossierFormData, string>({
      query: (slug) => ({
        url: `dossiers/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["dossier", "dossierByCabinet", "listDossiers"],
    }),
  }),
});

export const {
  useAddOrUpdateDossierMutation,
  useDeleteDossierMutation,
  useGetDossierByCabinetQuery,
  useLazyGetDossierBySlugQuery,
  useGetDossierListQuery
} = DossierApi;

export function useDossierFromLocation(): [
  Dossier,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<Dossier | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading }] =
    useLazyGetDossierBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findBySlug(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  return [item as Dossier, isLoading, slug as string, findBySlug];
}
