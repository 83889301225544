import React from "react";
import { BtnSubmit } from "../../../common/Button";
import { Input } from "../../../common/Input";
import { ISpecialite } from "../../../../utils/api/specialite/specialite.type";
import UseAddOrEditSpecialiteForm from "../form/UseAddOrEditSpecialiteForm";

function AddOrEditSpecialiteModal({
  modalId,
  item,
}: {
  modalId: string;
  item?: ISpecialite;
}) {
  const { register, onSubmit, isLoading, errors, handleReset } =
    UseAddOrEditSpecialiteForm(modalId, item);
  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby={modalId + "Label"}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id={modalId + "Label"}>
              Abonnement
            </h1>
            <button
              type="button"
              className="btn-close"
              //   data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => handleReset()}
            ></button>
          </div>
          <div className="modal-body">
            <div className="pt-3">
              <form onSubmit={onSubmit}>
                <div className="row auth-form-row">
                  <div className="col-md-12 mb-3">
                    <Input
                      type="text"
                      label="Nom de la spécialité"
                      id="Nom de la spécialité"
                      placeholder="Nom de la spécialité"
                      {...register("nom")}
                      required
                      error={errors?.nom?.message}
                    />
                  </div>

                  <div className="flex-r">
                    <button
                      className="btn btn-cancelled"
                      type="button"
                      //   data-bs-dismiss="modal"
                      onClick={() => handleReset()}
                    >
                      Annuler
                    </button>
                    <BtnSubmit
                      label="Enregistrer"
                      isLoading={isLoading}
                      customClass="w-auto"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrEditSpecialiteModal;
