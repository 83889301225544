import React from "react";
import { useEffect } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Analyse,
  AnalyseFormData,
} from "../../../utils/api/analyse/analyse.type";
import { useAddOrUpdateAnalyseMutation } from "../../../utils/api/analyse/analyse.api";
import { Color } from "../../../utils/theme";
import { cleannerError, closeModal } from "../../../utils/Utils";

function UseAddOrEditAnalyseForm(modalId: string, item: Analyse) {
  const validationSchema = yup.object().shape({
    resultat: yup.mixed().required().label("Le fichier").nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
    resetField,
  } = useForm<AnalyseFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [sendData, { isLoading, isSuccess, isError, error }] =
    useAddOrUpdateAnalyseMutation();

  const handleChange = (e) => {
    let file = e?.target?.files[0];
    setValue("resultat", file);
  };
  const handleReset = () => {
    resetField("resultat");
    closeModal(modalId);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Le fichier a été joint avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        handleReset();
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    // console.log("cabinet", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: AnalyseFormData) => {
    
    let fd = new FormData();

    for (const field in data) {
      fd.append(field, data[field]);
    }
    await sendData({ slug: item?.slug, data: fd });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    control,
    handleReset,
    handleChange,
  };
}

export default UseAddOrEditAnalyseForm;
