import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Facturation, FacturationFormData } from "./facturation.type";

export const FacturationApi = createApi({
  reducerPath: "facturationApi",
  tagTypes: [
    "facturationList",
    "facturationBySlug",
    "facturation",
    "facturationByMedecin",
    "facturationByPatient",
    "facturationByCabinet",
    "facturationByUser",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListFacturationsAdmin: builder.query<
      PaginationResults<Facturation>,
      TypeQuery
    >({
      providesTags: ["facturationList"],
      query: (query) => QueryUrl("facturations/", query),
    }),
    getFacturationByPatient: builder.query<
      PaginationResults<Facturation>,
      TypeQuery
    >({
      providesTags: ["facturationByPatient"],
      query: ({ slug, ...query }) =>
        QueryUrl(`patient/${slug}/facturations/`, query),
    }),
    getFacturationByMedecin: builder.query<
      PaginationResults<Facturation>,
      TypeQuery
    >({
      providesTags: ["facturationByMedecin"],
      query: ({ slug, ...query }) =>
        QueryUrl(`medecin/${slug}/facturations/`, query),
    }),
    getFacturationByCabinet: builder.query<
      PaginationResults<Facturation>,
      TypeQuery
    >({
      providesTags: ["facturationByCabinet"],
      query: ({ slug, ...query }) =>
        QueryUrl(`cabinet/${slug}/facturations/`, query),
    }),
    getFacturationByUser: builder.query<
      PaginationResults<Facturation>,
      TypeQuery
    >({
      providesTags: ["facturationByUser"],
      query: ({ slug, type, ...query }) =>
        QueryUrl(
          `${type === "medecin" ? "medecin" : "cabinet"}/${slug}/facturations/`,
          query
        ),
    }),
    addOrEditFacturation: builder.mutation<
      Facturation,
      { slug?: string; data: FacturationFormData }
    >({
      query: ({ slug, data }) => ({
        url: slug ? `facturations/${slug}/` : `facturations/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: [
        "facturationList",
        "facturationByMedecin",
        "facturationByPatient",
        "facturationByUser",
      ],
    }),
    deleteFacturation: builder.mutation<Facturation, string>({
      query: (slug) => ({
        url: `facturations/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "facturationList",
        "facturationByMedecin",
        "facturationByPatient",
        "facturationByUser",
      ],
    }),
    facturationBySlug: builder.query<Facturation | any, string>({
      query: (slug) => `facturations/${slug}/`,
      providesTags: ["facturationBySlug"],
    }),
  }),
});

export const {
  useAddOrEditFacturationMutation,
  useDeleteFacturationMutation,
  useGetFacturationByCabinetQuery,
  useGetFacturationByMedecinQuery,
  useGetFacturationByPatientQuery,
  useGetFacturationByUserQuery
} = FacturationApi;
