import React, { useEffect, useState } from "react";
import "./PraticiensAdmin.css";
import { MdOutlineAddBox } from "react-icons/md";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useGetPraticiensQuery } from "../../../utils/api/user/user.api";
import { AlertInfo } from "../../common/Alert";
import {
  getAvatar,
  getName,
  getNameCabinet,
  useUser,
} from "../../../utils/Utils";
import { CustomPagination } from "../../common/CustomPagination";
import { IUser } from "../../../utils/api/user/user.type";
import { useAppSelector } from "../../../redux/hook";
import { hasAccess, isAdmin, isCabinet } from "../../../routes/routerUtils";
import { PraticienSkeleton } from "../../secretaire/praticienSecretaire/PraticienSecretaire";

export const PraticienItem = ({ item }: { item: IUser }) => {
  const { user } = useAppSelector((s) => s?.user);
  return (
    <div className="content-col-praticien-item w-100">
      <div className="content-img-profil-praticien py-3">
        <img
          src={getAvatar(item?.avatar)}
          alt={getName(item) || "avatar"}
          className="img-profil-praticien"
        />
      </div>
      <p className="name-praticien-admin">{getName(item)}</p>
      <p className="poste-pratiien-admin">{item?.specialite?.nom}</p>
      {!!isAdmin(user) && (
        <div className="d-flex justify-content-center gap-2 align-items-baseline">
          <span className="poste-pratiien-admin">Cabinet:</span>
          <span className="name-praticien-admin"> {getNameCabinet(item)} </span>
        </div>
      )}
      <div className="container-btn-viez-profil-praticien pt-3">
        <NavLink
          to={`/${
            isAdmin(user)
              ? "admin"
              : isCabinet(user)
              ? "cabinet"
              : "secretaire-medical"
          }/praticiens/${item?.slug}`}
          className="btn btn-view-profil-praticien"
          state={item}
        >
          Voir le profil
        </NavLink>
      </div>
    </div>
  );
};

function PraticiensAdmin() {
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(16);
  const { data = { results: [], count: 0 }, isLoading,refetch } = useGetPraticiensQuery(
    {
      page,
      limit,
      q: query,
    }
  );
  const location = useLocation();
  const user = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) refetch();
  }, []);

  useEffect(() => {
    if (user && hasAccess(user, "praticien_habilitation")) {
      navigate("/admin/parametres");
    }
  }, [user]);

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="content-graph-admin">
          <div className="container-title-dash align-items-center justify-content-between d-flex mb-4">
            <h2 className="chart-item-admin-title mb-0">Praticiens</h2>
            <NavLink
              to={
                location?.pathname?.startsWith("/admin")
                  ? "/admin/ajouter-praticien"
                  : "/cabinet/ajouter-praticien"
              }
              className="btn custom-add-item-btn"
            >
              <span className="add-icon pe-2">
                <MdOutlineAddBox />
              </span>
              <span>Ajouter un praticien</span>
            </NavLink>
          </div>
          <div className="search-patient-box d-flex align-items-center gap-3 mt-4">
            <input
              type="search"
              name="search_patient"
              id="search_patient"
              placeholder="Rechercher un médecin sans le terme 'Dr'"
              onChange={(e) => setQuery(e?.target?.value)}
            />
          </div>
          <div className="row py-5">
            {!!isLoading && <PraticienSkeleton />}
            {!isLoading &&
              (data?.results?.length ? (
                data?.results?.map((item) => (
                  <div
                    className="col-lg-3 col-md-6 col-praticien-item text-center d-flex"
                    key={item.id}
                  >
                    <PraticienItem item={item} />
                  </div>
                ))
              ) : (
                <AlertInfo message="Aucune donnée trouvée !" />
              ))}
            <div className="col-md-12">
              <div className="custom-pagination-container">
                <CustomPagination
                  nbPages={data?.count}
                  page={page}
                  onChange={(page, perPage) => {
                    setLimit(perPage);
                    setPage(page);
                  }}
                  perPage={limit}
                  showPerPage={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PraticiensAdmin;
