import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  FaFileInvoiceDollar,
  FaFileMedical,
  FaStethoscope,
  FaTasks,
  FaUserMd,
  FaUsers,
} from "react-icons/fa";
import { ImProfile } from "react-icons/im";
import {
  AiTwotonePieChart,
  AiTwotoneSetting,
  AiOutlineMenu,
  AiFillExperiment,
} from "react-icons/ai";
import { IoDocumentText, IoMail } from "react-icons/io5";
import Logo from "../../../../assets/appImages/logo-nav.png";
import { HiOutlineDocumentText } from "react-icons/hi2";
import { BsCreditCard, BsFileEarmarkArrowDownFill, BsFileTextFill } from "react-icons/bs";
import VersionChecker from "../../../../versionChecker/VersionChecker";
import { useGetChatItemQuery } from "../../../../utils/api/chat/chat.api";
import { getNameCabinet, useUser } from "../../../../utils/Utils";
import socket from "../../../../utils/socket-config";
import { FileIcon, InvoiceIcon, OrdonnanceIcon, RdvIcon, ResultIcon, VaccinateIcon } from "../../../common/Icons";
import { BiSolidCalendar } from "react-icons/bi";
import { hasAccess, isPatient } from "../../../../routes/routerUtils";
import { useGetTacheByMedecinQuery } from "../../../../utils/api/tache/tache.api";

function SidebarAdmin() {
  const location = useLocation();
  const user = useUser();
  const { data = [], refetch } = useGetChatItemQuery(user?.slug);
  const [count, setCount] = useState(0);
  const { data: taches = { new_tasks_count: 0 }, isLoading } =
    useGetTacheByMedecinQuery({
      slug: user?.slug,
    });

  useEffect(() => {
    if (data?.length) {
      setCount(
        data?.reduce((acc, t) => {
          return acc + t?.messages_received_non_lus;
        }, 0)
      );
    }
  }, [data]);

  useEffect(() => {
    socket.on("messageResponse", (data) => {
      if ([data?.sender, data?.receiver].includes(user?.id)) {
        refetch();
      }
    });
    return () => {
      socket.off("messageResponse");
    };
  }, [socket]);

  return (
    <div className="navbar-vertical-customisation-v2 no-view-mobile navbar-vertical-customisation-admin">
      <div className="hoverable mt-3" id="scrollbar">
        <div className="content">
          <div className="move-left-side-menu"></div>
          <div className="logo-container-sidebar">
            <div className="container-logo-sidebar-nav">
              <img src={Logo} alt="Logo" className="logo-sidebar-nav" />
            </div>
            {!isPatient(user) && (
              <div className="cabinet-name-container">
                <span className="cabinet-name-sidebar">
                  {getNameCabinet(user)}
                </span>
              </div>
            )}
          </div>

          <ul className="nav nav-items-custom flex-column">
            {location?.pathname?.startsWith("/admin") && (
              <>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={hasAccess(user, "dashboard")}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/dashboard"
                    data-tooltip-content="Dashboard"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <AiTwotonePieChart />
                    </span>
                    <span className="hiddenable-navlink-label">Dashboard</span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={hasAccess(user, "specialite_habilitation")}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/specialites"
                    data-tooltip-content="Spécialités"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <AiFillExperiment />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Spécialités
                    </span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={hasAccess(user, "praticien_habilitation")}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/praticiens"
                    data-tooltip-content="Praticiens"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaUserMd />
                    </span>
                    <span className="hiddenable-navlink-label">Praticiens</span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={hasAccess(user, "rdv_habilitation")}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/rendez-vous"
                    data-tooltip-content="Rendez-vous"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaStethoscope />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Rendez-vous
                    </span>
                  </NavLink>
                </li>
                {/* <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/dossiers"
                    data-tooltip-content="Dossiers"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <HiOutlineDocumentText />
                    </span>
                    <span className="hiddenable-navlink-label">Dossiers</span>
                  </NavLink>
                </li> */}
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={hasAccess(user, "hopitaux_habilitation")}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/cabinets"
                    data-tooltip-content="Hôpitaux/cabinets"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <HiOutlineDocumentText />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Hôpitaux/cabinets
                    </span>
                  </NavLink>
                </li>
                {/* <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/messages"
                    data-tooltip-content="Messages"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <IoMail />
                    </span>
                    <span className="hiddenable-navlink-label">Messages</span>
                  </NavLink>
                </li> */}
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/parametres"
                    data-tooltip-content="Paramètres"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <AiTwotoneSetting />
                    </span>
                    <span className="hiddenable-navlink-label">Paramètres</span>
                  </NavLink>
                </li>
              </>
            )}
            {location?.pathname?.startsWith("/medecin") && (
              <>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/medecin/dashboard"
                    data-tooltip-content="Dashboard"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <AiTwotonePieChart />
                    </span>
                    <span className="hiddenable-navlink-label">Dashboard</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/medecin/rendez-vous"
                    data-tooltip-content="Rendez-vous"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaStethoscope />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Rendez-vous
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/medecin/patients"
                    data-tooltip-content="Patients"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaUsers />
                    </span>
                    <span className="hiddenable-navlink-label">Patients</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/medecin/paiements"
                    data-tooltip-content="Paiements"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <BsCreditCard />
                    </span>
                    <span className="hiddenable-navlink-label">Paiements</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/medecin/taches"
                    data-tooltip-content="Tâches"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaTasks />
                    </span>
                    <span className="hiddenable-navlink-label">Tâches</span>
                    <span>
                      {taches?.new_tasks_count &&
                        taches?.new_tasks_count > 0 ? (
                          <span className="statut-message-tabs-non-lu">
                            {taches?.new_tasks_count}
                          </span>
                        ):null}
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/medecin/messages"
                    data-tooltip-content="Messages"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <IoMail />
                    </span>
                    <span className="hiddenable-navlink-label">Messages</span>
                    {count > 0 && (
                      <span>
                        <span className="statut-message-tabs-non-lu">
                          {count}
                        </span>
                      </span>
                    )}
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/medecin/parametres"
                    data-tooltip-content="Paramètres"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <AiTwotoneSetting />
                    </span>
                    <span className="hiddenable-navlink-label">Paramètres</span>
                  </NavLink>
                </li>
              </>
            )}
            {location?.pathname?.startsWith("/secretaire-medical") && (
              <>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/secretaire-medical/dashboard"
                    data-tooltip-content="Dashboard"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <AiTwotonePieChart />
                    </span>
                    <span className="hiddenable-navlink-label">Dashboard</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/secretaire-medical/praticiens"
                    data-tooltip-content="Agenda Médecin"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <RdvIcon />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Agenda Médecin
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/secretaire-medical/patients"
                    data-tooltip-content="Patients"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaUsers />
                    </span>
                    <span className="hiddenable-navlink-label">Patients</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/secretaire-medical/rendez-vous"
                    data-tooltip-content="Rendez-vous"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaStethoscope />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Rendez-vous
                    </span>
                  </NavLink>
                </li>
                {/* <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/secretaire-medical/dossiers"
                    data-tooltip-content="Dossiers"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <HiOutlineDocumentText />
                    </span>
                    <span className="hiddenable-navlink-label">Dossiers</span>
                  </NavLink>
                </li> */}
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/secretaire-medical/paiements"
                    data-tooltip-content="Paiements"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <BsCreditCard />
                    </span>
                    <span className="hiddenable-navlink-label">Paiements</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/secretaire-medical/messages"
                    data-tooltip-content="Messages"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <IoMail />
                    </span>
                    <span className="hiddenable-navlink-label">Messages</span>
                    {count > 0 && (
                      <span>
                        <span className="statut-message-tabs-non-lu">
                          {count}
                        </span>
                      </span>
                    )}
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/secretaire-medical/parametres"
                    data-tooltip-content="Paramètres"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <AiTwotoneSetting />
                    </span>
                    <span className="hiddenable-navlink-label">Paramètres</span>
                  </NavLink>
                </li>
              </>
            )}
            {location?.pathname?.startsWith("/patient") && (
              <>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/patient/menu"
                    data-tooltip-content="MENU"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <AiOutlineMenu />
                    </span>
                    <span className="hiddenable-navlink-label">MENU</span>
                  </NavLink>
                </li>
                <div className="pt-3 pb-3"></div>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/patient/dashboard"
                    data-tooltip-content="Dashboard"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <AiTwotonePieChart />
                    </span>
                    <span className="hiddenable-navlink-label">Dashboard</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/patient/rendez-vous"
                    data-tooltip-content="Rendez-vous"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <RdvIcon />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Rendez-vous
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/patient/facture"
                    data-tooltip-content="Facture"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <InvoiceIcon />
                    </span>
                    <span className="hiddenable-navlink-label">Facture</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/patient/vaccins"
                    data-tooltip-content="Vaccins"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <VaccinateIcon />
                    </span>
                    <span className="hiddenable-navlink-label">Vaccins</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/patient/fichiers"
                    data-tooltip-content="Mes fichiers"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FileIcon />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Mes fichiers
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/patient/ordonnance"
                    data-tooltip-content="Ordonnance"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <OrdonnanceIcon />
                    </span>
                    <span className="hiddenable-navlink-label">Ordonnance</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/patient/messages"
                    data-tooltip-content="Messages"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <IoMail />
                    </span>
                    <span className="hiddenable-navlink-label">Messages</span>
                    {count > 0 && (
                      <span>
                        <span className="statut-message-tabs-non-lu">
                          {count}
                        </span>
                      </span>
                    )}
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/patient/resultats"
                    data-tooltip-content="Résultats"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <ResultIcon />
                    </span>
                    <span className="hiddenable-navlink-label">Résultats</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/patient/antécédents"
                    data-tooltip-content="Antécédents"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaFileMedical />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Antécédents
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/patient/profil"
                    data-tooltip-content="Profil"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <ImProfile />
                    </span>
                    <span className="hiddenable-navlink-label">Profil</span>
                  </NavLink>
                </li>
              </>
            )}
            {location?.pathname?.startsWith("/cabinet") && (
              <>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/cabinet/dashboard"
                    data-tooltip-content="Dashboard"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <AiTwotonePieChart />
                    </span>
                    <span className="hiddenable-navlink-label">Dashboard</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/cabinet/praticiens"
                    data-tooltip-content="Praticiens"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaUserMd />
                    </span>
                    <span className="hiddenable-navlink-label">Praticiens</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/cabinet/secretaires"
                    data-tooltip-content="Secrétaires médicaux"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaUsers />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Secrétaires médicaux
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/cabinet/rendez-vous"
                    data-tooltip-content="Rendez-vous"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaStethoscope />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Rendez-vous
                    </span>
                  </NavLink>
                </li>
                {/* <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/cabinet/dossiers"
                    data-tooltip-content="Dossiers"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <HiOutlineDocumentText />
                    </span>
                    <span className="hiddenable-navlink-label">Dossiers</span>
                  </NavLink>
                </li> */}
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/cabinet/tarifications"
                    data-tooltip-content="Tarification"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaFileInvoiceDollar />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Tarification
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/cabinet/paiements"
                    data-tooltip-content="Paiements"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <BsCreditCard />
                    </span>
                    <span className="hiddenable-navlink-label">Paiements</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/cabinet/parametres"
                    data-tooltip-content="Paramètres"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <AiTwotoneSetting />
                    </span>
                    <span className="hiddenable-navlink-label">Paramètres</span>
                  </NavLink>
                </li>
              </>
            )}
            <li className="nav-item nav-item-vertical-custom">
              <div className="nav-link nav-link-vertival-custom-admin with-tooltip-menu active">
                <span className="icon-container-nav-link-vertival">
                  V{VersionChecker()}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SidebarAdmin;
