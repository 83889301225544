import React, { useState } from "react";
import WrapperComponent from "../../shared/WrapperComponent";
// import AllUpComingRdvTable from "../Tables/PatientUpComingRdvTable";
import PatientUpComingRdvTable from "../Tables/PatientUpComingRdvTable";


function DashboardPatient() {
    const [date, setDate] = useState(new Date())

    return (
      <>
        <WrapperComponent>
          <div className="container-title-dash d-flex align-items-center gap-4 pb-4">
            <h2 className="custom-sm-top-title mb-0">Rendez-vous à venir</h2>
            {/* <CalendrierStep setDate={setDate} /> */}
          </div>

          <div className="custom-table pt-4 pb-4">
            <PatientUpComingRdvTable date={date} q={""} />
          </div>
        </WrapperComponent>
      </>
    );
}

export default DashboardPatient;