import React, { useEffect, useState } from "react";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import { AiOutlineEye } from "react-icons/ai";
import TableSkeleton from "../../common/TableSkeleton";
import { NavLink, useNavigate } from "react-router-dom";
import "./Table.css";
import { useGetRdvsByPatientQuery } from "../../../utils/api/rdvs/rdvs.api";
import { formatDate, formatHour, getName, useUser } from "../../../utils/Utils";
import { CustomPagination } from "../../common/CustomPagination";
import { IUser } from "../../../utils/api/user/user.type";
import { StatusBtn } from "../../secretaire/Tables/ActiviteRdvSecretaireTable";
import { IRdvs } from "../../../utils/api/rdvs/rdvs.type";
import moment from "moment";
import { ISpecialite } from "../../../utils/api/specialite/specialite.type";

function PatientRdvTable({
  q,
  statut,
  date,
}: {
  q: string;
  statut: string;
  date?: Date;
}) {
  const user = useUser();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { data = { results: [], count: 0 }, isLoading,refetch } =
    useGetRdvsByPatientQuery({
      slug: user?.slug,
      page,
      limit,
      q,
      status: statut,
      date: date ? moment(date).format("DD-MM-YYYY") : "",
    });

    useEffect(() => {
      if (!isLoading) refetch();
    }, []);

  // const actionFormatter = (cell: string, row: IRdvs) => {
  //   return (
  //     <>
  //       <div className="d-flex gap-3">
  //         <div className="container-btn-action-icon-modal">
  //           <NavLink
  //             to={`/patient/rendez-vous/detail/${cell}`}
  //             className="btn btn-action-icon-edit with-tooltip"
  //             data-tooltip-content="Voir"
  //             state={row}
  //           >
  //             <AiOutlineEye />
  //           </NavLink>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };
  const statutFormatter = (cell: string, row: IRdvs) => {
    return (
      <>
        <div className="statut-formatter-container d-flex align-items-center gap-2">
          <StatusBtn item={row} />
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "date",
      text: `Date`,
      formatter: (cell: string) => formatDate(cell),
    },

    {
      dataField: "medecin",
      text: "Médecin",
      formatter: (cell: IUser) => getName(cell),
    },
    {
      dataField: "medecin.specialite",
      text: "Spécialité",
      formatter: (cell: ISpecialite) => cell?.nom,
    },
    {
      dataField: "motif",
      text: "Motif",
    },
    {
      dataField: "status",
      text: "Statut",
      formatter: statutFormatter,
    },
    // {
    //   dataField: "slug",
    //   text: "Action",
    //   formatter: actionFormatter,
    // },
  ];

  const navigate = useNavigate();

  const rowEvents = {
    onClick: (e, row: IUser) => {
      navigate(`/patient/rendez-vous/detail/${row?.slug}`, { state: row });
    },
  };

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            rowEvents={rowEvents}
            condensed
            hover
            rowClasses="custom-table-row-class"
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default PatientRdvTable;
