/* eslint-disable @typescript-eslint/no-explicit-any */
import { IRdvs } from "../../../../utils/api/rdvs/rdvs.type";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Color } from "../../../../utils/theme";
import { cleannerError } from "../../../../utils/Utils";
import { DocumentFormData } from "../../../../utils/api/document/document.type";
import { useAddOrUpdateDocumentMutation, useDeleteDocumentMutation } from "../../../../utils/api/document/document.api";

function UseAddFileForm(
  item: IRdvs,
  setShow: Dispatch<SetStateAction<boolean>>
) {
  const validationSchema = yup.object().shape({
    nom: yup.string().required().label("Le nom du fichier").nullable(),
    fichier: yup.mixed().required().label("Le fichier").nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
    resetField,
  } = useForm<DocumentFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [file, setFile] = useState<File | null>(null);
  const [sendData, { isLoading, isSuccess, isError, error }] =
    useAddOrUpdateDocumentMutation();

  const handleReset = () => {
    resetField("nom");
    resetField("fichier");
  };

  const handleChange = (e) => {
    let file = e?.target?.files[0];
    setFile(file);
    setValue("fichier", file);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Le fichier a été joint avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        setShow(false);
        handleReset();
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    // console.log("cabinet", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (item?.id) {
      setValue("rdv", item?.id);
      setValue("patient", item?.patient?.id);
    }
  }, [item]);

  const onSubmit = async (data: DocumentFormData) => {
    let fd = new FormData();

    for (const field in data) {
      fd.append(field, data[field]);
    }
    await sendData({ slug: undefined, data: fd });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    control,
    handleChange,
    handleReset
  };
}

export default UseAddFileForm;

export function UseDeleteDocument(slug: string) {
  const [sendData] = useDeleteDocumentMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce document ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le document a été supprimée avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}