import React from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import { BtnSubmit } from "../../common/Button";
import { FormError, Input } from "../../common/Input";
import { NavLink } from "react-router-dom";
import useCrudAdminForm from "./form/useCrudAdminForm";
import { useAdminFromLocation } from "../../../utils/api/user/user.api";
import PhoneInput from "react-phone-input-2";
import { Authorisations } from "../../../utils/constante";

function AddAdmin() {
  const [item] = useAdminFromLocation();
  const { onSubmit, register, errors, phone, handleChangePhone, isLoading } =
    useCrudAdminForm(item);

  return (
    <>
      <WrapperComponent>
        <div className="container-title-dash">
          <h2 className="custom-sm-top-title mb-0">
            {!item ? "Ajouter" : "Modifier"} un admin
          </h2>
        </div>
        <div className="custom-form mt-5">
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-6">
                <Input
                  label="Prénom de l’administrateur"
                  type="text"
                  placeholder="Prénom"
                  {...register("prenom")}
                  error={errors?.prenom}
                  required
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Nom de l’administrateur"
                  type="text"
                  placeholder="Nom"
                  {...register("nom")}
                  error={errors?.nom}
                  required
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Adresse mail"
                  type="email"
                  placeholder="email"
                  {...register("email")}
                  error={errors?.email}
                  required
                />
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label form-label-modal-custom ">
                    Téléphone{" "}
                    <span className="text-danger" style={{ fontSize: 15 }}>
                      *
                    </span>
                  </label>
                  <PhoneInput
                    defaultMask={".. ... .. .."}
                    containerClass={`mb-3 p-0 input-modal-form-add-phone-container`}
                    inputClass={`input-modal-form-add-phone praticiens`}
                    country={"sn"}
                    placeholder=""
                    inputProps={{
                      name: "telephone",
                      required: true,
                      autoFocus: true,
                    }}
                    countryCodeEditable={false}
                    enableAreaCodes={true}
                    prefix="+"
                    value={phone}
                    onChange={(phone, country: any) =>
                      handleChangePhone(phone, country)
                    }
                    inputStyle={{
                      width: "100%",
                      background: "rgba(232, 240, 253, 0.7)",
                    }}
                  />
                  <FormError error={errors?.telephone?.message} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="container-title-dash">
                  <h2 className="custom-title mb-0">Permissions</h2>
                </div>
                <div className="all-permissions-content pt-4">
                  {Authorisations.map((perm, key) => (
                    <label
                      htmlFor={`permission-${key}`}
                      className="permission-small-card"
                      key={key}
                    >
                      <input
                        type="checkbox"
                        className="permission-input"
                        {...register(`${perm?.field}`)}
                        id={`permission-${key}`}
                      />
                      <label htmlFor={`permission-${key}`}>{perm?.label}</label>
                    </label>
                  ))}
                </div>
              </div>
            </div>
            <div className="d-flex flex-r mt-4">
              <div className="col-md-2">
                <NavLink
                  className="btn custom-cancel-btn"
                  to={"/admin/parametres/"}
                >
                  Annuler
                </NavLink>
              </div>
              <div className="col-md-3">
                <BtnSubmit label="Enregistrer" isLoading={isLoading} />
              </div>
            </div>
          </form>
        </div>
      </WrapperComponent>
    </>
  );
}

export default AddAdmin;
