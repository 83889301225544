import React from "react";
import WrapperComponent from "../../../shared/WrapperComponent";
import { Input } from "../../../common/Input";
import { BtnAnnuler, BtnSubmit } from "../../../common/Button";
// import AnalysesMedecinTable from "../../Tables/AnalysesMedecinTable";
import { useRdvFromLocation } from "../../../../utils/api/rdvs/rdvs.api";
import UseAddAnalyseForm from "../../rdvMedecin/form/UseAddAnalyseForm";
import { useNavigate } from "react-router-dom";

function AddNewAnalyse() {
  const [rdv] = useRdvFromLocation();
  const { register, errors, onSubmit, isLoading } = UseAddAnalyseForm(rdv);
  const navigate = useNavigate();
  return (
    <WrapperComponent>
      <div className="content-chart-title mb-4">
        <div className="container-title-dash d-flex align-items-center gap-4">
          <h2 className="custom-sm-top-title mb-0">Ajouter une analyse</h2>
        </div>
      </div>

      <div className="custom-form mt-5">
        <form onSubmit={onSubmit}>
          <div className="row w-100">
            <div className="col-form-add-medicament-item col-md-6">
              <Input
                label="Type d'analyse demandée"
                type="text"
                placeholder="Type d'analyse demandée"
                required
                {...register("type_analyse")}
                error={errors?.type_analyse}
              />
            </div>
            <div className="col-form-add-medicament-item col-md-6">
              <Input
                type="text"
                label="Fréquence"
                placeholder="Fréquence"
                required
                {...register("frequence")}
                error={errors?.frequence}
              />
            </div>
            <div className="col-form-add-medicament-item col-md-6">
              <Input
                type="text"
                label="Laboratoire"
                placeholder="Laboratoire"
                required
                {...register("laboratoire")}
                error={errors?.laboratoire}
              />
            </div>
            <div className="col-form-add-medicament-item col-md-6">
              <Input
                type="text"
                label="Délai"
                placeholder="Délai"
                required
                {...register("delai")}
                error={errors?.delai}
              />
            </div>
          </div>
          <div className="d-flex flex-r mt-4">
            <div className="col-md-2">
              <BtnAnnuler
                label="Annuler"
                customClass="custom-btn-annuler"
                onClick={() =>
                  navigate(`/medecin/rendez-vous/${rdv?.slug}?step=resultat`, {
                    state: rdv,
                  })
                }
              />
            </div>
            <div className="col-md-2">
              <BtnSubmit label="Ajouter" isLoading={isLoading} />
            </div>
          </div>
        </form>
        {/* <hr className='my-5' />
                <div className='admin-table'>
                    <AnalysesMedecinTable  />
                </div> */}
      </div>
    </WrapperComponent>
  );
}

export default AddNewAnalyse;
