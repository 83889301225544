import React from "react";
import { Medicament } from "../../../../utils/api/ordonnance/ordonnance.type";
import UseAddOrEditMedicamentForm from "../form/UseAddOrEditMedicamentForm";
import { Input } from "../../../common/Input";
import { BtnSubmit } from "../../../common/Button";

function AddOrEditMedicamentModal({
  modalId,
  item,
}: {
  modalId: string;
  item?: Medicament;
}) {
  const { register, onSubmit, isLoading, handleReset, errors } =
    UseAddOrEditMedicamentForm(modalId, item);
  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby={modalId + "Label"}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id={modalId + "Label"}>
              Médicament
            </h1>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => handleReset()}
            ></button>
          </div>
          <div className="modal-body">
            <div className="pt-3">
              <form onSubmit={onSubmit}>
                <div className="row auth-form-row">
                  <div className="col-md-6 mb-3">
                    <Input
                      label="Nom du médicament"
                      type="text"
                      placeholder="Nom du médicament"
                      required
                      {...register("nom")}
                      error={errors?.nom}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <Input
                      label="Quantité"
                      type="number"
                      placeholder="Quantité"
                      min={0}
                      required
                      {...register("quantite")}
                      error={errors?.quantite}
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <Input
                      label="Posologie"
                      type="text"
                      placeholder="Posologie"
                      required
                      {...register("posologie")}
                      error={errors?.posologie}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <Input
                      label="Dosage"
                      type="text"
                      placeholder="Dosage"
                      required
                      {...register("dosage")}
                      error={errors?.dosage}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <Input
                      label="Durée"
                      placeholder="Durée en jours"
                      type="text"
                      required
                      {...register("duree")}
                      error={errors?.duree}
                    />
                  </div>

                  <div className="flex-r">
                    <button
                      className="btn btn-cancelled"
                      type="button"
                      onClick={() => handleReset()}
                    >
                      Annuler
                    </button>
                    <BtnSubmit
                      label="Enregistrer"
                      isLoading={isLoading}
                      customClass="w-auto"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrEditMedicamentModal;
