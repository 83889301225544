import React, { useState } from "react";
import { AlertInfo } from "../../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import { MdOutlineModeEdit } from "react-icons/md";
import TableSkeleton from "../../../common/TableSkeleton";
import { NavLink } from "react-router-dom";
import { BsTrash } from "react-icons/bs";
import { AiOutlineClockCircle, AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useGetRdvsQuery } from "../../../../utils/api/rdvs/rdvs.api";
import { CustomPagination } from "../../../common/CustomPagination";
import { IUser } from "../../../../utils/api/user/user.type";
import {
  formatDate,
  formatHour,
  getName,
  getNameCabinet,
  getStatus,
} from "../../../../utils/Utils";
import {
  BtnDeleteRdv,
  StatusBtn,
} from "../../../secretaire/Tables/ActiviteRdvSecretaireTable";
import { IRdvs } from "../../../../utils/api/rdvs/rdvs.type";

function RdvAdminTable({ q }: { q: string }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } = useGetRdvsQuery({
    page,
    q,
    limit,
  });
  const navigate = useNavigate();

  const actionFormatter = (cell: string, row: IRdvs) => {
    return (
      <>
        <div className="d-flex justify-content-center gap-3">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/rendez-vous/${cell}`}
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Voir"
              color={`#099876`}
              state={row}
            >
              <AiOutlineEye />
            </NavLink>
          </div>
          {/* <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/rendez-vous/modifier-rendez-vous/${cell}`}
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Modifier"
              state={row}
            >
              <MdOutlineModeEdit />
            </NavLink>
          </div> */}
          {/* <div className="container-btn-action-icon-modal">
            <BtnDeleteRdv slug={cell} />
          </div> */}
        </div>
      </>
    );
  };
  const statutFormatter = (cell: string, row: IRdvs) => {
    return (
      <>
        <div className="statut-formatter-container d-flex align-items-center gap-2">
          {/* <StatusBtn item={row} /> */}
          <span className={`btn statut-rdv-${cell} with-tooltip`}>
            {getStatus(row)}
          </span>
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "date",
      text: `Date`,
      formatter: (cell: string) => formatDate(cell),
    },
    {
      dataField: "horaire_de_visite",
      text: "Heure de visite",
      formatter: (cell: string) => formatHour(cell),
    },
    {
      dataField: "patient",
      text: "Cabinet",
      formatter: (cell: IUser) => getNameCabinet(cell),
    },
    {
      dataField: "patient",
      text: "Patient",
      formatter: (cell: IUser) => getName(cell),
    },
    {
      dataField: "patient.adresse",
      text: "Adresse",
      formatter: (cell: string) => cell,
    },

    {
      dataField: "motif",
      text: "Motif",
    },
    {
      dataField: "status",
      text: "Statut",
      formatter: statutFormatter,
    },
    // {
    //   dataField: "slug",
    //   text: "Action",
    //   formatter: actionFormatter,
    // },
  ];

  // const navigate = useNavigate();

  const rowEvents = {
    onClick: (e, row: IUser) => {
      navigate(`/admin/rendez-vous/${row?.slug}`, { state: row });
    },
  };

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            rowClasses="custom-table-row-class"
            rowEvents={rowEvents}
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default RdvAdminTable;
