import React from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import TacheMedecinTable from "../Tables/TacheMedecinTable";
import { Tache } from "../../../utils/api/tache/tache.type";
import Swal from "sweetalert2";
import { Color } from "../../../utils/theme";
import { useEditTacheMutation } from "../../../utils/api/tache/tache.api";

function TacheMedecin() {
  return (
    <WrapperComponent>
      <div className="container-title-dash d-flex align-items-center gap-4">
        <h2 className="custom-sm-top-title mb-0">Mes tâches</h2>
      </div>
      <div className="taches-tabs-content mt-5">
        <div className="custom-table mt-5">
          <TacheMedecinTable />
        </div>
      </div>
    </WrapperComponent>
  );
}

export default TacheMedecin;
export function UseEditStatutTache(item: Tache) {
  const [sendData] = useEditTacheMutation();
  const onEdit = async () => {
    await Swal.fire({
      title: `Status de la tache`,
      input: "select",
      inputValue: item?.status,
      inputOptions: {
        todo: "À faire",
        doing: "En cours",
        done: "Terminer",
      },
      inputPlaceholder: "Selectionner un statut",
      showCancelButton: true,
      confirmButtonText: "Modifier",
      cancelButtonText: "Annuler",
      reverseButtons: true,
      confirmButtonColor: Color.success,
      showLoaderOnConfirm: true,

      preConfirm: (status) => {
        if (!status) {
          return Swal.showValidationMessage(`Veuillez sélectionner un statut`);
        }
        return sendData({
          slug: item?.slug,
          status: status,
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le statut de la tache a été modifié avec succès!`,
            iconColor: Color.success,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onEdit;
}
