import React from "react";
import AllDetailPatientMedicamentTable from "../../../Tables/AllDetailPatientMedicamentTable";
import { NavLink } from "react-router-dom";
import { IUser } from "../../../../../utils/api/user/user.type";
import { IRdvs } from "../../../../../utils/api/rdvs/rdvs.type";
import { MdOutlineAddBox } from "react-icons/md";

function Medicaments({ item, rdv }: { item: IUser; rdv?: IRdvs }) {
  return (
    <>
      {!!rdv && rdv?.status !== "annuler" && (
        <div className="add-notes-btn-content d-flex flex-r pb-4">
          <NavLink
            to={`/medecin/ajouter-medicament/${rdv?.slug}`}
            className="add-notes-btn no-link"
            state={rdv}
          >
            <MdOutlineAddBox className="add-icon" />
            Ajouter une ordonnance
          </NavLink>
        </div>
      )}
      <AllDetailPatientMedicamentTable item={item} rdv={rdv} />
    </>
  );
}

export default Medicaments;
