export const regions = [
  {
    label: "Dakar",
    value: "Dakar",
  },
  {
    label: "Diourbel",
    value: "Diourbel",
  },
  {
    label: "Fatick",
    value: "Fatick",
  },
  {
    label: "Kaffrine",
    value: "Kaffrine",
  },
  {
    label: "Kaolack",
    value: "Kaolack",
  },
  {
    label: "kédougou",
    value: "kédougou",
  },
  {
    label: "Kolda",
    value: "Kolda",
  },
  {
    label: "Louga",
    value: "Louga",
  },
  {
    label: "Matam",
    value: "Matam",
  },
  {
    label: "Saint-Louis",
    value: "Saint-Louis",
  },
  {
    label: "Sédhiou",
    value: "Sédhiou",
  },
  {
    label: "Tambacounda",
    value: "Tambacounda",
  },
  {
    label: "Thiès",
    value: "Thiès",
  },
  {
    label: "Ziguinchor",
    value: "Ziguinchor",
  },
];

type Authorization = {
  field: FieldType;
  label: string;
  path: string;
};

export type FieldType =
  | "dashboard"
  | "praticien_habilitation"
  | "specialite_habilitation"
  | "hopitaux_habilitation"
  | "rdv_habilitation"
  | "admin_habilitation";
export const Authorisations: Authorization[] = [
  {
    field: "dashboard",
    label: "Dashboard",
    path: "/admin/dashboard",
  },
  {
    field: "praticien_habilitation",
    label: "Praticiens",
    path: "/admin/praticiens",
  },
  {
    field: "specialite_habilitation",
    label: "Spécialités",
    path: "/admin/specialites",
  },
  {
    field: "rdv_habilitation",
    label: "Rendez-vous",
    path: "/admin/rendez-vous",
  },
  {
    field: "hopitaux_habilitation",
    label: "Hôpitaux/cabinets",
    path: "/admin/cabinets",
  },
  {
    field: "admin_habilitation",
    label: "Administrateurs",
    path: "/admin/parametres?step=administrateurs",
  },
];
