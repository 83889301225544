import React from "react";


function WrapperComponent({children}: {children: any}) {
    return (
        <>
            <div className="dashbord-admin-component">
                <div className="dash-admin-page-content-container mb-3">
                    <div className="content-graph-admin">
                        { children }
                    </div>
                </div>
            </div>
        </>
    )
}

export default WrapperComponent;