/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../utils/type";
import InfosPersonnelles from "./parametreTabs/InfosPersonnelles";
import Disponibilites from "./parametreTabs/Disponibilites";
import { Input } from "../../common/Input";
import { FiEdit } from "react-icons/fi";
import "./ParametreMedecin.css";
import ChangePasswordModal from "./modals/ChangePasswordModal";
import { FaFileSignature } from "react-icons/fa";
import { showModal } from "../../../utils/Utils";
import EditSignatureModal from "./modals/EditSignatureModal";
const steps = [
  { id: "medecin-infos-perso", Component: InfosPersonnelles },
  { id: "medecin-disponibilites", Component: Disponibilites },
];

const tabs = [
  {
    key: "medecin-infos-perso",
    text: "Informations personnelles",
  },
  {
    key: "medecin-disponibilites",
    text: "Mes disponibilités",
  },
];
function ParametreMedecin() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const [modalGererDisponibilite, setModalGererDisponibilite] = useState(false);
  function openModalGererDisponibilite(e: any) {
    e.preventDefault();
    setModalGererDisponibilite(true);
  }
  const [modalChangePassword, setModalChangePassword] = useState(false);
  function openModalChangePassword(e: any) {
    e.preventDefault();
    setModalChangePassword(true);
  }

  const props = {
    modalGererDisponibilite,
    setModalGererDisponibilite,
  };
  return (
    <>
      <WrapperComponent>
        <div className="custom-sm-top">
          <div className="d-flex justify-content-between w-100 align-items-center">
            <h2 className="custom-sm-top-title mb-0">Paramètres</h2>
            <div className="content-btn-theme-title text-end d-flex gap-3">
              <button
                className="btn custom-add-item-btn"
                onClick={() => showModal("editSignatureModal")}
              >
                <span className="add-icon">
                  <FaFileSignature />
                </span>
                Signature
              </button>
              {index === 1 && (
                <button
                  className="btn custom-add-item-btn"
                  onClick={openModalGererDisponibilite}
                >
                  <span>Gérer ma disponibilité</span>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="parametres-tabs-content mt-5">
          <ul className="param-tabs-title-content">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item ${index === key && "tab-item-active"}`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab.text}
              </li>
            ))}
          </ul>
          <div className="custom-table mt-5">
            <Component {...props} />
          </div>
        </div>
      </WrapperComponent>
      {index === 0 ? (
        <div className="mt-4">
          <WrapperComponent>
            <h2 className="custom-sm-pass-title mb-0">Mot de passe</h2>
            <div className="pt-5">
              <form action="">
                <div className="col-md-6">
                  <Input label="Mot de passe" type="password" />
                </div>
              </form>
            </div>

            <button
              className="btn modify-password-btn"
              onClick={openModalChangePassword}
            >
              <span className="icon">
                <FiEdit />
              </span>
              Changer de mot de passe
            </button>
            <ChangePasswordModal
              modalChangePassword={modalChangePassword}
              setModalChangePassword={setModalChangePassword}
            />
          </WrapperComponent>
        </div>
      ) : null}
      <EditSignatureModal />
    </>
  );
}

export default ParametreMedecin;
