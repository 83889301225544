import React from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import { FormError, Input, animatedComponents } from "../../common/Input";
import { BtnSubmit } from "../../common/Button";
import Select from "react-select";
import { useSecretaireFromLocation } from "../../../utils/api/user/user.api";
import UseAddOrEditSecretaireForm from "./form/UseAddOrEditSecretaireForm";
import PhoneInput from "react-phone-input-2";

function AddSecretaireMedicalCabinet() {
  const [item] = useSecretaireFromLocation();
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    option,
    options,
    handleSelectPraticien,
    control,
    Controller,
    phone,
    handleChangePhone,
    setSearch,
  } = UseAddOrEditSecretaireForm(item);
  return (
    <WrapperComponent>
      <div className="content-chart-title mb-4">
        <div className="container-title-dash d-flex align-items-center gap-4">
          <h2 className="custom-sm-top-title mb-0">
            {!item ? "Ajouter" : "Modifier"} une secrétaire
          </h2>
        </div>
      </div>

      <div className="custom-form mt-5">
        <form onSubmit={onSubmit}>
          <div className="row">
            <div className="col-md-6">
              <Input
                label="Prenom"
                type="text"
                placeholder="Prénom"
                required
                {...register("prenom")}
                error={errors?.prenom}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Nom"
                type="text"
                placeholder="Nom"
                required
                {...register("nom")}
                error={errors?.nom}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Adresse mail"
                type="email"
                placeholder="Email"
                required
                {...register("email")}
                error={errors?.email}
              />
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label form-label-modal-custom ">
                  Téléphone{" "}
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                <PhoneInput
                  defaultMask={".. ... .. .."}
                  containerClass={`mb-3 p-0 input-modal-form-add-phone-container`}
                  inputClass={`input-modal-form-add-phone praticiens`}
                  country={"sn"}
                  placeholder=""
                  inputProps={{
                    name: "telephone",
                    required: true,
                    autoFocus: true,
                  }}
                  countryCodeEditable={false}
                  enableAreaCodes={true}
                  prefix="+"
                  value={phone}
                  onChange={(phone, country: any) =>
                    handleChangePhone(phone, country)
                  }
                  inputStyle={{
                    width: "100%",
                    background: "rgba(232, 240, 253, 0.7)",
                  }}
                />
                <FormError error={errors?.telephone?.message} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label form-label-register-tabs">
                  Assigné à un médecin
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>

                <Controller
                  name="praticien"
                  control={control}
                  render={({ field }: any) => {
                    return (
                      <Select
                        {...field}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        placeholder="Assigné à un médecin"
                        options={options}
                        onInputChange={(val) => setSearch(val)}
                        classNamePrefix="react-select-option-custom"
                        onChange={handleSelectPraticien}
                        value={option}
                      />
                    );
                  }}
                />
                <FormError error={errors?.praticien} />
              </div>
            </div>
          </div>
          <div className="d-flex flex-r mt-4">
            <div className="col-md-2">
              <BtnSubmit label="Enregistrer" isLoading={isLoading} />
            </div>
          </div>
        </form>
      </div>
    </WrapperComponent>
  );
}

export default AddSecretaireMedicalCabinet;
