import React, { useEffect, useState } from "react";
import "./RdvAdmin.css";
import RdvAdminTable from "./RdvAdminTable/RdvAdminTable";
import ActiviteRdv from "../../shared/ActiviteRdv/ActiviteRdv";
import { useNavigate } from "react-router-dom";
import { hasAccess } from "../../../routes/routerUtils";
import { useUser } from "../../../utils/Utils";

function RdvAdmin() {
  const [query, setQuery] = useState("");
  const user = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && hasAccess(user, "rdv_habilitation")) {
      navigate("/admin/parametres");
    }
  }, [user]);
  return (
    <>
      <ActiviteRdv
        setQuery={setQuery}
        tableComponent={<RdvAdminTable q={query} />}
      />
    </>
  );
}

export default RdvAdmin;
