import { configureStore } from "@reduxjs/toolkit";
import { AuthApi } from "../utils/api/auth/auth.api";
import { UserApi } from "../utils/api/user/user.api";
import { currentEnv, Env } from "../utils/http";
import { UserSlice } from "./slice/User.slice";
import { NotificationApi } from "../utils/api/notification/notification.api";
import { SpecialiteApi } from "../utils/api/specialite/specialite.api";
import { RdvsApi } from "../utils/api/rdvs/rdvs.api";
import { DossierApi } from "../utils/api/dossier/dossier.api";
import { OrdonnanceApi } from "../utils/api/ordonnance/ordonnance.api";
import { AnalyseApi } from "../utils/api/analyse/analyse.api";
import { ScannerApi } from "../utils/api/scanner/scanner.api";
import { NoteApi } from "../utils/api/note/note.api";
import { VaccinApi } from "../utils/api/vaccin/vaccin.api";
import { DocumentApi } from "../utils/api/document/document.api";
import { ChatApi } from "../utils/api/chat/chat.api";
import { DisponibiliteApi } from "../utils/api/disponibilite/disponibilite.api";
import { TarifApi } from "../utils/api/tarif/tarif.api";
import { FacturationApi } from "../utils/api/facturation/facturation.api";
import { DashboardApi } from "../utils/api/dashboard/dashboard.api";
import { TacheApi } from "../utils/api/tache/tache.api";

const store = configureStore({
  reducer: {
    [AuthApi.reducerPath]: AuthApi.reducer,
    [UserSlice.name]: UserSlice.reducer,
    [UserApi.reducerPath]: UserApi.reducer,
    [NotificationApi.reducerPath]: NotificationApi.reducer,
    [SpecialiteApi.reducerPath]: SpecialiteApi.reducer,
    [RdvsApi.reducerPath]: RdvsApi.reducer,
    [DossierApi.reducerPath]: DossierApi.reducer,
    [OrdonnanceApi.reducerPath]: OrdonnanceApi.reducer,
    [AnalyseApi.reducerPath]: AnalyseApi.reducer,
    [ScannerApi.reducerPath]: ScannerApi.reducer,
    [NoteApi.reducerPath]: NoteApi.reducer,
    [VaccinApi.reducerPath]: VaccinApi.reducer,
    [DocumentApi.reducerPath]: DocumentApi.reducer,
    [ChatApi.reducerPath]: ChatApi.reducer,
    [DisponibiliteApi.reducerPath]: DisponibiliteApi.reducer,
    [TarifApi.reducerPath]: TarifApi.reducer,
    [FacturationApi.reducerPath]: FacturationApi.reducer,
    [DashboardApi.reducerPath]: DashboardApi.reducer,
    [TacheApi.reducerPath]: TacheApi.reducer
  },
  devTools: Env === currentEnv,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    AuthApi.middleware,
    UserApi.middleware,
    NotificationApi.middleware,
    SpecialiteApi.middleware,
    RdvsApi.middleware,
    DossierApi.middleware,
    OrdonnanceApi.middleware,
    AnalyseApi.middleware,
    ScannerApi.middleware,
    NoteApi.middleware,
    VaccinApi.middleware,
    DocumentApi.middleware,
    ChatApi.middleware,
    DisponibiliteApi.middleware,
    TarifApi.middleware,
    FacturationApi.middleware,
    DashboardApi.middleware,
    TacheApi.middleware
  ],
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export default store;
