import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Dashboard } from "../../../../utils/api/dashboard/dashboard.type";
import moment from "moment";
import { formatCurrency } from "../../../../utils/Utils";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function Revenus({
  graph,
  date,
  type,
}: {
  graph: Dashboard["lastGainGraph"];
  isLoading: boolean;
  type: string;
  date: Date;
}) {
  const [previousDate, setPreviousDate] = useState<any>(null);

  useEffect(() => {
    if (type === "week") {
      setPreviousDate(moment(date).subtract(7, "days").startOf("isoWeek"));
    }

    if (type === "month") {
      setPreviousDate(moment(date).subtract(1, "months").startOf("month"));
    }

    if (type === "year") {
      setPreviousDate(moment(date).subtract(1, "years").startOf("year"));
    }
  }, [date, type]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
    },
  };

  const labels = graph?.map((el) => el?.date);

  const data = {
    labels,
    datasets: [
      {
        type: "line" as const,
        label: `Revenus  ${type === "week" ? "Semaine du " : ""} ${moment(
          previousDate
        ).format(
          type === "year"
            ? "YYYY"
            : type === "month"
            ? "MMMM YYYY"
            : "DD/MM/YYYY"
        )}`,
        borderColor: "#099876",
        borderWidth: 2,
        fill: false,
        data: graph?.map((el) => el?.gain),
        lineTension: 0.3,
      },
    ],
  };

  let somme = graph?.reduce((acc, t) => {
    return acc + t?.gain;
  }, 0);

  return (
    <div className="chart-item-container">
      <div className="content-chart-title mb-4">
        <h2 className="chart-item-admin-title mb-0">
          {" "}
          {formatCurrency(somme)}{" "}
        </h2>
        <div className="content-select-date-graph">
          <p className="text-subtitle-graph">{`Revenus  ${
            type === "week" ? "Semaine du " : ""
          } ${moment(previousDate).format(
            type === "year"
              ? "YYYY"
              : type === "month"
              ? "MMMM YYYY"
              : "DD/MM/YYYY"
          )}`}</p>
        </div>
      </div>
      <Line options={options} data={data} />
    </div>
  );
}

export default Revenus;
