type ENV = "dev" | "prod" | "demo";

export const currentEnv: ENV = "prod";

const env: ENV = currentEnv;

export const Env = env;

const API_DEV_URL = "https://excelsis-api.volkeno-engineering.click";
const API_DEMO_URL = "https://api.excelsis-medical.com";
const API_PROD_URL = "https://api.excelsis-medical.com";

export const APP_URL_DEV = "https://excelsis.volkeno-engineering.click/";
export const APP_URL_DEMO = "https://excelsis-medical.com/";
export const APP_URL_PROD = "https://excelsis-medical.com/";

const API_PROD_SOCKET_URL = "https://www.socket.excelsis-medical.com";

function processApiUrl() {
  if (env === "prod") return API_PROD_URL;
  if (env === "demo") return API_DEMO_URL;
  return API_DEV_URL;
}

function processAppUrl() {
  if (env === "prod") return APP_URL_PROD;
  if (env === "demo") return APP_URL_DEMO;
  return APP_URL_DEV;
}
export const ApiBaseUrl = processApiUrl();
export const AppBaseUrl = processAppUrl();
export const SocketUrl: string = API_PROD_SOCKET_URL;
export const GoogleApikey = "AIzaSyD0klc1PQS8QK--Be3rF3i8kW_idy04AVQ";
