/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import "../Secretaire.css";
import { NavLink, useLocation } from "react-router-dom";
import { AllPraticienSecretaireData } from "../Tables";
import "./PraticienSecretaire.css";
import { MdOutlineAddBox } from "react-icons/md";
import { isCabinet, isSecretaire } from "../../../routes/routerUtils";
import { useAppSelector } from "../../../redux/hook";
import { useGetPraticienByCabinetQuery } from "../../../utils/api/user/user.api";
import { AlertInfo } from "../../common/Alert";
import { PraticienItem } from "../../admin/PraticiensAdmin/PraticiensAdmin";
import { CustomPagination } from "../../common/CustomPagination";
import Skeleton from "react-loading-skeleton";


function PraticienSecretaire() {
  const { user } = useAppSelector((s) => s?.user);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(16);
  const { data = { results: [], count: 0 }, isLoading,refetch } =
    useGetPraticienByCabinetQuery({
      slug: isSecretaire(user) ? user?.structure?.slug : user?.slug,
      page,
      limit,
      q: query
    });

    useEffect(() => {
      if (!isLoading) refetch();
    }, []);

  return (
    <WrapperComponent>
      <div className="container-title-dash d-flex align-items-center gap-4 pb-4 justify-content-between">
        <h2 className="custom-sm-top-title mb-0">Praticiens</h2>
        {!!isCabinet(user) && (
          <NavLink
            to={"/cabinet/praticiens/ajouter-praticien"}
            className="btn custom-add-item-btn"
          >
            <span className="add-icon pe-2">
              <MdOutlineAddBox />
            </span>
            <span>Ajouter un praticien</span>
          </NavLink>
        )}
      </div>
      <div className="search-patient-box d-flex align-items-center gap-3 mt-4 mb-4 ">
        <input
          type="search"
          name="search_patient"
          id="search_patient"
          placeholder="Rechercher un praticien"
          onChange={(e) => setQuery(e?.target?.value)}
        />
      </div>

      <div className="custom-content pt-4 pb-4">
        <div className="row">
          {!!isLoading && <PraticienSkeleton />}
          {!isLoading &&
            (data?.results?.length ? (
              data?.results?.map((item) => (
                <div
                  className="col-lg-3 col-md-6 col-praticien-item text-center d-flex"
                  key={item.id}
                >
                  <PraticienItem item={item} />
                </div>
              ))
            ) : (
              <AlertInfo message="Aucune donnée trouvée !" />
            ))}
          <div className="col-md-12">
            <div className="custom-pagination-container">
              <CustomPagination
                nbPages={data?.count}
                page={page}
                onChange={(page, perPage) => {
                  setLimit(perPage);
                  setPage(page);
                }}
                perPage={limit}
                showPerPage={false}
              />
            </div>
          </div>
        </div>
      </div>
    </WrapperComponent>
  );
}

export default PraticienSecretaire;

export const PraticienSkeleton = () => {
  return (
    <>
      {[...Array(16)]?.map((el, i) => (
        <div
          className="col-lg-3 col-md-6 col-praticien-item text-center d-flex"
          key={i}
        >
          <div className="content-col-praticien-item w-100">
            <div className="content-img-profil-praticien py-3">
              <Skeleton
                width={100}
                height={100}
                circle
                className="img-profil-praticien"
              />
            </div>
            <Skeleton className="name-praticien-admin" width={`90%`} />
            <Skeleton className="poste-pratiien-admin" width={`75%`} />
            <div className="container-btn-viez-profil-praticien pt-3">
              <Skeleton className="btn btn-view-profil-praticien" />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
