import React, { useEffect, useState } from "react";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../common/TableSkeleton";
import "./Table.css";
import { useGetAnalyseByPatientQuery } from "../../../utils/api/analyse/analyse.api";
import { IUser } from "../../../utils/api/user/user.type";
import { CustomPagination } from "../../common/CustomPagination";
import { Analyse } from "../../../utils/api/analyse/analyse.type";
import { formatDate, getFile, getName, showModal } from "../../../utils/Utils";
import { UseEditStatutAnalyse } from "../rdvMedecin/form/UseAddAnalyseForm";
import { MdAttachFile } from "react-icons/md";
import { IoDocumentAttachOutline } from "react-icons/io5";
import JointResultsFileModal from "../Modals/JointResultsFileModal";
import ShowFileModal from "../../shared/modal/ShowFileModal";

const ChangeStatusBtn = ({ item }: { item: Analyse }) => {
  const onEdit = UseEditStatutAnalyse(item);
  return (
    <span
      className={`status ${
        item?.status === "en_cours" ? "on-going-color" : "finished-color"
      } linkable`}
      onClick={onEdit}
    >
      {item?.status === "en_cours" ? "En cours" : "Terminé"}
    </span>
  );
};

function AllDetailPatientResultatTable({ item }: { item: IUser }) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data = { results: [], count: 0 }, isLoading, refetch } =
    useGetAnalyseByPatientQuery({
      slug: item?.slug,
      page,
      limit: perPage,
    });
    useEffect(() => {
      if (!isLoading) refetch();
    }, []);

  const statusFormatter = (cell: "en_cours" | "terminer", row: Analyse) => {
    return (
      <>
        <div className="status-formatter-container">
          <ChangeStatusBtn item={row} />
        </div>
      </>
    );
  };

  const resultFormatter = (cell: string, row: Analyse) => {
    return (
      <>
        <div className="results-btn">
          {/* <span>Résultat</span> */}
          {!cell && (
            <button
              className="btn join-file-btn px-0"
              onClick={() => showModal(`jointModal${row?.slug}`)}
            >
              <MdAttachFile className="attach-icon" />
              Joindre le résultat
            </button>
          )}
          {!!cell && (
            <button
              className="btn join-file-btn px-0"
              onClick={() => showModal(`fileJointModal${row?.slug}`)}
            >
              <IoDocumentAttachOutline className="attach-icon-joint" />
              Résultat
            </button>
          )}
        </div>
        <JointResultsFileModal modalId={`jointModal${row?.slug}`} item={row} />
        <ShowFileModal
          modalId={`fileJointModal${row?.slug}`}
          file={getFile(cell)}
          title={`Résultat de l'analyse ${row?.type_analyse}`}
        />
      </>
    );
  };

  const columns = [
    {
      dataField: "created_at",
      text: `Date`,
      formatter: (cell: string) => formatDate(cell),
    },
    {
      dataField: "type_analyse",
      text: "Examen",
    },
    {
      dataField: "status",
      text: "Statut",
      formatter: statusFormatter,
    },
    {
      dataField: "resultat",
      text: "Résultat",
      formatter: resultFormatter,
      headerStyle: () => {
        return { width: "200px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "rdv.medecin",
      text: "Médecin",
      formatter: (cell: IUser) => getName(cell),
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            condensed
            hover
            wrapperClasses="table-responsive medecin-patients-detail-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  );
}

export default AllDetailPatientResultatTable;
