import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";
import { Analyse, AnalyseFormData } from "./analyse.type";
import { prepareHeaders } from "../user/user.api";

export const AnalyseApi = createApi({
  reducerPath: "analyse",
  tagTypes: ["analyse", "analyseList", "analyseByPatient"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getAnalyse: builder.query<PaginationResults<Analyse>, TypeQuery>({
      query: (query) => QueryUrl(`analyses/`, query),
      providesTags: ["analyseList"],
    }),
    analyseBySlug: builder.query<Analyse, string>({
      query: (slug) => `analyse/${slug}/`,
      providesTags: ["analyse"],
    }),
    addOrUpdateAnalyse: builder.mutation<
      Analyse,
      { slug?: string; data: AnalyseFormData | FormData }
    >({
      query: ({ slug, data }) => ({
        url: slug ? `analyses/${slug}/` : `analyses/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["analyse", "analyseByPatient", "analyseList"],
    }),
    deleteAnalyse: builder.mutation<AnalyseFormData, string>({
      query: (slug) => ({
        url: `analyses/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["analyse", "analyseByPatient", "analyseList"],
    }),
    getAnalyseByPatient: builder.query<PaginationResults<Analyse>, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`patient/${slug}/analyses/`, query),
      providesTags: ["analyseByPatient"],
    }),
  }),
});

export const {
  useAddOrUpdateAnalyseMutation,
  useAnalyseBySlugQuery,
  useDeleteAnalyseMutation,
  useGetAnalyseByPatientQuery,
  useGetAnalyseQuery,
} = AnalyseApi;
