import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleannerError, useUser } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import {
  Disponibilite,
  DisponibiliteFormData,
} from "../../../../utils/api/disponibilite/disponibilite.type";
import { useAddOrUpdateDisponibiliteMutation } from "../../../../utils/api/disponibilite/disponibilite.api";
import moment from "moment";

function UseAddOrEditDisponibiliteForm(setShow, item?: Disponibilite) {
  const validationSchema = yup.object().shape({
    date_debut: yup.string().required().label("La date de début"),
    date_fin: yup.string().required().label("La date de fin"),
    start_time: yup.string().required().label("L'heure de début"),
    end_time: yup.string().required().label("L'heure de fin"),
    duree: yup
      .number()
      .required()
      .label("La durée")
      .transform((val: any) => (isNaN(val) ? "" : val)),
    jours: yup
      .array()
      .of(yup.string().required())
      .label("La liste des jours")
      .required()
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    setError,
  } = useForm<DisponibiliteFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [days, setDays] = useState<string[]>([]);
  const [hours, setHours] = useState<string[]>([]);
  const user = useUser();

  const [sendData, { isLoading, isSuccess, isError, error }] =
    useAddOrUpdateDisponibiliteMutation();

  const handleReset = () => {
    setShow(false);
    if (!item) {
      reset();
      setDays([]);
      setHours([]);
    }
  };
  const onChangeDays = (e: React.ChangeEvent<HTMLInputElement>) => {
    let frequences: string[];
    let targetValue = e.currentTarget.value;
    if (e.currentTarget.checked) {
      frequences = [...days, targetValue];
      setDays(frequences);
      setValue("jours", frequences);
    } else {
      frequences = days?.filter((item) => item !== targetValue);
      setDays(frequences);
      setValue("jours", frequences);
    }
  };

  const onChangeHours = (e: React.ChangeEvent<HTMLInputElement>) => {
    let heures: string[];
    let targetValue = e.currentTarget.value;
    if (e.currentTarget.checked) {
      heures = [...hours, targetValue];
      setHours(heures);
      setValue("horaires", heures);
    } else {
      heures = hours?.filter((item) => item !== targetValue);
      setHours(heures);
      setValue("horaires", heures);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "La disponibilité a été mise à jour avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        handleReset();
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    // console.log("cabinet", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (item?.id) {
      let fields: (keyof DisponibiliteFormData)[] = [
        "slug",
        "medecin",
        "date_debut",
        "date_fin",
        "jours",
        "duree",
        "start_time",
        "end_time",
      ];
      setDays(item?.jours);

      for (let field of fields) {
        register(field);
        if (field !== "medecin") {
          setValue(field, item[field]);
        } else {
          if (item[field]) setValue(field, item[field]?.id);
        }
      }
    }
  }, [item]);

  const onSubmit = async (data: DisponibiliteFormData) => {
    if (!item) data["medecin"] = user?.id;
    if (moment(data?.date_debut).isAfter(data?.date_fin)) {
      setError("date_fin", {
        message: "La date de fin doit être après la date de début",
      });
    } else {
      await sendData(data);
    }
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    days,
    hours,
    onChangeHours,
    onChangeDays,
    handleReset,
  };
}

export default UseAddOrEditDisponibiliteForm;

export function UseEditDisponibilite(
  data: { start: Date; end: Date },
  item: Disponibilite,
  isAvailable: boolean
) {
  const [sendData] = useAddOrUpdateDisponibiliteMutation();
  let dataToSend = {
    slug: item?.slug,
    indisponibilite_dates:
      !!item &&
      (isAvailable
        ? [...item?.indisponibilite_dates, data]
        : item?.indisponibilite_dates?.filter(
            (el) => el?.start !== data?.start?.toISOString()
          )),
  };

  const onEdit = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir marquer cette date comme une ${
        !isAvailable ? "disponibilité" : "indisponibilité"
      } ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData(dataToSend);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `L'agenda a été modifié avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onEdit;
}
