import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";

import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";
import { Dashboard } from "./dashboard.type";

export const DashboardApi = createApi({
  reducerPath: "dashboardApi",
  tagTypes: ["dashboard"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getDashboard: builder.query<Dashboard, TypeQuery>({
      query: ({ slug, type, ...query }) =>
        QueryUrl(type !== "admin" ? `${type}/${slug}/dashboard/` : `dashboard/`, query),
      providesTags: ["dashboard"],
    }),
  }),
});

export const { useGetDashboardQuery } = DashboardApi;
