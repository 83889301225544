import React from "react";
import { FormError, Input, animatedComponents } from "../../common/Input";
import { SelectInput } from "../../common/SelectInput";
import { HiOutlineCamera } from "react-icons/hi2";
import { usePraticienFromLocation } from "../../../utils/api/user/user.api";
import UseAddOrEditPraticienForm from "./requestForm/UseAddOrEditPraticienForm";
import Select, { components } from "react-select";
import { getAvatar } from "../../../utils/Utils";
import PhoneInput from "react-phone-input-2";
import { useAppSelector } from "../../../redux/hook";
import { isCabinet } from "../../../routes/routerUtils";
import GoogleInput from "../../common/GoogleInput";

export const selectOptions = [
  {
    label: "Homme",
    value: "homme",
  },
  {
    label: "Femme",
    value: "femme",
  },
];
function AddOrUpdatePraticienAdmin() {
  const { user } = useAppSelector((s) => s?.user);
  const [item] = usePraticienFromLocation();
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    phone,
    handleChangePhone,
    control,
    Controller,
    handleSelectSpecialite,
    specialite,
    specialites,
    options,
    option,
    handleSelectCabinet,
    setSearch,
    onChange,
    avatar,
    onChangeAddress,
    address,
    addOptionIfNeeded,
  } = UseAddOrEditPraticienForm(item);

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="content-graph-admin page-title-container mb-2">
          <div className="chart-item-admin-title mb-0">
            {!item ? "Ajouter" : "Modifier"} un praticien
          </div>
          <div className="form-add-container mt-4">
            <form className="mt-4" onSubmit={onSubmit}>
              <div className="d-flex justify-content-center align-items-center mb-4 leader-auto-user-avatar-container">
                <div>
                  <label htmlFor="input-file" className="input-testt">
                    <img
                      src={
                        avatar
                          ? URL.createObjectURL(avatar)
                          : getAvatar(item?.avatar)
                      }
                      className="custom-img-profil-user-admin mon-profil-img"
                      alt="Photo"
                    />

                    <div className="leader-auto-user-avatar-edit-hover-deux">
                      <HiOutlineCamera className="edit-avatar-camera-icon" />
                    </div>
                  </label>
                  <form className="mt-4">
                    <input
                      type="file"
                      className="input-add-avatar"
                      id="input-file"
                      onChange={onChange}
                      accept="image/*"
                    />
                  </form>
                  <p className="libelle-input-file-pp-praticien">
                    Ajouter une photo
                  </p>
                </div>
              </div>
              <p className="title-info-perso-praticien">
                Informations personnelles
              </p>
              <div className="row row-add-student my-3">
                <div className="col-md-4">
                  <Input
                    type="text"
                    label="Prénoms"
                    id="prenom"
                    placeholder="Prénoms"
                    required
                    {...register("prenom")}
                    error={errors?.prenom}
                  />
                </div>
                <div className="col-md-4">
                  <Input
                    type="text"
                    label="Nom"
                    id="nom"
                    placeholder="Nom"
                    required
                    {...register("nom")}
                    error={errors?.nom}
                  />
                </div>
                <div className="col-md-4">
                  <SelectInput
                    label="Genre"
                    options={selectOptions}
                    required
                    {...register("civilite")}
                    error={errors?.civilite}
                  />
                </div>
                <div className="col-md-4">
                  {/* <Input
                    type="date"
                    label="Date de naissance "
                    id="date"
                    placeholder="Date de naissance "
                    {...register("date_de_naissance")}
                    error={errors?.date_de_naissance}
                  /> */}
                  <div className="mb-3">
                    <label className="form-label form-label-modal-custom">
                      Adresse{" "}
                      <span className="text-danger" style={{ fontSize: 15 }}>
                        *
                      </span>
                    </label>
                    <GoogleInput
                      className="form-control form-control-modal-custom"
                      value={address}
                      placeholder="Adresse"
                      onChange={(val) => onChangeAddress(val)}
                    />
                    <FormError error={errors?.adresse?.message} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label className="form-label form-label-modal-custom ">
                      Téléphone{" "}
                      <span className="text-danger" style={{ fontSize: 15 }}>
                        *
                      </span>
                    </label>
                    <PhoneInput
                      defaultMask={".. ... .. .."}
                      containerClass={`mb-3 p-0 input-modal-form-add-phone-container`}
                      inputClass={`input-modal-form-add-phone praticiens`}
                      country={"sn"}
                      placeholder=""
                      inputProps={{
                        name: "telephone",
                        required: true,
                        autoFocus: true,
                      }}
                      countryCodeEditable={false}
                      enableAreaCodes={true}
                      prefix="+"
                      value={phone}
                      onChange={(phone, country: any) =>
                        handleChangePhone(phone, country)
                      }
                      inputStyle={{
                        width: "100%",
                        background: "rgba(232, 240, 253, 0.7)",
                      }}
                    />
                    <FormError error={errors?.telephone?.message} />
                  </div>
                </div>
                <div className="col-md-4">
                  <Input
                    type="email"
                    label="Email"
                    id="email"
                    placeholder="Email"
                    required
                    {...register("email")}
                    error={errors?.email}
                  />
                </div>
              </div>
              <p className="title-info-perso-praticien pt-4">
                Informations professionnelles
              </p>
              <div className="row row-add-student my-3">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label className="form-label form-label-register-tabs">
                      Spécialité
                      <span className="text-danger" style={{ fontSize: 15 }}>
                        *
                      </span>
                    </label>

                    <Controller
                      name="specialite"
                      control={control}
                      render={({ field }: any) => {
                        return (
                          <Select
                            {...field}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            placeholder="Sélectionnez une spécialité"
                            options={specialites}
                            onInputChange={(val) => addOptionIfNeeded(val)}
                            classNamePrefix="react-select-option-custom"
                            onChange={handleSelectSpecialite}
                            value={specialite}
                            // onBlur={(val) => addOptionIfNeeded(val)}
                          />
                        );
                      }}
                    />
                    <FormError error={errors?.specialite} />
                  </div>
                </div>
                <div className="col-md-4">
                  <Input
                    type="text"
                    label="Numéro de licence"
                    id="numero"
                    placeholder="Numéro de licence"
                    {...register("numero_de_licence")}
                    error={errors?.numero_de_licence}
                  />
                </div>
                <div className="col-md-4">
                  <Input
                    type="text"
                    label="Année d’obtention"
                    id="date"
                    placeholder="Année d’obtention"
                    {...register("annee_obtention")}
                    error={errors?.annee_obtention}
                  />
                </div>
                <div className="col-md-4">
                  <Input
                    type="number"
                    min={0}
                    label="Expérience"
                    id="experience"
                    placeholder="Expérience"
                    {...register("experience")}
                    error={errors?.experience}
                  />
                </div>
                {!isCabinet(user) && (
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label className="form-label form-label-register-tabs">
                        Hôpital ou clinique affilié(e)
                        <span className="text-danger" style={{ fontSize: 15 }}>
                          *
                        </span>
                      </label>

                      <Controller
                        name="structure"
                        control={control}
                        render={({ field }: any) => {
                          return (
                            <Select
                              {...field}
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              placeholder="Hôpital ou clinique affilié(e)"
                              options={options}
                              onInputChange={(val) => setSearch(val)}
                              classNamePrefix="react-select-option-custom"
                              onChange={handleSelectCabinet}
                              value={option}
                            />
                          );
                        }}
                      />
                      <FormError error={errors?.structure} />
                    </div>
                  </div>
                )}

                <div className="col-md-3 offset-md-9 auth-submit-btn-container text-end pt-5">
                  <button
                    className="btn custom-add-item-btn w-100"
                    disabled={isLoading}
                  >
                    {!isLoading ? (
                      `Enregistrer`
                    ) : (
                      <>
                        <span
                          className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>Chargement...</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrUpdatePraticienAdmin;
