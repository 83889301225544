import React, { useEffect } from "react";
import WrapperComponent from "../../../shared/WrapperComponent";
import { useStepType } from "../../../../utils/type";
import { useStep } from "react-hooks-helper";
import Notes from "../../patientMedecin/patientDetail/patientDetailsTabs/Notes";
import RendezVous from "../../patientMedecin/patientDetail/patientDetailsTabs/RendezVous";
import Resultats from "../../patientMedecin/patientDetail/patientDetailsTabs/Resultats";
import Medicaments from "../../patientMedecin/patientDetail/patientDetailsTabs/Medicaments";
import Vaccins from "../../patientMedecin/patientDetail/patientDetailsTabs/Vaccins";
import Dossier from "../../patientMedecin/patientDetail/patientDetailsTabs/Dossier";
import {
  useAddOrUpdateRdvMutation,
  useRdvFromLocation,
} from "../../../../utils/api/rdvs/rdvs.api";
import { SideBarPatientDetail } from "../../patientMedecin/patientDetail/PatientDetail";
import { MdOutlineAddBox } from "react-icons/md";
import { NavLink, useSearchParams } from "react-router-dom";
import { IoDocumentLockOutline } from "react-icons/io5";
import { UseCloseRdv } from "../../../secretaire/rdvSecretaire/form/UseAddOrEditRdvForm";
import Swal from "sweetalert2";
import { useGetNoteByRdvQuery } from "../../../../utils/api/note/note.api";
import Historique from "../../patientMedecin/patientDetail/patientDetailsTabs/Historique";

const steps = [
  { id: "notes", Component: Notes },
  { id: "rendez-vous", Component: RendezVous },
  { id: "resultat", Component: Resultats },
  { id: "medicament", Component: Medicaments },
  { id: "vaccin", Component: Vaccins },
  { id: "dossier", Component: Dossier },
  { id: "historique", Component: Historique },
];
const tabs = [
  "Notes",
  "Rendez-vous",
  "Résultats",
  "Médicaments",
  "Vaccins",
  "Dossier",
  "Antécédents",
];

function ConsultationPage() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  let [searchParams] = useSearchParams();
  let key = searchParams.get("step") || "";
  const [item] = useRdvFromLocation();
  const onClose = UseCloseRdv(item);
  const { data = { results: [] } } = useGetNoteByRdvQuery({ slug: item?.slug });
  const [onEdit] = useAddOrUpdateRdvMutation();

  useEffect(() => {
    if (item?.status === "en_attente") {
      onEdit({ slug: item?.slug, data: { status: "en_cours" } });
    }
  }, [item]);

  const props = {
    item: item?.patient,
    rdv: item,
  };

  useEffect(() => {
    if (key) {
      go(key);
    }
  }, [key]);

  const constante = {
    // poids: item?.poids,
    pouls: item?.pouls,
    // temperature: item?.temperature,
    // taille: item?.taille,
    tension: item?.tension,
  };

  const handleClose = () => {
    if (Object?.values(constante)?.every((el) => el) && data?.results?.length) {
      // console.log("valid", Object?.values(constante));
      onClose();
    } else {
      // console.log("not valid", Object?.values(constante));
      Swal.fire({
        icon: "warning",
        title: `Veuillez renseigner les constantes et la note pour pouvoir clôturer la consultation`,
        showConfirmButton: true,
        confirmButtonText: "Fermer",
      });
    }
  };

  return (
    <WrapperComponent>
      <div className="row">
        <div className="col-md-2">
          <SideBarPatientDetail item={item?.patient} rdv={item} />
        </div>
        <div className="col-md-10 ajust-content">
          <div className="pt-2 pb-2 d-flex justify-content-end gap-3">
            {item?.status !== "annuler" && (
              <div className="content-btn-theme-title text-end">
                <NavLink
                  className="btn custom-add-item-btn"
                  to={`/medecin/ajouter-scanner/${item?.slug}`}
                  state={item}
                >
                  <span className="add-icon">
                    <MdOutlineAddBox />
                  </span>
                  Ajouter une demande de scanner
                </NavLink>
              </div>
            )}

            {item?.status !== "terminer" && item?.status !== "annuler" && (
              <div className="content-btn-theme-title text-end">
                <button
                  className="btn custom-close-rdv-btn"
                  onClick={() => handleClose()}
                >
                  <span className="add-icon">
                    <IoDocumentLockOutline />
                  </span>
                  Clôturer le rendez-vous
                </button>
              </div>
            )}
          </div>
          <div className="patient-detail-tabs-content mt-5">
            <ul className="tabs-title-content detail-patient-tabs">
              {tabs.map((tab, key) => (
                <li
                  className={`tab-item ${index === key && "tab-item-active"}`}
                  key={key}
                  onClick={() => go(key)}
                  role="button"
                >
                  {tab}
                </li>
              ))}
            </ul>
            <div className="patient-detail-content custom-table-content mt-5">
              <Component {...props} />
            </div>
          </div>
        </div>
      </div>
    </WrapperComponent>
  );
}

export default ConsultationPage;
