import React, { useState } from "react";
import "./Login.css";
import Logo from "../../assets/appImages/logo.png";
import { NavLink } from "react-router-dom";
import { UseForgetPasswordForm } from "./requestForm/UseForgetPasswordForm";
import { FormError } from "../common/Input";

const ForgetPassword = () => {
  const { register, errors, isLoading, onSubmit } = UseForgetPasswordForm();
  return (
    <div>
      <div className="empty-header-auth">
        <div className="content-img-logo-login-app">
          <img src={Logo} alt="Logo" className="img-logo-login-app" />
        </div>
      </div>
      <div className="container-page-login-app">
        <div className="auth-row">
          <div className="auth-col auth-right-side-col">
            <div className="auth-right-side-container">
              <div className="auth-form-container text-center pb-4">
                <h2 className="title-auth-form-text pt-5">
                  Mot de passe oublié{" "}
                </h2>
                <div className="mt-5">
                  <form id="auth-form" onSubmit={onSubmit}>
                    <div className="row auth-form-row">
                      <div className="col-md-12 auth-input-col mb-3">
                        <div className="auth-form-group">
                          <label className="form-label form-label-auth-login">
                            Adresse email
                          </label>
                          <input
                            type={`email`}
                            className="form-control auth-form-control"
                            id="email-or-username"
                            aria-label="Email"
                            placeholder="Email"
                            {...register("email")}
                          />
                          <FormError error={errors?.email?.message} />
                        </div>
                      </div>
                      <div className="col-md-12 auth-submit-btn-container mt-5">
                        <button
                          type="submit"
                          className="btn auth-submit-btn trans-0-2"
                          disabled={isLoading}
                        >
                          {!isLoading ? (
                            `Envoyer`
                          ) : (
                            <>
                              <span
                                className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                                role="status"
                              ></span>
                              <span>Chargement...</span>
                            </>
                          )}
                        </button>
                      </div>
                      <div className="col-md-12 mt-3 text-right">
                        <span className="mt-2">
                          <NavLink
                            className="btn forget-password-link"
                            to="/"
                            data-tooltip-content="Gestionnaires"
                          >
                            Se connecter
                          </NavLink>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
