import React from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import { useUser } from "../../../utils/Utils";
import { HistoryItem } from "../../medecin/patientMedecin/patientDetail/patientDetailsTabs/Historique";

function HistoryPatient() {
  const user = useUser();
  return (
    <WrapperComponent>
      <div className="container-title-dash d-flex align-items-center gap-4 pb-4">
        <h2 className="custom-sm-top-title mb-0">Mes antécédents médicaux</h2>
      </div>

      <div className="custom-table pt-4 pb-4">
        <HistoryItem item={user} />
      </div>
    </WrapperComponent>
  );
}

export default HistoryPatient;
