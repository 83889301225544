import React, { useEffect, useState } from "react";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../common/TableSkeleton";
import "./Table.css";
import { useGetVaccinByPatientQuery } from "../../../utils/api/vaccin/vaccin.api";
import { formatDate, useUser } from "../../../utils/Utils";
import { CustomPagination } from "../../common/CustomPagination";
import { IUser } from "../../../utils/api/user/user.type";
import { IRdvs } from "../../../utils/api/rdvs/rdvs.type";
import { Vaccin } from "../../../utils/api/vaccin/vaccin.type";
import { MdOutlineModeEdit } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { isMedecin } from "../../../routes/routerUtils";
import { UseEditStatutVaccin } from "../rdvMedecin/form/UseAddVaccinForm";

const BtnStatus = ({ item }: { item: Vaccin }) => {
  const onEdit = UseEditStatutVaccin(item);
  const user = useUser();
  return (
    <>
      <span
        className={`btn statut-rdv-${
          item?.status === true ? "terminer" : "annuler"
        } with-tooltip`}
        onClick={() => {
          if (isMedecin(user)) {
            onEdit();
          }
        }}
      >
        {item?.status ? "Administré" : "Non administré"}
      </span>
    </>
  );
};

function AllDetailPatientVaccinTable({
  item,
  rdv,
}: {
  item: IUser;
  rdv?: IRdvs;
}) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const {
    data = { results: [], count: 0 },
    isLoading,
    refetch,
  } = useGetVaccinByPatientQuery({ page, limit, slug: item?.slug });
  useEffect(() => {
    if (!isLoading) refetch();
  }, []);
  const actionFormatter = (cell: string, row: Vaccin) => {
    return (
      <>
        <div className="d-flex gap-3">
          {!!rdv && (
            <div className="container-btn-action-icon-modal">
              <NavLink
                to={`/medecin/modifier-vaccination/${rdv?.slug}/vaccin/${row?.slug}`}
                // state={rdv}
                className="btn btn-action-icon-edit with-tooltip"
                data-tooltip-content="Modifier"
              >
                <MdOutlineModeEdit />
              </NavLink>
            </div>
          )}
        </div>
      </>
    );
  };

  const nameFormatter = (cell: string, row: Vaccin) => {
    return (
      <div>
        <span>
          {" "}
          {cell} <b>{row?.is_updated === true ? `(addendum)` : ""}</b>
        </span>
      </div>
    );
  };

  const statusFormatter = (cell: boolean, row: Vaccin) => {
    return (
      <div className="statut-formatter-container d-flex align-items-center gap-2">
        <BtnStatus item={row} />
      </div>
    );
  };

  const columns = [
    {
      dataField: "lot",
      text: "N° lot",
      // formatter: nameFormatter,
    },
    {
      dataField: "date",
      text: `Date`,
      formatter: (cell: string) => formatDate(cell),
    },
    {
      dataField: "nom",
      text: "Nom du vaccin",
      formatter: nameFormatter,
    },

    {
      dataField: "date_expiration",
      text: `Expiration`,
      formatter: (cell: string) => formatDate(cell),
    },
    {
      dataField: "status",
      text: "Statut",
      formatter: statusFormatter,
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
      headerStyle: () => {
        return { width: "80px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            condensed
            hover
            wrapperClasses="table-responsive medecin-patients-detail-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default AllDetailPatientVaccinTable;
