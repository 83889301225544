import React, { useState } from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import AllPaiementMedecinTable from "../Tables/AllPaiementMedecinTable";
import CalendrierStep from "../../shared/CalendrierStep";

function PaiementMedecin() {
  const [date, setDate] = useState(new Date());
  return (
    <div className="row">
      <div className="col-md-12">
        <WrapperComponent>
          {/* <div className="container-title-dash d-flex align-items-center gap-4">
            <div className="custom-sm-top">
              <h2 className="custom-sm-top-title mb-0">Mes paiements</h2>
              
            </div>
          </div> */}
          <div className="container-title-dash d-flex align-items-center gap-4">
            <h2 className="custom-sm-top-title mb-0">Mes paiements</h2>
            <CalendrierStep setDate={setDate} />
          </div>

          <div className="custom-table mt-5">
            <AllPaiementMedecinTable date={date} />
          </div>
        </WrapperComponent>
      </div>
      {/* <AddOrEditFacturationModal modalId="addFacturationModal" /> */}
    </div>
  );
}

export default PaiementMedecin;
