import React, { useEffect, useState } from "react";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../utils/type";
import "./ParametreAdmin.css";
import MonCompte from "./ParametreTabs/MonCompte";
import Administrateurs from "./ParametreTabs/Administrateurs";
import WrapperComponent from "../../shared/WrapperComponent";
import { NavLink, useSearchParams } from "react-router-dom";
import { MdOutlineAddBox } from "react-icons/md";
import ChangePasswordModal from "../../medecin/parametreMedecin/modals/ChangePasswordModal";
import { useLocationState, useUser } from "../../../utils/Utils";
import { hasAccess } from "../../../routes/routerUtils";

const steps = [
  { id: "mon-compte", Component: MonCompte },
  { id: "administrateurs", Component: Administrateurs },
];

const tabs = ["Mon compte", "Administrateurs"];

function ParametreAdmin() {
  const itemState = useLocationState<string>(undefined);
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  let [searchParams] = useSearchParams();
  let key = searchParams.get("step") || "";
  const [modalChangePassword, setModalChangePassword] = useState(false);
  const user = useUser()

  useEffect(() => {
    if (itemState === "administrateurs") {
      go(itemState);
    } else if (key === "administrateurs") {
      go(key);
    }
  }, [itemState, key]);
  function openModalChangePassword(e: any) {
    e.preventDefault();
    setModalChangePassword(true);
  }

  useEffect(() => {
    if (user && hasAccess(user, "admin_habilitation")) {
      go("mon-compte");
    }
  }, [user]);
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <WrapperComponent>
            <div className="container-title-dash d-flex align-items-center justify-content-between gap-4">
              <h2 className="custom-sm-top-title mb-0">Paramètres</h2>
              {index === 1 && (
                <NavLink
                  to="/admin/parametres/ajouter-un-admin"
                  className="btn custom-add-admin-btn"
                >
                  <span className="add-icon">
                    <MdOutlineAddBox />
                  </span>
                  Ajouter un admin
                </NavLink>
              )}
            </div>

            <div className="parametres-tabs-content mt-5">
              {!hasAccess(user, "admin_habilitation") && (
                <ul className="tabs-title-content">
                {tabs.map((tab: any, key: any) => (
                  <li
                    className={`tab-item  ${
                      index === key && "tab-item-active"
                    }`}
                    key={key}
                    onClick={() => go(key)}
                    role="button"
                  >
                    {tab}
                  </li>
                ))}
              </ul>
              )}
              
              <div className="custom-tabs-content mt-5">
                <Component />
              </div>
            </div>
          </WrapperComponent>
        </div>
        {index === 0 && (
          <div className="col-md-12">
            <WrapperComponent>
              <div className="container-title-dash">
                <h2 className="custom-sm-top-title mb-0">Mot de passe</h2>
              </div>
              <div className="content-btn-theme-title pt-4">
                <button
                  className="btn btn-theme-admin"
                  onClick={openModalChangePassword}
                >
                  Changer mot de passe
                </button>
                <ChangePasswordModal
                  modalChangePassword={modalChangePassword}
                  setModalChangePassword={setModalChangePassword}
                />
              </div>
            </WrapperComponent>
          </div>
        )}
      </div>
    </>
  );
}

export default ParametreAdmin;
