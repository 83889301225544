import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Tarif, TarifFormData } from "./tarif.type";

export const TarifApi = createApi({
  reducerPath: "tarifApi",
  tagTypes: ["tarif", "tarifList", "tarifByCabinet", "tarifBySlug"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListTarif: builder.query<PaginationResults<Tarif>, TypeQuery>({
      providesTags: ["tarifList"],
      query: (query) => QueryUrl("tarifs", query),
    }),
    getTarifByCabinet: builder.query<PaginationResults<Tarif>, TypeQuery>({
      providesTags: ["tarifByCabinet"],
      query: ({ slug, ...query }) => QueryUrl(`cabinet/${slug}/tarifs`, query),
    }),
    addOrEditTarif: builder.mutation<Tarif, TarifFormData>({
      query: ({ slug, ...data }) => {
        if (slug) {
          return {
            url: `tarifs/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `tarifs/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["tarifByCabinet", "tarifBySlug"],
    }),
    deleteTarif: builder.mutation<Tarif, string>({
      query: (slug) => ({
        url: `tarifs/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["tarif", "tarifList", "tarifByCabinet"],
    }),
    tarifBySlug: builder.query<Tarif | any, string>({
      query: (slug) => `tarifs/${slug}/`,
      providesTags: ["tarifBySlug"],
    }),
  }),
});

export const {
  useAddOrEditTarifMutation,
  useDeleteTarifMutation,
  useGetListTarifQuery,
  useLazyTarifBySlugQuery,
  useGetTarifByCabinetQuery,
} = TarifApi;
