import React, { useEffect, useState } from "react";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import { MdOutlineModeEdit, MdUnarchive } from "react-icons/md";
import TableSkeleton from "../../common/TableSkeleton";
import { NavLink, useNavigate } from "react-router-dom";
import "./Table.css";
import { useGetPatientsByMedecinQuery } from "../../../utils/api/user/user.api";
import { formatDate, useUser } from "../../../utils/Utils";
import { CustomPagination } from "../../common/CustomPagination";
import { IUser } from "../../../utils/api/user/user.type";
import { UseArchivePatient } from "../patientMedecin/form/UseAddOrEditPatient";
import { BiArchiveIn } from "react-icons/bi";
import moment from "moment";

export const BtnArchive = ({ item }: { item: IUser }) => {
  const onArchive = UseArchivePatient(item);

  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content={item?.is_archive ? "Désarchiver" : "Archiver"}
      style={{ color: "#E53E3E" }}
      onClick={onArchive}
    >
      {/* <BsTrash /> */}
      {item?.is_archive ? <MdUnarchive /> : <BiArchiveIn />}
    </button>
  );
};

function AllPatientsMedecinTable({
  q,
  date,
}: {
  q: string;
  date: Date | null;
}) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("");
  const user = useUser();
  const {
    data = { results: [], count: 0 },
    isLoading,
    refetch,
  } = useGetPatientsByMedecinQuery({
    page,
    limit,
    slug: user?.slug,
    q,
    date: date ? moment(date).format("YYYY-MM-DD") : "",
    order,
  });
  useEffect(() => {
    if (!isLoading) refetch();
  }, []);

  const navigate = useNavigate();

  const actionFormatter = (cell: string, row: IUser) => {
    return (
      <>
        <div className="d-flex justify-content-start gap-3">
          {/* <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/medecin/patients/${cell}`}
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Voir"
              style={{ color: `#099876` }}
              state={row}
            >
              <AiOutlineEye />
            </NavLink>
          </div> */}
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/medecin/patients/modifier-patient/${cell}`}
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Modifier"
              state={row}
            >
              <MdOutlineModeEdit />
            </NavLink>
          </div>
          <div className="container-btn-action-icon-modal">
            <BtnArchive item={row} />
          </div>
        </div>
      </>
    );
  };
  // const folderFormatter = (cell: any, row: any) => {
  //   return (
  //     <>
  //       <div className="statut-formatter-container d-flex align-items-center gap-2">
  //         <span className="file-icon">
  //           <AiOutlineFilePdf />
  //         </span>
  //         {row.folder}
  //       </div>
  //     </>
  //   );
  // };

  const columns = [
    {
      dataField: "matricule",
      text: `Numéro`,
    },
    {
      dataField: "nom",
      text: "Nom",
      sort: true,
      onSort: (field, order) => {
        setPage(1);
        if (order === "asc") {
          setOrder("asc_nom");
        }
        if (order === "desc") {
          setOrder("desc_nom");
        }
        return "";
      },
    },
    {
      dataField: "prenom",
      text: "Prénom(s)",
      sort: true,
      classes: "id-custom-cell",
      onSort: (field, order) => {
        setPage(1);
        if (order === "asc") {
          setOrder("asc_prenom");
        }
        if (order === "desc") {
          setOrder("desc_prenom");
        }
        return;
      },
    },
    {
      dataField: "adresse",
      text: "Adresse",
    },
    {
      dataField: "date_de_naissance",
      text: "Date de naissance",
      formatter: (cell: string) => formatDate(cell),
    },
    // {
    //   dataField: "folder",
    //   text: "Dossier",
    //   formatter: folderFormatter,
    // },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
    },
  ];

  const rowEvents = {
    onClick: (e, row: IUser, rowIndex) => {
      let getCurrentCellIndex = e.target.cellIndex;
      let id: any = document.querySelector("table tr:last-child td:last-child");
      let getLastCellIndex = id?.cellIndex;

      if (
        getCurrentCellIndex !== getLastCellIndex &&
        getCurrentCellIndex !== undefined
      ) {
        navigate(`/medecin/patients/${row?.slug}`, { state: row });
      }
    },
  };

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            classes="react-bootstrap-table"
            data={data?.results}
            columns={columns}
            rowEvents={rowEvents}
            rowClasses="custom-table-row-class"
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default AllPatientsMedecinTable;
