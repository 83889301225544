import React, { useEffect, useState } from "react";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../common/TableSkeleton";
import "./Table.css";
import { formatDate, useUser } from "../../../utils/Utils";
import { useGetVaccinByPatientQuery } from "../../../utils/api/vaccin/vaccin.api";
import { CustomPagination } from "../../common/CustomPagination";

function PatientVaccinTable() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const user = useUser();
  const { data = { results: [], count: 0 }, isLoading,refetch } =
    useGetVaccinByPatientQuery({ page, limit, slug: user?.slug });

  useEffect(() => {
    if (!isLoading) refetch();
  }, []);

  const columns = [
    {
      dataField: "date",
      text: `Date`,
      formatter: (cell: string) => formatDate(cell),
    },
    {
      dataField: "nom",
      text: "Nom du vaccin",
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            condensed
            hover
            wrapperClasses="table-responsive medecin-patients-detail-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default PatientVaccinTable;
