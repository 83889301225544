/* eslint-disable @typescript-eslint/no-explicit-any */
import { IRdvs } from "../../../../utils/api/rdvs/rdvs.type";
import { useEffect } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Color } from "../../../../utils/theme";
import { cleannerError } from "../../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import {
  Analyse,
  AnalyseFormData,
} from "../../../../utils/api/analyse/analyse.type";
import { useAddOrUpdateAnalyseMutation } from "../../../../utils/api/analyse/analyse.api";

function UseAddAnalyseForm(item: IRdvs) {
  const validationSchema = yup.object().shape({
    type_analyse: yup.string().required().label("Le type d'analyse").nullable(),
    laboratoire: yup.string().required().label("Le laboratoire").nullable(),
    frequence: yup.string().required().label("La fréquence").nullable(),
    delai: yup.string().required().label("Le délai").nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
  } = useForm<AnalyseFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [sendData, { isLoading, isSuccess, isError, error }] =
    useAddOrUpdateAnalyseMutation();
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "L'analyse a été créée avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        navigate(`/medecin/rendez-vous/${item?.slug}?step=resultat`, {
          state: item,
        });
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    // console.log("cabinet", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (item?.id) {
      setValue("rdv", item?.id);
    }
  }, [item]);

  const onSubmit = async (data: AnalyseFormData) => {
    await sendData({ data });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    control,
  };
}

export default UseAddAnalyseForm;

export function UseEditStatutAnalyse(item: Analyse) {
  const [sendData] = useAddOrUpdateAnalyseMutation();
  const onEdit = async () => {
    await Swal.fire({
      title: `Status de l'analyse`,
      input: "select",
      inputValue: item?.status,
      inputOptions: {
        en_cours: "En cours",
        terminer: "Terminé",
      },
      inputPlaceholder: "Selectionner un statut",
      showCancelButton: true,
      confirmButtonText: "Modifier",
      cancelButtonText: "Annuler",
      reverseButtons: true,
      confirmButtonColor: Color.success,
      showLoaderOnConfirm: true,

      preConfirm: (status) => {
        // console.log("statut", status);
        if (!status) {
          return Swal.showValidationMessage(`Veuillez sélectionner un statut`);
        }
        return sendData({slug: item?.slug, data: { status: status } });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le statut de l'analyse a été modifié avec succès!`,
            iconColor: Color.success,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onEdit;
}
