import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { Disponibilite, DisponibiliteFormData } from "./disponibilite.type";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";

export const DisponibiliteApi = createApi({
  reducerPath: "disponibiliteApi",
  tagTypes: ["disponibilite", "disponibiliteMedecin"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    addOrUpdateDisponibilite: builder.mutation<
      Disponibilite,
      DisponibiliteFormData
    >({
      query: ({ slug, ...data }) => {
        if (slug) {
          return {
            url: `disponibilites/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `disponibilites/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["disponibiliteMedecin", "disponibilite"],
    }),
    getDisponibilite: builder.query<
      PaginationResults<Disponibilite>,
      TypeQuery
    >({
      query: (query) => QueryUrl(`disponibilites/`, query),
      providesTags: ["disponibilite"],
    }),
    getDisponibiliteByMedecin: builder.query<Disponibilite[], TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`mobile/medecin/${slug}/disponibilites/`, query),
      providesTags: ["disponibiliteMedecin"],
    }),

    deleteDisponibilite: builder.mutation<DisponibiliteFormData, string>({
      query: (slug) => ({
        url: `disponibilites/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["disponibilite", "disponibiliteMedecin"],
    }),
  }),
});
export const {
  useAddOrUpdateDisponibiliteMutation,
  useDeleteDisponibiliteMutation,
  useGetDisponibiliteQuery,
  useGetDisponibiliteByMedecinQuery,
  useLazyGetDisponibiliteByMedecinQuery
} = DisponibiliteApi;
