import React, { useEffect, useState } from "react";
import "./DashboardAdmin.css";
import LineChart from "./LineChat/LineChart";
import Icon1 from "../../../assets/icons/icon1.svg";
import Icon2 from "../../../assets/icons/icon2.svg";
// import Icon3 from "../../../assets/icons/icon3.svg";
import Icon4 from "../../../assets/icons/icon4.svg";
import Revenus from "./LineChat/Revenus";
import Incomming from "./LineChat/Incomming";
import ActiviteRdvTable from "./ActiviteRdvTable/ActiviteRdvTable";
import { formatCurrency, useUser } from "../../../utils/Utils";
import { hasAccess, isAdmin, isMedecin } from "../../../routes/routerUtils";
import { useNavigate } from "react-router-dom";
import { useGetDashboardQuery } from "../../../utils/api/dashboard/dashboard.api";
import Skeleton from "react-loading-skeleton";
import moment from "moment";

const DashboardAdmin = () => {
  const user = useUser();
  const navigate = useNavigate();
  const [type, setType] = useState("year");
  const [date, setDate] = useState(new Date());
  const {
    data = {
      graph: [],
      lastGraph: [],
      patients_count: 0,
      rdvs_count: 0,
      patients: 0,
      rdvs: 0,
      gains: 0,
      gainGraph: [],
      lastGainGraph: [],
    },
    isLoading,
    refetch
  } = useGetDashboardQuery({
    slug: user?.slug,
    type: isAdmin(user) ? "admin" : user?.user_type,
    filter: type,
    date: moment(date).format("DD-MM-YYYY"),
  });

  useEffect(() => {
    if (!isLoading) refetch();
  }, []);

  useEffect(() => {
    if (isAdmin(user) && user && hasAccess(user, "dashboard")) {
      navigate("/admin/parametres");
    }
  }, [user]);
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          {!!isLoading && <CardSkeleton />}
          {!isLoading && (
            <>
              <div className="col-lg-4 col-md-6 mb-3 dis-flex">
                <div className="item-stat-dashboad-view ">
                  <div className="content-stat-dashboard-view">
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Icon1}
                        alt="Dashboard"
                        className="img-icon-dash-view"
                      />
                    </div>
                    <div className="content-text-item-stat-dashboad-view">
                      <div className="icon-item-stat-dashboad-view">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Rendez-vous
                        </p>
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {data?.rdvs_count || data?.rdvs}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-3 dis-flex">
                <div className="item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Icon2}
                        alt="Dashboard"
                        className="img-icon-dash-view"
                      />
                    </div>
                    <div className="content-text-item-stat-dashboad-view">
                      <div className="icon-item-stat-dashboad-view">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Nouveaux Patients
                        </p>
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {data?.patients_count || data?.patients}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-6 mb-3 dis-flex">
                <div className="item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Icon3}
                        alt="Dashboard"
                        className="img-icon-dash-view"
                      />
                    </div>
                    <div className="content-text-item-stat-dashboad-view">
                      <div className="icon-item-stat-dashboad-view">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Operations
                        </p>
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          24
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-4 col-md-6 mb-3 dis-flex">
                <div className="item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Icon4}
                        alt="Dashboard"
                        className="img-icon-dash-view"
                      />
                    </div>
                    <div className="content-text-item-stat-dashboad-view">
                      <div className="icon-item-stat-dashboad-view">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Gain
                        </p>
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {formatCurrency(data?.gains)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="content-graph-admin mt-5 mb-3">
          <LineChart
            graph={data?.graph}
            lastGraph={data?.lastGraph}
            isLoading={isLoading}
            date={date}
            setDate={setDate}
            type={type}
            setType={setType}
          />
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 mb-3">
            <div className="content-graph-admin">
              <Revenus
                graph={data?.lastGainGraph}
                isLoading={isLoading}
                date={date}
                type={type}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 mb-3">
            <div className="content-graph-admin">
              <Incomming
                graph={data?.gainGraph}
                isLoading={isLoading}
                date={date}
                type={type}
              />
            </div>
          </div>
        </div>
        {!!isMedecin(user) && <RdvDashboard />}
      </div>
    </div>
  );
};

export default DashboardAdmin;

const RdvDashboard = () => {
  return (
    <div className="content-graph-admin">
      <div className="container-title-dash">
        <h2 className="chart-item-admin-title mb-0">Activité de rendez-vous</h2>
      </div>
      <div className="admin-table pt-5">
        <ActiviteRdvTable />
      </div>
    </div>
  );
};

const CardSkeleton = () => {
  return (
    <>
      {[...Array(3)]?.map((itm, i) => (
        <div className="col-lg-4 col-md-6 mb-3 dis-flex" key={i}>
          <div className="item-stat-dashboad-view ">
            <div className="content-stat-dashboard-view">
              <div className="content-item-icon-stat-dashboard-view">
                <Skeleton
                  className="img-icon-dash-view"
                  width={45}
                  height={45}
                />
              </div>
              <div className="content-text-item-stat-dashboad-view">
                <div className="icon-item-stat-dashboad-view">
                  <Skeleton className="titre-item-stat-dashboad-view mb-1" />
                  <Skeleton
                    className="chiffre-item-stat-dashboad-view mb-1"
                    width={`25%`}
                    height={30}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
