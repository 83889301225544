import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { Scanner, ScannerFormData } from "./scanner.type";

export const ScannerApi = createApi({
  reducerPath: "scanner",
  tagTypes: ["scannersByPatient", "scanner", "scannerList", "scannerBySlug"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getScanner: builder.query<PaginationResults<Scanner>, TypeQuery>({
      query: (query) => QueryUrl(`scanners/`, query),
      providesTags: ["scannerList"],
    }),
    getScannerByPatient: builder.query<PaginationResults<Scanner>, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`patient/${slug}/scanners/`, query),
      providesTags: ["scannersByPatient"],
    }),
    scannerBySlug: builder.query<Scanner | any, string>({
      query: (slug) => `scanners/${slug}/`,
      providesTags: ["scannerBySlug"],
    }),
    addOrUpdateScanner: builder.mutation<Scanner, ScannerFormData>({
      query: ({ slug, ...data }) => ({
        url: slug ? `scanners/${slug}/` : `scanners/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["scannerList", "scannersByPatient"],
    }),
    deleteScanner: builder.mutation<ScannerFormData, string>({
      query: (slug) => ({
        url: `scanners/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["scannerList", "scannersByPatient"],
    }),
  }),
});

export const {
  useAddOrUpdateScannerMutation,
  useDeleteScannerMutation,
  useGetScannerByPatientQuery,
  useGetScannerQuery,
} = ScannerApi;
