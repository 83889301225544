import React, { Dispatch, ReactElement, SetStateAction } from "react";
import "../../medecin/Medecin.css";
import { NavLink } from "react-router-dom";
import WrapperComponent from "../WrapperComponent";
import { MdOutlineAddBox } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../redux/hook";
import {
  isCabinet,
  isMedecin,
  isSecretaire,
} from "../../../routes/routerUtils";
import CalendrierStep from "../CalendrierStep";

function ActiviteRdv({
  tableComponent,
  setQuery,
  setDate,
}: {
  tableComponent?: ReactElement;
  setQuery?: Dispatch<SetStateAction<string>>;
  setDate?: Dispatch<SetStateAction<Date>>;
}) {
  const location = useLocation();
  const { user } = useAppSelector((s) => s?.user);

  return (
    <WrapperComponent>
      <div className="content-chart-title mb-4">
        <div className="container-title-dash d-flex align-items-center gap-4">
          <h2 className="custom-sm-top-title mb-0">Activité de rendez-vous</h2>
          {!!setDate && <CalendrierStep setDate={setDate} />}
        </div>
        {!!isSecretaire(user) && (
          <div className="content-btn-theme-title">
            <NavLink
              to={
                isMedecin(user)
                  ? "/medecin/rendez-vous/ajouter-rendez-vous"
                  : isCabinet(user)
                  ? "/cabinet/rendez-vous/ajouter-rendez-vous"
                  : "/admin/rendez-vous/ajouter-rendez-vous"
              }
              className="btn custom-add-item-btn"
            >
              <span className="add-icon">
                <MdOutlineAddBox />
              </span>
              Ajouter un rendez-vous
            </NavLink>
          </div>
        )}
      </div>
      <div className="search-patient-box d-flex align-items-center gap-3 mt-4">
        <input
          type="search"
          name="search_patient"
          id="search_patient"
          placeholder="Rechercher"
          onChange={(e) => {
            if (setQuery) {
              setQuery(e?.target?.value);
            }
          }}
        />
      </div>

      <div className="custom-table mt-5">{tableComponent}</div>
    </WrapperComponent>
  );
}

export default ActiviteRdv;
