import { ChangeEvent, useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { IUser, UserFormData } from "../../../../utils/api/user/user.type";
import { cleannerError, useUser, validatePhone } from "../../../../utils/Utils";
import { useAddOrEditPatientMutation } from "../../../../utils/api/user/user.api";
import { Color } from "../../../../utils/theme";
import { IRdvs } from "../../../../utils/api/rdvs/rdvs.type";

function UseCompleteAntecedentPatient(item: IRdvs) {
  const [antecedent, setAntecedent] = useState("");
  const [allergie, setAllergie] = useState("");
  const [chirurgie, setChirurgie] = useState("");
  const [gynecologie, setGynecologie] = useState("");
  const [obstetrique, setObstetrique] = useState("");
  const [phone, setPhone] = useState<any>();
  const [code, setCode] = useState("");
  const [searchSocial, setSearchSocial] = useState("")
  const [option, setOption] = useState<any>([])
  const [options, setOptions] = useState([
    {
      label: "Alcool",
      value: "Alcool",
    },
    {
      label: "Drogue",
      value: "Drogue",
    },
    {
      label: "Fumeur",
      value: "Fumeur",
    },
  ]);
  const validationSchema = yup.object().shape({
    social: yup.array().of(yup.string()).nullable().label("Social"),
    assurance_medicale: yup
      .string()
      .transform((val: string) => (val === "<p></br></p>" ? "" : val))
      .nullable()
      .label("L'assurance médicale"),
    numero_assurance: yup.string().nullable().label("Le numéro de l'assurance"),
    groupe_sanguin: yup
      .string()
      .required()
      .label("Le groupe sanguin")
      .nullable(),
    contact_urgence: validatePhone(yup, code).required(),
    antecedent_medical: yup
      .string()
      .transform((val: string) => (val === "<p></br></p>" ? "" : val))
      .nullable()
      .label("L'antécédent médical"),
    chirurgie: yup
      .string()
      .label("Chirurgie")
      .transform((val: string) => (val === "<p></br></p>" ? "" : val))
      .nullable(),
    allergie: yup
      .string()
      .transform((val: string) => (val === "<p></br></p>" ? "" : val))
      .nullable()
      .label("Les allergies et réactions"),
    gynecologie: yup
      .string()
      .label("Gynécologie")
      .transform((val: string) => (val === "<p></br></p>" ? "" : val))
      .nullable(),
    obstetrique: yup
      .string()
      .transform((val: string) => (val === "<p></br></p>" ? "" : val))
      .nullable()
      .label("Obstetrique"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control
  } = useForm<UserFormData>({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const [sendData, { isLoading, isSuccess, isError, error }] =
    useAddOrEditPatientMutation();

  const onChangeAntecedent = (val) => {
    setAntecedent(val);
    setValue("antecedent_medical", val);
  };

  const onChangeAllergie = (val) => {
    setAllergie(val);
    setValue("allergie", val);
  };

  const onChangeChirurgie = (val) => {
    setChirurgie(val);
    setValue("chirurgie", val);
  };

  const onChangeGynecologie = (val) => {
    setGynecologie(val);
    setValue("gynecologie", val);
  };

  const onChangeObstetrique = (val) => {
    setObstetrique(val);
    setValue("obstetrique", val);
  };

  const handleChangeInput = (val) => {
    setSearchSocial(val)
    const optionExists = options?.some((opt) =>
      opt?.label?.toLocaleLowerCase()?.includes(val?.toLocaleLowerCase())
    );

    if (!optionExists) {
      setOptions((prevOptions) => [
        ...prevOptions,
        { label: val, value: val },
      ]);
    }
  }

  const handleSelectSocial = (val) => {
    setOption(val)
    if (val?.length) {
      console.log("val",val)
      setValue("social", val?.map((el) => el?.value));
    }
    
    setOptions([
      {
        label: "Alcool",
        value: "alcohol",
      },
      {
        label: "Drogue",
        value: "drogue",
      },
      {
        label: "Fumeur",
        value: "fumeur",
      },
    ]);
  }

  const handleChangePhone = (item: any, country: any) => {
    setPhone(item);
    setCode(country?.countryCode);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue("contact_urgence", item);
    } else {
      setValue("contact_urgence", "");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Le informations ont été mises à jour avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        navigate(`/medecin/rendez-vous/${item?.slug}?step=historique`, {
          state: item,
        });

        // navigate(-1);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    console.log("errors", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (item?.id) {
      
      let fields: (keyof UserFormData)[] = [
        "groupe_sanguin",
        "antecedent_medical",
        "allergie",
        "assurance_medicale",
        "chirurgie",
        "contact_urgence",
        "gynecologie",
        "numero_assurance",
        "obstetrique",
        "social",
      ];

      if (item?.patient?.contact_urgence) {
        setPhone(item?.patient?.contact_urgence);
      }

      if (item?.patient?.allergie) {
        setAllergie(item?.patient?.allergie);
      }

      if (item?.patient?.antecedent_medical) {
        setAntecedent(item?.patient?.antecedent_medical);
      }

      if (item?.patient?.chirurgie) {
        setChirurgie(item?.patient?.chirurgie);
      }

      if (item?.patient?.obstetrique) {
        setObstetrique(item?.patient.obstetrique);
      }

      if (item?.patient?.gynecologie) {
        setGynecologie(item?.patient?.gynecologie);
      }

      if (item?.patient?.social) {
        setOption(item?.patient?.social?.map((el) => {
          return { label: el, value: el };
        }))
      }

      for (let field of fields) {
        register(field);
        if (field !== "structure") {
          setValue(field, item?.patient[field]);
        } else {
          if (item?.patient[field]) setValue(field, item?.patient[field]?.id);
        }
      }
    }
  }, [item]);

  const onSubmit = async (data: UserFormData) => {
    
    await sendData({
      slug: item?.patient?.slug,
      data: data,
    });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChangePhone,
    phone,
    onChangeAllergie,
    onChangeAntecedent,
    onChangeChirurgie,
    onChangeGynecologie,
    onChangeObstetrique,
    allergie,
    antecedent,
    chirurgie,
    gynecologie,
    obstetrique,
    Controller,
    control,
    handleChangeInput,
    handleSelectSocial,
    option,
    options
  };
}

export default UseCompleteAntecedentPatient;
