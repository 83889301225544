import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useGetSpecialiteQuery } from "../../../../utils/api/specialite/specialite.api";
import { ISpecialite } from "../../../../utils/api/specialite/specialite.type";
import TableSkeleton from "../../../common/TableSkeleton";
import { AlertInfo } from "../../../common/Alert";
import { CustomPagination } from "../../../common/CustomPagination";
import { MdOutlineModeEdit } from "react-icons/md";
import AddOrEditSpecialiteModal from "../modal/AddOrEditSpecialiteModal";
import { showModal } from "../../../../utils/Utils";
import { UseDeleteSpecialite } from "../form/UseAddOrEditSpecialiteForm";
import { BsTrash } from "react-icons/bs";

const BtnDelete = ({ slug }: { slug: string }) => {
  const onDelete = UseDeleteSpecialite(slug);

  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content={"Supprimer"}
      style={{ color: "#E53E3E" }}
      onClick={onDelete}
    >
      <BsTrash />
    </button>
  );
};
function SpecialiteTable({ q }: { q: string }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } = useGetSpecialiteQuery(
    {
      page,
      limit,
      q,
    }
  );

  const actionFormatter = (cell: string, row: ISpecialite) => {
    return (
      <>
        <div className="d-flex justify-content-center gap-3">
          <div className="container-btn-action-icon-modal">
            <button
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Modifier"
              onClick={() => showModal(`editSpecialiteModal${cell}`)}
            >
              <MdOutlineModeEdit />
            </button>
          </div>
          <div className="container-btn-action-icon-modal">
            <BtnDelete slug={cell} />
          </div>
        </div>
        <AddOrEditSpecialiteModal
          modalId={`editSpecialiteModal${cell}`}
          item={row}
        />
      </>
    );
  };

  const columns = [
    {
      dataField: "nom",
      text: "Nom de la spécialité",
    },
    {
      dataField: "slug",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: string, row: ISpecialite) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "150px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading ? (
        <TableSkeleton headers={columns} />
      ) : (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default SpecialiteTable;
