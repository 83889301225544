import { IRdvs } from "../../../../utils/api/rdvs/rdvs.type";
import { useEffect } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Color } from "../../../../utils/theme";
import { cleannerError, closeModal } from "../../../../utils/Utils";
import {
  Medicament,
  MedicamentFormData,
} from "../../../../utils/api/ordonnance/ordonnance.type";
import { useAddOrEditMedicamentMutation } from "../../../../utils/api/ordonnance/ordonnance.api";

function UseAddOrEditMedicamentForm(modalId: string, item?: Medicament) {
  const validationSchema = yup.object().shape({
    posologie: yup.string().required().label("La posologie").nullable(),
    nom: yup.string().required().label("Le nom").nullable(),
    dosage: yup.string().required().label("Le dosage").nullable(),
    duree: yup.string().required().label("La durée").nullable(),
    quantite: yup
      .number()
      .required()
      .label("La quantite")
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    trigger,
    control,
    reset,
  } = useForm<MedicamentFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [sendData, { isLoading, isSuccess, isError, error }] =
    useAddOrEditMedicamentMutation();

  const handleReset = () => {
    if (!item) {
      reset();
    }
    closeModal(modalId);
  };
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `L'ordonnance a été ${!item ? "créée" : "modifiée"} avec succès`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        handleReset();
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    // console.log("cabinet", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (item?.slug) {
      let fields: (keyof MedicamentFormData)[] = [
        "dosage",
        "duree",
        "nom",
        "posologie",
        "quantite",
        "slug",
      ];

      for (let field of fields) {
        register(field);

        setValue(field, item[field]);
      }
    }
  }, [item]);

  const onSubmit = async (data: MedicamentFormData) => {
    await sendData(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    control,
    trigger,
    handleReset,
  };
}

export default UseAddOrEditMedicamentForm;
