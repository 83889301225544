import React from "react";
import AdministrateurTable from "../ParametreTables/AdministrateurTable";


function Administrateurs() {
    return(
        <>
            <div className="all-admins-page">
                <div className="filtered-and-search-container pt-4 pb-4">
                    <div className="d-flex align-items-center gap-3">
                        <label htmlFor="filter">Filter</label>
                        <input 
                            type="search" 
                            name="search_item" 
                            id="search_item" 
                            placeholder="Chercher"
                        />
                    </div>
                </div>
                <div className="custom-table pt-4">
                    <AdministrateurTable />
                </div>
            </div>
        </>
    )
}

export default Administrateurs;