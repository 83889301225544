/* eslint-disable @typescript-eslint/no-explicit-any */
import FullCalendar from "@fullcalendar/react";
import rrulePlugin from "@fullcalendar/rrule";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";
import { IUser } from "../../../utils/api/user/user.type";
import { useLazyGetDisponibiliteByMedecinQuery } from "../../../utils/api/disponibilite/disponibilite.api";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { generateDisponibilites, getName, useUser } from "../../../utils/Utils";
import { useLazyGetRdvsFreePraticienQuery } from "../../../utils/api/rdvs/rdvs.api";
import moment from "moment";
import AddOrEditRdvEventModal from "./modal/AddOrEditRdvModal";
import { useNavigate } from "react-router-dom";
import { UseEditDisponibilite } from "../../medecin/parametreMedecin/form/UseAddOrEditDisponibiliteForm";
import { isSecretaire } from "../../../routes/routerUtils";
import { Disponibilite } from "../../../utils/api/disponibilite/disponibilite.type";
import Swal from "sweetalert2";
// import _ from "lodash";

const EventItem = ({
  arg,
  setShow,
  data,
}: {
  arg: any;
  setShow: Dispatch<SetStateAction<boolean>>;
  data: Disponibilite[];
}) => {
  const user = useUser();
  
  const onEdit = UseEditDisponibilite(
    {
      start: arg?.event?.start,
      end: arg?.event?.end,
    },
    data[0],
    arg?.event?.extendedProps?.dispo
  );
  return (
    <div
      className={`w-100 position-relative linkable`}
      style={{ height: "100%" }}
      onClick={() => {
        if (isSecretaire(user)) {
          if (arg?.event?.extendedProps?.dispo) {
            setShow(true);
          }
        } else {
          if (arg?.event?.extendedProps?.type !== "rdv") {
            onEdit();
          }
        }
      }}
    >
      <div
        style={{
          // color: arg?.borderColor || "#099876",
          padding: "2px 5px",
          height: "100%",
          overflow: "hidden",
          // border: `2px solid`,
          // borderColor: arg?.borderColor || "#099876",
        }}
        className={
          arg?.event?.extendedProps?.dispo === true
            ? "available"
            : "no-available"
        }
      >
        <div className="d-flex align-items-center justify-content-between">
          <p
            className="event-time m-0"
            style={
              {
                // color: arg?.borderColor || "#099876",
              }
            }
          >
            {arg?.timeText}
          </p>
        </div>
        <h5
          className="calendar-event-title mb-1"
          style={
            {
              // color: arg?.borderColor || "#099876",
            }
          }
        >
          {arg?.event?.title}
        </h5>
      </div>
    </div>
  );
};

const EventRenderItem = (arg, element, setShow, data) => {
  // console.log(arg)
  return (
    <>
      <EventItem arg={arg} setShow={setShow} data={data} />
    </>
  );
};

export type EventDisponibiliteType = {
  title: string;
  start: string;
  end: string;
  className?: string;
};

export default function Calendar({ user }: { user: IUser }) {
  const navigate = useNavigate();
  const [getData, { data = [], isSuccess: isDone }] =
    useLazyGetDisponibiliteByMedecinQuery();
  const [getRdv, { data: rdvs = [], isSuccess }] =
    useLazyGetRdvsFreePraticienQuery();
  const [events, setEvents] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const userInfos = useUser();

  useEffect(() => {
    if (user?.slug) {
      getRdv(user?.slug);
      getData({ slug: user?.slug });
    }
  }, [user]);

  useEffect(() => {
    if (isSuccess && isDone) {
      let initEvents: any = [];
      if (data?.length) {
        const disponibilites = generateDisponibilites(
          data[0],
          rdvs?.filter((el) => el?.status !== "annuler")
        );
        initEvents = [...initEvents, ...disponibilites];
        setEvents(initEvents);
      } else if (rdvs?.length) {
        let rdvEvents = rdvs
          ?.filter((el) => el?.status !== "annuler")
          ?.map((item) => {
            const [heure, minute] = item?.horaire_de_visite?.split(":");
            const originalTime = new Date();
            originalTime.setHours(Number(heure), Number(minute), 0);
            const endTime = moment(
              new Date(originalTime.getTime() + 45 * 60000)
            ).format("HH:mm");

            return {
              start: new Date(`${item?.date}T${item?.horaire_de_visite}`),
              end: new Date(`${item?.date}T${endTime}`),
              title: `Rendez-vous avec ${getName(item?.patient)}`,
              id: item?.id,
              // backgroundColor: "red",
              borderColor: "red",
              dispo: false,
              start_time: moment(
                new Date(`${item?.date}T${item?.horaire_de_visite}`)
              ).format("HH:mm"),
              end_time: moment(new Date(`${item?.date}T${endTime}`)).format(
                "HH:mm"
              ),
              groupId: item?.slug,
            };
          });
        // console.log(rdvEvents, "rdvEvents");
        setEvents(rdvEvents);
      } else {
        setEvents([]);
      }
      // console.log("data", data, "rdv", rdvs);
    }
  }, [isSuccess, isDone, rdvs, data, user]);

  const onClickEvent = (arg) => {
    setSelectedEvent(arg?.event);
    if (isSecretaire(userInfos)) {
      if (
        !arg?.event?.extendedProps?.dispo &&
        arg?.event?.extendedProps?.type === "rdv"
      ) {
        navigate(`/secretaire-medical/rendez-vous/${arg?.event?.groupId}`);
      }
    } else {
      if (
        !arg?.event?.extendedProps?.dispo &&
        arg?.event?.extendedProps?.type === "rdv"
      ) {
        let rdv = rdvs?.find((el) => el?.slug === arg?.event?.groupId);
        if (rdv?.status !== "terminer") {
          navigate(`/medecin/rendez-vous/${rdv?.patient?.slug}`, {
            state: rdv,
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: `Ce rendez-vous a été officiellement clôturé.`,
            text: "Toute information ou ajout ultérieur sera traité comme une note additionnelle.",
            showConfirmButton: true,
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            showCancelButton: true,
          }).then((result) => {
            if (result?.isConfirmed) {
              navigate(`/medecin/rendez-vous/${rdv?.patient?.slug}`, {
                state: rdv,
              });
            }
          });
        }
      }
    }

    // console.log(arg?.event);
  };
  return (
    <div className="demo-app">
      <div className="custom-width-100 demo-app-main">
        <FullCalendar
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            rrulePlugin,
          ]}
          headerToolbar={{
            left: "prev,next",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          // dateClick={(arg) => console.log("click date", arg)}
          eventClick={(arg) => onClickEvent(arg)}
          aspectRatio={6}
          height={700}
          initialView="timeGridWeek"
          editable={false}
          selectable={true}
          selectMirror={false}
          dayMaxEvents={false}
          weekends={true}
          locales={[frLocale]}
          slotLaneClassNames="teste-class"
          eventContent={(arg, ele) => EventRenderItem(arg, ele, setShow, data)}
          events={events}
        />
        <AddOrEditRdvEventModal
          show={show}
          setShow={setShow}
          date={selectedEvent?.start}
          user={user}
        />
      </div>
    </div>
  );
}
