import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  cleannerError,
  generateDisponibilites,
  getName,
  isAdmin,
  useUser,
  validatePhone,
} from "../../../../utils/Utils";
import { IRdvs, RdvsFormData } from "../../../../utils/api/rdvs/rdvs.type";
import {
  useAddOrUpdateRdvMutation,
  useDeleteRdvMutation,
  useLazyGetRdvsFreePraticienQuery,
} from "../../../../utils/api/rdvs/rdvs.api";
import {
  isCabinet,
  isMedecin,
  isSecretaire,
} from "../../../../routes/routerUtils";
import {
  useGetPatientsByCabinetQuery,
  useGetPraticienByCabinetQuery,
} from "../../../../utils/api/user/user.api";
import { Data } from "../../../common/GoogleInput";
import { Color } from "../../../../utils/theme";
import { useLazyGetDisponibiliteByMedecinQuery } from "../../../../utils/api/disponibilite/disponibilite.api";
import moment from "moment";
import { current } from "@reduxjs/toolkit";

function UseAddOrEditRdvForm(
  item?: IRdvs,
  setShow?: Dispatch<SetStateAction<boolean>>
) {
  const [showFields, setShowFields] = useState(false);
  const [address, setAddress] = useState("");
  const [contenu, setContenu] = useState("");
  const [phone, setPhone] = useState<any>();
  const [code, setCode] = useState("");
  const [search, setSearch] = useState("");
  const [searchPraticien, setSearchPraticien] = useState("");
  const [options, setOptions] = useState<{ label: string; value: number }[]>(
    []
  );
  const [option, setOption] = useState<any>(null);

  const [optionsPraticien, setOptionsPraticien] = useState<any>([]);
  const [optionPraticien, setOptionPraticien] = useState<any>(null);

  const validationSchema = yup.object().shape({
    exist: yup.boolean(),
    nom: yup.string().required().label("Le nom"),
    prenom: yup.string().required().label("Le prénom"),
    civilite: yup.string().required().label("Le genre").nullable(),
    adresse: yup.string().required().label("L'adresse").nullable(),
    date_de_naissance: yup
      .string()
      .required()
      .label("La date de naissance")
      .nullable(),
    motif: yup.string().required().label("Le motif").nullable(),
    date: yup.string().required().label("La date").nullable(),
    horaire_de_visite: yup
      .string()
      .required()
      .label("L'heure de visite")
      .nullable(),
    telephone: validatePhone(yup, code),
    email: yup.string().email().required().label("L'email"),
    patient: yup
      .number()
      .when("exist", {
        is: true,
        then: yup
          .number()
          .required()
          .label("L'hôpital ou clinique affilié(e)")
          .transform((val: any) => (isNaN(val) ? null : val))
          .nullable(),
      })
      .transform((val: any) => (isNaN(val) ? null : val))
      .nullable(),
    medecin: yup
      .number()
      .required()
      .label("Le médecin")
      .transform((val: any) => (isNaN(val) ? null : val))
      .nullable(),
    instructions: yup
      .string()
      .label("L'instruction")
      .nullable()
      .transform((val) => (val === "<p><br></p>" ? "" : val)),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
    reset,
  } = useForm<RdvsFormData>({
    resolver: yupResolver(validationSchema),
  });
  const user = useUser();
  const [events, setEvents] = useState<any>([]);
  const [getData, { data: disponibilities = [], isSuccess: isDone }] =
    useLazyGetDisponibiliteByMedecinQuery();
  const [getRdv, { data: rdvs = [], isSuccess: isSucc }] =
    useLazyGetRdvsFreePraticienQuery();
  const { data = { results: [] } } = useGetPatientsByCabinetQuery({
    q: search,
    limit: 50,
    slug: isCabinet(user) ? user?.slug : user?.structure?.slug,
  });
  const { data: praticiens = { results: [] } } = useGetPraticienByCabinetQuery({
    q: searchPraticien,
    limit: 50,
    slug: isCabinet(user) ? user?.slug : user?.structure?.slug,
  });
  const navigate = useNavigate();
  const [sendData, { isLoading, isSuccess, isError, error }] =
    useAddOrUpdateRdvMutation();

  useEffect(() => {
    if (optionPraticien?.slug) {
      getRdv(optionPraticien?.slug);
      getData({ slug: optionPraticien?.slug });
    }
  }, [optionPraticien]);

  useEffect(() => {
    if (isSucc && isDone) {
      let initEvents: any = [];
      if (disponibilities?.length) {
        disponibilities.forEach((item) => {
          const disponibilites = generateDisponibilites(
            item,
            rdvs?.filter((el) => el?.status !== "annuler")
          );
          initEvents = [...initEvents, ...disponibilites];
        });
        setEvents(initEvents);
      } else if (rdvs?.length) {
        let rdvEvents = rdvs
          ?.filter((el) => el?.status !== "annuler")
          ?.map((item) => {
            const [heure, minute] = item?.horaire_de_visite?.split(":");
            const originalTime = new Date();
            originalTime.setHours(Number(heure), Number(minute), 0);
            const endTime = moment(
              new Date(originalTime.getTime() + 45 * 60000)
            ).format("HH:mm");

            return {
              start: new Date(`${item?.date}T${item?.horaire_de_visite}`),
              end: new Date(`${item?.date}T${endTime}`),
              title: `Rendez-vous avec ${getName(item?.patient)}`,
              id: item?.id,
              backgroundColor: "red",
              borderColor: "red",
              dispo: false,
              start_time: moment(
                new Date(`${item?.date}T${item?.horaire_de_visite}`)
              ).format("HH:mm"),
              end_time: moment(new Date(`${item?.date}T${endTime}`)).format(
                "HH:mm"
              ),
            };
          });
        // console.log(rdvEvents, "rdvEvents");
        setEvents(rdvEvents);
      } else {
        setEvents([]);
      }
      // console.log("data", data, "rdv", rdvs);
    }
  }, [isSucc, isDone, rdvs, disponibilities, user]);

  useEffect(() => {
    if (isCabinet(user)) {
      setValue("structure", user?.id);
    } else if (isSecretaire(user)) {
      setValue("structure", user?.structure?.id);
      setValue("created_by_secretaire", user?.id);
    } else if (isMedecin(user)) {
      setValue("structure", user?.structure?.id);
      setValue("medecin", user?.id);
    }
  }, [user]);

  useEffect(() => {
    if (data?.results?.length) {
      setOptions(
        data?.results?.map((el) => {
          return {
            label: getName(el),
            value: el?.id,
            ...el,
          };
        })
      );
    }

    if (praticiens?.results?.length) {
      setOptionsPraticien(
        praticiens?.results?.map((el) => {
          return {
            label: getName(el),
            value: el?.id,
            ...el,
          };
        })
      );
    }
  }, [data, praticiens]);

  const handleAddNewPatient = () => {
    setValue("exist", false);
    setShowFields(true);
  };

  const onChangeAddress = (val: Data) => {
    setAddress(val?.rue);
    setValue(`adresse`, val?.rue);
    setValue(`latitude`, parseFloat(val?.latitude).toFixed(6));
    setValue(`longitude`, parseFloat(val?.longitude).toFixed(6));
  };

  const onChange = (val: string) => {
    setContenu(val);
    setValue("instructions", val);
  };
  const handleSelectPraticien = (val) => {
    setOptionPraticien(val);
    setValue("medecin", val?.value);
  };

  const handleSelectPatient = (val) => {
    setOption(val);
    setValue(`patient`, val?.value);
    setValue("prenom", val?.prenom);
    setValue("nom", val?.nom);
    setValue("email", val?.email);
    setValue("telephone", val?.telephone);
    setValue("adresse", val?.adresse);
    setValue("civilite", val?.civilite);
    setValue("date_de_naissance", val?.date_de_naissance);
    setPhone(val?.telephone);
    setAddress(val?.adresse);
  };

  const handleChangePhone = (item: any, country: any) => {
    setPhone(item);
    setCode(country?.countryCode);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue("telephone", item);
    } else {
      setValue("telephone", "");
    }
  };

  const handleReset = () => {
    if (setShow) {
      setShow(false);
      reset();
      setPhone("+221");
      setAddress("");
      // setOptionPraticien(null);
      setContenu("");
      setOption(null);
      setShowFields(false);
      setValue("exist", true);
      setValue("medecin", optionPraticien?.id);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: !item
          ? "Rendez-vous ajouté avec succès"
          : "Le rendez-vous a été modifié avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (setShow) {
          handleReset();
        } else {
          if (isAdmin(user)) {
            navigate("/admin/rendez-vous");
          } else if (isCabinet(user)) {
            navigate("/cabinet/rendez-vous");
          } else if (isMedecin(user)) {
            navigate("/medecin/rendez-vous");
          } else {
            navigate("/secretaire-medical/rendez-vous");
          }
        }
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    // console.log("errors", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (item?.id) {
      
      let fields: (keyof RdvsFormData)[] = [
        "structure",
        "medecin",
        "patient",
        "motif",
        "date",
        "horaire_de_visite",
        "instructions",
      ];

      if (item?.patient?.telephone) {
        setPhone(item?.patient?.telephone);
      }
      if (item?.patient) {
        setValue("prenom", item?.patient?.prenom);
        setValue("nom", item?.patient?.nom);
        setValue("email", item?.patient?.email);
        setValue("telephone", item?.patient?.telephone);
        setValue("adresse", item?.patient?.adresse);
        setValue("civilite", item?.patient?.civilite);
        setValue("date_de_naissance", item?.patient?.date_de_naissance);
        setOption({
          label: getName(item?.patient),
          value: item?.patient?.id,
        });
      }

      if (item?.patient?.adresse) {
        setAddress(item?.patient?.adresse);
      }

      if (item?.medecin) {
        setOptionPraticien({
          label: getName(item?.medecin),
          value: item?.medecin?.id,
        });
      }

      if (item?.instructions) {
        setContenu(item?.instructions);
      }

      for (let field of fields) {
        register(field);
        if (
          field !== "structure" &&
          field !== "medecin" &&
          field !== "patient"
        ) {
          setValue(field, item[field]);
        } else {
          setValue(field, item[field]?.id);
        }
      }
    }
  }, [item]);

  const onSubmit = async (data: RdvsFormData) => {
    let dispo = events?.find((el) => {
      let date = moment(el?.start)?.format("YYYY-MM-DD");
      if (
        moment(date, "YYYY-MM-DD")?.isSame(
          moment(data?.date, "YYYY-MM-DD"),
          "d"
        ) &&
        moment(el?.start_time, "HH:mm")?.isSame(
          moment(data?.horaire_de_visite, "HH:mm"),
          "hour"
        )
      ) {
        if (
          moment(el?.start_time, "HH:mm")?.isSame(
            moment(data?.horaire_de_visite, "HH:mm"),
            "minute"
          )
        ) {
          return true;
        }

        return false;
      }
      return false;
    });

    // console.log("dispo", dispo);
    const fd = new FormData();

    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }

    if (dispo && dispo?.dispo === true) {
      await sendData({
        slug: item?.slug,
        data: fd,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: `Ce médecin n'est pas disponible à cette date pour un rendez-vous!`,
        text: "Veuillez consulter son agenda.",
        showConfirmButton: false,
        timer: 5000,
      });
    }
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChangePhone,
    handleSelectPraticien,
    Controller,
    control,
    phone,
    address,
    options,
    option,
    optionsPraticien,
    optionPraticien,
    handleSelectPatient,
    setSearchPraticien,
    setSearch,
    onChange,
    onChangeAddress,
    contenu,
    showFields,
    handleAddNewPatient,
    handleReset,
  };
}

export default UseAddOrEditRdvForm;

export function UseEditStatutRdv(item: IRdvs) {
  const [sendData] = useAddOrUpdateRdvMutation();
  const onEdit = async () => {
    await Swal.fire({
      title: `Status du rendez-vous`,
      input: "select",
      inputValue: item?.status,
      inputOptions: {
        en_attente: "En attente",
        en_cours: "En cours",
        annuler: "Annuler",
        terminer: "Terminer",
      },
      inputPlaceholder: "Selectionner un statut",
      showCancelButton: true,
      confirmButtonText: "Modifier",
      cancelButtonText: "Annuler",
      reverseButtons: true,
      confirmButtonColor: Color.success,
      showLoaderOnConfirm: true,

      preConfirm: (status) => {
        // console.log("statut", status);
        if (!status) {
          return Swal.showValidationMessage(`Veuillez sélectionner un statut`);
        }
        return sendData({
          slug: item?.slug,
          data: { status: status },
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le statut du rendez-vous a été modifié avec succès!`,
            iconColor: Color.success,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onEdit;
}

export function UseDeleteRdv(slug: string, redirectRoute?: string) {
  const [sendData] = useDeleteRdvMutation();
  const navigate = useNavigate();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce rendez-vous ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le rendez-vous a été supprimée avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            if (redirectRoute) {
              navigate(redirectRoute);
            }
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}

export function UseCancelRdv(item: IRdvs) {
  const [sendData] = useAddOrUpdateRdvMutation();
  const onCancel = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir annuler ce rendez-vous ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData({
          slug: item?.slug,
          data: { status: "annuler" },
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le rendez-vous a été annulé avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onCancel;
}

export function UseCloseRdv(item: IRdvs) {
  const navigate = useNavigate();
  const [sendData] = useAddOrUpdateRdvMutation();
  const onClose = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir fermer ce rendez-vous ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.default,
      confirmButtonColor: Color.default,
      preConfirm: () => {
        return sendData({
          slug: item?.slug,
          data: { status: "terminer" },
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le rendez-vous a été fermé avec succès!`,
            iconColor: Color.default,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            navigate(`/medecin/rendez-vous`);
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onClose;
}
