import React, { useState } from "react";
import AllDetailPatientNotesTable from "../../../Tables/AllDetailPatientNotesTable";
import { IUser } from "../../../../../utils/api/user/user.type";
import { IRdvs } from "../../../../../utils/api/rdvs/rdvs.type";
import { RiFileEditLine } from "react-icons/ri";
import AddNoteModal from "../../../../patient/Modal/AddNoteModal";

function Notes({ item, rdv }: { item: IUser; rdv?: IRdvs }) {
  const [modalAddNote, setModalAddNote] = useState<boolean>(false);
  function openModalAddNote(e: any) {
    e.preventDefault();
    setModalAddNote(true);
  }
  return (
    <>
      {!!rdv && rdv?.status !== "annuler" && (
        <div className="add-notes-btn-content d-flex flex-r pb-4">
          <button className="add-notes-btn" onClick={openModalAddNote}>
            <RiFileEditLine className="add-icon" />
            Ajouter une note
          </button>
          <AddNoteModal
            modalAddNote={modalAddNote}
            setModalAddNote={setModalAddNote}
            rdv={rdv}
          />
        </div>
      )}

      <AllDetailPatientNotesTable item={item} rdv={rdv} />
    </>
  );
}

export default Notes;
