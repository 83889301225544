import { IRdvs } from "../../../../utils/api/rdvs/rdvs.type";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Color } from "../../../../utils/theme";
import { cleannerError } from "../../../../utils/Utils";
import { Note, NoteFormData } from "../../../../utils/api/note/note.type";
import {
  useAddOrUpdateNoteMutation,
  useDeleteNoteMutation,
} from "../../../../utils/api/note/note.api";

function UseAddNoteForm(
  item: IRdvs,
  setClose: Dispatch<SetStateAction<boolean>>,
  note?: Note
) {
  const validationSchema = yup.object().shape({
    titre: yup.string().required().label("Le titre").nullable(),
    contenu: yup
      .string()
      .required()
      .label("Le contenu")
      .nullable()
      .transform((val: string) => (val === "<p><br></p>" ? "" : val)),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
    resetField,
  } = useForm<NoteFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [contenu, setContenu] = useState("");
  const [sendData, { isLoading, isSuccess, isError, error }] =
    useAddOrUpdateNoteMutation();

  const onChange = (val) => {
    setContenu(val);
    setValue("contenu", val);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `La note a été ${!note ? "créée" : "modifiée"} avec succès`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        setClose(false);
        if (!note) {
          resetField("contenu");
          resetField("titre");
          setContenu("");
        }
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (note?.id) {
      let fields: (keyof NoteFormData)[] = ["contenu", "titre", "slug", "rdv"];

      setContenu(note?.contenu);

      for (let field of fields) {
        register(field);
        if (field !== "rdv") {
          setValue(field, note[field]);
        } else {
          if (note[field]) setValue(field, note[field]?.id);
        }
      }
    }
  }, [note]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (item?.id) {
      setValue("rdv", item?.id);
    }
  }, [item]);

  const onSubmit = async (data: NoteFormData) => {
    await sendData(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    control,
    resetField,
    onChange,
    contenu,
    setContenu,
  };
}

export default UseAddNoteForm;

export function UseDeleteNote(slug: string) {
  const [sendData] = useDeleteNoteMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette note ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `La note a été supprimée avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}
