import React, { useEffect, useState } from "react";
import Sommaire, { SommaireSkeleton } from "./Sommaire";
import "./MessagerieAdmin.css";
import DetailsMessagerieAdmin from "./DetailsMessagerieAdmin";
import { MdOutlineAddBox } from "react-icons/md";
import NewChatModal from "./modal/NewChatModal";
import { getName, useLocationState, useUser } from "../../../utils/Utils";
import {
  useGetChatItemQuery,
  useMajMessageMutation,
} from "../../../utils/api/chat/chat.api";
import { Conversation } from "../../../utils/api/chat/chat.type";
import { IUser } from "../../../utils/api/user/user.type";
import socket from "../../../utils/socket-config";

function MessagerieAdmin() {
  const [modalNewChat, setModalNewChat] = useState<boolean>(false);
  const user = useUser();
  const [search, setSearch] = useState("");
  const [updateChat] = useMajMessageMutation();
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const { data = [], isLoading, refetch } = useGetChatItemQuery(user?.slug);
  const [selectedChat, setSelectedChat] = useState<Conversation | null>(null);
  const sUser = useLocationState<IUser>(null);

  useEffect(() => {
    if (!isLoading) refetch();
  }, []);

  useEffect(() => {
    socket.on("messageResponse", (data) => {
      if ([data?.sender, data?.receiver].includes(user?.id)) {
        // setSenderActive(data?.sender);
        // setReceiverActive(data?.receiver);
        // console.log("socket", data);
        refetch();
      }
    });
    return () => {
      socket.off("messageResponse");
    };
  }, [socket]);

  useEffect(() => {
    if (!selectedUser) {
      if (sUser && user?.id !== sUser?.id) {
        setSelectedUser(sUser);
      } else {
        if (data.length > 0) {
          let item = [...data]?.sort(
            (a, b) => b?.last_message?.id - a?.last_message?.id
          )[0];
          if (user?.id !== item?.last_message?.receiver?.id) {
            setSelectedUser(item?.last_message?.receiver);
          } else {
            setSelectedUser(item?.last_message?.sender);
          }
        }
      }
    }
  }, [sUser, data, selectedUser, user]);
  useEffect(() => {
    if (selectedUser) {
      // console.log("selectedUser", selectedUser);
      setSelectedChat(
        data.find(
          (l) =>
            l?.last_message?.receiver?.id === selectedUser?.id ||
            l?.last_message?.sender?.id === selectedUser?.id
        ) || null
      );
    } else {
      setSelectedChat(null);
    }
  }, [selectedUser, data]);

  function openModalNewChat(e: any) {
    e.preventDefault();
    setModalNewChat(true);
  }

  const onSelectedUser = (item: Conversation) => {
    if (user?.id !== item?.last_message?.receiver?.id) {
      // console.log("here rece");
      setSelectedUser(item?.last_message?.receiver);
    } else {
      // console.log("sender");
      setSelectedUser(item?.last_message?.sender);
    }
  };

  useEffect(() => {
    if (selectedChat) {
      setTimeout(() => {
        const promises = selectedChat?.messages
          .filter((chat) => {
            return !chat?.read && chat?.sender?.id !== user?.id;
          })
          .map((chat) =>
            updateChat({ slug: chat?.slug, data: { read: true } })
          );
        Promise.all(promises).then((res) =>
          console.log("marked as read", res?.length)
        );
      }, 2000);
    }
  }, [selectedChat, user]);


  const s = search?.trim()?.toLowerCase();
  return (
    <div className="content-graph-admin">
      <div className="admin-page-title-header-container">
        <div className="container-title-dash d-flex align-items-center gap-4">
          <div className="custom-sm-top">
            <h2 className="custom-sm-top-title mb-0">Messages</h2>
            <button
              className="btn custom-add-item-btn"
              onClick={openModalNewChat}
            >
              <span className="add-icon pe-2">
                <MdOutlineAddBox />
              </span>
              <span>Nouvelle discussion</span>
            </button>
            <NewChatModal
              modalNewChat={modalNewChat}
              setModalNewChat={setModalNewChat}
              onSelect={setSelectedUser}
            />
          </div>
        </div>
        <div className="admin-tabs-container">
          <div className="tabs-component-container-admin">
            <div className="users-network-connect-list mt-4">
              <div className="no-view-mobile">
                <div className="row">
                  <div className="col-md-4">
                    <div className="parent-table-container-message-tabs custom-parent-table-container-message-tabs">
                      {/* <div className="container-btn-nav-message">
                        <div className="btn-message-item">
                          <button className="btn btn-nav-message active">
                            Tout
                          </button>
                        </div>
                        <div className="btn-message-item">
                          <button className="btn btn-nav-message">
                            Non lus
                          </button>
                        </div>
                        <div className="btn-message-item">
                          <button className="btn btn-nav-message">
                            Marquer tout comme lu
                          </button>
                        </div>
                      </div> */}
                      <div className="bloc-search-message mb-3 position-relative">
                        <form>
                          <input
                            className="form-control"
                            type="search"
                            placeholder="Rechercher"
                            onChange={(e) => setSearch(e?.target?.value)}
                          />
                          <button type="submit" value="search">
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </button>
                        </form>
                      </div>
                      <div>
                        <p className="titre-messages-bloc pb-3">Message</p>
                      </div>
                      <ul className="list-group list-group-message list-group-flush">
                        {!!isLoading && <SommaireSkeleton />}
                        {!isLoading &&
                          (s
                            ? [...data]
                                ?.sort(
                                  (a, b) =>
                                    b?.last_message?.id - a?.last_message?.id
                                )
                                ?.filter((i) =>
                                  i?.last_message?.sender?.id !== user?.id
                                    ? getName(i?.last_message?.sender)
                                        ?.toLocaleLowerCase()
                                        ?.includes(s)
                                    : getName(i?.last_message?.receiver)
                                        ?.toLocaleLowerCase()
                                        ?.includes(s)
                                )
                            : [...data]?.sort(
                                (a, b) =>
                                  b?.last_message?.id - a?.last_message?.id
                              )
                          )?.map((item) => (
                            <Sommaire
                              item={item}
                              onClick={() => onSelectedUser(item)}
                              key={item?.slug}
                              active={selectedChat?.id === item?.id}
                              selectedUser={selectedUser}
                            />
                          ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <DetailsMessagerieAdmin
                      user={selectedUser}
                      messages={selectedChat?.messages || []}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              </div>
              <div className="no-view-desktop">
                <div className="bloc-content-message-mobile">
                  <div className="bloc-search-message mb-3 position-relative">
                    <form>
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Rechercher"
                        onChange={(e) => setSearch(e?.target?.value)}
                      />
                      <button type="submit" value="search">
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </button>
                    </form>
                  </div>
                  <div className="parent-table-container-message-tabs custom-parent-table-container-message-tabs">
                    <div>
                      <p className="titre-messages-bloc ps-3 pb-3">
                        Vos discussions
                      </p>
                    </div>
                    <ul className="list-group list-group-message list-group-flush">
                      {!!isLoading && <SommaireSkeleton />}
                      {!isLoading &&
                        (s
                          ? [...data]
                              ?.sort(
                                (a, b) =>
                                  b?.last_message?.id - a?.last_message?.id
                              )
                              ?.filter((i) =>
                                i?.last_message?.sender?.id !== user?.id
                                  ? getName(i?.last_message?.sender)
                                      ?.toLocaleLowerCase()
                                      ?.includes(s)
                                  : getName(i?.last_message?.receiver)
                                      ?.toLocaleLowerCase()
                                      ?.includes(s)
                              )
                          : [...data]?.sort(
                              (a, b) =>
                                b?.last_message?.id - a?.last_message?.id
                            )
                        )?.map((item) => (
                          <Sommaire
                            item={item}
                            onClick={() => onSelectedUser(item)}
                            key={item?.slug}
                            active={selectedChat?.id === item?.id}
                            selectedUser={selectedUser}
                          />
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessagerieAdmin;
