import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { IRdvs, RdvsFormData } from "./rdvs.type";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl, useLocationState } from "../../Utils";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const RdvsApi = createApi({
  reducerPath: "rdvsApi",
  tagTypes: [
    "rdvs",
    "rdv",
    "rdvByPraticien",
    "rdvByCabinet",
    "upcommingRdvByPraticien",
    "rdvByPatient",
    "upcommingRdvByPatient",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    addOrUpdateRdv: builder.mutation<
      IRdvs,
      { slug?: string; data: RdvsFormData | FormData }
    >({
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `rdvs/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `rdvs/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [
        "rdvs",
        "rdv",
        "rdvByCabinet",
        "rdvByPraticien",
        "upcommingRdvByPraticien",
        "rdvByPatient",
        "upcommingRdvByPatient",
      ],
    }),
    getRdvs: builder.query<PaginationResults<IRdvs>, TypeQuery>({
      query: (query) => QueryUrl(`rdvs/`, query),
      providesTags: ["rdvs"],
    }),
    getRdvBySlug: builder.query<IRdvs, string>({
      query: (slug) => `rdvs/${slug}/`,
      providesTags: ["rdv"],
    }),
    getRdvsByPraticien: builder.query<PaginationResults<IRdvs>, TypeQuery>({
      query: ({ slug, ...query }) => QueryUrl(`medecin/${slug}/rdvs/`, query),
      providesTags: ["rdvByPraticien"],
    }),
    getRdvsFreePraticien: builder.query<IRdvs[], string>({
      query: (slug) => `mobile/medecin/${slug}/rdvs/`,
      providesTags: ["rdvByPraticien"],
    }),
    upcommingRdvByPraticien: builder.query<PaginationResults<IRdvs>, TypeQuery>(
      {
        query: ({ slug, ...query }) =>
          QueryUrl(`medecin/${slug}/prochains_rdvs/`, query),
        providesTags: ["upcommingRdvByPraticien"],
      }
    ),
    getRdvsByCabinet: builder.query<PaginationResults<IRdvs>, TypeQuery>({
      query: ({ slug, ...query }) => QueryUrl(`cabinet/${slug}/rdvs/`, query),
      providesTags: ["rdvByCabinet"],
    }),
    getRdvsByPatient: builder.query<PaginationResults<IRdvs>, TypeQuery>({
      query: ({ slug, ...query }) => QueryUrl(`patient/${slug}/rdvs/`, query),
      providesTags: ["rdvByPatient"],
    }),
    upcommingRdvByPatient: builder.query<PaginationResults<IRdvs>, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`patient/${slug}/prochains_rdvs/`, query),
      providesTags: ["upcommingRdvByPatient"],
    }),
    deleteRdv: builder.mutation<RdvsFormData, string>({
      query: (slug) => ({
        url: `rdvs/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "rdvs",
        "rdvByCabinet",
        "rdvByPraticien",
        "upcommingRdvByPraticien",
        "rdvByPatient",
        "upcommingRdvByPatient",
      ],
    }),
  }),
});
export const {
  useAddOrUpdateRdvMutation,
  useDeleteRdvMutation,
  useGetRdvsQuery,
  useGetRdvBySlugQuery,
  useGetRdvsByPraticienQuery,
  useGetRdvsByCabinetQuery,
  useLazyGetRdvBySlugQuery,
  useUpcommingRdvByPraticienQuery,
  useGetRdvsByPatientQuery,
  useUpcommingRdvByPatientQuery,
  useGetRdvsFreePraticienQuery,
  useLazyGetRdvsFreePraticienQuery
} = RdvsApi;

export function useRdvFromLocation(): [
  IRdvs,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<IRdvs | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading }] = useLazyGetRdvBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findBySlug(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      
      setItem(data);
    }
  }, [data]);

  return [item as IRdvs, isLoading, slug as string, findBySlug];
}
