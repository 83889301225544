import React from "react";
import { Medicament } from "../../../utils/api/ordonnance/ordonnance.type";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import { MdOutlineModeEdit } from "react-icons/md";
import AddOrEditMedicamentModal from "../rdvMedecin/modal/AddOrEditMedicamentModal";
import { showModal } from "../../../utils/Utils";

function OrdonnanceItemsTable({ items = [],type }: { items: Medicament[],type?:string }) {
  const actionFormatter = (cell: string, row: Medicament) => {
    return (
      <>
        <div className="d-flex justify-content-center gap-3">
          <div className="container-btn-action-icon-modal">
            <button
              className="btn with-tooltip btn-action-icon-edit p-0"
              data-tooltip-content="Modifier"
              type="button"
              onClick={() => showModal(`editMedicamentModal${cell}`)}
            >
              <MdOutlineModeEdit />
            </button>
          </div>
        </div>
        <AddOrEditMedicamentModal
          item={row}
          modalId={`editMedicamentModal${cell}`}
        />
      </>
    );
  };
  const nameFormatter = (cell: string,row:Medicament) => {
    return (
      <div>
        <span>
          {" "}
          {cell} <b>{row?.is_updated === true && type === "rdv" ? `(addendum)` : ""}</b>
        </span>
      </div>
    );
  };
  const columns = [
    {
      dataField: "nom",
      text: `Nom du médicament`,
      formatter: nameFormatter,
    },
    {
      dataField: "posologie",
      text: "Posologie",
    },
    {
      dataField: "quantite",
      text: "Quantité",
    },
    {
      dataField: "duree",
      text: "Durée",
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={items}
        columns={columns}
        condensed
        hover
        rowClasses="custom-table-row-class"
        wrapperClasses="table-responsive admin-table"
        noDataIndication={() => (
          <AlertInfo message="Aucun médicament disponible" />
        )}
      />

      {/* <div className="custom-pagination-container">
              <CustomPagination
                  nbPages={FacturePraticienDatas?.length}
                  page={page}
                  onChange={(page, perPage) => {
                  setPerPage(perPage)
                  setPage(page)
                  }}
                  perPage={perPage}
              />
              </div> */}
    </>
  );
}

export default OrdonnanceItemsTable;
