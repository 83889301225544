import React from "react";
import { BtnSubmit } from "../../common/Button";
import { Analyse } from "../../../utils/api/analyse/analyse.type";
import UseAddOrEditAnalyseForm from "../form/UseAddOrEditAnalyseForm";
import { Input } from "../../common/Input";

function JointResultsFileModal({
  modalId,
  item,
}: {
  modalId: string;
  item: Analyse;
}) {
  const { handleChange, onSubmit, isLoading, errors, handleReset } =
    UseAddOrEditAnalyseForm(modalId, item);
  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby={modalId + "Label"}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id={modalId + "Label"}>
              Résultats
            </h1>
            <button
              type="button"
              className="btn-close"
              //   data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => handleReset()}
            ></button>
          </div>
          <div className="modal-body">
            <div className="pt-3">
              <form onSubmit={onSubmit}>
                <div className="row auth-form-row">
                  <div className="col-md-12 mb-3">
                    <Input
                      label="Fichier"
                      type="file"
                      onChange={handleChange}
                      accept=".pdf, .doc, .docx"
                      error={errors?.resultat}
                    />
                  </div>

                  <div className="flex-r">
                    <button
                      className="btn btn-cancelled"
                      type="button"
                      //   data-bs-dismiss="modal"
                      onClick={() => handleReset()}
                    >
                      Annuler
                    </button>
                    <BtnSubmit
                      label="Enregistrer"
                      isLoading={isLoading}
                      customClass="w-auto"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JointResultsFileModal;
