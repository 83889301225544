/* eslint-disable @typescript-eslint/no-explicit-any */
import { IRdvs } from "../../../../utils/api/rdvs/rdvs.type";
import { useEffect } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Color } from "../../../../utils/theme";
import { cleannerError } from "../../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import { ScannerFormData } from "../../../../utils/api/scanner/scanner.type";
import { useAddOrUpdateScannerMutation } from "../../../../utils/api/scanner/scanner.api";

function UseAddScannerForm(item: IRdvs) {
  const validationSchema = yup.object().shape({
    type_scanner: yup.string().required().label("Le type de scanner").nullable(),
    raisons: yup.string().required().label("La raison").nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
  } = useForm<ScannerFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [sendData, { isLoading, isSuccess, isError, error }] =
    useAddOrUpdateScannerMutation();
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "La demande de scanner a été créée avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        navigate(`/medecin/rendez-vous/${item?.slug}`, {
          state: item,
        });
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    // console.log("cabinet", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (item?.id) {
      setValue("rdv", item?.id);
    }
  }, [item]);

  const onSubmit = async (data: ScannerFormData) => {
    await sendData(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    control,
  };
}

export default UseAddScannerForm;
