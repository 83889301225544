import React, { useState } from "react";
import { AlertInfo } from "../../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../../common/TableSkeleton";
import { useNavigate } from "react-router-dom";
import "./Table.css";
import { useGetRdvsByPraticienQuery } from "../../../../utils/api/rdvs/rdvs.api";
import {
  formatDate,
  formatHour,
  getAvatar,
  getName,
  useUser,
} from "../../../../utils/Utils";
import { IRdvs } from "../../../../utils/api/rdvs/rdvs.type";
import { IUser } from "../../../../utils/api/user/user.type";
import { CustomPagination } from "../../../common/CustomPagination";
import { BtnDeleteRdv } from "../../../secretaire/Tables/ActiviteRdvSecretaireTable";
import moment from "moment";
import Swal from "sweetalert2";

function ActiviteRdvTable() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const user = useUser();
  const { data = { results: [], count: 0 }, isLoading } =
    useGetRdvsByPraticienQuery({
      slug: user?.slug,
      limit,
      page,
      date: moment(new Date()).format("DD-MM-YYYY"),
    });
  

  const actionFormatter = (cell: string) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <BtnDeleteRdv slug={cell} />
          </div>
        </div>
      </>
    );
  };
  const nameFormatter = (cell: IUser) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <div className="content-img-profil-praticient-table">
          <img
            src={getAvatar(cell?.avatar)}
            alt={cell?.prenom}
            className="img-profil-praticient-table"
          />
        </div>
        <p className="name-profil-patient-table mb-0">{getName(cell)}</p>
      </div>
    );
  };

  const columns = [
    {
      dataField: "patient",
      text: `Noms et Photos`,
      formatter: nameFormatter,
      headerStyle: () => {
        return { width: "200px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "patient.email",
      text: "Email",
    },
    {
      dataField: "date",
      text: "Date",
      formatter: (cell: string) => formatDate(cell),
      headerStyle: () => {
        return { width: "100px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "horaire_de_visite",
      text: "Heure de visite",
      formatter: (cell: string) => formatHour(cell),
      headerStyle: () => {
        return { width: "150px", whiteSpace: "normal" };
      },
    },
    // {
    //   dataField: "medecin",
    //   text: "Medecin",
    // },
    {
      dataField: "motif",
      text: "Motif",
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
      headerStyle: () => {
        return { width: "100px", whiteSpace: "normal" };
      },
    },
  ];

  const rowEvents = {
    onClick: (e, row: IRdvs) => {
      let getCurrentCellIndex = e.target.cellIndex;
      let id: any = document.querySelector("table tr:last-child td:last-child");
      let getLastCellIndex = id?.cellIndex;

      if (
        getCurrentCellIndex !== getLastCellIndex &&
        getCurrentCellIndex !== undefined
      ) {
        // navigate(`/medecin/rendez-vous/${row?.slug}`, { state: row });
        if (row?.status !== "terminer") {
          navigate(`/medecin/rendez-vous/${row?.slug}`, { state: row });
        } else {
          Swal.fire({
            icon: "warning",
            title: `Ce rendez-vous a été officiellement clôturé.`,
            text: "Toute information ou ajout ultérieur sera traité comme une note additionnelle.",
            showConfirmButton: true,
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            showCancelButton: true,
          }).then((result) => {
            if (result?.isConfirmed) {
              navigate(`/medecin/rendez-vous/${row?.slug}`, { state: row });
            }
          });
        }
      }
    },
  };

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            condensed
            hover
            rowEvents={rowEvents}
            rowClasses="custom-table-row-class"
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default ActiviteRdvTable;
