import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Dashboard } from "../../../../utils/api/dashboard/dashboard.type";
import DatePicker from "react-datepicker";
import Skeleton from "react-loading-skeleton";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function LineChart({
  graph,
  isLoading,
  type,
  date,
  setDate,
  setType,
  lastGraph,
}: {
  graph: Dashboard["graph"];
  lastGraph: Dashboard["lastGraph"];
  isLoading: boolean;
  type: string;
  date: Date;
  setDate: Dispatch<SetStateAction<Date>>;
  setType: Dispatch<SetStateAction<string>>;
}) {
  const [previousDate, setPreviousDate] = useState<any>(null)
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: true,
      },
    },
  };

  const labels = graph?.map((el) => el?.date);
  // const previousDate = moment(date).subtract(7, "days").startOf("isoWeek");

  useEffect(() => {
    if (type === "week") {
      setPreviousDate(moment(date).subtract(7, "days").startOf("isoWeek"));
    }

    if (type === "month") {
      setPreviousDate(moment(date).subtract(1, 'months').startOf('month'))
    }

    if (type === "year") {
      setPreviousDate(moment(date).subtract(1, "years").startOf("year"));
    }
  }, [date,type])

  const data = {
    labels,
    datasets: [
      {
        type: "line" as const,
        label: `Patients ${type === "week" ? "Semaine du " : ""} ${moment(
          previousDate
        ).format(
          type === "year"
            ? "YYYY"
            : type === "month"
            ? "MMMM YYYY"
            : "DD/MM/YYYY"
        )}`,
        borderColor: "#FAC032",
        borderWidth: 2,
        fill: false,
        data: lastGraph?.map((el) => el?.frequentation_count),
        lineTension: 0.3,
      },
      {
        label: `Patients ${type === "week" ? "Semaine du " : ""} ${moment(
          date
        ).format(
          type === "year"
            ? "YYYY"
            : type === "month"
            ? "MMMM YYYY"
            : "DD/MM/YYYY"
        )}`,
        data: graph?.map((el) => el?.frequentation_count),
        borderColor: "#099876",
        backgroundColor: "#a8bfd51f",
        fill: true,
        lineTension: 0.3,
      },
    ],
  };

  return (
    <div className="chart-item-container">
      <div className="content-chart-title mb-4">
        <h2 className="chart-item-admin-title mb-0">Fréquentation</h2>
        <div className="filter-container">
          <div className="fc-toolbar-chunk">
            <div className="fc-button-group">
              <button
                className={`fc-timeGridWeek-button fc-button fc-button-primary btn-type-date ${
                  type === "week" ? "fc-button-active" : ""
                }`}
                onClick={() => setType("week")}
              >
                Semaine
              </button>
              <button
                className={`fc-dayGridMonth-button fc-button fc-button-primary btn-type-date ${
                  type === "month" ? "fc-button-active" : ""
                }`}
                onClick={() => setType("month")}
              >
                Mois
              </button>

              <button
                className={`fc-timeGridDay-button fc-button fc-button-primary btn-type-date ${
                  type === "year" ? "fc-button-active" : ""
                }`}
                onClick={() => setType("year")}
              >
                Année
              </button>
              {/* <button
                className={`fc-timeGridDay-button fc-button fc-button-primary btn-type-date ${
                  type === "custom" ? "fc-button-active" : ""
                }`}
                onClick={() => setType("custom")}
              >
                Intervalle
              </button> */}
            </div>
          </div>
          <div className="content-select-date-graph">
            {/* <select className="form-control form-control-select-date-graph">
              <option>Semaine du 30/11/2020</option>
            </select> */}
            <DatePicker
              selected={date}
              onChange={(date: Date) => setDate(date)}
              startDate={date}
              locale="fr"
              maxDate={new Date()}
              className="form-control form-control-select-date-graph"
              showMonthYearPicker={type === "month"}
              showYearPicker={type === "year"}
              dateFormat={
                type === "month"
                  ? "MMMM/yyyy"
                  : type === "year"
                  ? "yyyy"
                  : "dd/MM/yyyy"
              }
            />
          </div>
        </div>
      </div>
      {!isLoading && graph?.length && <Line options={options} data={data} />}
      {!!isLoading && <Skeleton height={400} />}
    </div>
  );
}

export default LineChart;
