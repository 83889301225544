/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import Modal from "react-modal";
import { InputPassword } from "../../../common/Input";
import { CloseModalBtnV2 } from "../../../shared/CloseModalBtn";
import UseFormChangePassword from "../../../admin/Profil/request/UseFormChangePassword";
import { PasswordRules } from "../../../auth/PasswordRules";

const ChangePasswordModal = ({
  modalChangePassword,
  setModalChangePassword,
}) => {
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    passwordHandleChange,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
    passwordHandleReset,
  } = UseFormChangePassword(setModalChangePassword);
  const props = {
    haveMinCharacter,
    haveMinNumber,
    haveMinLowercase,
    haveMinSpecialCharacter,
    haveMinUppercase,
  };

  function closeModalChangePassword() {
    setModalChangePassword(false);
    passwordHandleReset();
  }

  return (
    <Modal
      isOpen={modalChangePassword}
      onRequestClose={closeModalChangePassword}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal-header flex-sb">
        <h5 className="modal-title-admin">Changer de mot de passe</h5>
        <CloseModalBtnV2
          onClick={() => {
            closeModalChangePassword();
          }}
        />
      </div>

      <div className="pt-3">
        <form onSubmit={onSubmit}>
          <div className="row py-3 px-2">
            <div className="col-12 mb-3">
              <InputPassword
                label="Ancien mot de passe"
                id="password"
                placeholder="Ancien mot de passe"
                {...register("old_password")}
                error={errors?.old_password}
              />
            </div>
            <div className="col-12 mb-3">
              <InputPassword
                label="Nouveau mot de passe"
                id="new_password"
                placeholder="Nouveau mot de passe"
                name="new_password"
                onChange={passwordHandleChange}
                error={errors?.new_password}
              />
            </div>

            <div className="col-12 mb-4">
              <InputPassword
                label="Confirmer le mot de passe"
                id="confirm_password"
                placeholder="Confirmer le mot de passe"
                {...register("confirm_password")}
                error={errors?.confirm_password}
              />
            </div>
            <div className="col-12 mb-4">
              <button
                className="btn auth-submit-btn"
                type="submit"
                disabled={isLoading}
              >
                {!isLoading ? (
                  `Enregistrer`
                ) : (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>En cours...</span>
                  </>
                )}
              </button>
            </div>
            <PasswordRules {...props} />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    zIndex: 99999,
    height: "95%",
  },
};
