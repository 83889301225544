import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { Note, NoteFormData } from "./note.type";

export const NoteApi = createApi({
  reducerPath: "note",
  tagTypes: ["note", "noteList", "notesByPatient", "notesByRdv"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    // get note patient
    getNoteByPatient: builder.query<PaginationResults<Note>, TypeQuery>({
      query: ({ slug, ...query }) => QueryUrl(`/patient/${slug}/notes/`, query),
      providesTags: ["notesByPatient"],
    }),
    getNoteByRdv: builder.query<PaginationResults<Note>, TypeQuery>({
      query: ({ slug, ...query }) => QueryUrl(`/rdv/${slug}/notes/`, query),
      providesTags: ["notesByRdv"],
    }),
    getNote: builder.query<Note, string>({
      query: (slug) => `note/${slug}/`,
      providesTags: ["note"],
    }),
    getNotes: builder.query<PaginationResults<Note>, TypeQuery>({
      providesTags: ["noteList"],
      query: (query) => QueryUrl("notes/", query),
    }),
    addOrUpdateNote: builder.mutation<Note, NoteFormData>({
      query: ({ slug, ...data }) => ({
        url: slug ? `notes/${slug}/` : `notes/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["note", "notesByPatient", "noteList", "notesByRdv"],
    }),

    deleteNote: builder.mutation<NoteFormData, string>({
      query: (slug) => ({
        url: `notes/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["notesByPatient", "noteList", "notesByRdv"],
    }),
  }),
});

export const {
  useAddOrUpdateNoteMutation,
  useDeleteNoteMutation,
  useGetNoteByPatientQuery,
  useGetNoteByRdvQuery
} = NoteApi;
