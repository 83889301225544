import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { AppStorage } from "../../storage";
import { QueryUrl, useLocationState } from "../../Utils";
import { ChangePasswordData } from "../auth/auth.type";
import {
  AuthState,
  IUser,
  PaginationResults,
  TypeQuery,
  UserFormData,
} from "./user.type";
import { RdvsApi } from "../rdvs/rdvs.api";

export const prepareHeaders = (headers: Headers, { getState }) => {
  const token =
    (getState() as { user: AuthState }).user.token ??
    AppStorage.getItem<AuthState>("user")?.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const UserApi = createApi({
  reducerPath: "userApi",
  tagTypes: [
    "user",
    "userList",
    "userById",
    "adminsList",
    "dashboard",
    "cabinetList",
    "cabinet",
    "medecinsList",
    "medecin",
    "medecinsByCabinet",
    "secretairesList",
    "secretaire",
    "secretairesByCabinet",
    "patientsByCabinet",
    "patientsByMedecin",
    "patient",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    me: builder.query<IUser, void>({
      providesTags: ["user"],
      // transformResponse: ({ data }) => data,
      query: () => {
        return "auth/me/";
      },
    }),
    addOrEditUser: builder.mutation<
      IUser,
      { slug?: string; data: UserFormData | FormData }
    >({
      invalidatesTags: ["user", "adminsList", "userList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `users/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `users/`,
          method: "POST",
          body: data,
        };
      },
    }),
    addOrEditAdmin: builder.mutation<
      IUser,
      { slug?: string; data: UserFormData | FormData }
    >({
      invalidatesTags: ["user", "adminsList", "userList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `user/admins/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `user/admins/`,
          method: "POST",
          body: data,
        };
      },
    }),
    addOrEditCabinet: builder.mutation<
      IUser,
      { slug?: string; data: UserFormData | FormData }
    >({
      invalidatesTags: ["cabinetList", "cabinet", "userList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `cabinets/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `cabinets/`,
          method: "POST",
          body: data,
        };
      },
    }),
    addOrEditPraticien: builder.mutation<
      IUser,
      { slug?: string; data: UserFormData | FormData }
    >({
      invalidatesTags: [
        "medecinsList",
        "medecinsByCabinet",
        "medecin",
        "userList",
        "user",
      ],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `medecins/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `medecins/`,
          method: "POST",
          body: data,
        };
      },
    }),
    addOrEditSecretaire: builder.mutation<
      IUser,
      { slug?: string; data: UserFormData | FormData }
    >({
      invalidatesTags: [
        "secretairesList",
        "secretairesByCabinet",
        "secretaire",
      ],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `secretaires/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `secretaires/`,
          method: "POST",
          body: data,
        };
      },
    }),
    addOrEditPatient: builder.mutation<
      IUser,
      { slug?: string; data: UserFormData | FormData }
    >({
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(RdvsApi.util.invalidateTags(["rdv"]));
        }, 1000);
      },
      invalidatesTags: ["patientsByMedecin", "patientsByCabinet", "patient"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `patients/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `patients/`,
          method: "POST",
          body: data,
        };
      },
    }),
    getInfoDashbordAdmin: builder.query<any, void>({
      providesTags: ["dashboard"],
      query: () => {
        return "admin/dashboard/";
      },
    }),

    changePassword: builder.mutation<any, ChangePasswordData>({
      query: (data) => ({
        url: `auth/change-password/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["user"],
    }),
    getListUser: builder.query<PaginationResults<IUser>, TypeQuery>({
      providesTags: ["userList"],
      query: (query) => QueryUrl("users", query),
    }),
    getListCabinets: builder.query<PaginationResults<IUser>, TypeQuery>({
      providesTags: ["cabinetList"],
      query: (query) => QueryUrl("cabinets", query),
    }),
    getListAdminUser: builder.query<PaginationResults<IUser>, TypeQuery>({
      providesTags: ["adminsList"],
      query: (query) => QueryUrl("user/admins", query),
    }),
    getPraticiens: builder.query<PaginationResults<IUser>, TypeQuery>({
      query: (query) => QueryUrl(`medecins/`, query),
      providesTags: ["medecinsList"],
    }),
    getPraticienByCabinet: builder.query<PaginationResults<IUser>, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`cabinet/${slug}/medecins/`, query),
      providesTags: ["medecinsByCabinet"],
    }),
    getSecretaires: builder.query<PaginationResults<IUser>, TypeQuery>({
      query: (query) => QueryUrl(`secretaires/`, query),
      providesTags: ["secretairesList"],
    }),
    getSecretaireByCabinet: builder.query<PaginationResults<IUser>, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`cabinet/${slug}/secretaires/`, query),
      providesTags: ["secretairesByCabinet"],
    }),
    getPatientsByCabinet: builder.query<PaginationResults<IUser>, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`cabinet/${slug}/patients/`, query),
      providesTags: ["patientsByCabinet"],
    }),
    getPatientsByMedecin: builder.query<PaginationResults<IUser>, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`medecin/${slug}/patients/`, query),
      providesTags: ["patientsByMedecin"],
    }),
    getPatient: builder.query<PaginationResults<IUser>, TypeQuery>({
      query: (query) => QueryUrl(`patients/`, query),
      providesTags: ["userList"],
    }),
    userById: builder.query<IUser | any, string>({
      query: (slug) => `users/${slug}/`,
      providesTags: ["userById"],
    }),
    adminBySlug: builder.query<IUser | any, string>({
      query: (slug) => `user/admins/${slug}/`,
    }),
    cabinetBySlug: builder.query<IUser | any, string>({
      query: (slug) => `cabinets/${slug}/`,
      providesTags: ["cabinet"],
    }),
    praticienBySlug: builder.query<IUser | any, string>({
      query: (slug) => `medecins/${slug}/`,
      providesTags: ["medecin"],
    }),
    patientBySlug: builder.query<IUser | any, string>({
      query: (slug) => `patients/${slug}/`,
      providesTags: ["patient"],
    }),
    secretaireBySlug: builder.query<IUser | any, string>({
      query: (slug) => `secretaires/${slug}/`,
      providesTags: ["secretaire"],
    }),
    archiveUser: builder.mutation<IUser | any, string>({
      query: (slug) => ({
        url: `users/${slug}/`,
        method: "GET",
      }),
      invalidatesTags: ["user", "adminsList", "userList"],
    }),
    unArchiveUser: builder.mutation<IUser | any, string>({
      query: (slug) => ({
        url: `reactive_user/${slug}/`,
        method: "GET",
      }),
      invalidatesTags: ["user", "adminsList", "userList"],
    }),
    deleteUser: builder.mutation<UserFormData, string>({
      query: (slug) => ({
        url: `users/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["user", "userList", "adminsList"],
    }),
    deletePraticien: builder.mutation<UserFormData, string>({
      query: (slug) => ({
        url: `medecins/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["medecinsByCabinet", "medecinsList"],
    }),
    deleteSecretaire: builder.mutation<UserFormData, string>({
      query: (slug) => ({
        url: `secretaires/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["secretairesByCabinet", "secretairesList"],
    }),
    searchPatients: builder.query<PaginationResults<IUser>, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`medecin/${slug}/search/patient/`, query),
    }),
  }),
});

export const {
  useMeQuery,
  useAddOrEditUserMutation,
  useChangePasswordMutation,
  useGetListUserQuery,
  useAddOrEditAdminMutation,
  useGetListAdminUserQuery,
  useLazyUserByIdQuery,
  useArchiveUserMutation,
  useUnArchiveUserMutation,
  useGetPraticiensQuery,
  useGetPatientQuery,
  useDeleteUserMutation,
  useGetInfoDashbordAdminQuery,
  useAddOrEditCabinetMutation,
  useLazyCabinetBySlugQuery,
  useGetListCabinetsQuery,
  useLazyPraticienBySlugQuery,
  useAddOrEditPraticienMutation,
  useGetPraticienByCabinetQuery,
  useAddOrEditSecretaireMutation,
  useGetSecretairesQuery,
  useGetSecretaireByCabinetQuery,
  useLazySecretaireBySlugQuery,
  useDeletePraticienMutation,
  useGetPatientsByCabinetQuery,
  useGetPatientsByMedecinQuery,
  useLazyPatientBySlugQuery,
  useAddOrEditPatientMutation,
  useDeleteSecretaireMutation,
  useLazyGetPatientsByCabinetQuery,
  useLazyGetPraticienByCabinetQuery,
  useLazyAdminBySlugQuery,
  useSearchPatientsQuery,
} = UserApi;

export function useUserFromLocation(): [
  IUser,
  boolean,
  string,
  (id: string) => any
] {
  const itemState = useLocationState<IUser | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { id } = useParams<{ id: string }>();
  const [findById, { data, isError, isLoading }] = useLazyUserByIdQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      findById(id as string);
    }
  }, [id]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      
      setItem(data);
    }
  }, [data]);

  return [item as IUser, isLoading, id as string, findById];
}

export function useCabinetFromLocation(): [
  IUser,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<IUser | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading }] =
    useLazyCabinetBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findBySlug(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      
      setItem(data);
    }
  }, [data]);

  return [item as IUser, isLoading, slug as string, findBySlug];
}

export function usePraticienFromLocation(): [
  IUser,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<IUser | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading }] =
    useLazyPraticienBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findBySlug(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      
      setItem(data);
    }
  }, [data]);

  return [item as IUser, isLoading, slug as string, findBySlug];
}

export function useSecretaireFromLocation(): [
  IUser,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<IUser | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading }] =
    useLazySecretaireBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findBySlug(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      
      setItem(data);
    }
  }, [data]);

  return [item as IUser, isLoading, slug as string, findBySlug];
}

export function usePatientFromLocation(): [
  IUser,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<IUser | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading }] =
    useLazyPatientBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findBySlug(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      
      setItem(data);
    }
  }, [data]);

  return [item as IUser, isLoading, slug as string, findBySlug];
}

export function useAdminFromLocation(): [
  IUser,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<IUser | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading }] = useLazyAdminBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findBySlug(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      
      setItem(data);
    }
  }, [data]);

  return [item as IUser, isLoading, slug as string, findBySlug];
}
