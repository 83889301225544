import React, { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useRoutes } from "react-router-dom";
import { AppRoutes } from "./routes/router";
import { useMeQuery } from "./utils/api/user/user.api";
import { useAppDispatch, useAppSelector } from "./redux/hook";
import { onSetUser } from "./redux/slice/User.slice";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

registerLocale("fr", fr);

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
}

function FetchUser() {
  const { isSuccess, data,isLoading,refetch } = useMeQuery();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((s) => s?.user);

  useEffect(() => {
    if (!isLoading) refetch();
  }, []);
  
  useEffect(() => {
    if (isSuccess) {
      
      if (data?.id === user?.id) {
        dispatch(onSetUser(data));
      }
    }
  }, [isSuccess, data]);

  return null;
}

function App() {
  const isConnected = useAppSelector((s) => !!s.user?.token);
  const routes = useRoutes(AppRoutes);
  

  return (
    <div className="app-routing-container">
      <ScrollToTop />
      {!!isConnected && <FetchUser />}
      {routes}
      <ToastContainer />
    </div>
  );
}

export default App;
