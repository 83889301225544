import { IRdvs } from "../../../../utils/api/rdvs/rdvs.type";
import { useEffect } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Color } from "../../../../utils/theme";
import { cleannerError } from "../../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import {
  Vaccin,
  VaccinFormData,
} from "../../../../utils/api/vaccin/vaccin.type";
import { useAddOrUpdateVaccinMutation } from "../../../../utils/api/vaccin/vaccin.api";

function UseAddVaccinForm(item: IRdvs, vaccin?: Vaccin) {
  const validationSchema = yup.object().shape({
    nom: yup.string().required().label("Le nom").nullable(),
    date: yup.string().required().label("La date").nullable(),
    lot: yup.string().required().label("Le numéro de lot").nullable(),
    date_expiration: yup.string().required().label("La date d'expiration").nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
  } = useForm<VaccinFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [sendData, { isLoading, isSuccess, isError, error }] =
    useAddOrUpdateVaccinMutation();
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `La demande de vaccination a été ${
          !vaccin ? "créée" : "modifiée"
        } avec succès`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        navigate(`/medecin/rendez-vous/${item?.slug}?step=vaccin`, {
          state: item,
        });
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    // console.log("cabinet", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (vaccin?.id) {
      let fields: (keyof VaccinFormData)[] = ["nom", "date", "rdv", "slug", "date_expiration", "lot"];

      for (let field of fields) {
        register(field);
        if (field !== "rdv") {
          setValue(field, vaccin[field]);
        } else {
          if (vaccin[field]) setValue(field, vaccin[field]?.id);
        }
      }
    }
  }, [vaccin]);

  useEffect(() => {
    if (item?.id) {
      setValue("rdv", item?.id);
    }
  }, [item]);

  const onSubmit = async (data: VaccinFormData) => {
    await sendData(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    control,
  };
}

export default UseAddVaccinForm;

export function UseEditStatutVaccin(item: Vaccin) {
  const [sendData] = useAddOrUpdateVaccinMutation();
  const onEdit = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr que ce vaccin ${
        item?.status ? "n'a pas été" : "a été"
      } administré ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData({ status: !item?.status, slug: item?.slug });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le statut du vaccin a été modifié avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onEdit;
}
