import React, { useEffect, useState } from "react";
import "./Table.css";
import doc_icon from "../../../assets/icons/icon-doc.png";
import pdf_icon from "../../../assets/icons/icon-pdf.png";
import { get_url_extension, useUser } from "../../../utils/Utils";
import { useGetDocumentByPatientQuery } from "../../../utils/api/document/document.api";
import { FileCardSkeleton, FileItemCard } from "../../medecin/Tables/AllDetailPatientDossierContent";
import { AlertInfo } from "../../common/Alert";
import { CustomPagination } from "../../common/CustomPagination";


function PatientFichierContent() {
  const user = useUser();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
   const { data = { results: [], count: 0 }, isLoading,refetch } =
     useGetDocumentByPatientQuery({
       slug: user?.slug,
       page,
       limit
     });

     useEffect(() => {
       if (!isLoading) refetch();
     }, []);

  return (
    <>
      <div className="row all-files-container pt-4 ">
        {!!isLoading && <FileCardSkeleton />}
        {!isLoading &&
          (data?.results?.length ? (
            data?.results?.map((file) => (
              <div className="col-md-3" key={file?.id}>
                <FileItemCard
                  icon={
                    get_url_extension(file?.fichier) === "pdf"
                      ? pdf_icon
                      : doc_icon
                  }
                  item={file}
                  //   isActive={file.isActive}
                />
              </div>
            ))
          ) : (
            <AlertInfo message="Aucun fichier trouvé!" />
          ))}
        <div className="col-md-12">
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
              showPerPage={false}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PatientFichierContent;
