import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl, useLocationState } from "../../Utils";
import { Document, DocumentFormData } from "./document.type";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const DocumentApi = createApi({
  reducerPath: "documentApi",
  tagTypes: [
    "document",
    "documentByCabinet",
    "listDocuments",
    "documentByPatient",
    "documentByDossier",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    addOrUpdateDocument: builder.mutation<
      Document,
      { slug?: string; data: DocumentFormData | FormData }
    >({
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `documents/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `documents/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [
        "documentByCabinet",
        "document",
        "listDocuments",
        "documentByPatient",
        "documentByDossier",
      ],
    }),
    getDocumentList: builder.query<PaginationResults<Document>, TypeQuery>({
      query: (query) => QueryUrl(`documents/`, query),
      providesTags: ["listDocuments"],
    }),
    getDocumentBySlug: builder.query<Document, string>({
      query: (slug) => `documents/${slug}/`,
      providesTags: ["document"],
    }),
    getDocumentByCabinet: builder.query<PaginationResults<Document>, TypeQuery>(
      {
        query: ({ slug, ...query }) =>
          QueryUrl(`cabinet/${slug}/documents/`, query),
        providesTags: ["documentByCabinet"],
      }
    ),
    getDocumentByDossier: builder.query<PaginationResults<Document>, TypeQuery>(
      {
        query: ({ slug, ...query }) =>
          QueryUrl(`dossier/${slug}/documents/`, query),
        providesTags: ["documentByDossier"],
      }
    ),
    getDocumentByPatient: builder.query<PaginationResults<Document>, TypeQuery>(
      {
        query: ({ slug, ...query }) =>
          QueryUrl(`patient/${slug}/documents/`, query),
        providesTags: ["documentByPatient"],
      }
    ),
    deleteDocument: builder.mutation<DocumentFormData, string>({
      query: (slug) => ({
        url: `documents/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "document",
        "documentByCabinet",
        "listDocuments",
        "documentByPatient",
        "documentByDossier",
      ],
    }),
  }),
});

export const {
  useAddOrUpdateDocumentMutation,
  useDeleteDocumentMutation,
  useGetDocumentByPatientQuery,
  useGetDocumentByCabinetQuery,
  useGetDocumentByDossierQuery
} = DocumentApi;
