import React from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import { FaCheckCircle, FaListAlt } from "react-icons/fa";
// import { MdLocationPin } from "react-icons/md";
import { useRdvFromLocation } from "../../../utils/api/rdvs/rdvs.api";
import { createMarkup, getName, getStatus } from "../../../utils/Utils";
import { formatDate } from "@fullcalendar/core";
import moment from "moment";

function DetailRdv() {
  const [item] = useRdvFromLocation();

  return (
    <>
      <WrapperComponent>
        <div className="container-title-dash d-flex align-items-center gap-4 pb-4">
          <h2 className="custom-sm-top-title mb-0">
            Mes rendez-vous {" > "}
            <strong>Détail rendez-vous</strong>
          </h2>
        </div>

        <div className="custom-content pt-4 pb-4">
          <div className="light-card">
            <div className="custom-card-header d-flex pb-3">
              <div className="rdv-title-content d-flex align-items-center gap-3">
                <FaCheckCircle className="icon" />
                <div className="custom-title d-flex flex-column gap-2">
                  <span className="demand">
                    Rendez-vous {getStatus(item)?.toLocaleLowerCase()}
                  </span>
                </div>
              </div>
            </div>
            <div className="card-separator"></div>
            <div className="custom-card-content">
              <div className="rdv-location mb-3">
                <span className="cs-title">Lieu:</span> &nbsp;
                <span className="cs-value">{item?.structure?.nom_cabinet}</span>
              </div>
              <div className="rdv-location mb-3">
                <span className="cs-title">Adresse du cabinet:</span> &nbsp;
                <span className="cs-value">
                  {item?.structure?.adresse_cabinet}
                </span>
              </div>
              <div className="rdv-location mb-3">
                <span className="cs-title">Motif:</span> &nbsp;
                <span className="cs-value">{item?.motif}</span>
              </div>
              <div className="person-concerned mb-3">
                <span className="cs-title">Réservé pour: </span> &nbsp;
                <span className="cs-value">{getName(item?.patient)}</span>
              </div>
              <div className="date-and-hour mb-3">
                <span className="cs-title">Date et heure:</span> &nbsp;
                <span className="cs-value">
                  {" "}
                  {formatDate(item?.date)} à{" "}
                  {moment(item?.horaire_de_visite, "HH:mm").format("HH[h]mm")}{" "}
                </span>
              </div>
              <div className="date-and-hour">
                <span className="cs-title">Médecin:</span> &nbsp;
                <span className="cs-value">{getName(item?.medecin)}</span>
              </div>
              {/* <button className="get-location-rdv-content d-flex align-items-center gap-3">
                Envoyer la localisation
                <MdLocationPin />
              </button> */}
            </div>
          </div>
          <div className="instruction-content">
            <div className="title d-flex align-items-center gap-3 pb-4">
              <FaListAlt />
              Instructions à suivre
            </div>
            <div className="intructions-list text-start">
              <p
                dangerouslySetInnerHTML={createMarkup(item?.instructions || "")}
              />
            </div>
          </div>
        </div>
      </WrapperComponent>
    </>
  );
}

export default DetailRdv;
