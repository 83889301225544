import Avatar from "../../../assets/appImages/avatar.png";
import React from "react";
import { RiCheckDoubleFill } from "react-icons/ri";
import { Conversation } from "../../../utils/api/chat/chat.type";
import {
  getAvatar,
  getChatRoute,
  getMsgDate,
  getName,
  useUser,
} from "../../../utils/Utils";
import { NavLink } from "react-router-dom";
import { IUser } from "../../../utils/api/user/user.type";
import Skeleton from "react-loading-skeleton";
import UseDeviceDetect from "../../../utils/detectSize";

export const SommaireSkeleton = () => {
  let isMobile = UseDeviceDetect();
  return (
    <>
      {[...Array(10)]?.map((el, i) => (
        <li className="list-group-item list-group-item-meessage" key={i}>
          <div>
            <div className="bloc-profil-contact">
              <div className="d-flex align-items-center gap-3 w-100">
                <Skeleton className="img-messge-contact" />
                <div className="w-90">
                  <div className="container-header-message-list">
                    <Skeleton
                      className="nom-contact mb-0"
                      width={isMobile ? `40vw` : `10vw`}
                    />
                    <Skeleton
                      className="time-message-contact mb-0"
                      width={isMobile ? `10vw` : `3vw`}
                    />
                  </div>
                  <div className="bloc-message-contact">
                    <div className="w-50">
                      <Skeleton
                        className="contenu-message-contact linkable mb-0"
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        width={isMobile ? `75%` : `100%`}
                      />
                    </div>
                    <div className="">
                      <span className="statut-message-tabs-traite statut-message-tabs-non-traite"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      ))}
    </>
  );
};

function Sommaire({
  item,
  active,
  onClick,
  selectedUser,
}: {
  item: Conversation;
  onClick: () => any;
  active: boolean;
  selectedUser: IUser | null;
}) {
  const user = useUser();
  return (
    <>
      <li
        className={`list-group-item list-group-item-meessage linkable ${
          active ? "active" : ""
        }`}
        key={item?.slug}
        onClick={() => onClick()}
      >
        <div className="no-view-mobile">
          <div className="bloc-profil-contact">
            <div className="d-flex align-items-center gap-3 w-100">
              <img
                src={
                  item?.last_message?.sender?.id === user?.id
                    ? getAvatar(item?.last_message?.receiver?.avatar)
                    : getAvatar(item?.last_message?.sender?.avatar)
                }
                className="img-messge-contact"
                alt={item?.slug}
              />
              <div className="w-90">
                <div className="container-header-message-list">
                  <p className="nom-contact mb-0 active">
                    {item?.last_message?.sender?.id === user?.id
                      ? getName(item?.last_message?.receiver)
                      : getName(item?.last_message?.sender)}
                  </p>
                  <p className="time-message-contact mb-0">
                    {getMsgDate(item?.last_message?.created_at)}
                  </p>
                </div>
                <div className="bloc-message-contact">
                  <div className="w-50">
                    <p
                      className="contenu-message-contact linkable mb-0"
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item?.last_message?.content}
                    </p>
                  </div>
                  <div className="">
                    {item?.messages_received_non_lus > 0 && (
                      <span>
                        <span className="statut-message-tabs-non-lu">
                          {item?.messages_received_non_lus}
                        </span>
                      </span>
                    )}

                    {/* <span className="statut-message-tabs-traite statut-message-tabs-non-traite">
                      <RiCheckDoubleFill />
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="no-view-desktop">
          <NavLink
            className={"no-link"}
            to={getChatRoute(user)}
            state={
              item?.last_message?.sender?.id === user?.id
                ? item?.last_message?.receiver
                : item?.last_message?.sender
            }
          >
            <div className="bloc-profil-contact">
              <div className="d-flex align-items-center gap-3 w-100">
                <img
                  src={
                    item?.last_message?.sender?.id === user?.id
                      ? getAvatar(item?.last_message?.receiver?.avatar)
                      : getAvatar(item?.last_message?.sender?.avatar)
                  }
                  className="img-messge-contact"
                  alt={item?.slug}
                />
                <div className="w-90">
                  <div className="container-header-message-list">
                    <p className="nom-contact mb-0 active">
                      {item?.last_message?.sender?.id === user?.id
                        ? getName(item?.last_message?.receiver)
                        : getName(item?.last_message?.sender)}
                    </p>
                    <p className="time-message-contact mb-0">
                      {getMsgDate(item?.last_message?.created_at)}
                    </p>
                  </div>
                  <div className="bloc-message-contact">
                    <div className="w-50">
                      <p
                        className="contenu-message-contact linkable mb-0"
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item?.last_message?.content}
                      </p>
                    </div>
                    <div className="">
                      {item?.messages_received_non_lus > 0 && (
                        <span>
                          <span className="statut-message-tabs-non-lu">
                            {item?.messages_received_non_lus}
                          </span>
                        </span>
                      )}

                      {/* <span className="statut-message-tabs-traite statut-message-tabs-non-traite">
                      <RiCheckDoubleFill />
                    </span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </NavLink>
        </div>
      </li>
    </>
  );
}

export default Sommaire;
