import React, { useEffect, useState } from "react";
import "./HeaderAdmin.css";
import { NavLink } from "react-router-dom";
import SidebarMobile from "../SidebarAdmin/SidebarMobile";
import "../SidebarAdmin/SidebarAdmin.css";
import { IoNotifications, IoSearch } from "react-icons/io5";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import {
  formatDateHour,
  getAvatar,
  getChatPageRoute,
  getName,
  useUser,
} from "../../../../utils/Utils";
import { getProfil, isMedecin } from "../../../../routes/routerUtils";
import { onlogout } from "../../../../redux/slice/User.slice";
import SearchList from "../../SearchList";
import {
  useNotificationsByUserQuery,
  useUpdateNotificationReadMutation,
} from "../../../../utils/api/notification/notification.api";
import { Notification } from "../../../../utils/api/notification/notification.type";
import { IUser } from "../../../../utils/api/user/user.type";
import socket from "../../../../utils/socket-config";

const HeaderAdmin: React.FC = () => {
  const user = useUser();
  const {
    data = { results: [], count: 0 },
    isLoading,
    refetch,
  } = useNotificationsByUserQuery({
    slug: user?.slug,
  });
  const [search, setSearch] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isLoading) refetch();
  }, []);

  const onLogout = () => {
    dispatch(onlogout());
  };

  // console.log("notif", data);

  return (
    <div className="admin-header-component">
      <nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark">
        <div className="headering-admin">
          <div className="input-group header-custom-input-search">
            {!!isMedecin(user) && (
              <>
                <span className="input-group-text" id="basic-addon1">
                  <IoSearch />
                </span>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Rechercher un patient"
                  aria-label="Rechercher un patient"
                  aria-describedby="basic-addon1"
                  value={search}
                  onChange={(e) => setSearch(e?.target?.value)}
                />
                <SearchList search={search} setSearch={setSearch} />
              </>
            )}
          </div>

          <div className="header-others-items">
            <div className="container-info-user-connect-header">
              <div className="container-menu-header-admin gap-3">
                <div className="dropdown dis-flex">
                  <button
                    className="user-acount-dashboard-header dropdown-toggle btn"
                    type="button"
                    id="dropdownNotificationMenuButton"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="user-acount-dashboard-header-display g-2">
                      <div
                        style={{ position: "relative", overflow: "visible" }}
                      >
                        <IoNotifications className="notif-icon" />

                        {data?.count > 0 ? (
                          <span className="notification-counter">
                            {data?.count}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-custom dropdown-menu-notifications shadow-lg"
                    aria-labelledby="dropdownNotificationMenuButton"
                  >
                    {data?.results?.length ? (
                      data?.results?.map((item, i) => (
                        <NotifItem
                          item={item}
                          key={item?.slug}
                          i={i}
                          count={data?.count}
                        />
                      ))
                    ) : (
                      <div className="dropdown-item">
                        <div
                          style={{
                            fontSize: 13,
                            fontWeight: 500,
                            color: "inherit",
                            whiteSpace: "normal",
                          }}
                        >
                          Aucune notification disponible
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="connected-user-container" id="header-user-admin">
                <div className="connected-user-content ml-auto">
                  <div className="dropdown dis-flex">
                    <button
                      className="user-acount-dashboard-header dropdown-toggle btn"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="user-acount-dashboard-header-display g-2">
                        <img
                          src={getAvatar(user?.avatar)}
                          className="user-avatar"
                          alt="User Avatar"
                        />
                      </div>
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-custom"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <NavLink
                        to={getProfil(user)}
                        className="dropdown-item"
                        style={{ fontSize: 16 }}
                      >
                        {getName(user)}
                      </NavLink>
                      <div className="dropdown-divider"></div>
                      <button
                        type="button"
                        className="dropdown-item"
                        style={{ fontSize: 16 }}
                        onClick={() => onLogout()}
                      >
                        Déconnexion
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hiddenable-nav-mobile-container no-view-desktop">
                <div className="hiddenable-nav-mobile-content">
                  <div className="container-nav-mobile">
                    <SidebarMobile />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default HeaderAdmin;

const getRoute = (item: Notification, user: IUser) => {
  let route = "/";
  let state = item?.data;
  if (item?.notif_type === "messagerie") {
    route = getChatPageRoute(user);
    state = item?.data?.sender;
  }

  return [route, state];
};

const NotifItem = ({
  item,
  i,
  count,
}: {
  item: Notification;
  i: number;
  count: number;
}) => {
  const user = useUser();
  const [updateNotif] = useUpdateNotificationReadMutation();
  const readNotif = async () => {
    await updateNotif({ slug: item?.slug, read: true });
  };
  const [route, state] = getRoute(item, user);
  return (
    <>
      <div className="dropdown-item">
        <NavLink
          to={route}
          state={state}
          className={"no-link"}
          onClick={() => readNotif()}
        >
          <div
            style={{
              fontSize: 13,
              fontWeight: 500,
              color: "inherit",
              whiteSpace: "normal",
            }}
          >
            {item?.content}
          </div>
          <div>
            <small style={{ fontSize: 10 }}>
              {formatDateHour(item?.created_at)}
            </small>
          </div>
        </NavLink>
      </div>
      {i + 1 < count ? <div className="dropdown-divider"></div> : null}
    </>
  );
};
