import React, { useState } from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import { MdOutlineAddBox } from "react-icons/md";
import { NavLink } from "react-router-dom";
import SecretaireMedicalTable from "./SecretaireMedicalTable";

function SecretaireMedical() {
  const [query, setQuery] = useState("");
  return (
    <WrapperComponent>
      <div className="container-title-dash d-flex align-items-center gap-4">
        <div className="custom-sm-top">
          <h2 className="custom-sm-top-title mb-0">Secrétaires médicaux</h2>
          <NavLink
            to={`/cabinet/secretaires/ajouter-secretaire`}
            className="btn custom-add-item-btn"
          >
            <span className="add-icon">
              <MdOutlineAddBox />
            </span>
            Ajouter une secrétaire
          </NavLink>
        </div>
      </div>
      <div className="search-patient-box d-flex align-items-center gap-3 mt-4">
        <input
          type="search"
          name="search_patient"
          id="search_patient"
          placeholder="Entrer un Nom, un numéro d’idenfication ou un email"
          onChange={(e) => setQuery(e?.target?.value)}
        />
        {/* <button className="btn search-submit-patient-btn">
          <span>
            <MdSearch />
          </span>
          Chercher
        </button> */}
      </div>

      <div className="custom-table mt-5">
        <SecretaireMedicalTable q={query} />
      </div>
    </WrapperComponent>
  );
}

export default SecretaireMedical;
